import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'

export default function AllPages(props) {
  const [numPage, setNumPage] = useState(null)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPage(numPages)
  }

  const { pdf } = props

  return (
    <Document
      file={pdf}
      options={{ workerSrc: '/pdf.worker.js' }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPage), (el, index) => (
        <Page
          scale="1.5"
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderMode={'svg'}
        />
      ))}
    </Document>
  )
}
