import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Progress,
  Row,
  Tooltip,
  Upload,
} from 'antd'
import CurrencyInput from 'react-currency-input-field'
import moment from 'moment'
import { FcInfo } from 'react-icons/fc'
import { AiFillDelete } from 'react-icons/ai'

import {
  addArtistGemaNumber,
  getArtistById,
  setMessageDetails,
} from '../Redux/actions/auth'
import {
  deleteSong,
  fileUpload,
  updateSongPrice,
} from '../Redux/actions/uploadPage'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'

import plusIcon from '../../Assets/Images/plus.png'

import styles from '../Login/login.module.css'
import styleUpload from './upload.module.css'
import GemmaModal from '../../components/GemmaModalPopup'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { minimumImageDimensionCoverImage } from '../../constants'
import InsertGemaModal from '../../components/GemmaModalPopup/InsertGemaModal'
import mark from '../../Assets/Images/newWhiteBg.svg'
import { getSinglePrice } from '../../iap-prices'

const { Dragger } = Upload

export default function UploadSingle(props) {
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const dispatch = useDispatch()
  const songs = useSelector(state => state.uploadPage.songs)
  const artistId = JSON.parse(localStorage.getItem('artistId'))
  const [songFileLists, setSongFileLists] = React.useState([])
  const [songDataArray, setSongDataArray] = React.useState([])
  const [imageFiles, setImageFiles] = React.useState([])
  const [imageUrl, setImageUrl] = useState('')
  const [songPrices, setSongPrices] = useState([])
  const [songNameArray, setSongNameArray] = useState([])
  const [hasError, sethasError] = useState(true)
  const [gemmaModalVisible, setGemmaModalVisible] = useState(false)
  const [songNameEmptyError, setSongNameEmptyError] = useState(false)
  const [insertGemaNumber, setInsertGemaNumber] = useState(false)
  const intl = useIntl()
  const userGemaNumber = useSelector(state => state.auth.gemaNumber)

  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      if (response && response.success) {
        hideLoader()
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  // function to handle empty songs list and update the song price and cover images

  const handleEmptyList = () => {
    setGemmaModalVisible(false)
    if (songs.length === 0) {
      dispatch(
        setMessageDetails('error', intl.formatMessage({ id: 'add_one_song' }))
      )
    } else if (songs.length > 0 && songPrices.length !== songs.length) {
      dispatch(
        setMessageDetails('error', intl.formatMessage({ id: 'add_song_price' }))
      )
    } else if (songs.length > 0 && imageFiles.length !== songs.length) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_cover_image' })
        )
      )
    } else if (hasError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'enter_correctly' })
        )
      )
    } else {
      var formdata = new FormData()
      formdata.append('songPrices', JSON.stringify(songPrices))
      for (const data of imageFiles) {
        if (data.file.type === 'image/jpeg' || data.file.type === 'image/png') {
          formdata.append(
            'songImages',
            data.file,
            `${data.file.id}.${data.file.type.split('/')[1]}`
          )
        } else {
          return dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'enter_image_type' })
            )
          )
        }
      }
      showLoader()
      dispatch(updateSongPrice(formdata)).then(response => {
        if (response && response.success) {
          setTimeout(() => {
            props.history.push('/upload')
            hideLoader()
          }, 1000)
          // return;
        }
      })
    }
  }

  const draggerProps = {
    name: 'file',
    multiple: true,
    onChange(event) {
      event.fileList.map(file => (file.status = 'done'))
      if (
        !Object.keys(event).includes('event') &&
        event.file.status === 'done'
      ) {
        if (!songFileLists.some(x => event.file.originFileObj.uid === x.uid)) {
          setSongFileLists([...songFileLists, event.file.originFileObj])
        }

        var audio = document.createElement('audio')

        var reader = new FileReader()
        reader.readAsDataURL(event.file.originFileObj)
        reader.onload = function (e) {
          audio.src = e.target.result
          audio.addEventListener('loadedmetadata', function () {
            let song_duration = moment.duration(
              Math.floor(audio.duration),
              'seconds'
            )
            let minutes = song_duration._data.minutes
            let seconds = song_duration._data.seconds
            seconds = seconds > 9 ? seconds : `0${seconds}`
            var songData = {
              songFile: event.file.originFileObj,
              songDuration: `${minutes}:${seconds}`,
            }

            if (
              !songDataArray.some(x => x.songFile.uid === songData.songFile.uid)
            ) {
              setSongDataArray([...songDataArray, songData])
            }
          })
          audio.addEventListener('error', function (e) {
            dispatch(
              setMessageDetails(
                'error',
                intl.formatMessage({ id: 'song_upload_file_error' })
              )
            )
          })
        }
      }
    },
  }

  // chunk upload functionality

  const [currentFileIndex, setCurrentFileIndex] = useState(null)
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null)
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null)
  const [files, setFiles] = useState([])
  const [songDuration, setSongDuration] = useState([])
  const [songName, setSongName] = useState([])
  const [length, setLength] = useState(0)

  function readAndUploadCurrentChunk() {
    const file = files[currentFileIndex]
    const chunkSize = Math.ceil(file.size / 100)
    const reader = new FileReader()
    if (!file) {
      return
    }
    const from = currentChunkIndex * chunkSize
    const to = from + chunkSize
    const blob = file.slice(from, to)
    reader.onload = e => uploadChunk(e)
    reader.readAsDataURL(blob)
  }

  function uploadChunk(readerEvent) {
    const file = files[currentFileIndex]
    const name = songName[currentFileIndex]
    const duration = songDuration[currentFileIndex]
    const data = readerEvent.target.result
    const params = new URLSearchParams()
    params.set('songName', name)
    params.set('name', file.name)
    params.set('songType', 1)
    params.set('size', file.size)
    params.set('duration', duration)
    params.set('currentChunkIndex', currentChunkIndex)
    params.set('totalChunks', 100)
    dispatch(fileUpload(data, params)).then(response => {
      const currentSongFile = files[currentFileIndex]
      const isLastChunk = currentChunkIndex === 99
      if (isLastChunk) {
        if (response && response.success) {
          currentSongFile.uploaded = response.success
          songPrices.push({
            songId: response.data._id,
            priceValue: getSinglePrice('basic'),
            gemmaProtected: false,
          })
          sethasError(false)
        }
        setLastUploadedFileIndex(currentFileIndex)
        setCurrentChunkIndex(null)
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1)
      }
    })
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return
    }
    const isLastFile = lastUploadedFileIndex === songDataArray.length - 1
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1
    setCurrentFileIndex(nextFileIndex)
    if (lastUploadedFileIndex === songDataArray.length - 1) {
      setLength(0)
      setFiles([])
      setSongDuration([])
      setSongName([])
      setSongFileLists([])
      setSongDataArray([])
      setSongNameArray([])
      setLastUploadedFileIndex(null)
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUploadedFileIndex])

  useEffect(() => {
    if (currentFileIndex !== null) {
      const tempfile = songDataArray[currentFileIndex].songFile
      const tempduration = songDataArray[currentFileIndex].songDuration

      const changedSongName = songNameArray.find(
        obj => obj.songId === songDataArray[currentFileIndex].songFile.uid
      )

      if (changedSongName) {
        const tempname = changedSongName.updateSongName
        setSongName([...songName, tempname])
      } else {
        const tempname =
          songDataArray[currentFileIndex].songFile.name.split('.')[0]
        setSongName([...songName, tempname])
      }
      setFiles([...files, tempfile])
      setSongDuration([...songDuration, tempduration])
      setCurrentChunkIndex(0)
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFileIndex])

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk()
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChunkIndex])

  useEffect(() => {
    if (length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        )
      }
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  const handleSongsUpload = e => {
    if (songDataArray.length === 0) {
      dispatch(
        setMessageDetails('error', intl.formatMessage({ id: 'add_one_song' }))
      )
    } else if (songNameEmptyError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'enter_song_name' })
        )
      )
    } else {
      dispatch(
        setMessageDetails('info', intl.formatMessage({ id: 'uploading_song' }))
      )
      setLength(songDataArray.length)
    }
  }

  const handleGemaStatusPopup = () => {
    console.log('handleGemaStatusPopup')
    const result = songPrices.some(element => element.gemmaProtected)
    const priceNotZero = songPrices.every(element => element.priceValue > 0)
    console.log('priceNotZero :', priceNotZero)
    console.log('result :', result)
    if (priceNotZero) {
      if (result) {
        handleEmptyList()
      } else {
        setGemmaModalVisible(true)
      }
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'zero_price_not_allowed' })
        )
      )
    }
  }

  const handleDeleteSong = id => {
    dispatch(deleteSong(id))
    const newSongPrices = songPrices.filter(song => song.songId !== id)
    const newImageFiles = imageFiles.filter(image => image.songId !== id)
    setSongPrices(newSongPrices)
    setImageFiles(newImageFiles)
  }

  const addSongImages = (file, id) => {
    if (file) {
      const temp = imageFiles.find(obj => obj.songId === id)
      if (!temp) {
        imageFiles.push({ songId: id, file: file })
      } else {
        imageFiles.find(obj => obj.songId === id).file = file
      }
    }
  }

  const handleSongCoverUpload = (event, id) => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      if (event.file.type === 'image/jpeg' || event.file.type === 'image/png') {
        setImageUrl('')
        getBase64(event.file.originFileObj, 'image', id)
        event.file.originFileObj.id = id
      } else {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'enter_image_type' })
          )
        )
      }
    }
  }

  const getBase64 = (file, str, id) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      var image = new Image()
      image.src = e.target.result
      image.onload = function () {
        if (
          this.width > minimumImageDimensionCoverImage &&
          this.height > minimumImageDimensionCoverImage &&
          str === 'image'
        ) {
          dispatch(
            setMessageDetails(
              'info',
              intl.formatMessage({ id: 'uploading_image' })
            )
          )
          addSongImages(file, id)
          songs.map(song => {
            if (song._id === id) {
              song.songImage = reader.result
            }
            return undefined
          })
          setImageUrl(reader.result)
        } else {
          return dispatch(
            setMessageDetails('error', intl.formatMessage({ id: 'image_size' }))
          )
        }
      }
      console.log('imageUrl', imageUrl)
    }
  }

  const singleSongCoverImage = singleSong => {
    if (singleSong.songImage === '') {
      return (
        <Tooltip
          placement="bottom"
          color="#7CD2C6"
          title={intl.formatMessage({ id: 'upload_song_cover_image' })}
        >
          <Upload
            className="uploadSongWithoutcover"
            onChange={e => handleSongCoverUpload(e, singleSong._id)}
          >
            <div
              style={{
                width: '50px',
                height: '50px',
                background: '#ECECEC',
                borderRadius: '12px',
                textAlign: 'center',
              }}
            >
              <img
                style={{ marginTop: '12px', cursor: 'pointer' }}
                src={plusIcon}
                alt="upload SongCover"
              />
            </div>
          </Upload>
        </Tooltip>
      )
    }
    return (
      <Tooltip
        placement="bottom"
        color="#7CD2C6"
        title={intl.formatMessage({ id: 'change_song_cover_image' })}
      >
        <Upload onChange={e => handleSongCoverUpload(e, singleSong._id)}>
          <img
            style={{
              height: '50px',
              width: '50px',
              borderRadius: '12px',
              marginLeft: '16px',
              cursor: 'pointer',
            }}
            src={singleSong.songImage}
            alt="songCover"
          />
        </Upload>
      </Tooltip>
    )
  }

  const uploadButton = song => {
    if (song.songImage === '') {
      return (
        <Tooltip placement="bottom" color="#7CD2C6" title="upload SongCover">
          <Upload
            className="uploadSongWithoutcover"
            onChange={e => handleSongCoverUpload(e, song._id)}
          >
            <div
              style={{
                width: '32px',
                height: '32px',
                background: '#ECECEC',
                borderRadius: '12px',
              }}
            >
              <img
                style={{ marginTop: '4px' }}
                src={plusIcon}
                alt="upload SongCover"
              />
            </div>
          </Upload>
        </Tooltip>
      )
    } else {
      ;<Upload onChange={e => handleSongCoverUpload(e, song._id)}>
        <img
          style={{
            height: '32px',
            width: '32px',
            borderRadius: '12px',
            marginLeft: '4px',
          }}
          src={song.songImage}
          alt="songCover"
        />
      </Upload>
    }
  }

  const changeSongName = (name, song) => {
    const newSongName = name.trim()
    if (newSongName) {
      setSongNameEmptyError(false)
      const temp = songNameArray.find(obj => obj.songId === song.uid)
      if (!temp) {
        songNameArray.push({ songId: song.uid, updateSongName: newSongName })
      } else {
        songNameArray.find(obj => obj.songId === song.uid).updateSongName =
          newSongName
      }
    } else {
      songNameArray.find(obj => obj.songId === song.uid).updateSongName =
        newSongName
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'song_name_cant_empty' })
        )
      )
      setSongNameEmptyError(true)
    }
  }

  const handleGemaStatusChange = (value, id) => {
    console.log('handleGemaStatusChange')
    let changed = false
    const newSongPrices = songPrices.map(x => {
      if (x.songId === id) {
        changed = true
        return {
          ...x,
          gemmaProtected: value,
        }
      } else {
        return x
      }
    })
    console.log(changed)
    if (!changed) {
      newSongPrices.push({ songId: id, gemmaProtected: value })
    }
    setSongPrices(newSongPrices)
    if (
      value === true &&
      (userGemaNumber === undefined ||
        userGemaNumber === null ||
        userGemaNumber === '')
    ) {
      setInsertGemaNumber(true)
    } else {
      setInsertGemaNumber(false)
    }
  }

  const resetGemaStatus = () => {
    const newSongPrices = songPrices.map(x => ({
      ...x,
      gemmaProtected: false,
    }))
    setSongPrices(newSongPrices)
    setInsertGemaNumber(false)
  }

  const updateGemaNumber = gemaNumber => {
    const data = {
      artistId: artistId,
      gemaNumber: gemaNumber,
    }
    dispatch(addArtistGemaNumber(data)).then(response => {
      if (response && response.success) {
        dispatch(getArtistById({ id: artistId }))
      }
    })
    setInsertGemaNumber(false)
  }

  return (
    <div>
      <div style={{ padding: '70px 0 0 0' }} className={styles.mainDiv}>
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            padding: '40px 30px 90px',
          }}
        >
          <Row style={{ height: '100%' }} gutter={[32, 32]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div
                style={{
                  backgroundColor: '#fff',
                  borderRadius: 12,
                  boxShadow: '2px 1px 9px #e0e0e0',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <img
                  alt="mark"
                  style={{
                    position: 'absolute',
                    width: 400,
                    bottom: '-12%',
                    right: '-10%',
                  }}
                  src={mark}
                />
                <div
                  style={{ height: '100%', zIndex: 2 }}
                  className={styleUpload.cardDiv}
                >
                  <div style={{ padding: '40px 24px', zIndex: 2 }}>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          zIndex: 2,
                        }}
                      >
                        <div className={styleUpload.selectedPageCount}>1</div>
                        <div
                          className={
                            songs.length > 0
                              ? styleUpload.selectedPageCount
                              : styleUpload.UnselectedPageCount
                          }
                        >
                          2
                        </div>
                      </div>
                      <div style={{ marginTop: '50px' }}>
                        <label
                          style={{
                            color: '#232324',
                            fontSize: '24px',
                            opacity: '0.9',
                          }}
                        >
                          <b>
                            <TranslateText id="upload_songs_to_profile" />
                          </b>
                          <br />
                          <br />
                        </label>
                        <div
                          style={{ maxWidth: '448px', margin: '15px auto 0' }}
                        >
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="upload_single_song_heading" />
                          </label>
                          <br />
                          <br />
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="upload_single_song_heading_description" />
                          </label>
                          <br />
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                            }}
                          >
                            <TranslateText id="upload_single_song_heading_description_content" />
                          </label>
                          <br />
                          <br />
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="upload_single_song_heading_description_content_two" />
                          </label>
                        </div>
                        <div>
                          <Form {...layout} name="basic" layout={'vertical'}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Form.Item>
                                <Button
                                  disabled={songs.length <= 0}
                                  style={{
                                    marginTop: '50px',
                                    width: '240px',
                                  }}
                                  htmlType="submit"
                                  className={
                                    songs.length > 0 && length === 0
                                      ? styles.greenColorBtn
                                      : styles.disableColorBtn
                                  }
                                  onClick={() => {
                                    handleGemaStatusPopup()
                                  }}
                                >
                                  <TranslateText id="done" />
                                </Button>
                              </Form.Item>
                              <GemmaModal
                                type="song"
                                show={gemmaModalVisible}
                                handleOk={() => {
                                  handleEmptyList()
                                }}
                                handleCancel={() => {
                                  setGemmaModalVisible(false)
                                }}
                                closePopup={() => setGemmaModalVisible(false)}
                              />
                            </div>
                          </Form>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label
                              style={{
                                color: '#232324',
                                opacity: '1',
                                position: 'relative',
                                cursor: 'pointer',
                              }}
                            >
                              <span
                                style={{
                                  color: '#7CD2C6',
                                  cursor: 'pointer',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => {
                                  props.history.push('/upload')
                                }}
                              >
                                <TranslateText id="back" />
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} style={{ height: '100%' }}>
              <div className={styleUpload.cardDiv2}>
                <Form name="basic" {...layout} layout={'vertical'}>
                  <div style={{ width: '100%', height: '231px' }}>
                    <Dragger
                      {...draggerProps}
                      style={{
                        border: '1px dashed #7CD2C6',
                        borderRadius: '12px',
                        background: '#F2FBF9 0% 0% no-repeat padding-box',
                      }}
                    >
                      <p
                        className="ant-upload-text"
                        style={{ color: '#7CD2C6', fontSize: '14px' }}
                      >
                        <TranslateText id="click_upload_songs" />
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ maxHeight: '370px', overflow: 'auto' }}>
                    {songDataArray.length > 0 &&
                      songDataArray.map((song, fileIndex) => {
                        let progress = 0
                        if (song.songFile.uploaded) {
                          progress = 100
                        } else {
                          const uploading = fileIndex === currentFileIndex
                          if (uploading) {
                            progress = Math.round(currentChunkIndex)
                          }
                        }
                        return (
                          <div
                            key={song.songFile.uid}
                            style={{ padding: '8px', textAlign: 'left' }}
                            className={styleUpload.UploadedlistDiv}
                          >
                            <span>
                              {fileIndex + 1}
                              <Input
                                className="file"
                                onChange={e =>
                                  changeSongName(e.target.value, song.songFile)
                                }
                                style={{
                                  border: '1px solid #7CD2C6',
                                  background: '#FFFFFF',
                                  color: '#303135',
                                  width: 'calc(100% - 90px)',
                                  height: '40px',
                                  margin: '4px 16px 0',
                                }}
                                defaultValue={song.songFile.name}
                              />
                              {length > 0 ? (
                                <Progress
                                  style={{ marginBottom: '4px' }}
                                  type="circle"
                                  percent={progress}
                                  width={40}
                                />
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '45px',
                    }}
                  >
                    <Form.Item>
                      <Button
                        className={styleUpload.greenColorBtn}
                        style={{ width: '240px' }}
                        onClick={() => {
                          handleSongsUpload()
                        }}
                        htmlType="submit"
                      >
                        <TranslateText id="upload_action" />
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div style={{ maxHeight: '350px', overflow: 'auto' }}>
                  {songs.length > 0 &&
                    songs.map((song, index) => (
                      <div
                        key={song._id}
                        className={styleUpload.UploadedlistDiv}
                        style={{
                          padding: '8px',
                          height: '90px',
                          textAlign: 'left',
                        }}
                      >
                        {window.innerWidth < 600 ? (
                          <span>
                            {index + 1}
                            {uploadButton(song)}
                            <Input
                              style={{
                                border: '1px solid #7CD2C6',
                                color: '#303135',
                                width: 'calc(100% - 160px)',
                                height: '40px',
                                borderRightStyle: 'none',
                                marginLeft: '8px',
                                marginRight: '-6px',
                                marginTop: '4px',
                                background: '#FFFFFF',
                                cursor: 'default',
                                padding: '4px',
                              }}
                              value={song.songName}
                              disabled
                            />
                            <CurrencyInput
                              style={{
                                width: '40px',
                                height: '40px',
                                marginRight: '4px',
                                padding: '4px',
                              }}
                              className="ant-input"
                              name="currency"
                              intlConfig={{
                                locale: 'de-DE',
                                currency: 'EUR',
                              }}
                              allowNegativeValue={false}
                              placeholder={intl.formatMessage({
                                id: 'price',
                              })}
                              decimalsLimit={2}
                              value={getSinglePrice('basic')}
                              disabled
                              disableGroupSeparators={true}
                            />
                            <label
                              style={{
                                padding: '4px',
                                fontSize: '14px',
                                color: '#303135',
                              }}
                            >
                              {song.songDuration}
                            </label>
                            <span
                              style={{
                                padding: '10px',
                                verticalAlign: 'middle',
                              }}
                            >
                              <Popconfirm
                                title={<TranslateText id="are_you_sure" />}
                                okText={<TranslateText id="delete" />}
                                cancelText={<TranslateText id="no" />}
                                onConfirm={() => {
                                  handleDeleteSong(song._id)
                                }}
                              >
                                <AiFillDelete
                                  style={{
                                    alignSelf: 'center',
                                    fontSize: '28px',
                                    cursor: 'pointer',
                                    marginRight: '4px',
                                  }}
                                />
                              </Popconfirm>
                            </span>
                          </span>
                        ) : (
                          <span>
                            {index + 1}
                            {singleSongCoverImage(song)}

                            <Input
                              style={{
                                border: '1px solid #7CD2C6',
                                color: '#303135',
                                width: 'calc(100% - 280px)',
                                height: '40px',
                                borderRightStyle: 'none',
                                marginLeft: '20px',
                                marginRight: '-6px',
                                marginTop: '4px',
                                background: '#FFFFFF',
                                cursor: 'default',
                              }}
                              value={song.songName}
                              disabled
                            />
                            <CurrencyInput
                              style={{
                                width: '67px',
                                height: '40px',
                                marginRight: '24px',
                                background: '#FFFFFF',
                                color: '#303135',
                              }}
                              className="ant-input"
                              name="currency"
                              intlConfig={{
                                locale: 'de-DE',
                                currency: 'EUR',
                              }}
                              allowNegativeValue={false}
                              defaultValue={0}
                              value={getSinglePrice('basic')}
                              disabled
                              placeholder={intl.formatMessage({
                                id: 'price',
                              })}
                              disableGroupSeparators={true}
                            />
                            <label
                              style={{
                                color: '#303135',
                                padding: '4px',
                                fontSize: '16px',
                              }}
                            >
                              {song.songDuration}
                            </label>
                            <span
                              style={{
                                padding: '10px',
                                verticalAlign: 'middle',
                              }}
                            >
                              <Popconfirm
                                style={{
                                  marginRight: '10px',
                                }}
                                title={<TranslateText id="are_you_sure" />}
                                okText={<TranslateText id="delete" />}
                                cancelText={<TranslateText id="no" />}
                                onConfirm={() => {
                                  handleDeleteSong(song._id)
                                }}
                              >
                                <AiFillDelete
                                  style={{
                                    alignSelf: 'center',
                                    fontSize: '22px',
                                    cursor: 'pointer',
                                    marginLeft: '15px',
                                  }}
                                />
                              </Popconfirm>
                            </span>
                          </span>
                        )}
                        <div
                          style={{
                            textAlign: 'right',
                            marginTop: '-4px',
                            width: 'calc(100% - 18px)',
                          }}
                        >
                          <span
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip
                              placement="bottom"
                              color="#7CD2C6"
                              title={intl.formatMessage({ id: 'gema_info' })}
                            >
                              <FcInfo
                                style={{
                                  fontSize: '18px',
                                  cursor: 'pointer',
                                  marginRight: '4px',
                                }}
                              />
                            </Tooltip>
                            <span style={{ marginRight: '12px' }}>
                              <TranslateText id="is_song_gema_protected" />
                            </span>
                            <span>
                              {' '}
                              <Checkbox
                                checked={
                                  songPrices.find(x => x.songId === song._id)
                                    ?.gemmaProtected ?? false
                                }
                                onChange={e =>
                                  handleGemaStatusChange(
                                    e.target.checked,
                                    song._id
                                  )
                                }
                              />{' '}
                            </span>
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <InsertGemaModal
          visible={insertGemaNumber}
          onCancel={() => {
            props.closePopup()
            resetGemaStatus()
          }}
          updateGemaNumber={updateGemaNumber}
          resetGemaStatus={resetGemaStatus}
        />
      </div>
      {loader}
    </div>
  )
}
