import { AuthType, SET_MESSAGE_DETAILS } from '../types'
import { plactronUrl } from '../../../services/api'
import { API_POST, API_PUT, API_GET } from '../../../services/ApiMethods'
import { GLOBALCONFIG, CHATBOTCONFIG } from '../../../services/Config'

// Common Codes

const postCall = (url, LOGINCONFIG, data) => {
  return async dispatch => {
    try {
      const result = await API_POST(url, data, LOGINCONFIG)
      console.log(result, 'Result Data')
      if (result && result?.success)
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
      else
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Artist Registration

export const signUp = (obj, flag) => {
  const url = `${plactronUrl.SIGN_UP} `
  const LOGINCONFIG = flag ? CHATBOTCONFIG() : GLOBALCONFIG()

  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      console.log(result, 'result')
      if (result && result?.success) {
        dispatch({
          type: AuthType.SIGN_UP_SUCCESS,
          payload: result,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: error.response.data.message },
        })
      }
    }
  }
}

// Artist email verify

export const emailVerify = email => {
  const url = `${plactronUrl.EMAIL_VERIFY}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, email, LOGINCONFIG)
      if (result && result?.success) {
        console.log(result, 'result')
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: error.response.data.message },
        })
      }
    }
  }
}

// Artist Login

export const login = obj => {
  const url = `${plactronUrl.LOGIN} `
  const LOGINCONFIG = GLOBALCONFIG()
  console.log(LOGINCONFIG, 'Login Details')
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      console.log(result, 'result')
      if (result && result?.success) {
        if (result?.data && result?.data.token) {
          localStorage.setItem('token', JSON.stringify(result?.data.token))
          localStorage.setItem(
            'artistId',
            JSON.stringify(result?.data.artist._id)
          )
          dispatch({
            type: AuthType.LOGIN_SUCCESS,
            payload: result,
          })
        }
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: error.response.data.message },
        })
      }
    }
  }
}

// Artist Change Password Request

export const changePasswordRequest = obj => {
  const url = `${plactronUrl.CHANGE_PASSWORD_REQUEST} `
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      console.log(result, 'result')
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Artist Password Change

export const artistPasswordChange = values => {
  const url = `${plactronUrl.ARTIST_PASSWORD_CHANGE}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, values)
}

// Artist Detail by Artist ID

export const getArtistById = data => {
  const url = `${plactronUrl.GET_ARTIST}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, data, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: AuthType.ARTIST_DETAIL_SUCESS,
          payload: result?.data,
        })
        return result
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

// Update Artist Profile Pic

export const updateProfilePic = body => {
  const url = `${plactronUrl.UPDATE_PROFILE_PIC} `
  const LOGINCONFIG = CHATBOTCONFIG()
  return postCall(url, LOGINCONFIG, body)
}

// Update About You and Profile Name
export const updateArtistProfileAboutDetails = (data, id) => {
  const url = `${plactronUrl.UPDATE_ABOUT}/${id}`
  const LOGINCONFIG = GLOBALCONFIG()
  console.log('data', data)
  return async dispatch => {
    try {
      const result = await API_PUT(url, data, LOGINCONFIG)
      console.log(result)
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Update Artist Contact Details

export const updateArtistContactDetails = (data, id) => {
  const url = `${plactronUrl.UPDATE_CONTACT_DETAILS}/${id}`
  const LOGINCONFIG = GLOBALCONFIG()
  console.log('data', data)
  return async dispatch => {
    try {
      const result = await API_PUT(url, data, LOGINCONFIG)
      console.log(result)
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Update Subscription Management

export const updateSubscriptionManagementDetails = (data, id) => {
  const url = `${plactronUrl.UPDATE_ARTIST_SUBSCRIPTION_MANAGEMENT}/${id}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_PUT(url, data, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
        dispatch({
          type: AuthType.CHANGED_SUBSCRIPTION_STATUS,
          payload: data.enable,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// GET Artist Total Follower Count

export const getArtistFollowerCount = () => {
  const url = `${plactronUrl.ARTIST_FOLLOW_COUNT}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_GET(url, LOGINCONFIG)
      console.log(result)
      if (result && result?.success) {
        dispatch({
          type: AuthType.ARTIST_FOLLOWER_COUNT,
          payload: result?.data,
        })
        return result
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getArtistSuperFanFollowerCount = () => {
  const url = `${plactronUrl.SUPERFAN_ARTIST_FOLLOW_COUNT}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_GET(url, LOGINCONFIG)
      console.log(result)
      if (result && result?.success) {
        dispatch({
          type: AuthType.SUPERFAN_ARTIST_FOLLOWER_COUNT,
          payload: result?.data,
        })
        return result
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

// Artist sign Up

export const artistSignUp = data => {
  const url = `${plactronUrl.ARTIST_SIGN_UP}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}

// Artist email verification with code

export const artistEmailVerification = data => {
  const url = `${plactronUrl.ARTIST_EMAIL_VERIFICATION}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}

// Artist update profile data

export const updateArtistProfileData = (data, flag) => {
  const url = `${plactronUrl.UPDATE_ARTIST_PROFILE_DATA}`
  const LOGINCONFIG = flag ? CHATBOTCONFIG() : GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}

// artist resend email verification code

export const resendEmailVerificationCode = data => {
  const url = `${plactronUrl.RESEND_EMAIL_VERIFICATION_CODE}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}

// Change Language

export const changeLanguage = language => {
  return async dispatch => {
    try {
      dispatch({
        type: AuthType.CHANGE_LANGUAGE,
        payload: language,
      })
      localStorage.setItem('language', JSON.stringify(language))
    } catch (error) {
      console.log(error)
    }
  }
}

// Update Popup Message and Status

export const setMessageDetails = (
  status = '',
  message = '',
  infoButtonText = '',
  infoButtonAction = () => {}
) => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_MESSAGE_DETAILS,
        payload: {
          status: status,
          message: message,
          infoButtonText: infoButtonText,
          infoButtonAction: infoButtonAction,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
}

// Update Entrepreneur status

export const updateEntrepreneurStatus = (id, data) => {
  const url = `${plactronUrl.UPDATE_ENTREPRENEUR_STATUS}/${id}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_PUT(url, data, LOGINCONFIG)
      console.log(result)
      if (result && result?.success) {
        dispatch({
          type: AuthType.UPDATE_ENTREPRENEUR_STATUS,
          payload: result?.data,
        })
        return result
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

// Artist Contact Number Verify

export const artistContactVerification = data => {
  const url = `${plactronUrl.ARTIST_CONTACT_VERIFICATION}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}

// Artist resend contact number verification

export const resendContactNumberVerificationOtp = data => {
  console.log('data', data)
  const url = `${plactronUrl.RESEND_CONTACT_VERIFICATION_OTP}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}

// Add Artist Account Details

export const addArtistAccountDetails = data => {
  const url = `${plactronUrl.ADD_ARTIST_ACCOUNT_DETAILS}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}

export const addArtistGemaNumber = data => {
  const url = `${plactronUrl.ADD_ARTIST_GEMA_NUMBER}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCall(url, LOGINCONFIG, data)
}
