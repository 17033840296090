import { LinearProgress, makeStyles } from '@material-ui/core'

export const LoadingIndicator = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#def4f1',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#7cd2c6',
    },
  },
}))
