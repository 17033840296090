import React, { useEffect, useState } from 'react'
import styles from '../Login/login.module.css'
import styleUpload from './upload.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { editSingleSongById } from '../Redux/actions/uploadPage'
import CurrencyInput from 'react-currency-input-field'
import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import { Redirect } from 'react-router-dom'
import devConfig from '../../services/index'
import { Input, Button, Row, Col, Upload } from 'antd'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { getArtistById, setMessageDetails } from '../Redux/actions/auth'
import {
  cloudFrontImageSize,
  minimumImageDimensionCoverImage,
} from '../../constants'
import { getSinglePrice } from '../../iap-prices'

const { Dragger } = Upload

export default function EditSingleSong(props) {
  const dispatch = useDispatch()
  const intl = useIntl()
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const [songName, setSongName] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [imageFile, setImageFile] = useState()
  const [songNameEmptyError, setSongNameEmptyError] = useState(false)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const songId = useSelector(state => state.uploadPage.singleSongId)
  const songOldPrice = useSelector(state => state.uploadPage.price)
  const songOldName = useSelector(state => state.uploadPage.singleSongName)
  const singleSong = useSelector(state => state.uploadPage.selectedSongs)
  const songOldCoverImage = useSelector(
    state => state.uploadPage.singleSongImage
  )

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      if (response && response.success) {
        hideLoader()
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const updateSingleSongRequest = (data, flag) => {
    dispatch(editSingleSongById(songId, data, flag)).then(response => {
      if (response && response.success) {
        hideLoader()
        props.history.push('/upload')
      }
    })
  }

  const updateSingleSongDetails = () => {
    let updatedSongName
    let values = {}
    var formdata = new FormData()

    if (songNameEmptyError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'song_name_cant_empty' })
        )
      )
    } else {
      if (songName) {
        updatedSongName = songName
      } else {
        updatedSongName = songOldName
      }
      showLoader()
      if (imageFile) {
        formdata.append('priceValue', songOldPrice)
        formdata.append('songName', updatedSongName)
        formdata.append('songImage', imageFile)

        updateSingleSongRequest(formdata, true)
      } else {
        values.songName = updatedSongName
        values.songImage = songOldCoverImage
        values.priceValue = songOldPrice

        updateSingleSongRequest(values, false)
      }
    }
  }

  const handleImageUpload = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      if (event.file.type === 'image/jpeg' || event.file.type === 'image/png') {
        getBase64(event.file.originFileObj)
      } else {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'upload_png_or_jpg' })
          )
        )
      }
    }
  }

  const getBase64 = file => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      var image = new Image()
      image.src = e.target.result
      image.onload = function () {
        // hideLoader()
        if (
          this.width >= minimumImageDimensionCoverImage &&
          this.height >= minimumImageDimensionCoverImage
        ) {
          dispatch(
            setMessageDetails(
              'info',
              intl.formatMessage({ id: 'uploading_song' })
            )
          )
          singleSong.songImage = ''
          setImageFile(file)
          setImageUrl(reader.result)
        } else {
          return dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'image_is_too_small' })
            )
          )
        }
      }
    }
  }

  const changeSongName = name => {
    const newSongName = name.trim()

    if (newSongName.length > 0) {
      singleSong.songName = newSongName
      setSongNameEmptyError(false)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'song_name_cant_empty' })
        )
      )
      singleSong.songName = newSongName
      setSongNameEmptyError(true)
    }
    setSongName(singleSong.songName)
  }

  const singleSongCoverImage = () => {
    if (singleSong.songImage === '') {
      return (
        <img
          style={{
            height: '50px',
            width: '50px',
            borderRadius: '12px',
            marginLeft: '16px',
          }}
          src={imageUrl}
          alt="songCover"
        />
      )
    }
    return (
      <img
        style={{
          height: '50px',
          width: '50px',
          borderRadius: '12px',
          marginLeft: '16px',
        }}
        src={devConfig.IMAGE_URL + cloudFrontImageSize + singleSong.songImage}
        alt="songCover"
      />
    )
  }

  return (
    <div>
      {songId === '' ? (
        <Redirect
          to={{
            pathname: '/upload',
          }}
        />
      ) : (
        <div style={{ padding: '70px 0 0 0' }} className={styles.mainDiv}>
          <div
            style={{
              height: '100%',
              overflow: 'auto',
              padding: '40px 30px 90px',
            }}
          >
            <Row style={{ height: '100%' }} gutter={[32, 32]}>
              <Col xs={24} lg={12}>
                <div style={{ height: '100%' }} className={styleUpload.cardDiv}>
                  <div style={{ paddingTop: '88px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className={styleUpload.selectedPageCount}>1</div>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                      <label
                        style={{
                          fontSize: '24px',
                          color: '#232324',
                          opacity: '1',
                        }}
                      >
                        <b>
                          <TranslateText id="edit_songs_profile" />
                        </b>
                      </label>
                      <div
                        style={{ margin: '15px auto 0', width: '73%' }}
                      ></div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '50px',
                        }}
                      >
                        <Button
                          style={{
                            marginTop: '248px',
                            width: '200px',
                            textTransform: 'uppercase',
                          }}
                          className={styleUpload.greenColorBtn}
                          onClick={() => {
                            updateSingleSongDetails()
                          }}
                        >
                          <TranslateText id="save" />
                        </Button>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '24px',
                        }}
                      >
                        <label>
                          <span
                            style={{
                              color: '#7CD2C6',
                              cursor: 'pointer',
                              textTransform: 'uppercase',
                            }}
                            onClick={() => {
                              props.history.push('/upload')
                            }}
                          >
                            <TranslateText id="cancel" />
                          </span>{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <div
                  className={styleUpload.cardDiv2}
                  style={{ height: '231px' }}
                >
                  <Dragger
                    style={{
                      background: '#F2FBF9 0% 0% no-repeat padding-box',
                      border: '1px dashed #7CD2C6',
                      borderRadius: '12px',
                    }}
                    onChange={handleImageUpload}
                  >
                    <p
                      className="ant-upload-text"
                      style={{ color: '#7CD2C6', fontSize: '14px' }}
                    >
                      <TranslateText id="drop_song_cover_image" />
                    </p>
                    <p
                      className="ant-upload-hint"
                      style={{ color: '#7CD2C6', fontSize: '12px' }}
                    >
                      <TranslateText id="song_cover_image_file_size" />
                    </p>
                  </Dragger>
                  <div
                    key={singleSong._id}
                    className={styleUpload.UploadedlistDiv}
                    style={{ padding: '8px 0px' }}
                  >
                    <span style={{ marginLeft: '-16px' }}>
                      {1}
                      {singleSongCoverImage()}
                      <Input
                        style={{
                          width: 'calc(100% - 200px)',
                          height: '40px',
                          borderRightStyle: 'none',
                          marginLeft: '20px',
                          marginRight: '-6px',
                          marginTop: '4px',
                        }}
                        defaultValue={singleSong.songName}
                        placeholder={intl.formatMessage({ id: 'song_name' })}
                        maxLength="50"
                        onChange={e => {
                          changeSongName(e.target.value)
                        }}
                      />
                      <CurrencyInput
                        style={{
                          width: '67px',
                          background: '#FFFFFF',
                          color: '#303135',
                          height: '40px',
                        }}
                        defaultValue={getSinglePrice('basic')}
                        value={getSinglePrice('basic')}
                        allowNegativeValue={false}
                        disabled
                        className="ant-input"
                        placeholder={intl.formatMessage({ id: 'price' })}
                        intlConfig={{ locale: 'de-DE', currency: 'EUR' }}
                        disableGroupSeparators={true}
                      />
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {loader}
    </div>
  )
}
