import React, { useState } from 'react'
import { Form, Input, Button, Upload, Dropdown, Menu } from 'antd'
import styles from '../../containers/Login/login.module.css'

import editIcon from '../../Assets/Images/editIcon.svg'
import greenDot from '../../Assets/Images/greenDot.svg'
import uploadPic from '../../Assets/Images/user.png'
import CropImageModalPopup from './cropImageModalPopup'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { setMessageDetails } from '../../containers/Redux/actions/auth'
import { useDispatch } from 'react-redux'
import { minimumImageDimension } from '../../constants'

export default function UploadProfilePicScreen({
  btnDisabled,
  handleDisableBtn,
  handleImageFile,
  handleStoreDetails,
}) {
  const [imageUrl, setImageUrl] = useState('')
  const [fileName, setFileName] = useState()
  const [fileUrl, setFileUrl] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [croppedImageFile, setCroppedImageFile] = useState('')
  const [recentImageUrl, setRecentImageUrl] = useState('')
  const [recentFileImage, setRecentFileImage] = useState('')
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleSignUp = values => {
    delete values.upload
    handleStoreDetails(values.nickName)
  }

  const uploadButton = (
    <div
      style={{
        background: '#F2FBF9',
        borderRadius: '50px',
        width: '100px',
        display: 'flex',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '8px',
      }}
    >
      {imageUrl === '' ? (
        <img className="cursor" src={uploadPic} alt="uploadIcon" />
      ) : (
        <img
          style={{
            height: '100px',
            width: '100px',
            borderRadius: '50px',
            cursor: 'pointer',
          }}
          src={imageUrl}
          alt="profileImage"
        />
      )}
    </div>
  )

  const handleProfilePicUpload = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      if (event.file.type === 'image/jpeg' || event.file.type === 'image/png') {
        getBase64(event.file.originFileObj, true)
      } else {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'upload_png_or_jpg' })
          )
        )
      }
    }
  }

  const getBase64 = (file, imageFirstTimeUploaded) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      var image = new Image()
      image.src = e.target.result
      image.onload = function () {
        if (
          this.width > minimumImageDimension &&
          this.height > minimumImageDimension
        ) {
          dispatch(
            setMessageDetails(
              'info',
              intl.formatMessage({ id: 'uploading_image' })
            )
          )
          setImageUrl(reader.result)
          handleImageFile(file)
          setFileName(file.name)
          if (imageFirstTimeUploaded) {
            setIsModalVisible(true)
          }
        } else {
          dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'image_is_too_small' })
            )
          )
          setImageUrl('')
          handleImageFile('')
          handleDisableBtn(true)
        }
      }
    }
  }

  const editImage = () => {
    setIsModalVisible(true)
  }

  const removeImage = () => {
    handleDisableBtn(true)
    setImageUrl('')
  }

  const profileMenu = (
    <Menu>
      <Menu.Item key="0" onClick={editImage}>
        <TranslateText id="crop_image" />
      </Menu.Item>
      <Menu.Item key="1" onClick={removeImage}>
        <TranslateText id="remove_image" />
      </Menu.Item>
    </Menu>
  )

  const dataURLtoFile = dataUrl => {
    let arr = dataUrl.split(','),
      decodedBase64String = atob(arr[1]),
      imageType = arr[0].match(/:(.*?);/)[1],
      n = decodedBase64String.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = decodedBase64String.charCodeAt(n)
    }
    let croppedProfileImage = new File([u8arr], fileName, { type: imageType })
    setCroppedImageFile(croppedProfileImage)
    setFileUrl(dataUrl)
  }

  const handleOk = () => {
    handleImageFile(croppedImageFile)
    getBase64(croppedImageFile, false)
    setRecentImageUrl(fileUrl)
    setRecentFileImage(croppedImageFile)
    handleDisableBtn(false)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setImageUrl(recentImageUrl)
    handleImageFile(recentFileImage)
  }

  const CurrentStep = () => {
    if (window.location.pathname === '/signup') {
      return (
        <div style={{ marginTop: '12px', opacity: 0.9 }}>
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img src={greenDot} alt="greenDot" />
        </div>
      )
    }
    return ''
  }

  return (
    <>
      <div>
        <label style={{ color: '#232324', fontSize: '24px', opacity: '0.9' }}>
          <b>
            <TranslateText id="create_account" />
          </b>
        </label>
      </div>
      <div
        style={{ marginTop: '70px', display: 'flex', justifyContent: 'center' }}
      >
        <Form
          onFinish={handleSignUp}
          name="basic"
          validateTrigger="onFinish"
          layout={'vertical'}
        >
          <Form.Item style={{ marginBottom: '37px' }}>
            <Upload onChange={handleProfilePicUpload}>
              {uploadButton}
              <TranslateText id="upload_image" />
            </Upload>
            <CropImageModalPopup
              imageUrl={imageUrl}
              showModal={isModalVisible}
              handleDataURLtoFile={dataUrl => dataURLtoFile(dataUrl)}
              handleOk={() => handleOk()}
              handleCancel={() => handleCancel()}
            />

            {btnDisabled ? (
              ''
            ) : (
              <Dropdown overlay={profileMenu} trigger={['click']}>
                <img
                  src={editIcon}
                  alt="editIcon"
                  style={{ height: '32px', cursor: 'pointer' }}
                />
              </Dropdown>
            )}
          </Form.Item>
          <Form.Item
            style={{ marginBottom: '0px', height: '100px' }}
            label=""
            name="nickName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: intl.formatMessage({ id: 'enter_your_page_name' }),
              },
            ]}
          >
            <Input
              maxLength="40"
              placeholder={intl.formatMessage({ id: 'my_page_name' })}
              style={{ width: '315px', height: '47px', borderRadius: '4px' }}
            />
          </Form.Item>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '4px',
              }}
            >
              <Button
                disabled={btnDisabled}
                htmlType="submit"
                className={
                  btnDisabled ? styles.disableColorBtn : styles.greenColorBtn
                }
              >
                <TranslateText id="next_step" />
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '16px',
              }}
            >
              <Button
                disabled={!btnDisabled}
                htmlType="submit"
                className={
                  btnDisabled ? styles.whiteColorBtn : styles.disableColorBtn
                }
              >
                <TranslateText id="continue_without_image" />
              </Button>
            </div>
          </div>
          <CurrentStep />
        </Form>
      </div>
    </>
  )
}
