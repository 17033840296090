import React from 'react'
import { useIntl } from 'react-intl'
import UrlFrame from '../../components/UrlFrame'

function TermsAndConditions() {
  const intl = useIntl()
  const url = intl.formatMessage({ id: 'terms_and_conditions_url' })

  return <UrlFrame url={url} />
}

export default TermsAndConditions
