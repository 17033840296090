import React from 'react'
import { Form, Input, Button, Switch } from 'antd'

import styleUpload from '../Upload/upload.module.css'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'

const { TextArea } = Input

export default function PostForm({
  heading,
  postTitle = '',
  postContent = '',
  handlePostTitle,
  handlePostContent,
  buttonName,
  handleSendPost,
  handleSchedule,
  gotoFanpage,
  showMediaIsLandscapeToggle = false,
  mediaIsLandscape,
  handleMediaIsLandscapeChange,
}) {
  const intl = useIntl()
  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const postType = localStorage.getItem('postType')
  let explanation = null
  if (postType === 'image') {
    explanation = <TranslateText id="format_explanation_image_post" />
  } else if (postType === 'video') {
    explanation = <TranslateText id="format_explanation_video_post" />
  }

  const schedulePostButton = () => {
    if (window.location.pathname === '/fanpage/editPost') {
      return ''
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Form.Item style={{ marginBottom: '16px' }}>
          <Button
            style={{ width: '240px', textTransform: 'uppercase' }}
            htmlType="submit"
            onClick={handleSchedule}
            className={styleUpload.whiteColorBtn}
          >
            <TranslateText id="schedule_post" />
          </Button>
        </Form.Item>
      </div>
    )
  }
  return (
    <div className={styleUpload.cardDiv} style={{ height: '100%' }}>
      <div style={{ paddingTop: '88px' }}>
        <div>
          <label style={{ color: '#232324', fontSize: '24px', opacity: '1' }}>
            <b>{heading}</b>
          </label>
          {explanation && (
            <div>
              <label
                style={{
                  color: '#232324',
                  fontSize: '16px',
                  paddingTop: '10px',
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  display: 'inline-block',
                }}
              >
                {explanation}
              </label>
            </div>
          )}
          <div
            style={{
              marginTop: '4vh',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Form
              {...layout}
              name="basic"
              layout={'vertical'}
              initialValues={{
                postName: postTitle,
                description: postContent,
              }}
              onFinish={handleSendPost}
            >
              <Form.Item
                label=""
                name="postName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: intl.formatMessage({
                      id: 'enter_your_post_title',
                    }),
                  },
                ]}
              >
                <Input
                  style={{ width: '316px', height: '47px' }}
                  value={postTitle}
                  placeholder={intl.formatMessage({ id: 'title' })}
                  maxLength="50"
                  // defaultValue={postTitle}
                  onChange={e => {
                    handlePostTitle(e)
                  }}
                />
              </Form.Item>
              <Form.Item
                label=""
                name="description"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: intl.formatMessage({
                      id: 'enter_your_post_content',
                    }),
                  },
                ]}
              >
                <TextArea
                  onChange={e => {
                    handlePostContent(e)
                  }}
                  style={{ width: '316px' }}
                  // defaultValue={postContent}
                  placeholder={intl.formatMessage({ id: 'post_content' })}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={postContent}
                />
              </Form.Item>
              {showMediaIsLandscapeToggle && (
                <Switch
                  checked={mediaIsLandscape}
                  onChange={handleMediaIsLandscapeChange}
                  checkedChildren={<TranslateText id="medium_is_landscape" />}
                  unCheckedChildren={<TranslateText id="medium_is_landscape" />}
                />
              )}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item style={{ marginBottom: '16px' }}>
                  <Button
                    style={{ marginTop: '70px', width: '240px' }}
                    htmlType="submit"
                    className={styleUpload.greenColorBtn}
                  >
                    {buttonName}
                  </Button>
                </Form.Item>
              </div>
              {schedulePostButton()}
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <label
                  style={{
                    color: '#232324',
                    opacity: '1',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <span
                    style={{
                      color: '#7CD2C6',
                      cursor: 'pointer',
                      textTransform: 'uppercase',
                    }}
                    onClick={gotoFanpage}
                  >
                    <TranslateText id="back" />
                  </span>{' '}
                </label>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
