import axios from 'axios'
import { GLOBALCONFIG } from './Config'

export const API_GET = (url, LOGINCONFIG = GLOBALCONFIG()) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, LOGINCONFIG)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })

export const API_POST = (url, body, LOGINCONFIG = GLOBALCONFIG()) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, body, LOGINCONFIG)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })

export const API_PUT = (url, body, LOGINCONFIG = GLOBALCONFIG()) =>
  new Promise((resolve, reject) => {
    axios
      .put(url, body, LOGINCONFIG)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })

export const API_POST_WITH_PARAMS = (url, body) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
