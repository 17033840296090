import React from 'react'
import { Button } from 'antd'
import { Redirect, withRouter } from 'react-router-dom'
import TranslateText from '../../translation/TranslateText'
import 'react-image-crop/dist/ReactCrop.css'
import styles from './login.module.css'

const HomePage = props => {
  const loginData = JSON.parse(localStorage.getItem('token'))

  if (loginData === null) {
    return (
      <div
        style={{
          paddingTop: '70px',
          minHeight: '100vh',
          backgroundColor: '#f2fbf9',
        }}
      >
        <div className={styles.homePage}>
          <div className={styles.btnPosition}>
            <Button
              style={{ bottom: '70px' }}
              onClick={() => {
                props.history.push('/signup')
              }}
              className={styles.SignUpButton}
            >
              <TranslateText id="signup" />
            </Button>
          </div>
          <div className={styles.btnPosition}>
            <Button
              onClick={() => {
                props.history.push('/login')
              }}
              className={styles.LoginButton}
            >
              <TranslateText id="login" />
            </Button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/upload',
        }}
      />
    )
  }
}

export default withRouter(HomePage)
