import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button } from 'antd';
import _ from 'lodash';
import InputCode from './inputCode';

import {
  artistContactVerification,
  resendContactNumberVerificationOtp,
} from '../../containers/Redux/actions/auth'

import greenDot from '../../Assets/Images/greenDot.svg'
import whiteDot from '../../Assets/Images/whiteDot.svg'

import styles from '../../containers/Login/login.module.css'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import {
  showEmptyFieldError,
  showContactNumberInvalidError,
} from './validation'

const countryCodes = require('country-codes-list')

export default function ConfirmOtpScreen({ data, sliderChange }) {
  const [otpCode, setOtpCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [countryCallingCode, setCountryCallingCode] = useState(
    data?.countryCode
  )
  const [contactNumber, setContactNumber] = useState(data?.contactNumber)
  const [emptyField, setEmptyField] = useState(false)
  const [contactNumberValid, setContactNumberValid] = useState(false)

  const dispatch = useDispatch()
  const intl = useIntl()

  const confirmOtp = values => {
    if (contactNumber.length < 8) {
      setContactNumberValid(true)
    } else {
      values.verifyOtp = parseInt(otpCode)
      values.countryCode = countryCallingCode
      values.contactNumber = contactNumber
      dispatch(artistContactVerification(values)).then(res => {
        if (res && res.success) {
          sliderChange()
        } else {
          console.log('something wrong')
        }
      })
    }
  }

  const allCountryCodes = _.uniq(Object.values(
      countryCodes.customList('countryNameEn', '+{countryCallingCode}')
  ))

  const resendOtp = () => {
    const otpData = {
      countryCode: countryCallingCode,
      contactNumber: contactNumber,
      email: data?.email,
    }
    dispatch(resendContactNumberVerificationOtp(otpData))
  }

  const CurrentStep = () => {
    if (window.location.pathname === '/signup') {
      return (
        <div style={{ marginTop: '12px', opacity: 0.9 }}>
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={whiteDot} alt="whiteDot" />
          <img src={whiteDot} alt="whiteDot" />
        </div>
      )
    }
    return ''
  }

  return (
    <>
      <div>
        <label style={{ color: '#232324', fontSize: '24px', opacity: '0.9' }}>
          <b>
            <TranslateText id="confirm_otp" />
          </b>
        </label>
      </div>
      <div
        style={{ margin: '15px auto 0', padding: '0 20px', maxWidth: '460px' }}
      >
        <label style={{ color: '#303135', fontSize: '16px', opacity: '0.5' }}>
          <TranslateText id="welcome_plactron" />
          <br />
          <TranslateText id="send_otp" />
           <br />
           <b>{`${countryCallingCode} ${contactNumber}`}</b>{' '}
          <br />
          <TranslateText id="otp_verification" />
        </label>
      </div>
      <div
        style={{
          marginTop: '80px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form
          onFinish={confirmOtp}
          name="basic"
          layout={'vertical'}
          requiredMark={false}
        >
          <div style={{ display: 'flex' }}>
            <select
              style={{
                outline: 0,
                zIndex: '10',
                width: '80px',
                padding: '8px',
                color: '#303135',
                cursor: 'pointer',
                marginRight: '-6px',
                border: '1px solid #d9d9d9',
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
              }}
              onChange={e => setCountryCallingCode(e.target.value)}
              defaultValue={countryCallingCode}
            >
              {allCountryCodes.map((countryCode, index) => (
                <option key={index} value={countryCode}>
                  {countryCode}
                </option>
              ))}
            </select>
            <input
              id=""
              name=""
              type="text"
              maxlength="12"
              pattern="[0-9]*"
              className="ant-input"
              // minlength='8'
              placeholder={intl.formatMessage({
                id: 'enter_your_contact_number',
              })}
              style={{ height: '40px', borderLeftStyle: 'none' }}
              value={contactNumber}
              onChange={e => {
                if (e.target.validity.valid) {
                  setContactNumber(e.target.value)
                  setEmptyField(false)
                  setContactNumberValid(false)
                }
              }}
              onBlur={e => {
                if (e.target.value === '') {
                  setEmptyField(true)
                }
              }}
            />
          </div>
          {showEmptyFieldError(
            emptyField,
            contactNumber,
            intl.formatMessage({ id: 'enter_your_contact_number' })
          )}
          {showContactNumberInvalidError(
            contactNumberValid,
            intl.formatMessage({ id: 'enter_correct_contact_number' })
          )}
          <div style={{ marginTop: '10px' }}>
            <InputCode
              length={4}
              label={<TranslateText id="4digit_code" />}
              loading={loading}
              onComplete={code => {
                setOtpCode(code)
                setLoading(true)
                setTimeout(() => setLoading(false), 100)
              }}
            />
          </div>
          <div>
            <Form.Item
              style={{
                marginBottom: '0px',
                marginTop: '80px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                htmlType="submit"
                className={styles.greenColorBtn}
                disabled={otpCode === ''}
              >
                <TranslateText id="confirm_otp" />
              </Button>
            </Form.Item>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              opacity: '0.6',
              color: '#303135',
              textAlign: 'center',
              lineHeight: '22px',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontFamily: 'Barlow',
            }}
          >
            <TranslateText id="didnt_receive_otp" />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
              color: '#7CD2C6',
              lineHeight: '19px',
              fontSize: '16px',
              fontFamily: 'Barlow',
              fontStyle: 'normal',
              fontWeight: '600',
            }}
          >
            <span
              style={{ cursor: 'pointer', opacity: 0.9 }}
              onClick={resendOtp}
            >
              <TranslateText id="resend_otp" />
            </span>
          </div>
          <CurrentStep />
        </Form>
      </div>
    </>
  )
}
