import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import InputCode from './inputCode'

import {
  artistEmailVerification,
  resendEmailVerificationCode,
  setMessageDetails,
} from '../../containers/Redux/actions/auth'
import { Form, Button } from 'antd'

import greenDot from '../../Assets/Images/greenDot.svg'
import whiteDot from '../../Assets/Images/whiteDot.svg'

import styles from '../../containers/Login/login.module.css'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'

export default function ConfirmCodeScreen({ email, sliderChange }) {
  const [otpCode, setOtpCode] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()

  const confirmCode = values => {
    values.email = email
    values.emailVerifiedOtp = parseInt(otpCode)
    const data = {
      email: email,
      emailVerifiedOtp: parseInt(otpCode),
    }
    if (email) {
      dispatch(artistEmailVerification(data)).then(response => {
        if (response && response.success) {
          sliderChange()
        } else {
          dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'enter_code_correctly' })
            )
          )
        }
      })
    }
  }

  const resendCode = () => {
    dispatch(resendEmailVerificationCode({ email: email }))
  }

  const CurrentStep = () => {
    if (window.location.pathname === '/signup') {
      return (
        <div style={{ marginTop: '12px', opacity: 0.9 }}>
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={whiteDot} alt="whiteDot" />
          <img src={whiteDot} alt="whiteDot" />
        </div>
      )
    }
    return ''
  }

  return (
    <>
      <div>
        <label style={{ color: '#232324', fontSize: '24px', opacity: '0.9' }}>
          <b>
            <TranslateText id="confirm_code" />
          </b>
        </label>
      </div>
      <label style={{ color: '#303135', fontSize: '16px', opacity: '0.5' }}>
        <TranslateText id="welcome_plactron" />
        <br />
        <TranslateText id="send_email" />
        <br />
        <b>{email}</b> <TranslateText id="email_verification" />
      </label>
      <div
        style={{
          marginTop: '100px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form
          onFinish={confirmCode}
          name="basic"
          layout={'vertical'}
          requiredMark={false}
        >
          <InputCode
            length={4}
            label={<TranslateText id="4digit_code" />}
            loading={loading}
            onComplete={code => {
              setLoading(true)
              setOtpCode(code)
              setTimeout(() => setLoading(false), 100)
            }}
          />
          <div>
            <Form.Item
              style={{
                marginBottom: '0px',
                marginTop: '80px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                htmlType="submit"
                className={styles.greenColorBtn}
                disabled={otpCode === ''}
              >
                <TranslateText id="confirm_code" />
              </Button>
            </Form.Item>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'Barlow',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '18px',
              lineHeight: '22px',
              color: '#303135',
              textAlign: 'center',
              opacity: '0.6',
              marginTop: '10px',
            }}
          >
            <TranslateText id="didnt_receive_code" />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontFamily: 'Barlow',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '19px',
              color: '#7CD2C6',
              marginTop: '10px',
            }}
          >
            <span
              style={{ cursor: 'pointer', opacity: 0.9 }}
              onClick={resendCode}
            >
              <TranslateText id="resend_code" />
            </span>
          </div>
          <CurrentStep />
        </Form>
      </div>
    </>
  )
}
