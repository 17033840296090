import React from 'react'
import logo from '../../../src/Assets/Images/new-logo-plactron.svg'
import justifyAlign from '../../../src/Assets/Images/align-justify.svg'
import BtnSecondary from '../../components/Button/BtnSecondary'
import Heading from '../../components/Heading/Heading'
import styles from './index.module.css'
import { routes } from '../Sidebar/index'
import { useHistory } from 'react-router-dom'
import TranslateText from '../../translation/TranslateText'
import beta from '../../Assets/Images/beta2.png'

const Topbar = props => {
  const path = window.location.pathname
  let history = useHistory()
  return (
    <header className={styles.container}>
      <div className={styles.imgContainer}>
        <img
          style={{
            width: '180px',
            height: '90px',
            float: 'left',
            cursor: 'pointer',
          }}
          className="cursor"
          onClick={() => history.push('/home')}
          src={logo}
          alt="logo"
        />
      </div>
      <div className={styles.imgContainer1}>
        <img
          className="cursor"
          onClick={() => props.openSidebar()}
          src={justifyAlign}
          alt="logo"
        />
      </div>
      <div className={styles.rightContainer}>
        <div>
          <span>
            {routes.map((item, index) => {
              return path === item.path ? (
                <Heading key={index} type="h2">
                  {item.name}
                </Heading>
              ) : (
                ''
              )
            })}
            {path === '/settings' ? (
              <Heading type="h2">
                <TranslateText id="settings" />
              </Heading>
            ) : (
              ''
            )}
          </span>
        </div>
        <div className={styles.btnContainer}>
          <img
            style={{
              height: '34px',
              position: 'relative',
            }}
            src={beta}
            alt="beta"
          />
          {/* <MainBtn text="NEW UPLOAD" /> */}
          <BtnSecondary
            propStyles={{ marginLeft: '1vw' }}
            image={props.profilePic}
          />
        </div>
      </div>
    </header>
  )
}

export default Topbar
