import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AppConfig from '../../services'
import { AuthType } from '../../containers/Redux/types'
import Avatar from 'react-avatar'
import { cloudFrontImageSize } from '../../constants'
import styles from './button.module.css'
import TranslateText from '../../translation/TranslateText'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { IoIosArrowDown } from 'react-icons/io'

const BtnSecondary = ({ image, propStyles }) => {
  const dispatch = useDispatch()

  const history = useHistory()
  const nickName = useSelector(state => state.auth.nickName).trim()
  const indexOfSpace = nickName.search(' ')
  const name = indexOfSpace === -1 ? nickName : nickName.slice(0, indexOfSpace)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch({
      type: AuthType.LOGOUT_SUCCESS,
    })
    localStorage.removeItem('token')
    history.push('/')
  }

  const profilePic =
    image === '' ? (
      <Avatar name={nickName} size="28" textSizeRatio={2} round={true} />
    ) : (
      <img
        src={AppConfig.IMAGE_URL + cloudFrontImageSize + image}
        alt="Profile"
      />
    )

  return (
    <>
      <div style={propStyles} className={styles.btnSecondary}>
        {profilePic}
        <p>{name}</p>
        <IconButton size={'small'} onClick={handleClick}>
          <IoIosArrowDown />
        </IconButton>
        <Menu
          disableScrollLock={true}
          variant={'menu'}
          anchorEl={anchorEl}
          PaperProps={{
            style: {
              transform: 'translateX(-40%) translateY(70%)',
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>
            <TranslateText id="logout" />
          </MenuItem>
        </Menu>
      </div>
    </>
  )
}

export default BtnSecondary
