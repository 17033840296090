import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { EyeTwoTone } from '@ant-design/icons'
import { login, updateArtistProfileData } from '../Redux/actions/auth'
import styles from './login.module.css'
import eyeIcon from '../../Assets/Images/eyeIcon.png'

import RegisterSuccessScreen from '../../components/CommonScreen/registerSuccessScreen'
import ContactDetailsScreen from '../../components/CommonScreen/contactDetailsScreen'
import UploadProfilePicScreen from '../../components/CommonScreen/uploadProfilePicScreen'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import ConfirmOtpScreen from '../../components/CommonScreen/confirmOtpScreen'
import mark from '../../Assets/Images/newWhiteBg.svg'

const LoginPage = props => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const [slider, setSlider] = useState(0)
  const [disableBtn, setDisableBtn] = useState(false)
  const [imageFile, setImageFile] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [artistEmail, setArtistEmail] = useState('')
  const [address, setAddress] = useState({ street: '', city: '', country: '' })
  const [countryCallingCode, setCountryCallingCode] = useState('')
  const [gemaNumber, setGemaNumber] = useState('')
  const [instagramName, setInstagramName] = useState('')

  const handleLogin = values => {
    values.loginType = 2

    dispatch(login(values)).then(resp => {
      if (resp) {
        if (resp.success) {
          if (resp.data) {
            if (resp.data.token) {
              props.history.push('/upload')
            } else if (resp.data.emailVerify === false) {
              props.history.push('/signup')
            } else if (resp?.data?.contactVerified === false) {
              setCountryCallingCode(resp?.data?.countryCode)
              setContactNumber(resp?.data?.contactNumber)
              setArtistEmail(resp?.data?.email)
              setSlider(4)
            } else {
              setSlider(0)
            }
          } else {
            setSlider(2)
          }
          setArtistEmail(values.email)
        } else {
          setSlider(1)
        }
      }
    })
  }

  const handleUpdateDetails = (data, flag) => {
    dispatch(updateArtistProfileData(data, flag)).then(response => {
      if (response && response.success === true) {
        setSlider(1)
        setDisableBtn(true)
      }
    })
  }

  const handleStoreDetails = nickName => {
    const values = {}
    values.email = artistEmail
    values.nickName = nickName
    values.contactNumber = contactNumber
    values.address = address
    values.countryCode = countryCallingCode
    values.gemaNumber = gemaNumber
    values.instagramName = instagramName

    if (imageFile === '') {
      handleUpdateDetails(values, false)
    } else {
      var formdata = new FormData()

      formdata.append('email', artistEmail)
      formdata.append('nickName', nickName)
      formdata.append('profilePic', imageFile)
      formdata.append('contactNumber', contactNumber)
      formdata.append('countryCode', countryCallingCode)
      formdata.append('address', JSON.stringify(address))
      formdata.append('gemaNumber', gemaNumber)
      formdata.append('instagramName', instagramName)

      handleUpdateDetails(formdata, true)
    }
  }

  const loginData = JSON.parse(localStorage.getItem('token'))
  if (loginData === null) {
    return (
      <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
        <div style={{ padding: '14px 0 16px 0' }}>
          <div className={styles.signInDiv}>
            <div
              className={styles.formDiv}
              style={{
                backgroundColor: '#fff',
                maxWidth: 700,
                alignSelf: 'center',
                position: 'relative',
                paddingBottom: '32px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: -90,
                  right: -70,
                }}
              >
                <img alt="altTag" style={{ width: 400 }} src={mark} />
              </div>
              {slider === 0 ? (
                <>
                  <div>
                    <label
                      style={{
                        color: '#232324',
                        fontSize: '24px',
                        opacity: '0.9',
                      }}
                    >
                      <b>
                        <TranslateText id="welcome_back" />
                      </b>
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Form
                      onFinish={handleLogin}
                      name="basic"
                      validateTrigger="onFinish"
                      layout={'vertical'}
                    >
                      <Form.Item
                        style={{ marginBottom: '12px' }}
                        label={<TranslateText id="email" />}
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: intl.formatMessage({
                              id: 'enter_valid_email_id',
                            }),
                          },
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'enter_email_id',
                            }),
                          },
                        ]}
                      >
                        <Input
                          placeholder={intl.formatMessage({
                            id: 'enter_your_email',
                          })}
                          style={{
                            width: '315px',
                            height: '47px',
                            borderRadius: '4px',
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: '20px' }}
                        label={<TranslateText id="password" />}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'enter_your_password',
                            }),
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder={intl.formatMessage({
                            id: 'enter_your_password',
                          })}
                          iconRender={visible =>
                            visible ? (
                              <EyeTwoTone />
                            ) : (
                              <img src={eyeIcon} alt="" />
                            )
                          }
                          style={{
                            width: '315px',
                            height: '47px',
                            borderRadius: '4px',
                          }}
                        />
                      </Form.Item>
                      <div
                        style={{
                          color: '#7CD2C6',
                          cursor: 'pointer',
                          marginBottom: '60px',
                          opacity: 0.9,
                        }}
                      >
                        <span
                          onClick={() => {
                            props.history.push('/forgotPassword')
                          }}
                        >
                          <TranslateText id="forgot_password" />
                        </span>
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Form.Item>
                          <Button
                            className={styles.greenColorBtn}
                            htmlType="submit"
                          >
                            <TranslateText id="login" />
                          </Button>
                        </Form.Item>
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <label
                          style={{
                            color: '#232324',
                            opacity: 0.9,
                          }}
                        >
                          <TranslateText id="not_member" />
                          <span
                            onClick={() => {
                              props.history.push('/signup')
                            }}
                            style={{
                              color: '#7CD2C6',
                              cursor: 'pointer',
                              paddingLeft: '8px',
                            }}
                          >
                            {' '}
                            <TranslateText id="signup" />
                          </span>{' '}
                        </label>
                      </div>
                    </Form>
                  </div>
                </>
              ) : (
                ''
              )}
              {slider === 1 ? (
                <RegisterSuccessScreen login={handleLogin} />
              ) : (
                ''
              )}
              {slider === 2 ? (
                <ContactDetailsScreen
                  btnDisabled={disableBtn}
                  handleDisableBtn={e => setDisableBtn(e)}
                  handleCountryCallingCode={e => setCountryCallingCode(e)}
                  handleAddress={e => setAddress(e)}
                  handleContactNumber={e => setContactNumber(e)}
                  handleGemaNumber={e => setGemaNumber(e)}
                  handleInstagramName={e => setInstagramName(e)}
                  sliderChange={() => setSlider(3)}
                />
              ) : (
                ''
              )}
              {slider === 3 ? (
                <UploadProfilePicScreen
                  btnDisabled={disableBtn}
                  handleDisableBtn={e => setDisableBtn(e)}
                  handleImageFile={e => setImageFile(e)}
                  handleStoreDetails={e => handleStoreDetails(e)}
                />
              ) : (
                ''
              )}
              {slider === 4 ? (
                <ConfirmOtpScreen
                  data={{
                    countryCode: countryCallingCode,
                    contactNumber: contactNumber,
                    email: artistEmail,
                  }}
                  goBack={() => setSlider(2)}
                  sliderChange={() => setSlider(1)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/upload',
        }}
      />
    )
  }
}

export default withRouter(LoginPage)
