import React, { useState, useCallback, useRef, useEffect } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { Button, Modal } from 'antd'
import styles from '../../containers/Login/login.module.css'
import TranslateText from '../../translation/TranslateText'

export default function CropImageModalPopup({
  showModal,
  handleOk,
  handleCancel,
  imageUrl,
  handleDataURLtoFile,
  aspectRatio = 1,
}) {
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [cropRatio, setCropRatio] = useState({
    unit: '%',
    width: 40,
    aspect: aspectRatio,
  })
  const [completedCrop, setCompletedCrop] = useState(null)

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    if (crop.x < 0 || crop.x > image.width) {
      crop.x = 0
    }
    if (crop.y < 0 || crop.y > image.height) {
      crop.y = 0
    }

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    canvas.toBlob(blob => {
      const reader = new FileReader()
      if (blob) {
        reader.readAsDataURL(blob)
        reader.onload = function (e) {
          var newImage = new Image()
          newImage.src = e.target.result
          handleDataURLtoFile(reader.result)
          newImage.onload = function () {
            console.log(reader)
          }
        }
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop])

  const cropImage = () => {
    setCropRatio({ unit: '%', width: 30, aspect: aspectRatio })
    handleOk()
  }

  const cancelImage = () => {
    setCropRatio({ unit: '%', width: 30, aspect: aspectRatio })
    handleCancel()
  }

  return (
    <Modal
      title={<TranslateText id="edit_image" />}
      width="1100"
      centered
      open={showModal}
      okText={'Crop'}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button
          style={{ width: '100px', height: '40px' }}
          key="cancel"
          onClick={() => cancelImage()}
        >
          <TranslateText id="cancel" />
        </Button>,
        <Button
          style={{ width: '100px', height: '40px' }}
          key="ok"
          type="primary"
          onClick={() => cropImage()}
        >
          <TranslateText id="crop" />
        </Button>,
      ]}
    >
      <div className={styles.cropModal}>
        <div style={{ marginRight: '40px' }}>
          <p>
            <TranslateText id="original_image" />
          </p>
          <ReactCrop
            src={imageUrl}
            onImageLoaded={onLoad}
            crop={cropRatio}
            onChange={c => {
              setCropRatio(c)
            }}
            onComplete={c => setCompletedCrop(c)}
          />
        </div>
        <div>
          <p>
            <TranslateText id="cropped_image" />
          </p>
          <div>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: 400,
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
