import React from 'react'

function UrlFrame({ url }) {
  return (
    <iframe
      height="100%"
      width="100%"
      style={{
        background: 'white',
        border: '0px',
        padding: 20,
      }}
      src={url}
      title="Terms and Conditions"
    />
  )
}

export default UrlFrame
