import { uploadPageType } from '../types'

const initialState = {
  songs: [],
  albums: '',
  singleSongs: '',
  selectedSongs: [],
  albumId: '',
  albumType: '',
  albumName: '',
  singleSongId: '',
  singleSongName: '',
  singleSongImage: '',
}

const uploadPageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case uploadPageType.CREATE_SONG:
      return {
        ...state,
        songs: [...state.songs, ...action.payload],
      }

    case uploadPageType.DELETE_SONG:
      return {
        ...state,
        songs: state.songs.filter(song => song._id !== action.payload),
        selectedSongs: state.selectedSongs.filter(
          song => song._id !== action.payload
        ),
      }

    case uploadPageType.GET_ALL_ALBUMS_SUCCESS:
      return {
        ...state,
        songs: [],
        albums: action.payload,
      }

    case uploadPageType.GET_ALL_SONGS_SUCCESS:
      return {
        ...state,
        songs: [],
        singleSongs: action.payload,
      }

    case uploadPageType.REMOVE_ALL_SONGS:
      return {
        ...state,
        songs: [],
      }

    case uploadPageType.UPDATE_ALBUM_DATA:
      return {
        ...state,
        albumId: action.payload,
        selectedSongs: state.albums.filter(
          album => album._id === action.payload
        )[0].songsId,
        albumType: state.albums.filter(album => album._id === action.payload)[0]
          .albumType,
        albumName: state.albums.filter(album => album._id === action.payload)[0]
          .albumName,
      }

    case uploadPageType.UPDATE_SINGLE_SONG_DATA:
      return {
        ...state,
        singleSongId: action.payload,
        selectedSongs: state.singleSongs.filter(
          song => song._id === action.payload
        )[0],
        albumType: state.singleSongs.filter(
          song => song._id === action.payload
        )[0].albumType,
        singleSongName: state.singleSongs.filter(
          song => song._id === action.payload
        )[0].songName,
        singleSongImage: state.singleSongs.filter(
          song => song._id === action.payload
        )[0].songImage,
      }

    default:
      return state
  }
}

export default uploadPageReducer
