import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Input, Button, Popconfirm } from 'antd'
import { IoTrashOutline } from 'react-icons/io5'
import { CloseOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import {
  commentsByPostId,
  createComment,
  deleteComment,
  getAllPosts,
  replyToComment,
} from '../../containers/Redux/actions/fanPage'

import user from '../../Assets/Images/user.png'
import AppConfig from '../../services'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { setMessageDetails } from '../../containers/Redux/actions/auth'
import { cloudFrontImageSize } from '../../constants'

const { TextArea } = Input

export default function CommentModalPopup(props) {
  let history = useHistory()
  const intl = useIntl()

  const [loaderInCommentButton, setLoaderInCommentButton] = useState()
  const [loaderInReplyButton, setLoaderInReplyButton] = useState()
  const [replyCommentText, setReplyCommentText] = useState()
  const [newCommentText, setNewCommentText] = useState()
  const [currentReplyBoxCommentId, setCurrentReplyBoxCommentId] = useState('')
  const [commentBoxEmptyError, setCommentBoxEmptyError] = useState(false)

  const allComments = useSelector(state => state.fanPage.allComments)
  const artistProfilePic = useSelector(state => state.auth.profilePic)
  const postId = history.location.postId

  const profilePic =
    artistProfilePic === ''
      ? user
      : AppConfig.IMAGE_URL + cloudFrontImageSize + artistProfilePic

  const dispatch = useDispatch()

  const checkEmptyValueInCommentBox = value => {
    const commentText = value.trim()
    if (commentText.length > 0) {
      setCommentBoxEmptyError(false)
    } else {
      setCommentBoxEmptyError(true)
    }
  }

  const createNewComment = () => {
    if (commentBoxEmptyError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'comment_message_empty' })
        )
      )
    } else {
      setLoaderInCommentButton(true)
      const body = {
        comment: newCommentText,
        postId: postId,
      }

      dispatch(createComment(body)).then(res => {
        setNewCommentText('')
        setLoaderInCommentButton(false)
        dispatch(
          commentsByPostId({
            postId: postId,
          })
        )
        dispatch(getAllPosts())
      })
    }
  }

  const replyByArtistComment = commentId => {
    if (commentBoxEmptyError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'comment_message_empty' })
        )
      )
    } else {
      setLoaderInReplyButton(true)
      const body = {
        comment: replyCommentText,
        commentId: commentId,
      }

      dispatch(replyToComment(body)).then(res => {
        setReplyCommentText('')
        setLoaderInReplyButton(false)
        setCurrentReplyBoxCommentId('')

        dispatch(
          commentsByPostId({
            postId: postId,
          })
        )
      })
    }
  }

  const showCommentBox = commentId => {
    setReplyCommentText('')
    setCurrentReplyBoxCommentId(commentId)
  }

  const handleDeleteComment = body => {
    dispatch(deleteComment(body)).then(response => {
      if (response) {
        dispatch(
          commentsByPostId({
            postId: postId,
          })
        )
        dispatch(getAllPosts())
      }
    })
  }

  const deleteCommentByArtist = commentId => {
    const body = {
      postId: postId,
      commentId: commentId,
      commentType: 1,
    }

    handleDeleteComment(body)
  }

  const deleteReplyComment = (commentId, replyCommentId) => {
    const body = {
      postId: postId,
      commentId: commentId,
      commentType: 2,
      replyCommentId: replyCommentId,
    }

    handleDeleteComment(body)
  }

  const replyComment = (commentId, comment) => {
    return (
      <div
        className="comment-card"
        style={{ display: 'flex', margin: '10px 0 0 0px' }}
      >
        <div className="userProfilepic">
          {profilePicImage(comment.commentId.commentOwner.profilePic)}
        </div>
        <div style={{ width: '100%', marginLeft: '10px' }}>
          <div class="topheader" style={{ display: 'flex' }}>
            <div className="username">
              <b>{comment.commentId.commentOwner.nickName}</b>
            </div>
          </div>
          <div className="bottom">
            <div className="usercomment">
              <p style={{ fontSize: '12px' }}>{comment.commentId.comment}</p>
            </div>
          </div>
        </div>
        <div className="deleteicon">
          <span style={{ cursor: 'pointer' }}>
            <Popconfirm
              title={<TranslateText id="are_you_sure" />}
              okText={<TranslateText id="delete" />}
              cancelText={<TranslateText id="no" />}
              onConfirm={() => {
                deleteReplyComment(commentId, comment.commentId._id)
              }}
            >
              <IoTrashOutline style={{ color: '#6c6c6c' }} />
            </Popconfirm>
          </span>
        </div>
      </div>
    )
  }

  const profilePicImage = image => {
    if (image === '') {
      return (
        <img
          style={{
            height: '32px',
            width: '32px',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
          src={user}
          alt="avatarIcon"
        />
      )
    }
    return (
      <img
        style={{
          height: '32px',
          width: '32px',
          borderRadius: '50%',
          objectFit: 'cover',
          border: '1px solid #d9d9d9',
        }}
        src={AppConfig.IMAGE_URL + cloudFrontImageSize + image}
        alt="profileImage"
      />
    )
  }

  return (
    <Modal
      title=""
      centered
      open={props.show}
      okText={'Comment'}
      width={600}
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <div>
        <div
          style={{
            fontSize: '20px',
            color: 'rgba(48, 49, 53, 0.3)',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              color: '#303135',
              fontWeight: '500',
            }}
          >
            <TranslateText id="comments" />
          </div>
          <div>
            <CloseOutlined
              style={{ color: '#777777' }}
              onClick={() => {
                setReplyCommentText('')
                setNewCommentText('')
                props.handleCancel()
              }}
            />
          </div>
        </div>
        <div style={{ maxHeight: '300px', overflow: 'auto' }}>
          {allComments.map(comment => (
            <div style={{ marginRight: '0.5em' }}>
              <div
                className="comment-card"
                style={{ display: 'flex', marginTop: '1em' }}
              >
                <div className="userProfilepic">
                  {profilePicImage(comment.commentOwner.profilePic)}
                </div>
                <div style={{ width: 'calc(100% - 60px)', marginLeft: '10px' }}>
                  <div class="topheader" style={{ display: 'flex' }}>
                    <div className="username">
                      <b>{comment.commentOwner.nickName}</b>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="usercomment">
                      <p style={{ fontSize: '12px', marginBottom: '4px' }}>
                        {comment.comment}
                      </p>
                    </div>
                    {currentReplyBoxCommentId === comment._id ? (
                      <div
                        className="replyInput"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <TextArea
                          value={replyCommentText}
                          onChange={e => {
                            checkEmptyValueInCommentBox(e.target.value)
                            setReplyCommentText(e.target.value)
                          }}
                          autoSize={{ minRows: 1, maxRows: 2 }}
                        />
                        <Button
                          style={{ marginLeft: '1em', borderRadius: '6px' }}
                          loading={loaderInReplyButton}
                          onClick={() => replyByArtistComment(comment._id)}
                          type="primary"
                        >
                          <TranslateText id="send" />
                        </Button>
                      </div>
                    ) : (
                      <div className="reply button">
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => showCommentBox(comment._id)}
                        >
                          <TranslateText id="reply" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="deleteicon">
                  <span style={{ cursor: 'pointer' }}>
                    <Popconfirm
                      title={<TranslateText id="are_you_sure" />}
                      okText={<TranslateText id="delete" />}
                      cancelText={<TranslateText id="no" />}
                      onConfirm={() => {
                        deleteCommentByArtist(comment._id)
                      }}
                    >
                      <IoTrashOutline style={{ color: '#6c6c6c' }} />
                    </Popconfirm>
                  </span>
                </div>
              </div>
              {comment.replyComment && comment.replyComment.length > 0 ? (
                <ul style={{ listStyle: 'none', marginLeft: '44px' }}>
                  {comment.replyComment.map(replycomment => (
                    <li>{replyComment(comment._id, replycomment)}</li>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>

        <div
          className="comment-card"
          style={{ border: 'none', display: 'flex', marginTop: '1em' }}
        >
          <div className="userProfilepic">
            <img
              style={{
                height: '32px',
                width: '32px',
                borderRadius: '50%',
                objectFit: 'cover',
                border: '1px solid #d9d9d9',
              }}
              src={profilePic}
              alt="profileImage"
            />
          </div>
          <div style={{ width: '100%', marginLeft: '1em' }}>
            <div className="bottom">
              <div className="usercomment">
                <TextArea
                  value={newCommentText}
                  onChange={e => {
                    checkEmptyValueInCommentBox(e.target.value)
                    setNewCommentText(e.target.value)
                  }}
                  autoSize={{ minRows: 2, maxRows: 2 }}
                />
              </div>
            </div>
            <Button
              style={{ marginTop: '1em', borderRadius: '6px' }}
              loading={loaderInCommentButton}
              onClick={() => createNewComment()}
              type="primary"
            >
              <TranslateText id="send" />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
