import React, { useState, useRef } from 'react'

const InputCode = ({ length, label, loading, onComplete }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ''))
  const inputs = useRef([])
  // Typescript
  // useRef<(HTMLInputElement | null)[]>([])

  const processPaste = clipboardTextData => {
    const splitted = clipboardTextData.split('')
    const boxValue1 = splitted[0]
    const boxValue2 = splitted[1]
    const boxValue3 = splitted[2]
    const boxValue4 = splitted[3]
    const newCode = [...code]
    newCode[0] = boxValue1
    newCode[1] = boxValue2
    newCode[2] = boxValue3
    newCode[3] = boxValue4
    setCode(newCode)
    if (newCode.every(number => number !== '')) {
      onComplete(newCode.join(''))
    }
  }
  const processInput = (e, slot) => {
    const num = e.target.value
    if (/[\D]/.test(num) || num === '') return
    const newCode = [...code]
    newCode[slot] = num
    setCode(newCode)
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus()
    }
    if (newCode.every(number => number !== '')) {
      onComplete(newCode.join(''))
    }
  }

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8) {
      const newCode = [...code]
      if (slot !== 0 && code[slot] === '') {
        newCode[slot - 1] = ''
      } else {
        newCode[slot] = ''
      }
      setCode(newCode)
      onComplete('')
      if (slot !== 0 && code[slot] === '') inputs.current[slot - 1].focus()
    }
  }

  return (
    <div className="code-input" style={{ opacity: 0.9 }}>
      <label className="code-label">{label}</label>
      <div
        className="code-inputs"
        onPaste={e => processPaste(e.clipboardData.getData('Text'))}
      >
        {code.map((num, idx) => {
          return (
            <>
              <input
                key={idx}
                type="text"
                inputMode="numeric"
                maxLength={1}
                value={num}
                autoFocus={!code[0].length && idx === 0}
                readOnly={loading}
                onChange={e => processInput(e, idx)}
                onKeyUp={e => onKeyUp(e, idx)}
                ref={ref => inputs.current.push(ref)}
              />
              {code.length - 1 === idx ? (
                ''
              ) : (
                <p style={{ margin: '0px 16px 4px' }}>-</p>
              )}
            </>
          )
        })}
      </div>
    </div>
  )
}

export default InputCode
