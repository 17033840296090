import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Col, Row } from 'antd'
import { getArtistById } from '../Redux/actions/auth'
import { getAllAlbums } from '../Redux/actions/uploadPage'
import { uploadPageType } from '../Redux/types'
import devConfig from '../../services/index'
import styles from './upload.module.css'
import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import editIcon from '../../Assets/Images/pencil.png'
import { cloudFrontImageSize } from '../../constants'
import { getAlbumPrice } from '../../iap-prices'
import { usePricesFormatting } from '../../hooks/usePricesFormatting'

export default function SeeAllAlbums(props) {
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(16)
  const [startIndex, setStartIndex] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const formatPrice = usePricesFormatting()

  const dispatch = useDispatch()

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId }))
    dispatch(getAllAlbums(startIndex, limit)).then(res => {
      if (res && res.success) {
        setData(res.data)
        hideLoader()
        setStartIndex(startIndex + limit)
        setLimit(4)
      }
    })
    dispatch({
      type: uploadPageType.REMOVE_ALL_SONGS,
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const addMoreAlbums = () => {
    setStartIndex(startIndex + limit)
    dispatch(getAllAlbums(startIndex, limit)).then(res => {
      if (res && res.success) {
        setData([...data, ...res.data])
      }
    })
  }

  const editAlbum = id => {
    dispatch({
      type: uploadPageType.UPDATE_ALBUM_DATA,
      payload: id,
    })
    props.history.push('/upload/editAlbumById')
  }

  return (
    <div>
      <InfiniteScroll
        dataLength={data.length}
        next={addMoreAlbums}
        hasMore={true}
      >
        <Row gutter={[30, 30]} style={{ width: '100%', maxWidth: '1140px' }}>
          {data &&
            data.map((album, index) => (
              <Col key={index}>
                <img
                  key={album._id}
                  className={styles.coverImageSize}
                  src={
                    devConfig.IMAGE_URL +
                    cloudFrontImageSize +
                    album.albumImages[0]
                  }
                  alt={album.albumName}
                />
                <img
                  onClick={() => editAlbum(album._id)}
                  src={editIcon}
                  alt="editIcon"
                  style={{
                    position: 'absolute',
                    top: '28px',
                    right: '28px',
                    cursor: 'pointer',
                    height: '32px',
                  }}
                />
                <div className={styles.songPrice}>
                  {formatPrice(getAlbumPrice(album.albumType))}
                </div>
                <div className={styles.titleStyle}>{album.albumName}</div>
              </Col>
            ))}
        </Row>
      </InfiniteScroll>
      {loader}
    </div>
  )
}
