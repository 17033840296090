const { useIntl } = require('react-intl')

export function usePricesFormatting() {
  const intl = useIntl()
  const locale = intl.locale // de or en

  switch (locale) {
    case 'de':
      return value => {
        return value.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
        })
      }
    case 'en':
      return value => {
        return value.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
        })
      }

    default:
      console.warn('no translation found')
      return value => value
  }
}
