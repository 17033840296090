import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import moment from 'moment'
import {
  getArtistById,
  getArtistFollowerCount,
  getArtistSuperFanFollowerCount,
} from '../Redux/actions/auth'
import {
  getDownloadCount,
  getTotalSale,
  getMostVisitedPost,
  getArtistSongList,
} from '../Redux/actions/user'
import 'moment/locale/de'
import TotalSales from './TotalSales'
import BarChart from './BarChart'
import MostVisitedPostsTable from './MostVisitedPostsTable'
import SuperFansTable from './SuperFansTable'
import AllSongsTable from './AllSongsTable'
import WonFansTable from './WonFansTable'

export default function HomeDashboard() {
  const dispatch = useDispatch()

  const lang = useSelector(state => state.auth.currentLanguage)
  moment.locale(lang)
  const artistId = JSON.parse(localStorage.getItem('artistId'))
  const [showSalesData, setShowSalesData] = useState(true)
  useEffect(() => {
    let currentDate = new Date()
    let endDate = currentDate.toISOString().split('T')

    const startDate = date => {
      date.setDate(date.getDate() - 6)
      let newStartDate = date.toISOString().split('T')
      return newStartDate[0]
    }

    dispatch(
      getDownloadCount({
        artistId: artistId,
        startDate: startDate(currentDate),
        endDate: endDate[0],
      })
    )
    dispatch(getArtistSuperFanFollowerCount())
    dispatch(getTotalSale({ id: artistId }))
    dispatch(getMostVisitedPost())
    dispatch(getArtistById({ id: artistId }))
    dispatch(getArtistFollowerCount())
    dispatch(getArtistSongList(0, 100))
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const saleData = useSelector(state => state.user.saleCount)
  const mostVisitedData = useSelector(state =>
    Object.values(state.user.mostVisitedPost)
  )
  const artistFollowerData = useSelector(state =>
    Object.values(state.auth.artistFollowerData)
  )
  const artistSuperFanFollowerData = useSelector(state =>
    Object.values(state.auth.artistSuperFanFollowerData)
  )
  const gemaSongsData = useSelector(state =>
    Object.values(state.user.songsList).filter(song => {
      return song.songStatus !== 2
    })
  )

  const filteredDonationAmount = { ...saleData }
  delete filteredDonationAmount.totalSales
  delete filteredDonationAmount.singles
  delete filteredDonationAmount.albums

  const isLoadingSaleData = saleData.hasOwnProperty('isLoading')
    ? saleData.isLoading
    : true
  return (
    <>
      <Row gutter={[32, 32]} style={{ maxWidth: '1140px' }}>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          lg={{ span: 14 }}
          xl={{ span: 16 }}
        >
          <BarChart saleData={saleData} isLoading={isLoadingSaleData} />
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          lg={{ span: 10 }}
          xl={{ span: 8 }}
        >
          <TotalSales
            showSalesData={showSalesData}
            setShowSalesData={setShowSalesData}
            saleData={saleData}
            filteredDonationAmount={filteredDonationAmount}
            isLoading={isLoadingSaleData}
          />{' '}
        </Col>
      </Row>
      <Row gutter={[32, 16]} style={{ maxWidth: '1140px', paddingTop: '32px' }}>
        <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 14 }}>
          <MostVisitedPostsTable mostVisitedData={mostVisitedData} />
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 10 }}>
          <SuperFansTable
            artistSuperFanFollowerData={artistSuperFanFollowerData}
          />
        </Col>
      </Row>
      <Row gutter={[32, 16]} style={{ maxWidth: '1140px', paddingTop: '32px' }}>
        <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 14 }}>
          <AllSongsTable gemaSongsData={gemaSongsData} />
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} lg={{ span: 10 }}>
          <WonFansTable artistFollowerData={artistFollowerData} />
        </Col>
      </Row>
    </>
  )
}
