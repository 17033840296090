export const font_size = [
  { value: '72px' },
  { value: '70px' },
  { value: '68px' },
  { value: '66px' },
  { value: '64px' },
  { value: '62px' },
  { value: '60px' },
  { value: '58px' },
  { value: '56px' },
  { value: '54px' },
  { value: '52px' },
  { value: '50px' },
  { value: '48px' },
  { value: '46px' },
  { value: '44px' },
  { value: '42px' },
  { value: '40px' },
  { value: '38px' },
  { value: '36px' },
  { value: '34px' },
  { value: '32px' },
  { value: '30px' },
  { value: '28px' },
  { value: '26px' },
  { value: '24px' },
  { value: '22px' },
  { value: '20px' },
  { value: '18px' },
  { value: '16px' },
  { value: '14px' },
]

export const font_family = [
  { value: '', name: 'Font Family' },
  { value: 'Aerial', name: 'Aerial' },
  { value: 'Andalus', name: 'Andalus' },
  { value: 'Andy', name: 'Andy' },
  { value: 'AngsanaUPC', name: 'AngsanaUPC' },
  { value: 'Aparajita', name: 'Aparajita' },
  { value: 'Arial', name: 'Arial' },
  { value: 'Bahnschrift', name: 'Bahnschrift' },
  { value: 'BrowalliaUPC', name: 'BrowalliaUPC' },
  { value: 'Bembo', name: 'Bembo' },
  { value: 'Biome', name: 'Biome' },
  { value: 'Broadway', name: 'Broadway' },
  { value: 'Calibri', name: 'Calibri' },
  { value: 'Cambria', name: 'Cambria' },
  { value: 'Candara', name: 'Candara' },
  { value: 'Cariadings', name: 'Cariadings' },
  { value: 'Castellar', name: 'Castellar' },
  { value: 'Cavolini', name: 'Cavolini' },
  { value: 'Centaur', name: 'Centaur' },
  { value: 'Century', name: 'Century' },
  { value: 'Chiller', name: 'Chiller' },
  { value: 'Colonna MT', name: 'Colonna MT' },
  { value: 'Comic Sans MS', name: 'Comic Sans MS' },
  { value: 'Consolas', name: 'Consolas' },
  { value: 'Constantia', name: 'Constantia' },
  { value: 'Contemporary Brush', name: 'Contemporary Brush' },
  { value: 'Cooper Black', name: 'Cooper Black' },
  { value: 'Copperplate Gothic', name: 'Copperplate Gothic' },
  { value: 'Corbel', name: 'Corbel' },
  { value: 'CordiaUPC', name: 'CordiaUPC' },
  { value: 'Courier New', name: 'Courier New' },
  { value: 'Curlz MT', name: 'Curlz MT' },
  { value: 'Dante', name: 'Dante' },
  { value: 'DaunPenh', name: 'DaunPenh' },
  { value: 'David', name: 'David' },
  { value: 'Daytona', name: 'Daytona' },
  { value: 'Desdemona', name: 'Desdemona' },
  { value: 'DilleniaUPC', name: 'DilleniaUPC' },
  { value: 'DokChampa', name: 'DokChampa' },
  { value: 'Ebrima', name: 'Ebrima' },
  { value: 'Eckmann', name: 'Eckmann' },
  { value: 'Edda', name: 'Edda' },
  { value: 'Elephant', name: 'Elephant' },
  { value: 'Enviro', name: 'Enviro' },
  { value: 'EucrosiaUPC', name: 'EucrosiaUPC' },
  { value: 'Euphemia', name: 'Euphemia' },
  { value: 'Eurostile', name: 'Eurostile' },
  { value: 'FangSong', name: 'FangSong' },
  { value: 'Flexure', name: 'Flexure' },
  { value: 'Forte', name: 'Forte' },
  { value: 'FrankRuehl', name: 'FrankRuehl' },
  { value: 'FreesiaUPC', name: 'FreesiaUPC' },
  { value: 'Futura', name: 'Futura' },
  { value: 'Gabriola', name: 'Gabriola' },
  { value: 'Gadugi', name: 'Gadugi' },
  { value: 'Garamond', name: 'Garamond' },
  { value: 'Gautami', name: 'Gautami' },
  { value: 'Georgia', name: 'Georgia' },
  { value: 'Gigi', name: 'Gigi' },
  { value: 'Gill', name: 'Gill' },
  { value: 'Gisha', name: 'Gisha' },
  { value: 'Gloucester', name: 'Gloucester' },
  { value: 'Goudy Old Style', name: 'Goudy Old Style' },
  { value: 'Goudy Stout', name: 'Goudy Stout' },
  { value: 'Gradl', name: 'Gradl' },
  { value: 'Grotesque', name: 'Grotesque' },
  { value: 'Gulim & GulimChe', name: 'Gulim & GulimChe' },
  { value: 'Gungsuh & GungsuhChe', name: 'Gungsuh & GungsuhChe' },
  { value: 'Hadassah Friedlaender', name: 'Hadassah Friedlaender' },
  { value: 'Haettenschweiler', name: 'Haettenschweiler' },
  { value: 'Harlow Solid Italic', name: 'Harlow Solid Italic' },
  { value: 'Harrington', name: 'Harrington' },
  { value: 'Helvetica', name: 'Helvetica' },
  { value: 'HGGothicE', name: 'HGGothicE' },
  { value: 'HGMinchoE', name: 'HGMinchoE' },
  { value: 'HGSoeiKakugothicUB', name: 'HGSoeiKakugothicUB' },
  { value: 'High Tower Text', name: 'High Tower Text' },
  { value: 'Holidays MT', name: 'Holidays MT' },
  { value: 'Impact', name: 'Impact' },
  { value: 'Imprint MT Shadow', name: 'Imprint MT Shadow' },
  { value: 'Informal Roman', name: 'Informal Roman' },
  { value: 'IrisUPC', name: 'IrisUPC' },
  { value: 'Iskoola Pota', name: 'Iskoola Pota' },
  { value: 'JasmineUPC', name: 'JasmineUPC' },
  { value: 'Javanese Text', name: 'Javanese Text' },
  { value: 'Jokerman', name: 'Jokerman' },
  { value: 'Juice ITC', name: 'Juice ITC' },
  { value: 'KaiTi', name: 'KaiTi' },
  { value: 'Kalinga', name: 'Kalinga' },
  { value: 'Kartika', name: 'Kartika' },
  { value: 'Keystrokes MT', name: 'Keystrokes MT' },
  { value: 'Khmer UI', name: 'Khmer UI' },
  { value: 'Kino MT', name: 'Kino MT' },
  { value: 'KodchiangUPC', name: 'KodchiangUPC' },
  { value: 'Kokila', name: 'Kokila' },
  { value: 'Kristen ITC', name: 'Kristen ITC' },
  { value: 'Kunstler Script', name: 'Kunstler Script' },
  { value: 'Lao UI', name: 'Lao UI' },
  { value: 'Latha', name: 'Latha' },
  { value: 'LCD', name: 'LCD' },
  { value: 'Leelawadee', name: 'Leelawadee' },
  { value: 'Levenim MT', name: 'Levenim MT' },
  { value: 'LilyUPC', name: 'LilyUPC' },
  { value: 'Lucida Bright', name: 'Lucida Bright' },
  { value: 'Lucida Fax', name: 'Lucida Fax' },
  { value: 'Lucida Sans', name: 'Lucida Sans' },
  { value: 'Magneto', name: 'Magneto' },
  { value: 'Maiandra GD', name: 'Maiandra GD' },
  { value: 'Mangal', name: 'Mangal' },
  { value: 'Marlett', name: 'Marlett' },
  { value: 'Matisse ITC', name: 'Matisse ITC' },
  { value: 'McZee', name: 'McZee' },
  { value: 'Mead Bold', name: 'Mead Bold' },
  { value: 'Meiryo', name: 'Meiryo' },
  { value: 'Microsoft Himalaya', name: 'Microsoft Himalaya' },
  { value: 'Microsoft JhengHei', name: 'Microsoft JhengHei' },
  { value: 'Microsoft PhagsPa', name: 'Microsoft PhagsPa' },
  { value: 'Microsoft Sans Serif', name: 'Microsoft Sans Serif' },
  { value: 'Microsoft Tai Le', name: 'Microsoft Tai Le' },
  { value: 'Microsoft Uighur', name: 'Microsoft Uighur' },
  { value: 'Microsoft YaHei', name: 'Microsoft YaHei' },
  { value: 'MingLiU', name: 'MingLiU' },
  { value: 'Minion Web', name: 'Minion Web' },
  { value: 'Miriam', name: 'Miriam' },
  { value: 'Mistral', name: 'Mistral' },
  { value: 'Modern Love', name: 'Modern Love' },
  { value: 'Modern No. 20', name: 'Modern No. 20' },
  { value: 'Mongolian Baiti', name: 'Mongolian Baiti' },
  { value: 'Monotype.com', name: 'Monotype.com' },
  { value: 'Monotype Corsiva', name: 'Monotype Corsiva' },
  { value: 'Monotype Sorts', name: 'Monotype Sorts' },
  { value: 'MoolBoran', name: 'MoolBoran' },
  { value: 'MS Gothic', name: 'MS Gothic' },
  { value: 'MS LineDraw', name: 'MS LineDraw' },
  { value: 'MS Mincho', name: 'MS Mincho' },
  { value: 'MS Outlook', name: 'MS Outlook' },
  { value: 'MS PGothic', name: 'MS PGothic' },
  { value: 'MS PMincho', name: 'MS PMincho' },
  { value: 'MS Reference', name: 'MS Reference' },
  { value: 'MT Extra', name: 'MT Extra' },
  { value: 'MV Boli', name: 'MV Boli' },
  { value: 'Myanmar Text', name: 'Myanmar Text' },
  { value: 'Narkisim', name: 'Narkisim' },
  { value: 'New Caledonia', name: 'New Caledonia' },
  { value: 'Niagara', name: 'Niagara' },
  { value: 'Nirmala UI', name: 'Nirmala UI' },
  { value: 'Nyala', name: 'Nyala' },
  { value: 'OCR-B-Digits', name: 'OCR-B-Digits' },
  { value: 'OCR A Extended', name: 'OCR A Extended' },
  { value: 'Old English Text MT', name: 'Old English Text MT' },
  { value: 'Onyx', name: 'Onyx' },
  { value: 'Palace Script MT', name: 'Palace Script MT' },
  { value: 'Palatino Linotype', name: 'Palatino Linotype' },
  { value: 'Papyrus', name: 'Papyrus' },
  { value: 'Parade', name: 'Parade' },
  { value: 'Parchment', name: 'Parchment' },
  { value: 'Parties MT', name: 'Parties MT' },
  { value: 'Peignot Medium', name: 'Peignot Medium' },
  { value: 'Pepita MT', name: 'Pepita MT' },
  { value: 'Perpetua', name: 'Perpetua' },
  { value: 'Placard Condensed', name: 'Placard Condensed' },
  { value: 'Plantagenet Cherokee', name: 'Plantagenet Cherokee' },
  { value: 'Playbill', name: 'Playbill' },
  { value: 'PMingLiU', name: 'PMingLiU' },
  { value: 'Poor Richard', name: 'Poor Richard' },
  { value: 'Posterama', name: 'Posterama' },
  { value: 'Pristina', name: 'Pristina' },
  { value: 'Quire Sans', name: 'Quire Sans' },
  { value: 'Raavi', name: 'Raavi' },
  { value: 'Rage Italic', name: 'Rage Italic' },
  { value: 'Ransom', name: 'Ransom' },
  { value: 'Ravie', name: 'Ravie' },
  { value: 'RefSpecialty', name: 'RefSpecialty' },
  { value: 'Rockwell', name: 'Rockwell' },
  { value: 'Rockwell Nova', name: 'Rockwell Nova' },
  { value: 'Rod', name: 'Rod' },
  { value: 'Runic MT Condensed', name: 'Runic MT Condensed' },
  { value: 'Sabon Next LT', name: 'Sabon Next LT' },
  { value: 'Sagona', name: 'Sagona' },
  { value: 'Sakkal Majalla', name: 'Sakkal Majalla' },
  { value: 'Script MT Bold', name: 'Script MT Bold' },
  { value: 'Segoe Chess', name: 'Segoe Chess' },
  { value: 'Segoe Print', name: 'Segoe Print' },
  { value: 'Segoe Script', name: 'Segoe Script' },
  { value: 'Segoe UI', name: 'Segoe UI' },
  { value: 'Selawik', name: 'Selawik' },
  { value: 'Shonar Bangla', name: 'Shonar Bangla' },
  { value: 'Showcard Gothic', name: 'Showcard Gothic' },
  { value: 'Shruti', name: 'Shruti' },
  { value: 'Signs MT', name: 'Signs MT' },
  { value: 'SimHei', name: 'SimHei' },
  { value: 'Simplified Arabic Fixed', name: 'Simplified Arabic Fixed' },
  { value: 'SimSun', name: 'SimSun' },
  { value: 'SimSun-ExtB', name: 'SimSun-ExtB' },
  { value: 'Sitka', name: 'Sitka' },
  { value: 'Snap ITC', name: 'Snap ITC' },
  { value: 'Sports MT', name: 'Sports MT' },
  { value: 'STCaiyun', name: 'STCaiyun' },
  { value: 'Stencil', name: 'Stencil' },
  { value: 'STFangsong', name: 'STFangsong' },
  { value: 'STHupo', name: 'STHupo' },
  { value: 'STKaiti', name: 'STKaiti' },
  { value: 'Stop', name: 'Stop' },
  { value: 'STXihei', name: 'STXihei' },
  { value: 'STXingkai', name: 'STXingkai' },
  { value: 'STXinwei', name: 'STXinwei' },
  { value: 'STZhongsong', name: 'STZhongsong' },
  { value: 'Sylfaen', name: 'Sylfaen' },
  { value: 'Symbol', name: 'Symbol' },
  { value: 'Tahoma', name: 'Tahoma' },
  { value: 'Tempo Grunge', name: 'Tempo Grunge' },
  { value: 'Tempus Sans ITC', name: 'Tempus Sans ITC' },
  { value: 'Temp Installer Font', name: 'Temp Installer Font' },
  { value: 'The Hand', name: 'The Hand' },
  { value: 'The Serif Hand', name: 'The Serif Hand' },
  { value: 'Times New Roman', name: 'Times New Roman' },
  { value: 'Times New Roman Special', name: 'Times New Roman Special' },
  { value: 'Tisa Offc Serif Pro', name: 'Tisa Offc Serif Pro' },
  { value: 'Traditional Arabic', name: 'Traditional Arabic' },
  { value: 'Transport MT', name: 'Transport MT' },
  { value: 'Transport MT', name: 'Transport MT' },
  { value: 'Tunga', name: 'Tunga' },
  { value: 'Tw Cen MT', name: 'Tw Cen MT' },
  { value: 'Univers', name: 'Univers' },
  { value: 'Urdu Typesetting', name: 'Urdu Typesetting' },
  { value: 'Utsaah', name: 'Utsaah' },
  { value: 'Vacation MT', name: 'Vacation MT' },
  { value: 'Vani', name: 'Vani' },
  { value: 'Verdana', name: 'Verdana' },
  { value: 'Verdana Ref', name: 'Verdana Ref' },
  { value: 'Vijaya', name: 'Vijaya' },
  { value: 'Viner Hand ITC', name: 'Viner Hand ITC' },
  { value: 'Vivaldi', name: 'Vivaldi' },
  { value: 'Vixar ASCI', name: 'Vixar ASCI' },
  { value: 'Vladimir Script', name: 'Vladimir Script' },
  { value: 'Vrinda', name: 'Vrinda' },
  { value: 'Walbaum', name: 'Walbaum' },
  { value: 'Webdings', name: 'Webdings' },
  { value: 'Westminster', name: 'Westminster' },
  { value: 'Wide Latin', name: 'Wide Latin' },
]

export const font_color = [
  { value: 'aqua', name: 'Aqua' },
  { value: 'aquamarine', name: 'Aquamarine' },
  { value: 'bisque', name: 'Bisque' },
  { value: 'black', name: 'Black' },
  { value: 'brown', name: 'Brown' },
  { value: 'burlywood', name: 'Burlywood' },
  { value: 'chartreuse', name: 'Chartreuse' },
  { value: 'chocolate', name: 'Chocolate' },
  { value: 'coral', name: 'Coral' },
  { value: 'cornflowerblue', name: 'Cornflowerblue' },
  { value: 'cornsilk', name: 'Cornsilk' },
  { value: 'crimson', name: 'Crimson' },
  { value: 'cyan', name: 'Cyan' },
  { value: 'darkgoldenrod', name: 'Darkgoldenred' },
  { value: 'firebrick', name: 'Firebrick' },
  { value: 'forestgreen', name: 'Forestgreen' },
  { value: 'hotpink', name: 'Hotpink' },
  { value: 'indigo', name: 'Indigo' },
  { value: 'green', name: 'Green' },
  { value: 'khaki', name: 'Khaki' },
  { value: 'lime', name: 'Lime' },
  { value: 'magenta', name: 'Magenta' },
  { value: 'maroon', name: 'Maroon' },
  { value: 'navy', name: 'Navy' },
  { value: 'olive', name: 'Olive' },
  { value: 'orange', name: 'Orange' },
  { value: 'orchid', name: 'Orchid' },
  { value: 'peru', name: 'Peru' },
  { value: 'plum', name: 'Plum' },
  { value: 'powderblue', name: 'Powderblue' },
  { value: 'rebeccapurple', name: 'Rebeccapurple' },
  { value: 'red', name: 'Red' },
  { value: 'salmon', name: 'Salmon' },
  { value: 'sienna', name: 'Sienna' },
  { value: 'skyblue', name: 'Skyblue' },
  { value: 'springgreen', name: 'Springgreen' },
  { value: 'teal', name: 'Teal' },
  { value: 'thistle', name: 'Thistle' },
  { value: 'tomato', name: 'Tomato' },
  { value: 'turquoise', name: 'Turquoise' },
  { value: 'violet', name: 'Violet' },
  { value: 'wheat', name: 'Wheat' },
  { value: 'yellow', name: 'Yellow' },
  { value: 'yellowgreen', name: 'Yellowgreen' },
]

export const background_color = [
  { value: 'transparent', name: 'None' },
  { value: 'aqua', name: 'Aqua' },
  { value: 'aquamarine', name: 'Aquamarine' },
  { value: 'bisque', name: 'Bisque' },
  { value: 'black', name: 'Black' },
  { value: 'brown', name: 'Brown' },
  { value: 'burlywood', name: 'Burlywood' },
  { value: 'chartreuse', name: 'Chartreuse' },
  { value: 'chocolate', name: 'Chocolate' },
  { value: 'coral', name: 'Coral' },
  { value: 'cornflowerblue', name: 'Cornflowerblue' },
  { value: 'cornsilk', name: 'Cornsilk' },
  { value: 'crimson', name: 'Crimson' },
  { value: 'cyan', name: 'Cyan' },
  { value: 'darkgoldenrod', name: 'Darkgoldenred' },
  { value: 'firebrick', name: 'Firebrick' },
  { value: 'forestgreen', name: 'Forestgreen' },
  { value: 'hotpink', name: 'Hotpink' },
  { value: 'indigo', name: 'Indigo' },
  { value: 'green', name: 'Green' },
  { value: 'khaki', name: 'Khaki' },
  { value: 'lime', name: 'Lime' },
  { value: 'magenta', name: 'Magenta' },
  { value: 'maroon', name: 'Maroon' },
  { value: 'navy', name: 'Navy' },
  { value: 'olive', name: 'Olive' },
  { value: 'orange', name: 'Orange' },
  { value: 'orchid', name: 'Orchid' },
  { value: 'peru', name: 'Peru' },
  { value: 'plum', name: 'Plum' },
  { value: 'powderblue', name: 'Powderblue' },
  { value: 'rebeccapurple', name: 'Rebeccapurple' },
  { value: 'red', name: 'Red' },
  { value: 'salmon', name: 'Salmon' },
  { value: 'sienna', name: 'Sienna' },
  { value: 'skyblue', name: 'Skyblue' },
  { value: 'springgreen', name: 'Springgreen' },
  { value: 'teal', name: 'Teal' },
  { value: 'thistle', name: 'Thistle' },
  { value: 'tomato', name: 'Tomato' },
  { value: 'turquoise', name: 'Turquoise' },
  { value: 'violet', name: 'Violet' },
  { value: 'wheat', name: 'Wheat' },
  { value: 'yellow', name: 'Yellow' },
  { value: 'yellowgreen', name: 'Yellowgreen' },
]
