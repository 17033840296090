const COLORS = {
  primary: {
    defaultLight: '#7cd2c6',
    dark: '#479a8e',
  },
  secondary: {
    light: '#fadcb1',
    dark: '#f9cb88',
  },
  special: {
    onlineGreen: '#4DDB68',
  },
}

export default COLORS
