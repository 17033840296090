import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Loading, { ProgressBar } from './Loading'

const useFullPageLoader = () => {
  const [loading, setLoading] = useState(false)
  return [
    loading ? <Loading /> : null,
    () => setLoading(true),
    () => setLoading(false),
  ]
}

export default useFullPageLoader

export const useProgressBar = () => {
  const [loading, setLoading] = useState(false)
  const progress = useSelector(state => state.fanPage.progress)
  return [
    loading ? <ProgressBar percent={progress} /> : null,
    () => setLoading(true),
    () => setLoading(false),
  ]
}
