import React from 'react'
import TranslateText from '../../translation/TranslateText'
import VideoScreen from './videoScreen'

export default function UploadExclusiveVideo() {
  return (
    <div>
      <VideoScreen
        heading={<TranslateText id="upload_exclusive_video" />}
        postType={2}
      />
    </div>
  )
}
