import React from 'react'
import styles from '../Login/login.module.css'
import { Redirect } from 'react-router-dom'
import TranslateText from '../../translation/TranslateText'
import playStoreIcon from '../../../../plactron_artist_dashboard/src/Assets/Images/playStore.svg'
import appleStoreIcon from '../../../../plactron_artist_dashboard/src/Assets/Images/appleStore.svg'

export default function OnlyForDashboard() {
  const screenWidth = window.innerWidth

  if (screenWidth < 1024) {
    return (
      <div
        style={{
          padding: '70px 0 0 0',
          height: '100vh',
          backgroundColor: '#f2fbf9',
        }}
      >
        <div
          style={{ padding: '40px 0', height: '100%', overflow: 'auto hidden' }}
        >
          <div
            className={styles.signInDiv}
            style={{ height: '100%', minHeight: '400px' }}
          >
            <div
              className={styles.storeContainer}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: '24px',
                padding: '0 20px',
              }}
            >
              <label
                style={{ color: '#303135', fontSize: '16px', opacity: '0.5' }}
              >
                <TranslateText id="only_for_desktop" />
              </label>
              <br />
              <br />
              <label
                style={{ color: '#303135', fontSize: '16px', opacity: '0.5' }}
              >
                <TranslateText id="only_for_desktop_get_app" />
              </label>
              <br />

              <div className={styles.appStoreMenuItem}>
                <a
                  href="https://play.google.com/store/apps/details?id=de.plactron.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playStoreIcon} alt="playStoreIcon" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/plactron-deine-plattform-musik/id1597739270"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleStoreIcon} alt="appleStoreIcon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/upload',
        }}
      />
    )
  }
}
