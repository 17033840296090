import moment from 'moment'

export const addKeysToTableData = data => {
  return data.map((data, index) => {
    return { key: index, ...data }
  })
}

export const getMonthName = month => {
  if (month === undefined) {
    return ''
  }

  const monthCount = parseInt(month.split('.')[0]) - 1
  const year = parseInt(month.split('.')[1])
  return moment().month(monthCount).year(year).format('MMMM yy')
}

export const getMonthNameFromObject = obj => {
  if (obj === undefined) {
    return ''
  }

  const monthCount = parseInt(obj.month) - 1
  const year = parseInt(obj.year)
  return moment().month(monthCount).year(year).format('MMMM yy')
}
