export const getLabelListForTotalSales = dataObj =>
  Object.keys(dataObj).map(x => {
    if (x === 'albums') {
      return 'Albums'
    } else if (x === 'singles') {
      return 'Singles'
    } else if (x === 'revenue') {
      return 'SuperFans'
    } else if (x === 'currentMonthlyDonation') {
      return 'Support'
    }
    return x
  })
