import React from 'react'
import { Form, Input, Button, Switch } from 'antd'

import styleUpload from '../Upload/upload.module.css'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'

const { TextArea } = Input

export default function EventForm({
  heading,
  eventTitle = '',
  eventLocation = '',
  eventLink = '',
  eventDate = '',
  eventPrice = '',
  eventImage = '',
  eventDescription = '',
  handleEventTitle,
  handleEventLocation,
  handleEventLink,
  handleEventDate,
  handleEventPrice,
  handeImage,
  handleEventDescription,
  handleUpdateEvent,
  gotoFanpage,
  mediaIsLandscape,
  handleMediaIsLandscapeChange,
}) {
  const intl = useIntl()
  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  return (
    <div className={styleUpload.cardDiv} style={{ height: '100%' }}>
      <div style={{ paddingTop: '88px' }}>
        <div>
          <label style={{ color: '#232324', fontSize: '24px', opacity: '1' }}>
            <b>{heading}</b>
          </label>
          <div
            style={{
              marginTop: '2vh',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Form
              {...layout}
              name="basic"
              layout={'vertical'}
              initialValues={{
                eventTitle: eventTitle,
                eventDescription: eventDescription,
                eventLocation: eventLocation,
                eventLink: eventLink,
                eventDate: eventDate,
                eventPrice: eventPrice,
                eventImage: eventImage,
              }}
              onFinish={handleUpdateEvent}
            >
              <Form.Item
                label=""
                name="eventTitle"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: intl.formatMessage({
                      id: 'enter_your_event_title',
                    }),
                  },
                ]}
              >
                <Input
                  style={{ width: '316px', height: '47px' }}
                  value={eventTitle}
                  placeholder={intl.formatMessage({ id: 'title' })}
                  maxLength="50"
                  onChange={e => {
                    handleEventTitle(e)
                  }}
                />
              </Form.Item>
              <Form.Item
                label=""
                name="eventDescription"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: intl.formatMessage({
                      id: 'enter_your_event_content',
                    }),
                  },
                ]}
              >
                <TextArea
                  onChange={e => {
                    handleEventDescription(e)
                  }}
                  style={{ width: '316px' }}
                  placeholder={intl.formatMessage({ id: 'event_description' })}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  value={eventDescription}
                />
              </Form.Item>

              <Form.Item
                label=""
                name="eventLocation"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: intl.formatMessage({
                      id: 'enter_your_event_location',
                    }),
                  },
                ]}
              >
                <Input
                  style={{ width: '316px', height: '47px' }}
                  value={eventLocation}
                  placeholder={intl.formatMessage({
                    id: 'ticket_location_missing',
                  })}
                  maxLength="50"
                  onChange={e => {
                    handleEventLocation(e)
                  }}
                />
              </Form.Item>

              <Form.Item
                label=""
                name="eventLink"
                rules={[
                  {
                    type: 'url',
                    message: intl.formatMessage({
                      id: 'ticket_link_wrong_format',
                    }),
                  },
                ]}
              >
                <Input
                  style={{ width: '316px', height: '47px' }}
                  value={eventLink}
                  placeholder={intl.formatMessage({ id: 'ticket_link' })}
                  maxLength="50"
                  onChange={e => {
                    handleEventLink(e)
                  }}
                />
              </Form.Item>

              <Form.Item
                label=""
                name="eventPrice"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'enter_your_event_price',
                    }),
                  },
                ]}
              >
                <Input
                  style={{ width: '316px', height: '47px' }}
                  value={eventPrice}
                  placeholder={intl.formatMessage({ id: 'price_per_ticket' })}
                  maxLength="6"
                  onChange={e => {
                    handleEventPrice(e)
                  }}
                />
              </Form.Item>
              <Switch
                checked={mediaIsLandscape}
                onChange={handleMediaIsLandscapeChange}
                checkedChildren={<TranslateText id="medium_is_landscape" />}
                unCheckedChildren={<TranslateText id="medium_is_landscape" />}
              />

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Form.Item style={{ marginBottom: '16px' }}>
                  <Button
                    style={{ marginTop: '70px', width: '240px' }}
                    htmlType="submit"
                    className={styleUpload.greenColorBtn}
                  >
                    <TranslateText id="update_now" />
                  </Button>
                </Form.Item>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <label
                  style={{
                    color: '#232324',
                    opacity: '1',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <span
                    style={{
                      color: '#7CD2C6',
                      cursor: 'pointer',
                      textTransform: 'uppercase',
                    }}
                    onClick={gotoFanpage}
                  >
                    <TranslateText id="back" />
                  </span>{' '}
                </label>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
