import React from 'react'
import TranslateText from '../../translation/TranslateText'
import AudioScreen from './audioScreen'

export default function UploadExclusiveAudio() {
  return (
    <div>
      <AudioScreen
        heading={<TranslateText id="upload_exclusive_audio" />}
        postType={2}
      />
    </div>
  )
}
