// regex constants

import TranslateText from '../../translation/TranslateText'

const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d]).{8,}$/
const regexNum = /^(?=.*?[\d]).+$/
const regexChar = /^(?=.*?[a-z]).+$/
const regexCaps = /^(?=.*?[A-Z]).+$/

// validation for empty input field

export const showEmptyFieldError = (emptyField, content, msg) => {
  if (emptyField && content === '') {
    return <div className="errMsg">{msg}</div>
  }
  return ''
}

// validation for contact number

export const showContactNumberInvalidError = (contactNumberValid, msg) => {
  if (contactNumberValid) {
    return <div className="errMsg">{msg}</div>
  }
  return ''
}

// validation for password

export const passwordValidation = (_, value) => {
  if (!value || /^\S*$/i.test(value)) {
    if (!value || regexNum.test(value)) {
      if (!value || regexChar.test(value)) {
        if (!value || regexCaps.test(value)) {
          if (!value || regex.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(
            <TranslateText id="password_must_contain_eight_letters" />
          )
        }
        return Promise.reject(
          <TranslateText id="password_must_contain_capital_letter" />
        )
      }
      return Promise.reject(
        <TranslateText id="password_must_contain_small_character" />
      )
    }
    return Promise.reject(
      <TranslateText id="password_must_contain_numeric_also" />
    )
  }
  return Promise.reject(
    <TranslateText id="password_must_contain_letter_with_no_whitespace" />
  )
}
