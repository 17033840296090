import React from 'react'
import { Modal, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import TranslateText from '../../translation/TranslateText'

export default function VideoPostTypePopup(props) {
  return (
    <Modal
      title=""
      closable={false}
      centered
      open={props.show}
      maskClosable={false}
      footer={null}
      width={430}
    >
      <div style={{ margin: '-1em 0 -0.5em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 style={{ width: '100%', textAlign: 'center' }}>
            <TranslateText id="video_post_type_popup_title" />
          </h4>
          <div>
            <CloseOutlined
              style={{ color: '#777777', marginRight: '-10px' }}
              onClick={() => {
                props.handleCancel()
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1em',
          }}
        >
          <div style={{ marginRight: '6em' }}>
            <Button
              onClick={() => props.handleSelect(4)}
              style={{
                borderColor: '#7cd2c6',
                background: 'white',
                borderRadius: '24px',
                color: '#7cd2c6',
              }}
            >
              <TranslateText id="video_post_type_file" />
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => props.handleSelect(2)}
              style={{
                background: '#7cd2c6',
                borderRadius: '24px',
                marginRight: '12px',
                borderColor: '#7cd2c6',
                color: 'white',
              }}
            >
              <TranslateText id="video_post_type_youtube" />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
