import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Tooltip, Popconfirm } from 'antd'
import { IoTrashOutline } from 'react-icons/io5'
import moment from 'moment'

import { getArtistById, setMessageDetails } from '../Redux/actions/auth'
import {
  commentsByPostId,
  getAllPosts,
  deletePost,
  updatePostTypeByID,
  getAllEvents,
  deleteEvent,
} from '../Redux/actions/fanPage'
import { fanPageType } from '../Redux/types'

import AppConfig from '../../services'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import CommentModalPopup from '../../components/CommentModalPopup'

import styles from '../Upload/upload.module.css'

import styleFanpage from './fanPage.module.css'
import PostTypePopup from './postTypePopup'

import commentIcon from '../../Assets/Images/comment.png'
import editIcon from '../../Assets/Images/editIcon.svg'
import EventImage from '../../Assets/Images/NewEvent.png'
import likeIcon from '../../Assets/Images/heart.svg'
import postImage from '../../Assets/Images/PostImage.png'
import videoImage from '../../Assets/Images/uploadVideo.png'
import superFanIcon from '../../Assets/Images/superFan.png'
import nonSuperFanIcon from '../../Assets/Images/nonSuperFan.png'
import audioImage from '../../Assets/Images/file-audio.png'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { cloudFrontImageSize } from '../../constants'
import VideoPostTypePopup from './videoPostTypePopup'

const FanPage = props => {
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [commentModalVisible, setCommentModalVisible] = useState(false)
  const [postTypeModalVisible, setPostTypeModalVisible] = useState(false)
  const [showVideoPostTypePopup, setShowVideoPostTypePopup] = useState(false)
  const artistId = JSON.parse(localStorage.getItem('artistId'))
  const superfanSubscriptionEnabled =
    useSelector(state => state.auth.subscriptionEnabledStatus) || false

  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId }))
    dispatch(getAllPosts()).then(response => {
      if (response) {
        hideLoader()
      }
    })
    dispatch(getAllEvents()).then(response => {
      if (response) {
        hideLoader()
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const posts = useSelector(state => state.fanPage.posts)
  const artistEvents = useSelector(state => state.fanPage.artistEvents)

  const editPost = id => {
    dispatch({
      type: fanPageType.UPDATE_POST_DATA,
      payload: id,
    })
    props.history.push('/fanpage/editPost')
  }

  const editEvent = id => {
    dispatch({
      type: fanPageType.UPDATE_EVENT_DATA,
      payload: id,
    })
    props.history.push('/fanpage/editEvent')
  }

  const handleDeletePost = id => {
    dispatch(deletePost(id))
  }

  const handleDeleteEvent = id => {
    dispatch(deleteEvent(id))
  }

  const likeCount = count => {
    if (count > 1) {
      return `${count} Likes`
    }
    return `${count} Like`
  }

  // unused
  //  const interestedCount = count => {
  //    if (count > 1) {
  //      return `${count} Interested`;
  //    }
  //    return `${count} Interested`;
  //  };

  const commentCount = count => {
    if (count > 1) {
      return `${count} Comments`
    }
    return `${count} Comment`
  }

  const showComment = (postId, count) => {
    const body = {
      postId: postId,
    }
    if (count > 0) {
      dispatch(commentsByPostId(body)).then(res => {
        if (res && res.success) {
          props.history.push({
            postId: postId,
          })
          setCommentModalVisible(true)
        }
      })
    }
  }

  const updatePostType = (id, postType) => {
    showLoader()
    dispatch(
      updatePostTypeByID(id, {
        postType: postType,
      })
    ).then(res => {
      if (res && res.success) {
        window.location.reload()
      }
    })
  }

  const showExclusiveIcon = post => {
    if (post.postType === 1) {
      return (
        <Tooltip
          onClick={() => {
            if (superfanSubscriptionEnabled) {
              updatePostType(post._id, 2)
            } else {
              dispatch(
                setMessageDetails(
                  'info',
                  intl.formatMessage({ id: 'enable_superfan_subscription' })
                )
              )
            }
          }}
          placement="top"
          color="#fadcb1"
          title={intl.formatMessage({ id: 'non_superFan_post' })}
        >
          <img
            style={{
              width: '28px',
              height: '28px',
              marginRight: '0.5em',
              cursor: 'pointer',
            }}
            src={nonSuperFanIcon}
            alt="nonSuperFanIcon"
          />
        </Tooltip>
      )
    }
    return (
      <Tooltip
        onClick={() => updatePostType(post._id, 1)}
        placement="top"
        color="#fadcb1"
        title={intl.formatMessage({ id: 'superFan_post' })}
      >
        <img
          style={{
            width: '28px',
            height: '28px',
            cursor: 'pointer',
            marginRight: '0.5em',
          }}
          src={superFanIcon}
          alt="superFanIcon"
        />
      </Tooltip>
    )
  }

  const postMedia = post => {
    if (post.uploadedType === 1) {
      return (
        <div className={styleFanpage.imageContainer}>
          {post.postImage !== '' ? (
            <img
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              src={AppConfig.IMAGE_URL + cloudFrontImageSize + post.postImage}
              alt="postImage"
            />
          ) : (
            <div style={{ maxHeight: '100%', maxWidth: '100%' }}>
              <p>{post.description}</p>
            </div>
          )}
        </div>
      )
    } else if (post.uploadedType === 2) {
      return (
        <div className={styleFanpage.imageContainer}>
          <iframe
            width="100%"
            src={'https://www.youtube.com/embed/' + post.postYoutubeLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        </div>
      )
    } else if (post.uploadedType === 3) {
      return (
        <div className={styleFanpage.imageContainer}>
          <audio controls src={AppConfig.SONG_URL + post.postAudio}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </div>
      )
    } else if (post.uploadedType === 4) {
      return (
        <div className={styleFanpage.imageContainer}>
          <video
            style={{ maxHeight: '100%', maxWidth: '100%' }}
            src={AppConfig.SONG_URL + post.postVideo}
            controls
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )
    }
  }

  const showPostNameType = post => {
    switch (post.uploadedType) {
      case 1:
        return <TranslateText id="image_post" />
      case 2:
        return <TranslateText id="video_post" />
      case 3:
        return <TranslateText id="audio_post" />
      case 4:
        return <TranslateText id="video_post" />
      default:
        break
    }
  }

  return (
    <div>
      <div>
        <div>
          <div className={styles.contentDiv}>
            <label className={styles.label}>
              <TranslateText id="create_new_entry" />
            </label>
          </div>
        </div>{' '}
        <br />
        <div style={{ marginRight: '-1.8rem' }} className={styles.contentDiv}>
          <div
            style={{ marginRight: '1.8rem', marginBottom: '20px' }}
            className={styles.UploadFanpageDiv}
            onClick={() => {
              setPostTypeModalVisible(true)
              localStorage.setItem('postType', 'image')
            }}
          >
            <div className={styles.uploadSingleDiv}>
              <img alt="postImage" src={postImage} />
            </div>
            <label className={styles.UploadLabel} style={{ fontSize: '18px' }}>
              <TranslateText id="create_new_post" />
            </label>
          </div>
          <div
            className={styles.UploadFanpageDiv}
            style={{ marginRight: '1.8rem', marginBottom: '20px' }}
            onClick={() => {
              localStorage.setItem('postType', 'video')
              setShowVideoPostTypePopup(true)
            }}
          >
            <div className={styles.uploadSingleDiv}>
              <img alt="videoImage" src={videoImage} />
            </div>
            <label className={styles.UploadLabel} style={{ fontSize: '18px' }}>
              <TranslateText id="upload_video" />
            </label>
          </div>
          <div
            className={styles.UploadFanpageDiv}
            style={{ marginRight: '1.8rem', marginBottom: '20px' }}
            onClick={() => {
              setPostTypeModalVisible(true)
              localStorage.setItem('postType', 'audio')
            }}
          >
            <div className={styles.uploadSingleDiv}>
              <img alt="audioImage" src={audioImage} />
            </div>
            <label className={styles.UploadLabel} style={{ fontSize: '18px' }}>
              <TranslateText id="upload_audio" />
            </label>
          </div>

          <div
            className={styles.UploadFanpageDiv}
            style={{ marginRight: '1.8rem', marginBottom: '20px' }}
            onClick={() => {
              history.push('/fanpage/uploadEvent')
              //setPostTypeModalVisible(true);
              localStorage.setItem('postType', 'event')
            }}
          >
            <div className={styles.uploadSingleDiv}>
              <img alt="eventImage" src={EventImage} />
            </div>
            <label className={styles.UploadLabel} style={{ fontSize: '18px' }}>
              <TranslateText id="create_event" />
            </label>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className={styles.contentDiv}>
            <label className={styles.label}>
              <TranslateText id="last_entries" />
            </label>
          </div>
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            marginRight: '-1.8rem',
          }}
        >
          <Row gutter={[32, 32]} style={{ width: '100%', maxWidth: '1140px' }}>
            {posts && posts.length > 0
              ? posts.map(post => (
                  <Col lg={12} sm={24} xl={8} key={post._id}>
                    <div
                      style={{
                        height: '300px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                      }}
                    >
                      <div style={{ height: '70px', padding: '15px 20px 0px' }}>
                        <div style={{ height: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                fontSize: '18px',
                                fontFamily: 'Barlow',
                                fontWeight: '500',
                                textAlign: 'left',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {post.postName}
                            </div>
                            <div style={{ display: 'flex' }}>
                              {showExclusiveIcon(post)}
                              <img
                                onClick={() => {
                                  editPost(post._id)
                                }}
                                style={{
                                  cursor: 'pointer',
                                  height: '24px',
                                  marginRight: '0.5em',
                                }}
                                src={editIcon}
                                alt="editIcon"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Barlow',
                              color: '#7CD2C6',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              {moment(post.scheduleOn).format('DD.MM.YYYY')}
                            </div>
                            <div>{showPostNameType(post)}</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.newClass}>{postMedia(post)}</div>
                      <div style={{ height: '42px' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '8px 20px',
                          }}
                        >
                          <Popconfirm
                            title={<TranslateText id="are_you_sure" />}
                            okText={<TranslateText id="delete" />}
                            cancelText={<TranslateText id="no" />}
                            onConfirm={() => {
                              handleDeletePost(post._id)
                            }}
                          >
                            <IoTrashOutline
                              style={{
                                fontSize: '20px',
                                cursor: 'pointer',
                                color: '#888888',
                              }}
                            />
                          </Popconfirm>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexGrow: 1,
                            }}
                          >
                            <span>
                              <img
                                src={likeIcon}
                                alt="likeIcon"
                                style={{ padding: '0 0.5em' }}
                              />{' '}
                              {likeCount(post.likesCount)}
                              <img
                                src={commentIcon}
                                alt="commentIcon"
                                style={{ padding: '0 0.5em' }}
                              />
                              <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  showComment(post._id, post.commentsCount)
                                }}
                              >
                                {' '}
                                {commentCount(post.commentsCount)}{' '}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              : ''}
          </Row>
        </div>
      </div>

      <div>
        <div>
          <div className={styles.contentDiv}>
            <label className={styles.label}>
              <TranslateText id="last_events" />
            </label>
          </div>
        </div>
        <br />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            marginRight: '-1.8rem',
          }}
        >
          <Row gutter={[32, 32]} style={{ width: '100%', maxWidth: '1140px' }}>
            {artistEvents && artistEvents.length > 0
              ? artistEvents.map(event => (
                  <Col lg={12} sm={24} xl={8} key={event._id}>
                    <div
                      style={{
                        height: '300px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                      }}
                    >
                      <div style={{ height: '70px', padding: '15px 20px 0px' }}>
                        <div style={{ height: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                fontSize: '18px',
                                fontFamily: 'Barlow',
                                fontWeight: '500',
                                textAlign: 'left',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {event.eventName}
                            </div>
                            {
                              <div style={{ display: 'flex' }}>
                                <img
                                  onClick={() => {
                                    editEvent(event._id)
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    height: '24px',
                                    marginRight: '0.5em',
                                  }}
                                  src={editIcon}
                                  alt="editIcon"
                                />
                              </div>
                            }
                          </div>
                          <div
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Barlow',
                              color: '#7CD2C6',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              {moment(event.eventOn).format('DD.MM.YYYY')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.newClass}>
                        <div className={styleFanpage.imageContainer}>
                          <img
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                            src={
                              AppConfig.IMAGE_URL +
                              cloudFrontImageSize +
                              event.eventImage
                            }
                            alt="postImage"
                          />
                        </div>
                      </div>
                      <div style={{ height: '42px' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '8px 20px',
                          }}
                        >
                          <Popconfirm
                            title={<TranslateText id="are_you_sure" />}
                            okText={<TranslateText id="delete" />}
                            cancelText={<TranslateText id="no" />}
                            onConfirm={() => {
                              handleDeleteEvent(event._id)
                            }}
                          >
                            <IoTrashOutline
                              style={{
                                fontSize: '20px',
                                cursor: 'pointer',
                                color: '#888888',
                              }}
                            />
                          </Popconfirm>
                          {
                            // Will be added in the upcomming versions
                            /*
												<div style={{display: "flex", justifyContent: "center", flexGrow: 1}}>
												<span>
													<img src={likeIcon} alt="likeIcon"
														 style={{padding: "0 0.5em"}}/> {interestedCount(event.intrested)}
												</span>
												</div> */
                          }
                        </div>
                      </div>
                    </div>
                  </Col>
                ))
              : ''}
          </Row>
        </div>
      </div>

      {loader}
      <CommentModalPopup
        show={commentModalVisible}
        handleCancel={() => setCommentModalVisible(false)}
      />
      <PostTypePopup
        show={postTypeModalVisible}
        handleCancel={() => setPostTypeModalVisible(false)}
      />
      <VideoPostTypePopup
        show={showVideoPostTypePopup}
        handleCancel={() => setShowVideoPostTypePopup(false)}
        handleSelect={uploadType => {
          localStorage.setItem('videoUploadType', uploadType)
          setShowVideoPostTypePopup(false)
          setPostTypeModalVisible(true)
        }}
      />
    </div>
  )
}

const WrappedFanPage = withRouter(FanPage)
export default WrappedFanPage
