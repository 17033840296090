import { mapKeys } from 'lodash'
import {
  GET_DOWNLOAD_COUNT,
  GET_SALE_COUNT,
  GET_MOST_VISITED_POST,
  ARTIST_SONGS_LIST,
  GET_SALE_COUNT_LOADING,
} from '../types'
const INITIAL_STATE = {
  downloadCount: {},
  saleCount: {},
  mostVisitedPost: {},
  songsList: {},
  totalSongs: null,
}

export default function userReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case GET_DOWNLOAD_COUNT:
      return {
        ...state,
        downloadCount: action.payload,
      }
    case GET_SALE_COUNT_LOADING:
      return {
        ...state,
        saleCount: {
          ...state.saleCount,
          isLoading: true,
        },
      }
    case GET_SALE_COUNT:
      return {
        ...state,
        saleCount: { ...action.payload, isLoading: false },
      }
    case GET_MOST_VISITED_POST:
      return {
        ...state,
        mostVisitedPost: { ...mapKeys(action.payload, '_id') },
      }
    case ARTIST_SONGS_LIST:
      return {
        ...state,
        songsList: { ...mapKeys(action.payload.songs, '_id') },
        totalSongs: action.payload.totalSongSCount,
      }
    default:
      return state
  }
}
