import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Form, Row, Col, Upload, Input, Button } from 'antd'
import moment from 'moment'
import youtubeUrl from 'youtube-url'

import { getArtistById, setMessageDetails } from '../Redux/actions/auth'
import { editPostById } from '../Redux/actions/fanPage'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import devConfig from '../../services/index'

import styles from '../Login/login.module.css'
import styleUpload from '../Upload/upload.module.css'
import styleFanpage from './fanPage.module.css'

import commentIcon from '../../Assets/Images/comment.png'
import likeIcon from '../../Assets/Images/heart.svg'
import PostForm from './postForm'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { cloudFrontImageSize } from '../../constants'

const { Dragger } = Upload

export default function EditPost(props) {
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const dispatch = useDispatch()
  const intl = useIntl()

  const [fileList, setFileList] = React.useState('')
  const [audioFileList, setAudioFileList] = React.useState('')
  const [videoFileList, setVideoFileList] = React.useState('')
  const [youtubeLink, setYoutubeLink] = React.useState('')
  const [youtubeVideoUniqueID, setYoutubeVideoUniqueID] = React.useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [currentAudioUrl, setCurrentAudioUrl] = useState('')
  const [currentVideoUrl, setCurrentVideoUrl] = useState('')
  const [description, setDescription] = useState('')
  const [title, setTitle] = React.useState('')
  const [loader, showLoader, hideLoader] = useFullPageLoader()

  const post = useSelector(state => state.fanPage.selectedPost)
  const postId = useSelector(state => state.fanPage.postId)
  const postImage = useSelector(state => state.fanPage.postImage)
  const postAudio = useSelector(state => state.fanPage.postAudio)
  const postYoutubeLink = useSelector(state => state.fanPage.postYoutubeLink)
  const postVideo = useSelector(state => state.fanPage.postVideo)
  const postOldName = useSelector(state => state.fanPage.postName)
  const postOldDescription = useSelector(state => state.fanPage.postDescription)
  const postOldIsLandscape = useSelector(state => state.fanPage.postIsLandscape)
  const [mediaIsLandscape, setMediaIsLandscape] = useState(postOldIsLandscape)

  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      hideLoader()
      if (response && response.success) {
        return
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleUpdatePostDetails = (id, data, flag) => {
    dispatch(editPostById(id, data, flag)).then(response => {
      hideLoader()
      if (response && response.success) {
        props.history.push('/fanpage')
      }
    })
  }

  const updatePostDetails = values => {
    let videoID

    if (youtubeLink === '') {
      videoID = postYoutubeLink
    } else {
      videoID = youtubeUrl.extractId(youtubeLink)
      if (!videoID)
        return dispatch(
          setMessageDetails('error', intl.formatMessage({ id: 'paste_url' }))
        )
    }

    console.log('postName', title)
    console.log('postContent', description)
    console.log('values', values)

    const id = postId
    const newPostName = values.postName.trim()
    const newPostDescription = values.description.trim()
    var formdata = new FormData()
    const data = {
      postName: newPostName,
      postImage: postImage,
      description: newPostDescription,
      postYoutubeLink: videoID,
      postAudio: postAudio,
      postVideo: postVideo,
      mediaIsLandscape,
    }

    formdata.append('postName', newPostName)
    formdata.append('description', newPostDescription)
    formdata.append('postImage', fileList)
    formdata.append('postAudio', audioFileList)
    formdata.append('postVideo', videoFileList)
    formdata.append('postYoutubeLink', youtubeVideoUniqueID)
    formdata.append('mediaIsLandscape', mediaIsLandscape)
    console.log('data', data)
    showLoader()
    if (imageUrl === '' && currentAudioUrl === '' && currentVideoUrl === '') {
      handleUpdatePostDetails(id, data, false)
    } else {
      handleUpdatePostDetails(id, formdata, true)
    }
  }

  const getBase64 = file => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      reader.onloadend = function (e) {
        setImageUrl(reader.result)
        setFileList(file)
      }
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'upload_jpg_or_png_file' })
        )
      )
    }
  }

  const handleImageUpload = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      getBase64(event.file.originFileObj)
    }
  }

  const storeAudioFile = file => {
    console.log('file', file)
    if (file.type === 'audio/mpeg' || file.type === 'audio/wav') {
      setAudioFileList(file)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'upload_wav_or_mp3_file' })
        )
      )
    }
  }

  const handleAudioUpload = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      storeAudioFile(event.file.originFileObj)
      var reader = new FileReader()
      reader.readAsDataURL(event.file.originFileObj)
      reader.onload = function (e) {
        setCurrentAudioUrl(e.target.result)
      }
    }
  }

  const storeVideoFile = file => {
    if (file.type === 'video/mp4') {
      console.log('file', file)
      setVideoFileList(file)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'upload_mp4_file' })
        )
      )
    }
  }

  const handleVideoUpload = ({ file }) => {
    console.log('fileEvent', file)
    storeVideoFile(file)

    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      setCurrentVideoUrl(e.target.result)
      console.log('videoURL', e.target.result)
    }
  }

  const gotoFanpage = () => {
    props.history.push('/fanpage')
  }

  const showPostImage = () => {
    if (imageUrl === '') {
      if (post.postImage === '') {
        return null
      }
      return (
        <img
          style={{ maxHeight: '300px', maxWidth: '500px' }}
          src={devConfig.IMAGE_URL + cloudFrontImageSize + post.postImage}
          alt="postImage"
        />
      )
    }
    return (
      <img
        style={{ maxWidth: '500px', maxHeight: '300px' }}
        src={imageUrl}
        alt="newPostImage"
      />
    )
  }

  const showVideoPost = () => {
    if (youtubeVideoUniqueID === '') {
      return (
        <iframe
          width="400"
          height="315"
          src={'https://www.youtube.com/embed/' + post.postYoutubeLink}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="true"
        ></iframe>
      )
    }
    return (
      <iframe
        width="400"
        height="315"
        src={'https://www.youtube.com/embed/' + youtubeVideoUniqueID}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="true"
      ></iframe>
    )
  }

  const showAudioPost = () => {
    if (currentAudioUrl === '') {
      return (
        <audio controls src={devConfig.SONG_URL + post.postAudio}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      )
    }
    return (
      <audio controls src={currentAudioUrl}>
        Your browser does not support the
        <code>audio</code> element.
      </audio>
    )
  }

  const showExclusiveVideoPost = () => {
    if (currentVideoUrl === '') {
      return (
        <video
          style={{ maxHeight: '370px', maxWidth: '500px' }}
          src={devConfig.SONG_URL + post.postVideo}
          controls
        >
          Your browser does not support the video tag.
        </video>
      )
    }
    return (
      <video
        style={{ maxHeight: '370px', maxWidth: '500px' }}
        src={currentVideoUrl}
        controls
      >
        Your browser does not support the video tag.
      </video>
    )
  }

  const showPostTypeName = () => {
    switch (post.uploadedType) {
      case 1:
        return <TranslateText id="image_post" />

      case 2:
        return <TranslateText id="video_post" />

      case 3:
        return <TranslateText id="audio_post" />

      case 4:
        return <TranslateText id="video_post" />

      default:
        break
    }
  }

  const showPostContent = () => {
    switch (post.uploadedType) {
      case 1:
        return showPostImage()

      case 2:
        return showVideoPost()

      case 3:
        return showAudioPost()

      case 4:
        return showExclusiveVideoPost()

      default:
        break
    }
  }

  const showPostForm = () => {
    return (
      <div style={{ height: '231px', width: '100%' }}>
        <Dragger
          style={{
            background: '#F2FBF9 0% 0% no-repeat padding-box',
            border: '1px dashed #7CD2C6',
            borderRadius: '12px',
          }}
          onChange={handleImageUpload}
        >
          <p
            className="ant-upload-text"
            style={{ color: '#7CD2C6', fontSize: '14px' }}
          >
            <TranslateText id="drop_images_for_post" />
          </p>
        </Dragger>
      </div>
    )
  }

  const showVideoForm = () => {
    return (
      <div style={{ width: '100%', height: '100px', display: 'flex' }}>
        <Input
          style={{ height: '47px' }}
          placeholder={intl.formatMessage({ id: 'paste_youtube_url' })}
          maxLength="50"
          onChange={e => {
            setYoutubeLink(e.target.value)
          }}
        />
        <Button
          style={{
            height: '47px',
            marginLeft: '1rem',
            borderRadius: '6px',
            fontSize: '1.25rem',
          }}
          className={styleUpload.greenColorBtn}
          onClick={() => {
            const id = youtubeUrl.extractId(youtubeLink)
            if (id) {
              setYoutubeVideoUniqueID(id)
            } else {
              console.log('invalid youtube url')
              setYoutubeVideoUniqueID('')
              dispatch(
                setMessageDetails(
                  'error',
                  intl.formatMessage({ id: 'paste_youtube_url' })
                )
              )
            }
          }}
        >
          <TranslateText id="preview" />
        </Button>
      </div>
    )
  }

  const showAudioForm = () => {
    return (
      <div style={{ height: '231px', width: '100%' }}>
        <Dragger
          style={{
            background: '#F2FBF9 0% 0% no-repeat padding-box',
            border: '1px dashed #7CD2C6',
            borderRadius: '12px',
          }}
          onChange={handleAudioUpload}
        >
          <p
            className="ant-upload-text"
            style={{ color: '#7CD2C6', fontSize: '14px' }}
          >
            <TranslateText id="drop_audio_for_post" />
          </p>
        </Dragger>
      </div>
    )
  }

  const showExclusiveVideoForm = () => {
    return (
      <div style={{ width: '100%', height: '231px' }}>
        <Dragger
          style={{
            borderRadius: '12px',
            border: '1px dashed #7CD2C6',
            background: '#F2FBF9 0% 0% no-repeat padding-box',
          }}
          beforeUpload={() => false}
          onChange={handleVideoUpload}
        >
          <p
            className="ant-upload-text"
            style={{ color: '#7CD2C6', fontSize: '14px' }}
          >
            <TranslateText id="drop_video_for_post" />
          </p>
        </Dragger>
      </div>
    )
  }

  const showUploadForm = () => {
    switch (post.uploadedType) {
      case 1:
        return showPostForm()

      case 2:
        return showVideoForm()

      case 3:
        return showAudioForm()

      case 4:
        return showExclusiveVideoForm()

      default:
        break
    }
  }

  const likeCount = count => {
    if (count > 1) {
      return `${count} Likes`
    }
    return `${count} Like`
  }

  const commentCount = count => {
    if (count > 1) {
      return `${count} Comments`
    }
    return `${count} Comment`
  }

  return (
    <div>
      {postId === '' ? (
        <Redirect
          to={{
            pathname: '/fanpage',
          }}
        />
      ) : (
        <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
          <div
            style={{
              padding: '40px 30px 90px',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Row gutter={[32, 32]} style={{ height: '100%' }}>
              <Col xs={24} lg={12}>
                <PostForm
                  heading={<TranslateText id="edit_your_post" />}
                  postTitle={postOldName}
                  postContent={postOldDescription}
                  handlePostTitle={e => setTitle(e.target.value)}
                  handlePostContent={e => {
                    setDescription(e.target.value)
                  }}
                  buttonName={<TranslateText id="update_now" />}
                  gotoFanpage={gotoFanpage}
                  handleSendPost={updatePostDetails}
                  showMediaIsLandscapeToggle={true}
                  mediaIsLandscape={mediaIsLandscape}
                  handleMediaIsLandscapeChange={setMediaIsLandscape}
                />
              </Col>
              <Col
                lg={12}
                xs={24}
                style={{ height: '100%', paddingLeft: '16px' }}
              >
                <div
                  style={{
                    maxWidth: '674px',
                    marginRight: 'auto',
                    overflow: 'hidden auto',
                  }}
                >
                  <Form {...layout} name="basic" layout={'vertical'}>
                    {showUploadForm()}
                    <div
                      style={{
                        width: '540px',
                        margin: '30px 0 0',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                      }}
                    >
                      <div style={{ padding: '15px 20px' }}>
                        <div style={{ height: '100%' }}>
                          <div
                            style={{
                              fontSize: '18px',
                              fontFamily: 'Barlow',
                              fontWeight: '500',
                              textAlign: 'left',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {postOldName}
                          </div>
                          <div
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Barlow',
                              color: '#7CD2C6',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>
                              {moment(post.createdAt).format('MMMM DD YYYY')}
                            </div>
                            <div>{showPostTypeName()}</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={styleUpload.newClass}>
                          <div className={styleFanpage.imageContainer}>
                            {showPostContent()}
                          </div>
                        </div>
                        <div style={{ height: '42px' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '8px',
                            }}
                          >
                            <span>
                              <img
                                src={likeIcon}
                                alt="likeIcon"
                                style={{ padding: '0 14px' }}
                              />{' '}
                              {likeCount(post.likesCount)}
                              <img
                                src={commentIcon}
                                alt="commentIcon"
                                style={{ padding: '0 14px' }}
                              />{' '}
                              {commentCount(post.commentsCount)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {loader}
    </div>
  )
}
