import React from 'react'
import TranslateText from '../../translation/TranslateText'
import PostScreen from './PostScreen'

export default function CreateExclusivePost() {
  return (
    <div>
      <PostScreen
        heading={<TranslateText id="create_exclusive_post" />}
        postType={2}
      />
    </div>
  )
}
