import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, withRouter, useLocation } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { EyeTwoTone } from '@ant-design/icons'
import {
  changePasswordRequest,
  artistPasswordChange,
  setMessageDetails,
} from '../Redux/actions/auth'
import styles from './login.module.css'
import musicIcon from '../../../src/Assets/Images/musicIcon.png'
import eyeIcon from '../../Assets/Images/eyeIcon.png'
import { passwordValidation } from '../../components/CommonScreen/validation'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import mark from '../../Assets/Images/newWhiteBg.svg'

const ForgotPasswordScreen = props => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [slider, setSlider] = useState(0)
  const [otpValue, setOtpValue] = useState('')

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const [email, setEmail] = useState(query.get('email'))

  const handleSendOtp = data => {
    dispatch(changePasswordRequest(data)).then(resp => {
      if (resp && resp.success) {
        setSlider(1)
      }
    })
  }

  const handlePasswordRequest = values => {
    setEmail(values.email)

    handleSendOtp(values)
  }

  const handleSavePassword = values => {
    delete values.confirmPassword
    values.email = email
    values.otpNum = otpValue
    if (otpValue === '') {
      dispatch(
        setMessageDetails('error', intl.formatMessage({ id: 'enter_your_otp' }))
      )
    } else {
      dispatch(artistPasswordChange(values)).then(resp => {
        if (resp && resp.success) {
          props.history.push('/login')
        }
      })
    }
  }

  const layout = {
    labelCol: {
      // offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const resendOtp = () => {
    const values = {
      email: email,
    }

    handleSendOtp(values)
  }

  const loginData = JSON.parse(localStorage.getItem('token'))

  if (loginData === null) {
    return (
      <div style={{ padding: '70px 0 0 0' }} className={styles.mainDiv}>
        <div
          style={{ padding: '14px 0 90px 0', height: '100%', overflow: 'auto' }}
        >
          <div style={{ height: '100%' }} className={styles.signInDiv}>
            <div
              className={styles.formDiv}
              style={{
                backgroundColor: '#fff',
                width: 700,
                height: 720,
                top: '12%',
                position: 'absolute',
                alignSelf: 'center',
                borderRadius: 12,
                boxShadow: '2px 1px 9px #e0e0e0',
                overflow: 'hidden',
              }}
            >
              <img
                alt="altTag"
                style={{
                  position: 'absolute',
                  width: 400,
                  bottom: '-12%',
                  right: '-10%',
                }}
                src={mark}
              />
            </div>
            <div className={styles.formDiv}>
              {slider === 0 ? (
                <>
                  <div>
                    <label
                      style={{
                        color: '#232324',
                        fontSize: '24px',
                        opacity: '0.9',
                      }}
                    >
                      <b>
                        <TranslateText id="password_recovery" />
                      </b>
                    </label>
                  </div>
                  <div style={{ margin: '15px auto 0', maxWidth: '428px' }}>
                    <label
                      style={{
                        color: '#303135',
                        fontSize: '16px',
                        opacity: '0.5',
                      }}
                    >
                      <TranslateText id="forgot_password_heading" />
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: '46px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Form
                      // {...layout}
                      onFinish={handlePasswordRequest}
                      name="basic"
                      validateTrigger="onFinish"
                      layout={'vertical'}
                      initialValues={{
                        email: email,
                      }}
                    >
                      <Form.Item
                        label={<TranslateText id="email" />}
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            message: intl.formatMessage({
                              id: 'enter_valid_email_id',
                            }),
                          },
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'enter_your_email',
                            }),
                          },
                        ]}
                      >
                        <Input
                          maxLength="40"
                          placeholder={intl.formatMessage({
                            id: 'enter_your_email',
                          })}
                          style={{
                            width: '315px',
                            height: '47px',
                            borderRadius: '4px',
                          }}
                        />
                      </Form.Item>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Form.Item style={{ marginTop: '242px' }}>
                          <Button
                            className={styles.greenColorBtn}
                            htmlType="submit"
                          >
                            <TranslateText id="send_verification_otp" />
                          </Button>
                        </Form.Item>
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <label>
                          <span
                            onClick={() => {
                              props.history.push('/login')
                            }}
                            style={{
                              color: '#7CD2C6',
                              cursor: 'pointer',
                              textTransform: 'uppercase',
                              opacity: 0.9,
                            }}
                          >
                            <TranslateText id="cancel" />
                          </span>{' '}
                        </label>
                      </div>
                    </Form>
                  </div>
                </>
              ) : (
                ''
              )}
              {slider === 1 ? (
                <>
                  <div>
                    <label
                      style={{
                        color: '#232324',
                        fontSize: '24px',
                        opacity: '1',
                      }}
                    >
                      <b>
                        <TranslateText id="check_your_email" />
                      </b>
                    </label>
                  </div>
                  <div style={{ maxWidth: '448px', margin: '15px auto 0' }}>
                    <label style={{ color: '#303135', fontSize: '16px' }}>
                      <TranslateText id="if" />{' '}
                      <span style={{ color: '#7CD2C6' }}>{email}</span>{' '}
                      <TranslateText id="send_otp_in_email" />
                    </label>
                  </div>
                  <div style={{ marginTop: '62px' }}>
                    <img
                      // style={{ marginTop: "25px" }}
                      src={musicIcon}
                      alt="musicIcon"
                    />
                  </div>
                  <div style={{ marginTop: '80px' }}>
                    <Form {...layout} name="basic" layout={'vertical'}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        {/* <Form.Item> */}
                        <Button
                          onClick={() => {
                            setSlider(2)
                          }}
                          className={styles.whiteColorBtn}
                        >
                          <TranslateText id="change_password_now" />
                        </Button>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        <Button
                          onClick={() => {
                            props.history.push('/login')
                          }}
                          className={styles.greenColorBtn}
                        >
                          <TranslateText id="login" />
                        </Button>
                        {/* </Form.Item> */}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        {/* <Form.Item> */}
                        <Button
                          onClick={resendOtp}
                          className={styles.whiteColorBtn}
                        >
                          <TranslateText id="resend_verification_otp" />
                        </Button>
                        {/* </Form.Item> */}
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <label
                          style={{
                            color: '#232324',
                            opacity: '1',
                            cursor: 'pointer',
                          }}
                        >
                          <span
                            onClick={() => {
                              setSlider(0)
                            }}
                            style={{
                              color: '#7CD2C6',
                              cursor: 'pointer',
                              textTransform: 'uppercase',
                            }}
                          >
                            <TranslateText id="back" />
                          </span>{' '}
                        </label>
                      </div>
                    </Form>
                  </div>
                </>
              ) : (
                ''
              )}
              {slider === 2 ? (
                <>
                  <div>
                    <label
                      style={{
                        color: '#232324',
                        fontSize: '24px',
                        opacity: '1',
                      }}
                    >
                      <b>
                        <TranslateText id="change_your_password" />
                      </b>
                    </label>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <label
                      style={{
                        color: '#303135',
                        fontSize: '16px',
                        opacity: '0.5',
                      }}
                    >
                      <TranslateText id="choose_strong_password" />
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: '70px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Form
                      name="basic"
                      validateTrigger="onFinish"
                      onFinish={handleSavePassword}
                      layout={'vertical'}
                    >
                      <div style={{ textAlign: 'left' }}>
                        <TranslateText id="otp" />
                      </div>
                      <input
                        className="ant-input"
                        pattern="[0-9]*"
                        maxlength="6"
                        placeholder={intl.formatMessage({
                          id: 'enter_your_otp',
                        })}
                        style={{
                          width: '315px',
                          height: '47px',
                          borderRadius: '4px',
                          marginBottom: '10px',
                        }}
                        type="text"
                        name=""
                        id=""
                        value={otpValue}
                        onChange={e => {
                          if (e.target.validity.valid) {
                            setOtpValue(e.target.value)
                          }
                        }}
                        onBlur={e => {
                          if (e.target.value === '') {
                            dispatch(
                              setMessageDetails(
                                'error',
                                intl.formatMessage({ id: 'enter_your_otp' })
                              )
                            )
                          }
                        }}
                      />
                      <Form.Item
                        // style={{marginBottom : "10px"}}
                        label={<TranslateText id="new_password" />}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'enter_your_password',
                            }),
                          },
                          { validator: passwordValidation },
                        ]}
                      >
                        <Input.Password
                          placeholder={intl.formatMessage({
                            id: 'enter_new_password',
                          })}
                          iconRender={visible =>
                            visible ? (
                              <EyeTwoTone />
                            ) : (
                              <img src={eyeIcon} alt="" />
                            )
                          }
                          style={{
                            width: '315px',
                            height: '47px',
                            borderRadius: '4px',
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label={<TranslateText id="confirm_new_password" />}
                        name="confirmPassword"
                        dependencies={['password']}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'confirm_your_password',
                            }),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                <TranslateText id="password_and_confirm_password_doesnt_match" />
                              )
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder={intl.formatMessage({
                            id: 'confirm_your_new_password',
                          })}
                          iconRender={visible =>
                            visible ? (
                              <EyeTwoTone />
                            ) : (
                              <img src={eyeIcon} alt="eyeIcon" />
                            )
                          }
                          style={{
                            width: '315px',
                            height: '47px',
                            borderRadius: '4px',
                          }}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '50px',
                        }}
                      >
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className={styles.greenColorBtn}
                          >
                            <TranslateText id="save_new_password" />
                          </Button>
                        </Form.Item>
                      </div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <label>
                          <span
                            onClick={() => {
                              props.history.push('/login')
                            }}
                            style={{
                              cursor: 'pointer',
                              color: '#7CD2C6',
                              textTransform: 'uppercase',
                            }}
                          >
                            <TranslateText id="cancel" />
                          </span>{' '}
                        </label>
                      </div>
                    </Form>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/upload',
        }}
      />
    )
  }
}
export default withRouter(ForgotPasswordScreen)
