const IAP_PRICES = {
  SINGLE: {
    BASIC: 1.99,
  },
  ALBUM: {
    SMALL: 9.99,
    MEDIUM: 12.99,
    LARGE: 17.99,
  },
  SUPER_FAN_SUBSCRIPTION: {
    SMALL: 2.49,
    MEDIUM: 4.99,
    LARGE: 8.99,
    EXTRA_LARGE: 12.99,
  },
}

export function getSinglePrice(type) {
  return IAP_PRICES.SINGLE[type.toUpperCase()] || 'no-valid-price-type'
}

export function getAlbumPrice(type) {
  return IAP_PRICES.ALBUM[type.toUpperCase()] || 'no-valid-price-type'
}

export function getSuperFanSubscriptionPrice(type) {
  return (
    IAP_PRICES.SUPER_FAN_SUBSCRIPTION[type.toUpperCase()] ||
    'no-valid-price-type'
  )
}
