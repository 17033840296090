import { SET_MESSAGE_DETAILS, uploadPageType } from '../types'

import { plactronUrl } from '../../../services/api'
import { API_GET, API_POST, API_PUT } from '../../../services/ApiMethods'
import {
  GLOBALCONFIG,
  CHATBOTCONFIG,
  UPLOADCONFIG,
} from '../../../services/Config'

const putCallUploadPage = (url, LOGINCONFIG, data) => {
  return async dispatch => {
    try {
      const result = await API_PUT(url, data, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
        return result
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
        return result
      }
    } catch (error) {
      console.log(error)
    }
  }
}

// Testing chunk file upload

export const fileUpload = (data, params) => {
  const url = `${plactronUrl.UPLOAD}?` + params.toString()
  const LOGINCONFIG = UPLOADCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, data, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: uploadPageType.CREATE_SONG,
          payload: Array(result?.data),
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Creating Multiple Song

export const createMultipleSongs = obj => {
  const url = `${plactronUrl.CREATE_MULTIPLE_SONGS}`
  const LOGINCONFIG = CHATBOTCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: uploadPageType.CREATE_SONG,
          payload: result?.data,
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Deleting Song

export const deleteSong = id => {
  const url = `${plactronUrl.DELETE_SONG}`
  const obj = { id: id }
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
        dispatch({
          type: uploadPageType.DELETE_SONG,
          payload: id,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Creating Album

export const createAlbum = obj => {
  const url = `${plactronUrl.CREATE_ALBUM}`
  const LOGINCONFIG = CHATBOTCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
        return result
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export const deleteAlbum = id => {
  const url = `${plactronUrl.DELETE_ALBUM}`
  const obj = { id: id }
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
        dispatch({
          type: uploadPageType.DELETE_ALBUM,
          payload: id,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Artist all Albums

export const getAllAlbums = (startIndex = 0, limit = 4) => {
  const url = `${plactronUrl.GET_ALL_ALBUMS}/${startIndex}/${limit}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_GET(url, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: uploadPageType.GET_ALL_ALBUMS_SUCCESS,
          payload: result?.data,
        })
        return result
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
}

// Artist all Songs

export const getArtistAllSongs = (startIndex = 0, limit = 4) => {
  const url = `${plactronUrl.GET_ALL_SONGS}/${startIndex}/${limit}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_GET(url, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: uploadPageType.GET_ALL_SONGS_SUCCESS,
          payload: result?.data,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Add Song price

export const updateSongPrice = data => {
  const url = `${plactronUrl.UPDATE_SONG_PRICE} `
  const LOGINCONFIG = CHATBOTCONFIG()
  return putCallUploadPage(url, LOGINCONFIG, data)
}

// Update Album Data

export const editAlbumById = (id, data) => {
  const url = `${plactronUrl.UPDATE_ALBUM_DATA}/${id}`
  const LOGINCONFIG = GLOBALCONFIG()
  return putCallUploadPage(url, LOGINCONFIG, data)
}

// Upload Single Song Data

export const editSingleSongById = (id, data, flag) => {
  const url = `${plactronUrl.UPDATE_SINGLE_SONG_DATA}/${id}`
  const LOGINCONFIG = flag ? CHATBOTCONFIG() : GLOBALCONFIG()
  return putCallUploadPage(url, LOGINCONFIG, data)
}
