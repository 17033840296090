import React from 'react'
import './App.css'
import Routes from '../src/containers/Routes/index'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import reduxThunk from 'redux-thunk'
import ReactPixel from 'react-facebook-pixel'

import reducers from './containers/Redux/root-reducer'
import { message } from 'antd'
// For developement
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(reduxThunk))
)

// for production
// const store = createStore(reducers);
function App() {
  message.config({
    top: 0,
    duration: 3,
    maxCount: 1,
  })

  ReactPixel.init('247083274783653')

  return (
    <Provider store={store}>
      <div className="App">
        <Routes />
      </div>
    </Provider>
  )
}
export default App
