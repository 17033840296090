import React from 'react'
import TranslateText from '../../translation/TranslateText'
import VideoScreen from './videoScreen'

export default function UploadVideo() {
  return (
    <div>
      <VideoScreen heading={<TranslateText id="upload_video" />} postType={1} />
    </div>
  )
}
