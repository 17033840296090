export const GLOBALCONFIG = () => {
  let apiKey = process.env.REACT_APP_API_KEY
  let logindetails = JSON.parse(localStorage.getItem('token'))
  let language = JSON.parse(localStorage.getItem('language')) || 'de'
  let AUTHENTICATION =
    logindetails === null || logindetails === undefined ? '' : logindetails
  return {
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'x-api-key': apiKey,
      language: language,
      authorization: AUTHENTICATION,
      Accept: 'application/json',
    },
  }
}

export const CHATBOTCONFIG = () => {
  let apiKey = process.env.REACT_APP_API_KEY
  let logindetails = JSON.parse(localStorage.getItem('token'))
  let language = JSON.parse(localStorage.getItem('language')) || 'de'
  let AUTHENTICATION =
    logindetails === null || logindetails === undefined ? '' : logindetails
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Cache-Control': 'no-cache',
      'x-api-key': apiKey,
      language: language,
      authorization: AUTHENTICATION,
      Accept: 'application/json',
    },
  }
}

export const UPLOADCONFIG = () => {
  let apiKey = process.env.REACT_APP_API_KEY
  let language = JSON.parse(localStorage.getItem('language')) || 'de'
  let logindetails = JSON.parse(localStorage.getItem('token'))
  let AUTHENTICATION =
    logindetails === null || logindetails === undefined ? '' : logindetails
  return {
    headers: {
      'Content-Type': 'application/octet-stream',
      'Cache-Control': 'no-cache',
      language: language,
      'x-api-key': apiKey,
      authorization: AUTHENTICATION,
      Accept: 'application/json',
    },
  }
}
