import TranslateText from '../../translation/TranslateText'
import { Button, Form, Input, Modal } from 'antd'
import { useIntl } from 'react-intl'
import { useState } from 'react'

export default function InsertGemaModal(props) {
  const [gemaNumber, setGemaNumber] = useState('')
  const intl = useIntl()
  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }
  return (
    <Modal
      title=""
      centered
      open={props.visible}
      width={300}
      maskClosable={false}
      footer={null}
      closable={false}
      onCancel={props.onCancel}
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <span>
              <label
                style={{
                  color: '#303135',
                  fontSize: '13px',
                  fontWeight: 500,
                  marginRight: '15px',
                  textAlign: 'left',
                }}
              >
                <TranslateText id="insert_gema" />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.gema.de/portal/app/login"
                >
                  <TranslateText id="here" />
                </a>
              </label>
            </span>
          </div>
        </div>
        <Form
          name="basic"
          {...layout}
          layout={'vertical'}
          validateTrigger="onFinish"
          onFinish={values => props.updateGemaNumber(values.gemaNumber)}
          initialValues={{
            gemaNumber: gemaNumber,
          }}
        >
          <Form.Item
            label={intl.formatMessage({ id: 'gema_number' })}
            name="gemaNumber"
            style={{ marginBottom: '0px', marginTop: '4px' }}
          >
            <Input
              onChange={e => setGemaNumber(e.target.value)}
              placeholder={intl.formatMessage({ id: 'enter_gema_number' })}
              style={{ height: '40px' }}
            />
          </Form.Item>
          <div style={{ marginTop: '40px' }}>
            <Button
              disabled={gemaNumber === ''}
              htmlType="submit"
              style={{
                marginRight: '4em',
                borderColor: '#7cd2c6',
                background: '#7cd2c6',
                borderRadius: '24px',
                color: 'white',
              }}
            >
              <TranslateText id="done" />
            </Button>
            <Button
              onClick={props.resetGemaStatus}
              style={{
                background: '#ffffff',
                borderRadius: '24px',
                marginRight: '12px',
                borderColor: '#7cd2c6',
                color: '#7cd2c6',
              }}
            >
              <TranslateText id="cancel" />
            </Button>
          </div>
        </Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2em',
          }}
        ></div>
      </div>
    </Modal>
  )
}
