import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Form, Row, Col, TimePicker } from 'antd'
import moment from 'moment'
import { getArtistById } from '../Redux/actions/auth'
import { editEventById } from '../Redux/actions/fanPage'
import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import styles from '../Login/login.module.css'
import styleUpload from '../Upload/upload.module.css'
import TranslateText from '../../translation/TranslateText'
import EventForm from './eventForm'
import Calendar from 'react-calendar'

//const { Dragger } = Upload;

export default function EditEvent(props) {
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const dispatch = useDispatch()

  const [loader, showLoader, hideLoader] = useFullPageLoader()
  // fix warnings
  // eslint-disable-next-line no-unused-vars
  const event = useSelector(state => state.fanPage.selectedEvent)
  const eventId = useSelector(state => state.fanPage.eventId)
  //  const eventImage = useSelector(state => state.fanPage.eventImage);
  const eventOldName = useSelector(state => state.fanPage.eventName)
  const eventOldLocation = useSelector(state => state.fanPage.eventLocation)
  const eventOldLink = useSelector(state => state.fanPage.eventLink)
  const eventOldPrice = useSelector(state => state.fanPage.eventPrice)
  const eventOldDate = useSelector(state => state.fanPage.eventDate)
  const eventOldDescription = useSelector(
    state => state.fanPage.eventDescription
  )
  const eventOldMediaIsLandscape = useSelector(
    state => state.fanPage.eventMediaIsLandscape
  )

  const currentEventDate = new Date(eventOldDate)

  const [imageUrl, setImageUrl] = useState('')
  const [title, setTitle] = React.useState(null)
  const [description, setDescription] = React.useState(null)
  const [location, setLocation] = React.useState(null)
  const [link, setLink] = React.useState(null)
  const [date, setDate] = React.useState(currentEventDate)
  const [time, setTime] = React.useState(null)
  const [price, setPrice] = React.useState(null)
  const [mediaIsLandscape, setMediaIsLandscape] = useState(
    eventOldMediaIsLandscape
  )

  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      hideLoader()
      if (response && response.success) {
        return
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleUpdateEventDetails = (id, data, flag) => {
    dispatch(editEventById(id, data, flag)).then(response => {
      hideLoader()
      if (response && response.success) {
        props.history.push('/fanpage')
      }
    })
  }

  const updateEventDetails = values => {
    let scheduledDate
    if (date !== null && time !== null) {
      const dateOnly = moment(date).format('LL')
      const hoursOnly = moment(time).format('LT')
      const requiredDate = moment(dateOnly + ' ' + hoursOnly)
      scheduledDate = requiredDate
    } else {
      scheduledDate = eventOldDate
    }
    const id = eventId
    const newEventName = title !== null ? title?.trim() : eventOldName
    const newEventDescription =
      description !== null ? description.trim() : eventOldDescription
    const data = {
      description: newEventDescription,
      eventName: newEventName,
      location: location !== null ? location : eventOldLocation,
      ticketLink: link !== null ? link : eventOldLink,
      eventOn: scheduledDate,
      price: price !== null ? price : eventOldPrice,
      priceUnit: 'Euro',
      mediaIsLandscape,
    }

    showLoader()

    handleUpdateEventDetails(id, data, false)
  }

  // unused
  //  const getBase64 = file => {
  //    var reader = new FileReader();
  //    reader.readAsDataURL(file);
  //    if (file.type === 'image/png' || file.type === 'image/jpeg') {
  //      reader.onloadend = function (e) {
  //        setImageUrl(reader.result);
  //        setFileList(file);
  //      };
  //    } else {
  //      dispatch(
  //        setMessageDetails(
  //          'error',
  //          intl.formatMessage({ id: 'upload_jpg_or_png_file' })
  //        )
  //      );
  //    }
  //  };

  //  unused
  //  const handleImageUpload = event => {
  //    event.fileList.map(file => (file.status = 'done'));
  //    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
  //      getBase64(event.file.originFileObj);
  //    }
  //  };

  const gotoFanpage = () => {
    props.history.push('/fanpage')
  }

  //unused
  //  const showPostImage = () => {
  //    if (imageUrl === '') {
  //      return (
  //        <img
  //          style={{ maxHeight: '300px', maxWidth: '500px' }}
  //          src={devConfig.IMAGE_URL + cloudFrontImageSize + event.postImage}
  //          alt="postImage"
  //        />
  //      );
  //    }
  //    return (
  //      <img
  //        style={{ maxWidth: '500px', maxHeight: '300px' }}
  //        src={imageUrl}
  //        alt="newPostImage"
  //      />
  //    );
  //  };

  // unused
  //  const showEventForm = () => {
  //    return (
  //      <div style={{ height: '231px', width: '100%' }}>
  //        <Dragger
  //          style={{
  //            background: '#F2FBF9 0% 0% no-repeat padding-box',
  //            border: '1px dashed #7CD2C6',
  //            borderRadius: '12px',
  //          }}
  //          onChange={handleImageUpload}
  //        >
  //          <p
  //            className="ant-upload-text"
  //            style={{ color: '#7CD2C6', fontSize: '14px' }}
  //          >
  //            <TranslateText id="drop_images_for_event" />
  //          </p>
  //        </Dragger>
  //      </div>
  //    );
  //  };

  return (
    <div>
      {eventId === '' ? (
        <Redirect
          to={{
            pathname: '/fanpage',
          }}
        />
      ) : (
        <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
          <div
            style={{
              padding: '40px 30px 90px',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Row gutter={[32, 32]} style={{ height: '100%' }}>
              <Col xs={24} lg={12}>
                <EventForm
                  heading={<TranslateText id="edit_your_event" />}
                  eventTitle={eventOldName}
                  eventLocation={eventOldLocation}
                  eventLink={eventOldLink}
                  eventDate={eventOldDate}
                  eventPrice={eventOldPrice}
                  eventImage={imageUrl}
                  eventDescription={eventOldDescription}
                  handleEventTitle={e => setTitle(e.target.value)}
                  handleEventLocation={e => setLocation(e.target.value)}
                  handleEventLink={e => setLink(e.target.value)}
                  handleEventDate={e => setDate(e.target.value)}
                  handleEventPrice={e => setPrice(e.target.value)}
                  handleEventImage={e => setImageUrl(e.target.value)}
                  handleEventDescription={e => setDescription(e.target.value)}
                  handleUpdateEvent={updateEventDetails}
                  gotoFanpage={gotoFanpage}
                  mediaIsLandscape={mediaIsLandscape}
                  handleMediaIsLandscapeChange={setMediaIsLandscape}
                />
              </Col>
              <Col
                lg={12}
                xs={24}
                style={{ height: '100%', paddingLeft: '16px' }}
              >
                <div
                  style={{
                    maxWidth: '674px',
                    marginRight: 'auto',
                    overflow: 'hidden auto',
                  }}
                >
                  <Form {...layout} name="basic" layout={'vertical'}>
                    <div
                      style={{
                        width: '350px',
                        margin: '0 0 0',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                      }}
                    >
                      <TranslateText id="event_date_and_time" />
                      <div
                        style={{
                          fontSize: '20px',
                          fontFamily: 'Barlow',
                          color: '#7CD2C6',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                        }}
                      >
                        <div>
                          {moment(eventOldDate).format('DD MMMM YYYY HH:mm')}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '350px',
                        margin: '0 0 0',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                      }}
                    >
                      <Calendar
                        className={styleUpload.reactCalender}
                        onChange={setDate}
                        value={date}
                        minDate={new Date()}
                      />
                      <TimePicker
                        style={{
                          marginTop: '30px',
                          width: '276px',
                          border: '1px solid #E4E9F2',
                          borderRadius: '4px',
                        }}
                        format="HH:mm"
                        onChange={setTime}
                        defaultValue={moment(eventOldDate, 'HH:mm')}
                      />
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {loader}
    </div>
  )
}
