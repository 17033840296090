import { fanPageType, SET_MESSAGE_DETAILS } from '../types'
import { plactronUrl } from '../../../services/api'
import { API_POST, API_GET } from '../../../services/ApiMethods'
import { CHATBOTCONFIG, GLOBALCONFIG } from '../../../services/Config'

const postCallFanPage = (url, LOGINCONFIG, data) => {
  return async dispatch => {
    try {
      const result = await API_POST(url, data, LOGINCONFIG)
      console.log(result, 'Result Data')
      if (result && result?.success)
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
      else
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

const postProgressCallFanPage = (url, data) => {
  return async dispatch => {
    try {
      dispatch({
        type: fanPageType.PROGRESS,
        payload: 0,
      })
      const result = await API_POST(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Cache-Control': 'no-cache',
          'x-api-key': process.env.REACT_APP_API_KEY,
          authorization: JSON.parse(localStorage.getItem('token')),
          Accept: 'application/json',
        },
        onUploadProgress: fileSize => {
          //Set the progress value to show the progress bar
          dispatch({
            type: fanPageType.PROGRESS,
            payload: Math.round((100 * fileSize.loaded) / fileSize.total),
          })
        },
      })
      if (result && result?.success) {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'success', message: result?.message },
        })
        dispatch({
          type: fanPageType.PROGRESS,
          payload: 0,
        })
      } else
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Creating Post by Artist

export const createPost = (obj, flag, post = '') => {
  const url = `${plactronUrl.CREATE_POST} `
  const LOGINCONFIG = flag ? CHATBOTCONFIG() : GLOBALCONFIG()
  if (post === 'video') {
    return postProgressCallFanPage(url, obj)
  } else {
    return postCallFanPage(url, LOGINCONFIG, obj)
  }
}

// Artist all Posts

export const getAllPosts = () => {
  const url = `${plactronUrl.GET_ALL_POST} `
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_GET(url, LOGINCONFIG)
      console.log(result, 'result')
      if (result && result?.success) {
        dispatch({
          type: fanPageType.GET_ALL_POSTS_SUCCESS,
          payload: result?.data,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Details of Artist Post

export const getPostChartDetails = obj => {
  const url = `${plactronUrl.GET_POST_CHART_DETAILS} `
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      console.log(result, 'result')
      if (result && result?.success) {
        dispatch({
          type: fanPageType.GET_POST_CHART_DETAILS_SUCCESS,
          payload: result?.data,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Creating and editing Event by Artist

export const createEvent = obj => {
  const url = `${plactronUrl.CREATE_EVENT} `
  const LOGINCONFIG = CHATBOTCONFIG()
  return postCallFanPage(url, LOGINCONFIG, obj)
}

export const editEventById = (id, data) => {
  const url = `${plactronUrl.UPDATE_EVENT}/${id}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCallFanPage(url, LOGINCONFIG, data)
}

// Artist all Events

export const getAllEvents = () => {
  const url = `${plactronUrl.GET_ALL_EVENT} `
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_GET(url, LOGINCONFIG)
      console.log(result, 'result')
      if (result && result?.success) {
        dispatch({
          type: fanPageType.GET_ALL_EVENTS_SUCCESS,
          payload: result,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Deleting Post by Artist

export const deletePost = id => {
  console.log(id)
  const url = `${plactronUrl.DELETE_POST}`
  const obj = { id: id }
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: fanPageType.DELETE_POST,
          payload: id,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

export const deleteEvent = id => {
  console.log(id)
  const url = `${plactronUrl.DELETE_EVENT}`
  const obj = { id: id }
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: fanPageType.DELETE_EVENT,
          payload: id,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Edit Post By Artist

export const editPostById = (id, data, flag) => {
  const url = `${plactronUrl.UPDATE_POST}/${id}`
  const LOGINCONFIG = flag ? CHATBOTCONFIG() : GLOBALCONFIG()
  return postCallFanPage(url, LOGINCONFIG, data)
}

// Fetch All Comments of Post

export const commentsByPostId = obj => {
  const url = `${plactronUrl.GET_ALL_POST_COMMENTS}`
  const LOGINCONFIG = GLOBALCONFIG()

  return async dispatch => {
    try {
      const result = await API_POST(url, obj, LOGINCONFIG)
      console.log(result, 'result')
      if (result && result?.success) {
        dispatch({
          type: fanPageType.GET_ALL_COMMENTS_SUCCESS,
          payload: result?.data.allComments,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

// Create Comments by Artist

export const createComment = obj => {
  const url = `${plactronUrl.CREATE_COMMENT}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCallFanPage(url, LOGINCONFIG, obj)
}

// Delete Comments by Artist

export const deleteComment = obj => {
  const url = `${plactronUrl.DELETE_COMMENT}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCallFanPage(url, LOGINCONFIG, obj)
}

// Reply to user Comments

export const replyToComment = obj => {
  const url = `${plactronUrl.REPLY_TO_COMMENT}`
  const LOGINCONFIG = GLOBALCONFIG()
  return postCallFanPage(url, LOGINCONFIG, obj)
}

// Update Post from Exclusive to Non-Exclusive

export const updatePostTypeByID = (id, data) => {
  const url = `${plactronUrl.UPDATE_POST_TYPE}/${id}`
  const LOGINCONFIG = GLOBALCONFIG()
  return async dispatch => {
    try {
      const result = await API_POST(url, data, LOGINCONFIG)
      if (result && result?.success) {
        dispatch({
          type: fanPageType.UPDATE_POST_TYPE,
          payload: id,
        })
      } else {
        dispatch({
          type: SET_MESSAGE_DETAILS,
          payload: { status: 'error', message: result?.message },
        })
      }
      return result
    } catch (error) {
      console.log(error)
    }
  }
}
