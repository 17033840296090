import React from 'react'

import styles from './heading.module.css'

const Heading = ({ type, children }) => {
  if (type === 'h2') {
    return <h2 className={styles[type]}>{children}</h2>
  } else if (type === 'h3') {
    return <h3 className={styles[type]}>{children}</h3>
  } else if (type === 'h4') {
    return <h4 className={styles[type]}>{children}</h4>
  }
  return <h5 className={styles[type]}>{children}</h5>
}

export default Heading
