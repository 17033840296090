import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import 'moment/locale/de'
import SimpleModal from '../../components/SimpleModal/SimpleModal'
import TranslateText from '../../translation/TranslateText'
import { LOCAL_STORAGE_USERS_WHO_HAVE_SEEN_SUPERFAN_REMINDER } from '../../constants'
import { useHistory } from 'react-router-dom'

import superFanIcon from '../../Assets/Images/superFan.png'
import { useSelector } from 'react-redux'

const SuperfanReminderModal = props => {
  const { onActivate } = props
  const history = useHistory()
  const [isSuperfanReminderOpen, setIsSuperfanReminderOpen] = useState(false)
  const subscriptionEnable = useSelector(
    state => state.auth.subscriptionEnabledStatus
  )

  useEffect(() => {
    if (subscriptionEnable) {
      setIsSuperfanReminderOpen(false)
      return
    }
    if (
      history.location.pathname !== '/home' &&
      history.location.pathname !== '/upload' &&
      history.location.pathname !== '/fanpage'
    )
      return
    const artistId = JSON.parse(localStorage.getItem('artistId'))
    const usersWhoHaveSeenSuperfanReminderSet =
      getUsersWhoHaveSeenSuperfanReminder()
    const hasUserSeenSuperfanReminder =
      usersWhoHaveSeenSuperfanReminderSet.has(artistId)
    if (!hasUserSeenSuperfanReminder && !subscriptionEnable)
      setIsSuperfanReminderOpen(true)
  }, [history.location.pathname, subscriptionEnable])

  const getUsersWhoHaveSeenSuperfanReminder = () => {
    const usersWhoHaveSeenSuperfanReminder = localStorage.getItem(
      LOCAL_STORAGE_USERS_WHO_HAVE_SEEN_SUPERFAN_REMINDER
    )
    const usersWhoHaveSeenSuperfanReminderArray = JSON.parse(
      usersWhoHaveSeenSuperfanReminder
    )
    const usersWhoHaveSeenSuperfanReminderSet =
      usersWhoHaveSeenSuperfanReminderArray === null
        ? new Set()
        : new Set(usersWhoHaveSeenSuperfanReminderArray)
    return usersWhoHaveSeenSuperfanReminderSet
  }

  const handleClose = () => {
    setIsSuperfanReminderOpen(false)
    const usersWhoHaveSeenSuperfanReminderSet =
      getUsersWhoHaveSeenSuperfanReminder()
    const artistId = JSON.parse(localStorage.getItem('artistId'))
    usersWhoHaveSeenSuperfanReminderSet.add(artistId)
    localStorage.setItem(
      LOCAL_STORAGE_USERS_WHO_HAVE_SEEN_SUPERFAN_REMINDER,
      JSON.stringify(Array.from(usersWhoHaveSeenSuperfanReminderSet))
    )
  }

  const handleActivate = () => {
    handleClose()
    onActivate && onActivate()
  }

  return (
    <SimpleModal
      isModalOpen={isSuperfanReminderOpen}
      translationIdTitle="modal_reminder_superfan_title"
      handleCloseClick={handleClose}
      width={400}
      icon={superFanIcon}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TranslateText id="modal_reminder_superfan_subtitle" />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
          }}
        >
          <Button
            onClick={handleActivate}
            style={{
              borderColor: '#7cd2c6',
              background: '#7cd2c6',
              borderRadius: '24px',
              color: 'white',
            }}
          >
            <TranslateText id="modal_reminder_superfan_yes" />
          </Button>
          <Button
            onClick={handleClose}
            style={{
              borderColor: '#7cd2c6',
              background: 'white',
              borderRadius: '24px',
              color: '#7cd2c6',
            }}
          >
            <TranslateText id="modal_reminder_superfan_no" />
          </Button>
        </div>
      </div>
    </SimpleModal>
  )
}

export default SuperfanReminderModal
