import React from 'react'
import { Modal, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { setMessageDetails } from '../Redux/actions/auth'
import superFanIcon from '../../Assets/Images/superFan.png'

export default function PostTypePopup(props) {
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const subscriptionEnable =
    useSelector(state => state.auth.subscriptionEnabledStatus) || false

  const gotoNextScreen = type => {
    const postType = localStorage.getItem('postType')

    switch (postType) {
      case 'image':
        if (type === 'Regular') return history.push('/fanpage/createPost')
        return history.push('/fanpage/createExclusivePost')
      case 'video':
        if (type === 'Regular') return history.push('/fanpage/uploadVideo')
        return history.push('/fanpage/uploadExclusiveVideo')
      case 'audio':
        if (type === 'Regular') return history.push('/fanpage/uploadAudio')
        return history.push('/fanpage/uploadExclusiveAudio')
      case 'event':
        if (type === 'Regular') return history.push('/fanpage/uploadEvent')
        return history.push('/fanpage/uploadExclusiveEvent')

      default:
        break
    }
  }

  const exclusiveOption = () => {
    if (subscriptionEnable) {
      gotoNextScreen('Exclusive')
    } else {
      dispatch(
        setMessageDetails(
          'info',
          intl.formatMessage({ id: 'enable_superfan_subscription' })
        )
      )
    }
  }

  return (
    <Modal
      title=""
      closable={false}
      centered
      open={props.show}
      maskClosable={false}
      footer={null}
      width={430}
    >
      <div style={{ margin: '-1em 0 -0.5em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4 style={{ width: '100%', textAlign: 'center' }}>
            <TranslateText id="post_type_popup_title" />
          </h4>
          <div>
            <CloseOutlined
              style={{ color: '#777777', marginRight: '-10px' }}
              onClick={() => {
                props.handleCancel()
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1em',
          }}
        >
          <div style={{ marginRight: '6em' }}>
            <Button
              onClick={() => gotoNextScreen('Regular')}
              style={{
                borderColor: '#7cd2c6',
                background: 'white',
                borderRadius: '24px',
                color: '#7cd2c6',
              }}
            >
              <TranslateText id="regular" />
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => exclusiveOption()}
              style={{
                background: '#7cd2c6',
                borderRadius: '24px',
                marginRight: '12px',
                borderColor: '#7cd2c6',
                color: 'white',
              }}
            >
              <TranslateText id="super_fan" />
            </Button>
            <img
              style={{ width: '28px', height: '28px', marginTop: '3px' }}
              src={superFanIcon}
              alt="superFanIcon"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
