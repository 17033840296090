import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Form, Input, Button, Row, Col, Upload, Select, Progress } from 'antd'
import moment from 'moment'
import { DownCircleTwoTone, UpCircleTwoTone } from '@ant-design/icons'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'

import { editAlbumById, fileUpload } from '../Redux/actions/uploadPage'

import styles from '../Login/login.module.css'
import styleUpload from './upload.module.css'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { getArtistById, setMessageDetails } from '../Redux/actions/auth'
import { getAlbumPrice } from '../../iap-prices'
import { usePricesFormatting } from '../../hooks/usePricesFormatting'

const { Dragger } = Upload
const { Option } = Select

export default function EditAlbum(props) {
  const [albumName, setAlbumName] = useState()
  //  const [albumPrice, setAlbumPrice] = useState();
  const [albumType, setAlbumType] = useState()
  const [albumSongDataArray, setAlbumSongDataArray] = React.useState([])
  const [priceEmptyError, setPriceEmptyError] = useState(false)
  const [songUpdate, setSongUpdate] = useState(false)
  let songsData = []
  let allSongsId = []
  const [allDeletedSongsId, setAllDeletedSongsId] = useState([])
  let allSongDataArray = []
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const newSongs = useSelector(state => state.uploadPage.songs)
  const albumId = useSelector(state => state.uploadPage.albumId)
  const oldAlbumType = useSelector(state => state.uploadPage.albumType)
  const oldAlbumName = useSelector(state => state.uploadPage.albumName)
  let songsId = useSelector(state => state.uploadPage.selectedSongs)
  const dispatch = useDispatch()
  const intl = useIntl()
  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const formatPrice = usePricesFormatting()

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      if (response && response.success) {
        hideLoader()
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const draggerProps = {
    name: 'file',
    multiple: true,
    onChange(event) {
      event.fileList.map(file => (file.status = 'done'))
      if (
        !Object.keys(event).includes('event') &&
        event.file.status === 'done'
      ) {
        var audio = document.createElement('audio')

        var reader = new FileReader()
        reader.readAsDataURL(event.file.originFileObj)
        reader.onload = function (e) {
          audio.src = e.target.result
          audio.addEventListener('loadedmetadata', function () {
            let duration = moment.duration(
              Math.floor(audio.duration),
              'seconds'
            )
            let minutes = duration._data.minutes
            let seconds = duration._data.seconds
            minutes = minutes > 9 ? minutes : `0${minutes}`
            seconds = seconds > 9 ? seconds : `0${seconds}`
            var songData = {
              song: event.file.originFileObj,
              songType: 2,
              songDuration: `${minutes}:${seconds}`,
              index: allSongDataArray.length,
            }
            allSongDataArray.push(songData)
            setAlbumSongDataArray([...albumSongDataArray, ...allSongDataArray])
          })
        }
      }
    },
  }

  if (newSongs && newSongs.length > 0) {
    songsId = [...songsId, ...newSongs]
  }

  // chunk upload functionality

  const [currentFileIndex, setCurrentFileIndex] = useState(null)
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null)
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null)
  const [files, setFiles] = useState([])
  const [songDuration, setSongDuration] = useState([])
  const [songName, setSongName] = useState([])
  const [length, setLength] = useState(0)

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return
    }
    const isLastFile = lastUploadedFileIndex === albumSongDataArray.length - 1
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1
    setCurrentFileIndex(nextFileIndex)
    if (lastUploadedFileIndex === albumSongDataArray.length - 1) {
      setLength(0)
      setFiles([])
      setSongDuration([])
      setSongName([])
      setAlbumSongDataArray([])
      setLastUploadedFileIndex(null)
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUploadedFileIndex])

  function readAndUploadCurrentChunk() {
    const file = files[currentFileIndex]
    const reader = new FileReader()
    const chunkSize = Math.ceil(file.size / 100)
    if (!file) {
      return
    }
    const from = currentChunkIndex * chunkSize
    const to = from + chunkSize
    const blob = file.slice(from, to)
    reader.onload = e => uploadChunk(e)
    reader.readAsDataURL(blob)
  }

  function uploadChunk(readerEvent) {
    const name = songName[currentFileIndex]
    const file = files[currentFileIndex]
    const duration = songDuration[currentFileIndex]
    const data = readerEvent.target.result
    const params = new URLSearchParams()
    params.set('name', file.name)
    params.set('songName', name)
    params.set('currentChunkIndex', currentChunkIndex)
    params.set('totalChunks', 100)
    params.set('duration', duration)
    params.set('size', file.size)
    params.set('songType', 2)
    dispatch(fileUpload(data, params)).then(response => {
      const currentAlbumSongFile = files[currentFileIndex]
      const isLastFileChunk = currentChunkIndex === 99
      if (isLastFileChunk) {
        if (response && response.success) {
          currentAlbumSongFile.uploaded = response.success
        }
        setCurrentChunkIndex(null)
        setLastUploadedFileIndex(currentFileIndex)
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1)
      }
    })
  }

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk()
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChunkIndex])

  useEffect(() => {
    if (currentFileIndex !== null) {
      const tempfile = albumSongDataArray[currentFileIndex].song
      const tempduration = albumSongDataArray[currentFileIndex].songDuration
      const tempname =
        albumSongDataArray[currentFileIndex].song.name.split('.')[0]

      setFiles([...files, tempfile])
      setSongDuration([...songDuration, tempduration])
      setSongName([...songName, tempname])
      setCurrentChunkIndex(0)
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFileIndex])

  useEffect(() => {
    if (length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        )
      }
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  const handleSongsUpload = e => {
    if (albumSongDataArray.length === 0) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_atleast_one_song' })
        )
      )
    } else {
      setLength(albumSongDataArray.length)
      dispatch(
        setMessageDetails('info', intl.formatMessage({ id: 'uploading_song' }))
      )
    }
  }

  const uploadAlbumDetails = values => {
    if (priceEmptyError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'price_field_cant_empty' })
        )
      )
      return
    }
    if (albumType) {
      values.albumType = albumType
    } else {
      values.albumType = oldAlbumType
    }
    let error = []
    songsId.map(song => {
      const temp = songsData.find(obj => obj.songId === song._id)
      if (!temp && song.songName) {
        songsData.push({ songId: song._id, songName: song.songName })
        allSongsId = [...allSongsId, song._id]
      } else if (song.songName) {
        songsData.find(obj => obj.songId === song._id).songName = song.songName
      } else {
        error.push(song._id)
      }
      return song
    })
    values.songsId = allSongsId
    values.songsData = songsData
    values.deletedSongsId = allDeletedSongsId
    if (allSongsId.length > 0) {
      if (error.length > 0) {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'enter_song_name' })
          )
        )
        return
      } else {
        showLoader()
        dispatch(editAlbumById(albumId, values)).then(response => {
          if (response && response.success) {
            hideLoader()
            setAllDeletedSongsId([])
            props.history.push('/upload')
          }
        })
      }
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'upload_atleast_one_song_to_update' })
        )
      )
    }
  }

  // unused
  //  const handleDeleteSong = id => {
  //    dispatch({
  //      type: uploadPageType.DELETE_SONG,
  //      payload: id,
  //    });
  //    allDeletedSongsId.push(id);
  //    songsId = songsId.filter(song => song._id !== id);
  //  };

  const changeSongName = (id, name) => {
    const song_name = name.trim()
    if (song_name.length > 0) {
      songsId.find(obj => obj._id === id).songName = song_name
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'song_name_cant_empty' })
        )
      )
      songsId.find(obj => obj._id === id).songName = song_name
    }
  }

  const moveSongUp = currentIndex => {
    if (currentIndex > 0) {
      const currentmovesong = albumSongDataArray.splice(currentIndex, 1)
      albumSongDataArray.splice(currentIndex - 1, 0, currentmovesong[0])
      setSongUpdate(!songUpdate)
      console.log(
        'move song up from position ' +
          currentIndex +
          1 +
          ' to position ' +
          currentIndex,
        albumSongDataArray
      )
    } else
      return dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({
            id: 'you_cant_move_song_up_from_first_position',
          })
        )
      )
  }
  const moveSongDown = currentIndex => {
    if (currentIndex < albumSongDataArray.length - 1) {
      const currentmovesong = albumSongDataArray.splice(currentIndex, 1)
      albumSongDataArray.splice(currentIndex + 1, 0, currentmovesong[0])
      setSongUpdate(!songUpdate)
    } else
      return dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({
            id: 'you_cant_move_song_down_from_last_position',
          })
        )
      )
  }

  const changeAlbumPrice = value => {
    if (value !== 'small' && value !== 'medium' && value !== 'large') {
      setPriceEmptyError(true)
      setAlbumType()
    } else {
      setPriceEmptyError(false)
      setAlbumType(value)
    }
  }

  return (
    <div>
      {albumId === '' ? (
        <Redirect
          to={{
            pathname: '/upload',
          }}
        />
      ) : (
        <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
          <div
            style={{
              padding: '40px 30px 90px',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Row gutter={[32, 32]} style={{ height: '100%' }}>
              <Col xs={24} lg={12}>
                <div className={styleUpload.cardDiv} style={{ height: '100%' }}>
                  <div style={{ paddingTop: '88px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className={styleUpload.selectedPageCount}>1</div>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                      <label
                        style={{
                          color: '#232324',
                          fontSize: '24px',
                          opacity: '1',
                        }}
                      >
                        <b>
                          <TranslateText id="edit_songs_your_album" />
                        </b>
                      </label>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '50px',
                        }}
                      >
                        <Form
                          name="basic"
                          layout={'vertical'}
                          onFinish={uploadAlbumDetails}
                          initialValues={{
                            albumName: oldAlbumName,
                          }}
                        >
                          <Form.Item>
                            <Form.Item
                              name="albumName"
                              style={{
                                display: 'inline-block',
                                width: '240px',
                              }}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: 'Enter your album name',
                                },
                              ]}
                            >
                              <Input
                                maxLength="25"
                                style={{ width: '243px' }}
                                placeholder={intl.formatMessage({
                                  id: 'album_name',
                                })}
                                size="large"
                                value={albumName}
                                onChange={e => setAlbumName(e.target.value)}
                              />
                            </Form.Item>
                            <Select
                              size="large"
                              style={{
                                outline: 0,
                                width: '200px',
                                marginLeft: '13px',
                                textAlign: 'left',
                                marginRight: '-6px',
                                cursor: 'pointer',
                                color: '#303135',
                                borderRadius: '6px',
                              }}
                              defaultValue={formatPrice(
                                getAlbumPrice(oldAlbumType)
                              )}
                              onChange={value => {
                                changeAlbumPrice(value)
                              }}
                            >
                              <Option key={1} value="Select">
                                {' '}
                                Select{' '}
                              </Option>
                              <Option key={2} value={'small'}>
                                {formatPrice(getAlbumPrice('small'))}
                              </Option>
                              <Option key={3} value={'medium'}>
                                {formatPrice(getAlbumPrice('medium'))}
                              </Option>
                              <Option key={4} value={'large'}>
                                {formatPrice(getAlbumPrice('large'))}
                              </Option>
                            </Select>
                          </Form.Item>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Form.Item style={{ marginBottom: '27px' }}>
                              <Button
                                style={{ marginTop: '124px', width: '200px' }}
                                htmlType="submit"
                                className={styleUpload.greenColorBtn}
                              >
                                <TranslateText id="save" />
                              </Button>
                            </Form.Item>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label>
                              <span
                                style={{
                                  color: '#7CD2C6',
                                  cursor: 'pointer',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => {
                                  props.history.push('/upload')
                                }}
                              >
                                <TranslateText id="cancel" />
                              </span>{' '}
                            </label>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                lg={12}
                style={{ height: '100%', paddingLeft: '32px' }}
              >
                <div className={styleUpload.cardDiv2}>
                  <Form {...layout} name="basic" layout={'vertical'}>
                    <div style={{ height: '231px', width: '100%' }}>
                      <Dragger
                        style={{
                          background: '#F2FBF9 0% 0% no-repeat padding-box',
                          border: '1px dashed #7CD2C6',
                          borderRadius: '12px',
                        }}
                        {...draggerProps}
                      >
                        <p
                          className="ant-upload-text"
                          style={{ color: '#7CD2C6', fontSize: '14px' }}
                        >
                          <TranslateText id="drop_songs_upload" />
                        </p>
                      </Dragger>
                    </div>
                    <div style={{ maxHeight: '370px', overflow: 'auto' }}>
                      {albumSongDataArray.length > 0 &&
                        albumSongDataArray.map((songData, index) => {
                          let progress = 0
                          if (songData.song.uploaded) {
                            progress = 100
                          } else {
                            const uploading = index === currentFileIndex
                            if (uploading) {
                              progress = Math.round(currentChunkIndex)
                            }
                          }
                          return (
                            <div
                              key={songData.song.uid}
                              className={styleUpload.UploadedlistDiv}
                              style={{
                                padding: '8px',
                                height: '90px',
                                textAlign: 'left',
                              }}
                            >
                              <span>
                                <UpCircleTwoTone
                                  twoToneColor="#7cd2c6"
                                  style={{ margin: '0 4px' }}
                                  onClick={() => moveSongUp(index)}
                                />
                                {index + 1}
                                <DownCircleTwoTone
                                  twoToneColor="#7cd2c6"
                                  style={{ marginLeft: '4px' }}
                                  onClick={() => moveSongDown(index)}
                                />
                                <Input
                                  style={{
                                    border: '1px solid #7CD2C6',
                                    color: '#303135',
                                    width: 'calc(100% - 130px)',
                                    height: '40px',
                                    margin: '4px 16px 0',
                                    background: '#FFFFFF',
                                    cursor: 'default',
                                  }}
                                  value={songData.song.name}
                                  disabled
                                />
                                {length > 0 ? (
                                  <Progress
                                    style={{ marginBottom: '4px' }}
                                    type="circle"
                                    percent={progress}
                                    width={40}
                                  />
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                          )
                        })}
                    </div>
                    <div
                      style={{
                        marginTop: '45px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Form.Item>
                        <Button
                          style={{ width: '240px' }}
                          htmlType="submit"
                          className={styleUpload.greenColorBtn}
                          onClick={() => handleSongsUpload()}
                        >
                          <TranslateText id="upload_action" />
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                  <div style={{ maxHeight: '350px', overflow: 'auto' }}>
                    {songsId.length > 0 &&
                      songsId.map((song, index) => (
                        <div
                          key={index}
                          className={styleUpload.UploadedlistDiv}
                          style={{ padding: '13px 0px' }}
                        >
                          <span>
                            {index + 1}{' '}
                            <Input
                              style={{
                                border: '1px solid #7CD2C6',
                                width: 'calc(100% - 180px)',
                                color: '#303135',
                                marginLeft: '20px',
                                marginRight: '20px',
                                height: '40px',
                                background: '#FFFFFF',
                              }}
                              maxLength="50"
                              onChange={e => {
                                changeSongName(song._id, e.target.value)
                              }}
                              defaultValue={song.songName}
                            />
                            <label style={{ color: '#303135', padding: '4px' }}>
                              {song.songDuration}
                            </label>
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {loader}
    </div>
  )
}
