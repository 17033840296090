import React from 'react'
import { Layout } from 'antd'
import 'antd/dist/antd.css'
import logo from '../../../src/Assets/Images/new-logo-plactron.svg'
import { useHistory } from 'react-router-dom'

const { Header } = Layout

export default function Topbar() {
  let history = useHistory()

  return (
    <Layout className="layout">
      <Header
        style={{
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          position: 'fixed',
          width: '100%',
          height: '70px',
          zIndex: 100,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="logo" />
        {/* <img style={{
                    width: "5%",
                    position: "absolute",
                    top: "16px",
                    right: "20px",
                }} src={beta} alt="beta" /> */}
        <img
          onClick={() => history.push('/home')}
          style={{
            width: '180px',
            height: '90px',
            float: 'left',
            cursor: 'pointer',
            marginTop: '-5px',
          }}
          src={logo}
          alt="logo"
        />
      </Header>
    </Layout>
  )
}
