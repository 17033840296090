import React from 'react'
import TranslateText from '../../translation/TranslateText'
import PostScreen from './PostScreen'

export default function CreatePost() {
  return (
    <div>
      <PostScreen
        heading={<TranslateText id="create_new_post" />}
        postType={1}
      />
    </div>
  )
}
