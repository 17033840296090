import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  TimePicker,
  message,
  Switch,
} from 'antd'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import CurrencyInput from 'react-currency-input-field'
import moment from 'moment'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'

import { getArtistById } from '../Redux/actions/auth'
import { createEvent } from '../Redux/actions/fanPage'

import styles from '../Login/login.module.css'
import styleUpload from '../Upload/upload.module.css'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'

const { Dragger } = Upload
const { TextArea } = Input

export default function CreateEvent(props) {
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const intl = useIntl()
  const dispatch = useDispatch()

  const [date, setDate] = useState(moment())
  const [description, setDescription] = useState('')
  const [fileList, setFileList] = React.useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [showMissingImageInfo, setShowMissingImageInfo] = useState(false)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [price, setPrice] = useState(0)
  // here to fix warnings
  // eslint-disable-next-line no-unused-vars
  const [priceUnit, setPriceUnit] = useState('Euro')

  const [slider, setSlider] = useState(1)
  const [ticketLink, setTicketLink] = useState('')
  const [ticketLocation, setTicketLocation] = useState('')
  const [ticketPrice, setTicketPrice] = useState()
  const [title, setTitle] = useState('')
  const [mediaIsLandscape, setMediaIsLandscape] = useState(false)

  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      if (response && response.success) {
        hideLoader()
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const getBase64 = file => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      reader.onloadend = function (e) {
        setImageUrl(reader.result)
      }
    } else {
      setImageUrl('')
      setFileList('')
      message.error('Upload jpg or png file')
    }
  }

  const handleImageUpload = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      setFileList(event.file.originFileObj)
      getBase64(event.file.originFileObj)
      setShowMissingImageInfo(false)
    }
  }

  const handleSchedule = () => {
    if (title !== '' && description !== '' && fileList !== '') {
      setSlider(2)
    } else {
      if (fileList === '') {
        setShowMissingImageInfo(true)
      }
      message.error(
        intl.formatMessage({
          id: 'fields_missing',
        })
      )
    }
  }

  const handleTicketInfo = () => {
    if (slider === 2 && date === '') {
      message.error(
        intl.formatMessage({
          id: 'date_time_missing',
        })
      )
    } else {
      setSlider(3)
    }
  }

  const handlePublishEvent = values => {
    const priceValue = parseFloat(ticketPrice)
    if (isNaN(priceValue)) {
      message.error('Enter the correct price')
    } else {
      if (ticketPrice === '') {
        message.error(
          intl.formatMessage({
            id: 'fields_missing',
          })
        )
      } else {
        setSlider(4)
      }
    }
  }

  const handleSendEvent = () => {
    if (slider === 2 && date === '') {
      message.error('Enter all fields')
    } else if (slider === 1 && fileList === '') {
      message.error('Upload an event Image')
    } else {
      var formdata = new FormData()
      formdata.append('eventName', title)
      formdata.append('description', description)
      formdata.append('eventOn', date)
      formdata.append('eventImage', fileList)
      formdata.append('price', price?.toString())
      formdata.append('priceUnit', priceUnit)
      formdata.append('ticketLink', ticketLink)
      formdata.append('location', ticketLocation)
      formdata.append('mediaIsLandscape', mediaIsLandscape)
      showLoader()

      console.log(date)
      dispatch(createEvent(formdata))
        .then(resp => {
          hideLoader()
          if (resp && resp.success) {
            props.history.push('/fanpage')
          } else {
            message.error(`Error creating event: ${resp}`)
          }
        })
        .catch(e => {
          message.error(`Error creating event: ${e}`)
        })
    }
  }

  const showEventImage = () => {
    if (imageUrl) {
      return (
        <div
          style={{
            height: '255px',
            paddingTop: '20px',
            marginBottom: '40px',
            backgroundColor: 'transparent',
          }}
        >
          <img
            style={{
              height: '255px',
              borderRadius: '12px',
              objectFit: 'contain',
            }}
            src={imageUrl}
            alt="evemtImage"
          />
        </div>
      )
    }
    return ''
  }

  const renderEventSummaryElement = (title, content) => {
    return (
      <div style={{ maxWidth: '674px' }}>
        <label
          style={{
            color: '#303135',
            fontSize: '16px',
            opacity: '0.5',
          }}
        >
          {title === '' ? '' : <TranslateText id={title} />}
          <b>{content}</b>
        </label>
      </div>
    )
  }
  const gotoFanpage = () => {
    props.history.push('/fanpage')
  }

  const onChangeTime = time => {
    date.set({ hour: time.get('hour'), minute: time.get('minute') })
    setDate(date)
  }

  const onChangeDate = changedDate => {
    const changedMomentDate = moment(changedDate)

    date.set({
      year: changedMomentDate.get('year'),
      month: changedMomentDate.get('month'),
      date: changedMomentDate.get('date'),
    })
    setDate(date)
  }

  return (
    <div>
      {slider === 1 ? (
        <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
          <div
            style={{
              padding: '40px 30px 90px',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Row gutter={[32, 32]} style={{ height: '100%' }}>
              <Col lg={12} xs={24}>
                <div className={styleUpload.cardDiv} style={{ height: '100%' }}>
                  <div style={{ paddingTop: '88px' }}>
                    <div>
                      <label
                        style={{
                          color: '#232324',
                          fontSize: '24px',
                          opacity: '1',
                        }}
                      >
                        <b>
                          <TranslateText id="create_event" />
                        </b>
                      </label>
                      <div>
                        <label
                          style={{
                            color: '#232324',
                            fontSize: '16px',
                            paddingTop: '10px',
                            paddingLeft: '40px',
                            paddingRight: '40px',
                            display: 'inline-block',
                          }}
                        >
                          <TranslateText id="format_explanation_event" />
                        </label>
                      </div>
                      <div
                        style={{
                          marginTop: '24px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Form {...layout} name="basic" layout={'vertical'}>
                          <Form.Item
                            label=""
                            name="eventName"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: intl.formatMessage({
                                  id: 'enter_your_event_title',
                                }),
                              },
                            ]}
                          >
                            <Input
                              style={{ width: '316px', height: '47px' }}
                              value={title}
                              defaultValue={title}
                              placeholder={intl.formatMessage({ id: 'title' })}
                              onChange={e => {
                                setTitle(e.target.value)
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label=""
                            name="description"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: intl.formatMessage({
                                  id: 'enter_your_event_description',
                                }),
                              },
                            ]}
                          >
                            <TextArea
                              onChange={e => {
                                setDescription(e.target.value)
                              }}
                              style={{ width: '316px' }}
                              placeholder={intl.formatMessage({
                                id: 'event_description',
                              })}
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              value={description}
                              defaultValue={description}
                            />
                          </Form.Item>
                          <Switch
                            checked={mediaIsLandscape}
                            onChange={setMediaIsLandscape}
                            checkedChildren={
                              <TranslateText id="medium_is_landscape" />
                            }
                            unCheckedChildren={
                              <TranslateText id="medium_is_landscape" />
                            }
                          />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Form.Item>
                              <Button
                                style={{ marginTop: '40px', width: '240px' }}
                                htmlType="submit"
                                onClick={handleSchedule}
                                className={styleUpload.greenColorBtn}
                              >
                                <TranslateText id="next_step" />
                              </Button>
                            </Form.Item>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label
                              style={{
                                color: '#232324',
                                opacity: '1',
                                position: 'relative',
                                cursor: 'pointer',
                              }}
                            >
                              <span
                                style={{ color: '#7CD2C6', cursor: 'pointer' }}
                                onClick={gotoFanpage}
                              >
                                <TranslateText id="back" />
                              </span>{' '}
                            </label>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={12}
                xs={24}
                style={{ height: '100%', paddingLeft: '32px' }}
              >
                <div style={{ maxWidth: '674px', marginRight: 'auto' }}>
                  <Form {...layout} name="basic" layout={'vertical'}>
                    <div
                      style={{
                        height: '231px',
                        width: '100%',
                      }}
                    >
                      <Dragger
                        style={{
                          background: '#F2FBF9 0% 0% no-repeat padding-box',
                          border: '1px dashed #7CD2C6',
                          borderRadius: '12px',
                        }}
                        onChange={handleImageUpload}
                      >
                        <p
                          className="ant-upload-text"
                          style={{ color: '#7CD2C6', fontSize: '14px' }}
                        >
                          <TranslateText id="drop_images_for_event" />
                        </p>
                      </Dragger>
                      {showMissingImageInfo && (
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          <TranslateText id="add_event_image" />
                        </p>
                      )}
                      {showEventImage()}
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        ''
      )}
      {slider === 2 ? (
        <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
          <div
            style={{
              padding: '14px 0 90px 0',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <div
              className={styleUpload.cardDiv}
              style={{ height: '100%', margin: '0 auto' }}
            >
              <div style={{ paddingTop: '75px', paddingLeft: '26px' }}>
                <label
                  style={{
                    color: '#232324',
                    fontSize: '24px',
                    opacity: '1',
                    lineHeight: '60px',
                  }}
                >
                  <b>
                    <TranslateText id="event_date_and_time" />
                  </b>
                </label>
                <div style={{ marginTop: '16px' }}>
                  <Calendar
                    className={styleUpload.reactCalender}
                    onChange={onChangeDate}
                    value={date.toDate()}
                    minDate={new Date()}
                  />
                  <TimePicker
                    style={{
                      marginTop: '30px',
                      width: '276px',
                      border: '1px solid #E4E9F2',
                      borderRadius: '4px',
                    }}
                    format="HH:mm"
                    onChange={onChangeTime}
                    defaultValue={date}
                  />
                </div>
                <div
                  style={{
                    marginTop: '90px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Form {...layout} name="basic" layout={'vertical'}>
                    <Form.Item>
                      <Button
                        style={{
                          width: '270px',
                        }}
                        htmlType="submit"
                        className={styleUpload.greenColorBtn}
                        onClick={handleTicketInfo}
                      >
                        <TranslateText id="next_step" />
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: '270px',
                          display: 'block',
                          color: '#7CD2C6',
                          border: 'transparent',
                          background: 'transparent',
                          marginTop: '-12px',
                        }}
                        onClick={() => setSlider(1)}
                      >
                        <TranslateText id="back" />
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {slider === 3 ? (
        <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
          <div
            style={{
              padding: '14px 0 90px 0',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <div
              className={styleUpload.cardDiv}
              style={{ margin: '0 auto', height: '100%' }}
            >
              <div style={{ paddingTop: '88px', paddingLeft: '26px' }}>
                <div>
                  <label
                    style={{ color: '#232324', fontSize: '24px', opacity: '1' }}
                  >
                    <b>
                      <TranslateText id="event_get_tickets_title" />
                    </b>
                  </label>
                </div>
                <div style={{ width: '73%', margin: '15px auto 0' }}>
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      opacity: '0.5',
                    }}
                  >
                    <TranslateText id="event_get_tickets_description" />
                  </label>
                  <br />
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      opacity: '0.5',
                    }}
                  >
                    <TranslateText id="event_get_tickets_description_second_line" />
                  </label>
                </div>
                <div
                  style={{
                    marginTop: '8vh',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Form
                    onFinish={handlePublishEvent}
                    {...layout}
                    name="basic"
                    layout={'vertical'}
                    initialValues={{
                      ticketLocation: ticketLocation,
                      ticketLink: ticketLink,
                    }}
                  >
                    <Form.Item
                      label=""
                      name="ticketLocation"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: intl.formatMessage({
                            id: 'ticket_location_missing',
                          }),
                        },
                      ]}
                    >
                      <Input
                        style={{ width: '316px', height: '47px' }}
                        placeholder={intl.formatMessage({
                          id: 'event_get_tickets_location',
                        })}
                        onChange={e => {
                          setTicketLocation(e.target.value)
                        }}
                        value={ticketLocation}
                        defaultValue={ticketLocation}
                      />
                    </Form.Item>

                    <CurrencyInput
                      style={{
                        width: '316px',
                        height: '47px',
                        marginBottom: '24px',
                      }}
                      className="ant-input"
                      name="currency"
                      prefix="€ "
                      placeholder={intl.formatMessage({
                        id: 'price_per_ticket',
                      })}
                      defaultValue={ticketPrice}
                      decimalsLimit={2}
                      onValueChange={value => {
                        setTicketPrice(value)
                        setPrice(value)
                      }}
                    />
                    <Form.Item
                      label=""
                      name="ticketLink"
                      rules={[
                        {
                          type: 'url',
                          message: intl.formatMessage({
                            id: 'ticket_link_wrong_format',
                          }),
                        },
                      ]}
                    >
                      <Input
                        style={{ width: '316px', height: '47px' }}
                        placeholder={intl.formatMessage({ id: 'ticket_link' })}
                        onChange={e => {
                          setTicketLink(e.target.value)
                        }}
                        value={ticketLink}
                        defaultValue={ticketLink}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Form.Item>
                        <Button
                          style={{ marginTop: '160px', width: '240px' }}
                          htmlType="submit"
                          className={styleUpload.greenColorBtn}
                        >
                          <TranslateText id="next_step" />
                        </Button>
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <label
                        style={{
                          color: '#232324',
                          opacity: '1',
                          position: 'relative',
                          cursor: 'pointer',
                        }}
                      >
                        <span
                          style={{ color: '#7CD2C6', cursor: 'pointer' }}
                          onClick={() => setSlider(2)}
                        >
                          <TranslateText id="back" />
                        </span>{' '}
                      </label>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {slider === 4 ? (
        <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
          <div
            style={{
              padding: '40px 30px 90px',
              height: '100%',
              overflow: 'auto',
            }}
          >
            <Row gutter={[32, 32]} style={{ height: '100%' }}>
              <Col lg={12} xs={24}>
                <div className={styleUpload.cardDiv} style={{ height: '100%' }}>
                  <div style={{ paddingTop: '88px' }}>
                    <div>
                      <label
                        style={{
                          color: '#232324',
                          fontSize: '24px',
                          opacity: '1',
                        }}
                      >
                        <b>
                          <TranslateText id="event_publish_title" />
                        </b>
                      </label>
                      <div style={{ width: '73%', margin: '15px auto 0' }}>
                        <label
                          style={{
                            color: '#303135',
                            fontSize: '16px',
                            opacity: '0.5',
                          }}
                        >
                          <TranslateText id="event_publish_description" />
                        </label>
                      </div>
                      <div style={{ marginTop: '360px' }}>
                        <Form {...layout} name="basic" layout={'vertical'}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Form.Item>
                              <Button
                                style={{ width: '240px' }}
                                htmlType="submit"
                                className={styleUpload.greenColorBtn}
                                onClick={handleSendEvent}
                              >
                                <TranslateText id="event_publish" />
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: '33%',
                            width: '36%',
                          }}
                        >
                          <label
                            style={{
                              color: '#232324',
                              opacity: '1',
                              position: 'relative',
                              cursor: 'pointer',
                            }}
                          >
                            <span
                              style={{ color: '#7CD2C6', cursor: 'pointer' }}
                              onClick={() => setSlider(1)}
                            >
                              <TranslateText id="event_back_and_edit" />
                            </span>{' '}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <div style={{ maxWidth: '674px' }}>
                {showEventImage()}
                {renderEventSummaryElement('event_summary_title', title)}
                {renderEventSummaryElement(
                  'event_summary_price',
                  <b>{ticketPrice} €</b>
                )}
                {renderEventSummaryElement(
                  'event_summary_date',
                  moment(date).format('DD.MM.YYYY')
                )}
                {renderEventSummaryElement(
                  'event_summary_time',
                  moment(date).format('HH:mm')
                )}
                {renderEventSummaryElement(
                  'event_summary_location',
                  ticketLocation
                )}
                {ticketLink.length > 0 &&
                  renderEventSummaryElement(
                    'event_summary_link',
                    <a
                      rel="noopener noreferrer"
                      href={ticketLink}
                      target="_blank"
                    >
                      Link
                    </a>
                  )}
                {renderEventSummaryElement('', description)}
              </div>
            </Row>
          </div>
        </div>
      ) : (
        ''
      )}
      {loader}
    </div>
  )
}
