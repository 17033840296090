import { combineReducers } from 'redux'
import user from './reducer/userReducer'
import auth from '../Redux/reducer/authReducer'
import fanPage from './reducer/fanPageReducer'
import uploadPage from './reducer/uploadPageReducer'
import { AuthType } from './types'
// export default combineReducers({
//   user,
//   auth,
//   fanPage,
//   uploadPage
// });
// Combine all reducers.
const appReducer = combineReducers({
  user,
  auth,
  fanPage,
  uploadPage,
})
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === AuthType.LOGOUT_SUCCESS) state = undefined

  return appReducer(state, action)
}
export default rootReducer
