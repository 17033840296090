const devConfig = {
  // for development
  // BASE_URL: process.env.REACT_APP_TEST_URL,
  BASE_URL:
    process.env.REACT_APP_ENV === 'development'
      ? process.env.REACT_APP_DEVELOPMENT_URL
      : process.env.REACT_APP_ENV === 'local'
      ? process.env.REACT_APP_TEST_URL
      : process.env.REACT_APP_PRODUCTION_URL,
  IMAGE_URL:
    process.env.REACT_APP_ENV === 'development'
      ? process.env.REACT_APP_DEVELOPMENT_IMAGE_URL
      : process.env.REACT_APP_PRODUCTION_IMAGE_URL,
  SONG_URL:
    process.env.REACT_APP_ENV === 'development'
      ? process.env.REACT_APP_DEVELOPMENT_AUDIO_URL
      : process.env.REACT_APP_PRODUCTION_AUDIO_URL,
}
module.exports = devConfig
