import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getArtistById } from '../Redux/actions/auth'
import AllPagesPDFViewer from '../../components/pdf/all-pages'
import englishImprint from './Imprint.pdf'
import germanImpressum from './Impressum.pdf'
import './styles.css'

function Impressum() {
  const lang = useSelector(state => state.auth.currentLanguage)
  const dispatch = useDispatch()

  const artistId = JSON.parse(localStorage.getItem('artistId'))
  useEffect(() => {
    dispatch(getArtistById({ id: artistId }))
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <div>
      {lang === 'en' ? (
        <AllPagesPDFViewer pdf={englishImprint} />
      ) : (
        <AllPagesPDFViewer pdf={germanImpressum} />
      )}
    </div>
  )
}

export default Impressum
