import React from 'react'
import { Link } from 'react-router-dom'
import { paths } from '../../paths'
import TranslateText from '../../translation/TranslateText'
import Heading from '../Heading/Heading'

const LINK_STYLE = {
  margin: '0 8px 0 8px',
}

export const LegalFooter = ({ style: styleOverride }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '20px',
      }}
    >
      <Link to={paths.imprint} style={LINK_STYLE}>
        <Heading type="h5">
          <TranslateText id="imprint" />
        </Heading>
      </Link>

      <Link to={paths.termsAndConditions} style={LINK_STYLE}>
        <Heading type="h5">
          <TranslateText id="legal_rights" />
        </Heading>
      </Link>

      <Link to={paths.dataPrivacy} style={LINK_STYLE}>
        <Heading type="h5">
          <TranslateText id="data_privacy" />
        </Heading>
      </Link>
    </div>
  )
}

export default LegalFooter
