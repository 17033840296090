import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './sidebar.module.css'

import home from '../../Assets/Images/HomeGrey.png'
import profile from '../../Assets/Images/ProfileGreen.png'
import upload from '../../Assets/Images/uploadgrey.png'
import fanpage from '../../Assets/Images/fanpageGrey.png'
import settings from '../../Assets/Images/settingsGrey.png'

import home1 from '../../Assets/Images/HomeWhite.png'
import profile1 from '../../Assets/Images/ProfileWhite.png'
import upload1 from '../../Assets/Images/uploadWhite.png'
import fanpage1 from '../../Assets/Images/fanpageWhite.png'
import settings1 from '../../Assets/Images/settingsWhite.png'
import { MdLanguage } from 'react-icons/md'
import { Button, Modal, Select, Switch } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import superFanIcon from '../../Assets/Images/superFan.png'
import billingIcon from '../../Assets/Images/piggy_bank.png'

import {
  setMessageDetails,
  updateSubscriptionManagementDetails,
} from '../../containers/Redux/actions/auth'

import stylesBtn from '../../components/Button/button.module.css'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { changeLanguage } from '../Redux/actions/auth'
import LegalFooter from '../../components/LegalFooter'
import mark from '../../Assets/Images/newWhiteBg.svg'
import { usePricesFormatting } from './../../hooks/usePricesFormatting'
import { getSuperFanSubscriptionPrice } from '../../iap-prices'
import SuperfanReminderModal from '../../components/SuperfanReminderModal/SuperfanReminderModal'

const { Option } = Select

const routes = [
  {
    label: (
      <b>
        <TranslateText id="home" />
      </b>
    ),
    name: <TranslateText id="home_dashboard" />,
    path: '/home',
    icon: home,
    icon1: home1,
    shaded: false,
  },
  {
    label: (
      <b>
        <TranslateText id="profile" />
      </b>
    ),
    name: <TranslateText id="profile" />,
    path: '/profile',
    icon: profile,
    icon1: profile1,
    shaded: false,
  },
  {
    label: (
      <b>
        <TranslateText id="upload" />
      </b>
    ),
    name: <TranslateText id="upload" />,
    path: '/upload',
    path2: '/upload/allSongs',
    path3: '/upload/allAlbums',
    icon: upload,
    icon1: upload1,
    shaded: false,
  },
  {
    label: (
      <b>
        <TranslateText id="fanpage" />
      </b>
    ),
    name: <TranslateText id="fanpage" />,
    path: '/fanpage',
    path2: '/fanpage/createPost',
    path3: '/fanpage/postAnalytics',
    icon: fanpage,
    icon1: fanpage1,
    shaded: false,
  },
]

const Sidebar = () => {
  const intl = useIntl()
  let history = useHistory()
  const dispatch = useDispatch()
  const artistId = JSON.parse(localStorage.getItem('artistId'))
  const [subscriptionTypeSize, setSubscriptionTypeSize] = useState('small')
  const [modalVisible, setModalVisible] = useState(false)
  const [billingModalVisible, setBillingModalVisible] = useState(false)
  const [languageModalVisible, setLanguageModalVisible] = useState(false)
  const [
    stopSuperfanSubscriptionModalVisible,
    setStopSuperfanSubscriptionModalVisible,
  ] = useState(false)
  const currentLanguage = useSelector(state => state.auth.currentLanguage)
  const [language, setLanguage] = React.useState(currentLanguage)
  const formatPrice = usePricesFormatting()

  const superFanPrice =
    useSelector(state => state.auth.loginData.subscriptionType) ||
    getSuperFanSubscriptionPrice('small')
  const subscriptionEnable = useSelector(
    state => state.auth.subscriptionEnabledStatus
  )

  const subscriptionValues = [
    {
      type: 2,
      name: 'Plactron SuperFan',
      price: 25,
      points: [
        'subscription_benefits_point1',
        'subscription_benefits_point2',
        'subscription_benefits_point3',
        'subscription_benefits_point4',
      ],
    },
  ]

  const billingValues = [
    {
      type: 3,
      points: [
        'billing_explanation_one',
        'billing_explanation_two',
        'billing_explanation_three',
        'billing_explanation_four',
        'billing_explanation_five',
      ],
    },
  ]

  const path = window.location.pathname
  const gotoPage = item => {
    item.path === undefined
      ? dispatch(
          setMessageDetails(
            'info',
            intl.formatMessage({ id: 'option_coming_soon' })
          )
        )
      : history.push(item.path)
  }

  const renderMenuItems = () => {
    return routes.map((item, index) => (
      <li
        onClick={() => gotoPage(item)}
        key={index}
        style={{ marginRight: 20 }}
        className={`${styles.item} ${
          item.shaded === false ? styles.shaded : null
        } ${
          path === item.path || path === item.path2 || path === item.path3
            ? styles.active
            : null
        }`}
      >
        <div>
          {path === item.path || path === item.path2 || path === item.path3 ? (
            <img
              alt="whiteIcon"
              src={item.icon1}
              className={styles.iconDetails}
            />
          ) : (
            <img
              alt="greyIcon"
              src={item.icon}
              className={styles.iconDetails}
            />
          )}
        </div>
        <div style={{ marginLeft: '12px' }}>{item.label}</div>
      </li>
    ))
  }

  const handleChange = value => {
    setSubscriptionTypeSize(value)
    const data = {
      type: value,
      enable: subscriptionEnable,
    }
    dispatch(updateSubscriptionManagementDetails(data, artistId))
  }

  const handleSwitchChange = checked => {
    if (subscriptionEnable === true) {
      setStopSuperfanSubscriptionModalVisible(true)
    } else {
      toggleSubscriptionEnabled()
    }
  }

  const toggleSubscriptionEnabled = () => {
    const data = {
      type: subscriptionTypeSize,
      enable: !subscriptionEnable,
    }
    dispatch(updateSubscriptionManagementDetails(data, artistId))
    setStopSuperfanSubscriptionModalVisible(false)
  }

  const subscriptionModal = () => {
    return (
      <Modal
        title=""
        centered
        width={570}
        open={modalVisible}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div
          style={{
            fontSize: '20px',
            color: 'rgba(48, 49, 53, 0.3)',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            className={stylesBtn.heading}
            style={{ width: '100%', textAlign: 'center' }}
          >
            <TranslateText id="subscription_management" />
          </div>
          <div>
            <CloseOutlined onClick={() => setModalVisible(false)} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: 350,
            justifyContent: 'space-between',
            marginTop: '32px',
            flexWrap: 'wrap',
          }}
        >
          {subscriptionValues.map((subscription, index) => (
            <div key={index} className={stylesBtn.subscription_card}>
              <div
                className={styles.formDiv}
                style={{
                  width: 500,
                  height: 350,
                  backgroundColor: subscriptionEnable ? '#7cd2c649' : '#fff',
                  position: 'absolute',
                  top: '20%',
                  alignSelf: 'center',
                  borderRadius: 12,
                  boxShadow: '2px 1px 9px #e0e0e0',
                  overflow: 'hidden',
                  zIndex: 0,
                }}
              >
                <img
                  alt="altTag"
                  style={{
                    position: 'absolute',
                    width: 300,
                    bottom: '-12%',
                    right: '-10%',
                  }}
                  src={mark}
                />
              </div>
              <div
                style={{
                  zIndex: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <div className={stylesBtn.heading2}>
                    <TranslateText id="subscription_benefits" />
                  </div>
                  <div style={{ marginTop: '10px', opacity: 0.9 }}>
                    <ul className={stylesBtn.subscription_points}>
                      {subscription.points.map((point, id) => (
                        <li key={id}>
                          <TranslateText id={point} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className={stylesBtn.second_block}>
                  <div>
                    <img
                      style={{ width: '48px' }}
                      src={superFanIcon}
                      alt="superFan"
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  opacity: 0.9,
                }}
              >
                <div className={stylesBtn.heading2}>{subscription.name}</div>
                {subscription.type === 1 ? (
                  <button className={stylesBtn.activeBtnStyle}>
                    <TranslateText id="active" />
                  </button>
                ) : (
                  <Select
                    defaultValue={superFanPrice}
                    size={'large'}
                    onChange={handleChange}
                  >
                    <Option value="small">
                      {formatPrice(getSuperFanSubscriptionPrice('small'))}
                    </Option>
                    <Option value="medium">
                      {formatPrice(getSuperFanSubscriptionPrice('medium'))}
                    </Option>
                    <Option value="large">
                      {formatPrice(getSuperFanSubscriptionPrice('large'))}
                    </Option>
                    <Option value="extra_large">
                      {formatPrice(getSuperFanSubscriptionPrice('extra_large'))}
                    </Option>
                  </Select>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '0.5em',
                }}
              >
                {subscription.type === 2 ? (
                  <Switch
                    checked={subscriptionEnable}
                    onChange={handleSwitchChange}
                    checkedChildren={<TranslateText id="enabled" />}
                    unCheckedChildren={<TranslateText id="disabled" />}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    )
  }

  const billingModal = () => {
    return (
      <Modal
        title=""
        centered
        width={600}
        open={billingModalVisible}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div
          style={{
            fontSize: '20px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            className={stylesBtn.heading}
            style={{ width: '100%', textAlign: 'center' }}
          >
            <TranslateText id="billing_heading" />
          </div>
          <div>
            <CloseOutlined onClick={() => setBillingModalVisible(false)} />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <div>
            <div
              className={styles.formDiv}
              style={{
                width: 550,
                height: 420,
                backgroundColor: subscriptionEnable ? '#7cd2c649' : '#fff',
                position: 'absolute',
                top: '13%',
                alignSelf: 'center',
                borderRadius: 12,
                boxShadow: '2px 1px 9px #e0e0e0',
                overflow: 'hidden',
                zIndex: 0,
              }}
            >
              <img
                alt="altTag"
                style={{
                  position: 'absolute',
                  width: 300,
                  bottom: '-12%',
                  right: '-10%',
                }}
                src={mark}
              />
            </div>
            <div className={stylesBtn.subscription_card_content}>
              {billingValues.map((subscription, index) => (
                <div
                  key={index}
                  style={{
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <div className={stylesBtn.heading2}>
                      <TranslateText id="billing_subheading" />
                    </div>
                    <div
                      style={{
                        marginTop: '10px',
                        marginLeft: '10px',
                        opacity: 0.9,
                      }}
                    >
                      <ol>
                        {subscription.points.map((point, id) => (
                          <li
                            key={id + point}
                            style={{ paddingBottom: '15px' }}
                          >
                            {id === 3 ? (
                              <b>
                                <TranslateText id={point} />{' '}
                              </b>
                            ) : (
                              <TranslateText id={point} />
                            )}
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>

                  <div className={stylesBtn.second_block}>
                    <div>
                      <img
                        style={{ width: '48px' }}
                        src={billingIcon}
                        alt="billing"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  const handleLanguageChange = e => {
    console.log('language selected', e)
    setLanguage(e)
    dispatch(changeLanguage(e))
    setLanguageModalVisible(false)
  }

  const stopSuperfanSubscriptionModal = () => {
    return (
      <Modal
        title={<TranslateText id="stop_superfan_subscription_title" />}
        okText={<TranslateText id="stop_superfan_subscription_confirm" />}
        cancelText={<TranslateText id="cancel" />}
        onOk={toggleSubscriptionEnabled}
        onCancel={() => setStopSuperfanSubscriptionModalVisible(false)}
        closable={true}
        centered
        open={stopSuperfanSubscriptionModalVisible}
        maskClosable={true}
        width={400}
      >
        <TranslateText id="stop_superfan_subscription_text" />
      </Modal>
    )
  }

  const changeLangugaeModal = () => {
    return (
      <Modal
        title=""
        closable={false}
        centered
        open={languageModalVisible}
        maskClosable={false}
        footer={null}
        width={300}
      >
        <div style={{ margin: '-1em 0 -0.5em' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ width: '100%', textAlign: 'center' }}>
              <TranslateText id="change_language" />
            </h2>
            <div>
              <CloseOutlined
                style={{ color: 'black', marginTop: '12px' }}
                onClick={() => setLanguageModalVisible(false)}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '1em',
              }}
            >
              <div style={{ marginRight: '2em' }}>
                <Button
                  onClick={() => handleLanguageChange('en')}
                  style={{
                    borderColor: '#7cd2c6',
                    background: language === 'en' ? '#7cd2c6' : 'white',
                    borderRadius: '24px',
                    color: language === 'en' ? 'white' : '#7cd2c6',
                  }}
                >
                  <TranslateText id="english" />
                </Button>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => handleLanguageChange('de')}
                  style={{
                    background: language === 'de' ? '#7cd2c6' : 'white',
                    borderRadius: '24px',
                    marginRight: '12px',
                    borderColor: '#7cd2c6',
                    color: language === 'de' ? 'white' : '#7cd2c6',
                  }}
                >
                  <TranslateText id="german" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <div className={styles.container}>
      <ul className={styles.menu}>{renderMenuItems()}</ul>
      <ul className={styles.menu}>
        <li
          onClick={() => setBillingModalVisible(true)}
          className={`${styles.item} ${styles.shaded}`}
        >
          <span style={{ marginLeft: 8, display: 'flex' }}>
            <img
              style={{ width: '28px', height: '28px', paddingBottom: '5px' }}
              src={billingIcon}
              alt="piggy_bank"
            />
            <span style={{ marginLeft: 8 }}>
              <b>
                <TranslateText id="billing_management" />
              </b>
            </span>
          </span>
        </li>

        <li
          onClick={() => setModalVisible(true)}
          className={`${styles.item} ${styles.shaded}`}
        >
          <span style={{ marginLeft: 8, display: 'flex' }}>
            <img
              style={{ width: '28px', height: '28px' }}
              src={superFanIcon}
              alt="superFanIcon"
            />
            <span style={{ marginLeft: 8 }}>
              <b>
                <TranslateText id="subscription_managemnt" />
              </b>
            </span>
          </span>
        </li>

        <li
          onClick={() => setLanguageModalVisible(true)}
          className={`${styles.item} ${styles.shaded}`}
        >
          <span style={{ marginLeft: 10, display: 'flex' }}>
            <MdLanguage style={{ fontSize: '22px' }} />
            <span style={{ marginLeft: '12px' }}>
              <b>
                <TranslateText id="change_language" />
              </b>
            </span>
          </span>
        </li>
        <li
          className={
            path === '/settings'
              ? `${styles.item} ${styles.active}`
              : `${styles.item} ${styles.shaded}`
          }
          onClick={() => gotoPage({ path: '/settings' })}
          style={{ marginLeft: 10 }}
        >
          <span>
            {path === '/settings' ? (
              <img
                alt="settingsIcon"
                src={settings1}
                style={{ width: '22px', height: '22px' }}
              />
            ) : (
              <img
                alt="settingsIcon"
                src={settings}
                style={{ width: '22px', height: '22px' }}
              />
            )}
            <span style={{ marginLeft: '12px' }}>
              <b>
                <TranslateText id="settings" />
              </b>
            </span>
          </span>
        </li>
        <li>
          <LegalFooter />
        </li>
      </ul>
      {subscriptionModal()}
      {changeLangugaeModal()}
      {billingModal()}
      {stopSuperfanSubscriptionModal()}
      <SuperfanReminderModal
        onActivate={() => {
          setModalVisible(true)
        }}
      />
    </div>
  )
}

export default Sidebar
export { routes }
