import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Button, Row, Col, Input, Upload } from 'antd'
import moment from 'moment'
import youtubeUrl from 'youtube-url'
import useFullPageLoader, {
  useProgressBar,
} from '../../components/Loader/useFullPageLoader'

import { getArtistById, setMessageDetails } from '../Redux/actions/auth'
import { createPost } from '../Redux/actions/fanPage'

import styles from '../Login/login.module.css'
import styleUpload from '../Upload/upload.module.css'
import { useHistory } from 'react-router-dom'
import PostForm from './postForm'
import SchedulePostScreen from './SchedulePostScreen'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'

const { Dragger } = Upload

export default function VideoScreen({ heading, postType }) {
  const dispatch = useDispatch()
  const intl = useIntl()

  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const history = useHistory()
  const [slider, setSlider] = useState(0)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState(moment().format('hh:mm:ss'))
  const [youtubeURL, setYoutubeURL] = useState()
  const [youtubevideoID, setYoutubeVideoID] = useState()
  const [videoURL, setVideoURL] = useState()
  const [videoIsLandscape, setVideoIsLandscape] = useState(false)
  const [videoFile, setVideoFile] = useState('')
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [progressBar, showProgressBar, hideProgressBar] = useProgressBar()
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const uploadType = parseInt(localStorage.getItem('videoUploadType'))

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      hideLoader()
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleSchedule = () => {
    if (title.trim() !== '' && description.trim() !== '' && youtubeURL !== '') {
      setTime(moment().format('hh:mm:ss'))
      setSlider(1)
    } else {
      if (youtubeURL === '') {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'paste_youtube_url' })
          )
        )
      }
    }
  }

  const handleTime = timeValue => {
    setTime(moment(timeValue && timeValue._d).format('HH:mm:ss'))
  }

  const handleSendPost = () => {
    const shecuduleDate =
      date !== ''
        ? moment(date).format('YYYY-MM-DD') + 'T' + time + moment().format('Z')
        : ''

    if (uploadType === 2) {
      const validUrl = youtubeUrl.valid(youtubeURL)
      const videoID = youtubeUrl.extractId(youtubeURL)
      if (slider === 1 && (date === '' || time === '')) {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'enter_all_fields' })
          )
        )
      } else if (!validUrl) {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'paste_youtube_url' })
          )
        )
      } else {
        const postData = {
          postName: title,
          postYoutubeLink: videoID,
          postType: postType,
          scheduleOn: shecuduleDate,
          description: description,
          uploadedType: uploadType,
          mediaIsLandscape: videoIsLandscape,
        }

        console.log('postdata', postData)

        showLoader()
        dispatch(createPost(postData, false)).then(resp => {
          hideLoader()
          if (resp && resp.success) {
            history.push('/fanpage')
          }
        })
      }
    } else if (uploadType === 4) {
      if (slider === 1 && (date === '' || time === '')) {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'enter_all_fields' })
          )
        )
      } else if (slider === 0 && videoFile === '') {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'upload_mp4_video_file' })
          )
        )
      } else {
        var formdata = new FormData()

        formdata.append('postName', title)
        formdata.append('postVideo', videoFile)
        formdata.append('postType', postType)
        formdata.append('scheduleOn', shecuduleDate)
        formdata.append('description', description)
        formdata.append('uploadedType', uploadType)
        formdata.append('mediaIsLandscape', videoIsLandscape)

        showProgressBar()
        setVideoURL('')
        setVideoFile('')
        dispatch(createPost(formdata, true, 'video')).then(resp => {
          hideProgressBar()
          if (resp && resp.success) {
            history.push('/fanpage')
          }
        })
      }
    }
  }

  const saveVideoFile = file => {
    if (file.type === 'video/mp4') {
      console.log('file', file)
      setVideoFile(file)
    } else {
      setVideoURL('')
      setVideoFile('')
      dispatch(
        setMessageDetails(
          'info',
          intl.formatMessage({ id: 'upload_mp4_file' }),
          intl.formatMessage({ id: 'upload_mp4_file_url_copy' }),
          () => {
            navigator.clipboard.writeText(
              intl.formatMessage({ id: 'upload_mp4_file_url' })
            )
          }
        )
      )
    }
  }

  const handleVideoUpload = ({ file }) => {
    console.log('fileEvent', file)
    saveVideoFile(file)
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      setVideoURL(e.target.result)
      console.log('videoURL', e.target.result)
    }
  }

  const showYoutubeVideoPost = () => {
    if (youtubevideoID) {
      return (
        <div
          style={{
            width: '540px',
            margin: '30px 0 0',
            backgroundColor: 'white',
            borderRadius: '8px',
          }}
        >
          <div style={{ padding: '15px 20px' }}>
            <div style={{ height: '100%' }}>
              <div
                style={{
                  fontSize: '18px',
                  fontFamily: 'Barlow',
                  fontWeight: '500',
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  fontFamily: 'Barlow',
                  color: '#7CD2C6',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>{moment().format('MMMM DD YYYY')}</div>
                <div>
                  <TranslateText id="video_post" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styleUpload.newClass}>
              <div
                className="imageContainer"
                style={{ height: '100%', padding: '0 20px 20px' }}
              >
                <iframe
                  width="400"
                  height="315"
                  src={'https://www.youtube.com/embed/' + youtubevideoID}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  const showVideoFilePost = () => {
    if (videoFile) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            margin: '30px 0 0',
            width: '540px',
          }}
        >
          <div style={{ padding: '15px 20px' }}>
            <div style={{ height: '100%' }}>
              <div
                style={{
                  fontSize: '18px',
                  fontFamily: 'Barlow',
                  fontWeight: '500',
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '12px',
                  fontFamily: 'Barlow',
                  color: '#7CD2C6',
                }}
              >
                <div>{moment().format('MMMM DD YYYY')}</div>
                <div>
                  <TranslateText id="video_post" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styleUpload.newClass}>
              <div
                className="imageContainer"
                style={{ height: '100%', padding: '0 20px 20px' }}
              >
                <video
                  style={{ maxHeight: '370px', maxWidth: '500px' }}
                  src={videoURL}
                  controls
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  const showYoutubeVideoForm = () => {
    return (
      <Form {...layout} name="basic" layout={'vertical'}>
        <div style={{ width: '100%', height: '100px', display: 'flex' }}>
          <Input
            style={{ height: '47px' }}
            placeholder={intl.formatMessage({ id: 'paste_youtube_link' })}
            maxLength="50"
            value={youtubeURL}
            onChange={e => {
              setYoutubeURL(e.target.value)
            }}
          />
          <Button
            style={{
              height: '47px',
              marginLeft: '1rem',
              borderRadius: '6px',
              fontSize: '1.25rem',
            }}
            className={styleUpload.greenColorBtn}
            onClick={() => {
              const id = youtubeUrl.extractId(youtubeURL)
              if (id) {
                setYoutubeVideoID(id)
              } else {
                console.log('invalid youtube url')
                setYoutubeVideoID('')
                dispatch(
                  setMessageDetails(
                    'error',
                    intl.formatMessage({ id: 'paste_youtube_url' })
                  )
                )
              }
            }}
          >
            <TranslateText id="preview" />
          </Button>
        </div>
        {showYoutubeVideoPost()}
      </Form>
    )
  }

  const showVideoFileForm = () => {
    return (
      <Form {...layout} name="basic" layout={'vertical'}>
        <div style={{ width: '100%', height: '231px' }}>
          <Dragger
            style={{
              borderRadius: '12px',
              border: '1px dashed #7CD2C6',
              background: '#F2FBF9 0% 0% no-repeat padding-box',
            }}
            onChange={handleVideoUpload}
            beforeUpload={() => false}
          >
            <p
              className="ant-upload-text"
              style={{ color: '#7CD2C6', fontSize: '14px' }}
            >
              <TranslateText id="drop_video_for_post" />
            </p>
          </Dragger>
        </div>
        {showVideoFilePost()}
      </Form>
    )
  }

  const videoForm = () => {
    switch (uploadType) {
      case 2:
        return showYoutubeVideoForm()

      case 4:
        return showVideoFileForm()

      default:
        break
    }
  }

  return (
    <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
      {slider === 0 ? (
        <div
          style={{
            height: '100%',
            padding: '40px 30px 90px',
            overflow: 'auto',
          }}
        >
          <Row gutter={[32, 32]} style={{ height: '100%' }}>
            <Col xs={24} lg={12}>
              <PostForm
                postTitle={title}
                postContent={description}
                heading={heading}
                handlePostTitle={e => setTitle(e.target.value)}
                handlePostContent={e => {
                  setDescription(e.target.value)
                }}
                buttonName={<TranslateText id="post_now" />}
                handleSchedule={handleSchedule}
                gotoFanpage={() => {
                  history.push('/fanpage')
                }}
                handleSendPost={handleSendPost}
                showMediaIsLandscapeToggle={true}
                mediaIsLandscape={videoIsLandscape}
                handleMediaIsLandscapeChange={setVideoIsLandscape}
              />
            </Col>
            <Col
              lg={12}
              xs={24}
              style={{ height: '100%', paddingLeft: '16px' }}
            >
              <div
                className={styleUpload.cardDiv2}
                style={{ overflow: 'hidden auto' }}
              >
                {videoForm()}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
      {slider === 1 ? (
        <SchedulePostScreen
          time={time}
          date={date}
          handleTime={handleTime}
          handleDate={setDate}
          handleSendPost={handleSendPost}
          goBack={() => setSlider(0)}
        />
      ) : (
        ''
      )}
      {loader}
      {progressBar}
    </div>
  )
}
