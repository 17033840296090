import React from 'react'
import TranslateText from '../../translation/TranslateText'
import AudioScreen from './audioScreen'

export default function UploadAudio() {
  return (
    <div>
      <AudioScreen heading={<TranslateText id="upload_audio" />} postType={1} />
    </div>
  )
}
