import React, { useState } from 'react'
import { Form, Input, Button, Tooltip } from 'antd'

import styles from '../../containers/Login/login.module.css'

import greenDot from '../../Assets/Images/greenDot.svg'
import whiteDot from '../../Assets/Images/whiteDot.svg'
import { useIntl } from 'react-intl'

import {
  showEmptyFieldError,
  showContactNumberInvalidError,
} from './validation'
import TranslateText from '../../translation/TranslateText'
import { FcInfo } from 'react-icons/fc'

const countryCodes = require('country-codes-list')

export default function ContactDetailsScreen({
  sliderChange,
  btnDisabled,
  handleAddress,
  handleContactNumber,
  handleCountryCallingCode,
  handleGemaNumber,
  handleDisableBtn,
  handleInstagramName,
}) {
  const [emptyField, setEmptyField] = useState(false)
  const [contactNumberValid, setContactNumberValid] = useState(false)
  const [streetValue, setStreetValue] = useState('')
  const [city, setCity] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  // here to fix warnings
  // eslint-disable-next-line no-unused-vars
  const [address, setAddress] = useState({ street: '', city: '', country: '' })
  const [country, setCountry] = useState('Germany')
  const [countryCallingCode, setCountryCallingCode] = useState('+49')
  const [gemaNumber, setGemaNumber] = useState('')
  const [instagramName, setInstagramName] = useState('')

  const intl = useIntl()

  const allCountryNames = Object.keys(
    countryCodes.customList('countryNameEn', '+{countryCallingCode}')
  )
  const allCountryCodes = Object.values(
    countryCodes.customList('countryNameEn', '+{countryCallingCode}')
  )
    .filter(x => x.length <= 3)
    .sort()

  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const storeContactDetails = () => {
    if (contactNumber === '' || country === '') {
      setEmptyField(true)
    } else {
      if (contactNumber.length < 8) {
        setContactNumberValid(true)
      } else {
        setAddress({
          street: streetValue,
          city: city,
          country: country,
        })
        handleContactNumber(contactNumber)
        handleCountryCallingCode(countryCallingCode)
        handleAddress({
          street: streetValue,
          city: city,
          country: country,
        })
        handleGemaNumber(gemaNumber)
        handleInstagramName(instagramName)
        handleDisableBtn(true)
        sliderChange()
      }
    }
  }

  const CurrentStep = () => {
    if (window.location.pathname === '/signup') {
      return (
        <div style={{ marginTop: '12px', opacity: 0.9 }}>
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img style={{ marginRight: '16px' }} src={greenDot} alt="greenDot" />
          <img src={whiteDot} alt="whiteDot" />
        </div>
      )
    }
    return ''
  }

  return (
    <>
      <div>
        <label style={{ color: '#232324', fontSize: '24px', opacity: '0.9' }}>
          <b>
            <TranslateText id="contact_details" />
          </b>
        </label>
      </div>
      <div style={{ marginTop: '15px', padding: '0 20px' }}>
        <label style={{ color: '#303135', fontSize: '16px', opacity: '0.5' }}>
          <TranslateText id="few_steps_away" />
        </label>
      </div>
      <div
        style={{ marginTop: '2vh', display: 'flex', justifyContent: 'center' }}
      >
        <Form
          onFinish={storeContactDetails}
          {...layout}
          validateTrigger="onFinish"
          name="basic"
          layout={'vertical'}
        >
          <div
            className="label"
            style={{
              textAlign: 'left',
              marginTop: '4px',
              marginBottom: '9px',
              opacity: 0.9,
            }}
          >
            <TranslateText id="contact_number" />
          </div>
          <div style={{ display: 'flex' }}>
            <select
              style={{
                padding: '8px',
                width: '80px',
                outline: 0,
                color: '#303135',
                border: '1px solid #d9d9d9',
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
                cursor: 'pointer',
                marginRight: '-6px',
                zIndex: '10',
              }}
              onChange={e => setCountryCallingCode(e.target.value)}
              defaultValue={countryCallingCode}
            >
              {allCountryCodes.map((countryCode, index) => (
                <option key={index} value={countryCode}>
                  {countryCode}
                </option>
              ))}
            </select>
            <input
              className="ant-input"
              pattern="[0-9]*"
              maxlength="12"
              // minlength='8'
              placeholder={intl.formatMessage({
                id: 'enter_your_contact_number',
              })}
              style={{ height: '40px', borderLeftStyle: 'none', opacity: 0.9 }}
              type="text"
              name=""
              id=""
              value={contactNumber}
              onChange={e => {
                if (e.target.validity.valid) {
                  setContactNumber(e.target.value)
                  setEmptyField(false)
                  setContactNumberValid(false)
                }
              }}
              onBlur={e => {
                if (e.target.value === '') {
                  setEmptyField(true)
                }
              }}
            />
          </div>
          {showEmptyFieldError(
            emptyField,
            contactNumber,
            intl.formatMessage({ id: 'enter_your_contact_number' })
          )}
          {showContactNumberInvalidError(
            contactNumberValid,
            intl.formatMessage({ id: 'enter_correct_contact_number' })
          )}
          <div
            className="label"
            style={{
              textAlign: 'left',
              marginBottom: '8px',
              marginTop: '8px',
              opacity: 0.9,
            }}
          >
            <TranslateText id="address" />
          </div>
          <Input
            onChange={e => {
              setEmptyField(false)
              setStreetValue(e.target.value.trim())
            }}
            placeholder={intl.formatMessage({ id: 'enter_your_address' })}
            style={{ height: '40px' }}
          />
          <Form.Item
            label={<TranslateText id="city" />}
            name="city"
            style={{ marginBottom: '0px', marginTop: '8px' }}
          >
            <Input
              onChange={e => {
                setEmptyField(false)
                setCity(e.target.value.trim())
              }}
              placeholder={intl.formatMessage({ id: 'enter_your_city' })}
              style={{ height: '40px' }}
            />
          </Form.Item>
          <Form.Item
            label={<TranslateText id="country" />}
            name="country"
            style={{ marginBottom: '0px', marginTop: '8px' }}
          >
            <select
              style={{
                padding: '10px',
                borderRadius: '4px',
                width: '100%',
                color: '#303135',
              }}
              className="ant-input"
              defaultValue={country}
              onChange={e => setCountry(e.target.value)}
            >
              {allCountryNames.map((countryName, index) => (
                <option key={index} value={countryName}>
                  {countryName}
                </option>
              ))}
            </select>
          </Form.Item>
          <Form.Item
            label={
              <div>
                <TranslateText id="gema_number" />
                <Tooltip
                  placement="bottom"
                  color="#7CD2C6"
                  title={intl.formatMessage({ id: 'gema_number_info' })}
                >
                  <FcInfo
                    style={{
                      fontSize: '18px',
                      cursor: 'pointer',
                      marginRight: '4px',
                    }}
                  />
                </Tooltip>
              </div>
            }
            name="gemaNumber"
            style={{ marginBottom: '0px', marginTop: '8px' }}
          >
            <Input
              onChange={e => {
                setEmptyField(false)
                setGemaNumber(e.target.value)
              }}
              placeholder={intl.formatMessage({ id: 'enter_gema_number' })}
              style={{ height: '40px' }}
            />
          </Form.Item>
          <Form.Item
            label={<TranslateText id="instagram_name" />}
            name="instagram_name"
            style={{ marginBottom: '0px', marginTop: '8px' }}
          >
            <Input
              onChange={e => {
                setEmptyField(false)
                setInstagramName(e.target.value.trim())
              }}
              placeholder={intl.formatMessage({ id: 'enter_instagram_name' })}
              style={{ height: '40px' }}
            />
          </Form.Item>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '24px',
              }}
            >
              <Button
                htmlType="submit"
                className={styles.greenColorBtn}
                disabled={contactNumber === ''}
              >
                <TranslateText id="next_step" />
              </Button>
            </div>
          </div>
          <CurrentStep />
        </Form>
      </div>
    </>
  )
}
