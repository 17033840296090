import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, withRouter, useLocation } from 'react-router-dom'
import { Form, Input, Button, Checkbox } from 'antd'
import { EyeTwoTone } from '@ant-design/icons'
import {
  artistSignUp,
  emailVerify,
  updateArtistProfileData,
} from '../Redux/actions/auth'
import styles from './login.module.css'
import ReactPixel from 'react-facebook-pixel'

import eyeIcon from '../../Assets/Images/eyeIcon.png'
import greenDot from '../../Assets/Images/greenDot.svg'
import whiteDot from '../../Assets/Images/whiteDot.svg'

import ConfirmCodeScreen from '../../components/CommonScreen/confirmCodeScreen'
import ContactDetailsScreen from '../../components/CommonScreen/contactDetailsScreen'
import RegisterSuccessScreen from '../../components/CommonScreen/registerSuccessScreen'
import UploadProfilePicScreen from '../../components/CommonScreen/uploadProfilePicScreen'
import { passwordValidation } from '../../components/CommonScreen/validation'
import ConfirmOtpScreen from '../../components/CommonScreen/confirmOtpScreen'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import mark from '../../Assets/Images/newWhiteBg.svg'
import { login } from '../Redux/actions/auth'
import { paths } from '../../paths'

const SignupPage = props => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [slider, setSlider] = useState(0)
  const [disableBtn, setDisableBtn] = useState(true)
  const [registerNewsletter, setRegisterNewsletter] = useState(false)
  const [imageFile, setImageFile] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [countryCallingCode, setCountryCallingCode] = useState('')
  const [gemaNumber, setGemaNumber] = useState('')
  const [artistEmail, setArtistEmail] = useState('')
  const [artistPassword, setArtistPassword] = useState('')
  const [address, setAddress] = useState({ street: '', city: '', country: '' })
  const lang = useSelector(state => state.auth.currentLanguage)
  const [instagramName, setInstagramName] = useState('')

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const email = query.get('email')

  const handleUpdateDetails = (data, flag) => {
    dispatch(updateArtistProfileData(data, flag)).then(response => {
      if (response && response.success === true) {
        setDisableBtn(true)
        setSlider(5)
      } else {
        setSlider(0)
      }
    })
  }

  const handleStoreDetails = nickName => {
    const values = {}
    values.address = address
    values.contactNumber = contactNumber
    values.email = artistEmail
    values.nickName = nickName
    values.countryCode = countryCallingCode
    values.gemaNumber = gemaNumber
    values.instagramName = instagramName

    if (imageFile === '') {
      handleUpdateDetails(values, false)
    } else {
      var formdata = new FormData()

      formdata.append('address', JSON.stringify(address))
      formdata.append('contactNumber', contactNumber)
      formdata.append('email', artistEmail)
      formdata.append('profilePic', imageFile)
      formdata.append('nickName', nickName)
      formdata.append('countryCode', countryCallingCode)
      formdata.append('gemaNumber', gemaNumber)
      formdata.append('instagramName', instagramName)

      handleUpdateDetails(formdata, true)
    }
  }

  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const NextStepStyle = disableBtn
    ? styles.disableColorBtn
    : styles.greenColorBtn

  const changeValue = e => {
    setDisableBtn(!e.target.checked)
  }
  const changeNewsletterValue = e => {
    setRegisterNewsletter(e.target.checked)
  }

  const storeSignUpValue = values => {
    setDisableBtn(true)
    const data = {
      email: values.email,
      password: values.password,
      loginType: 2,
      registerNewsLetter: registerNewsletter,
    }
    dispatch(artistSignUp(data)).then(response => {
      setDisableBtn(false)
      if (response && response.success) {
        setArtistEmail(values.email)
        setArtistPassword(values.password)
        setSlider(4)
      }
    })
    dispatch(emailVerify({ email: values.email }))
  }

  const termsAndConditionsFile = () => {
    return lang === 'en' ? (
      <a
        href="/Terms & Conditions Plactron App Web Application User EN.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ color: '#7CD2C6' }}>
          <TranslateText id="terms_and_condition" />
        </span>
      </a>
    ) : (
      <a
        href="/Nutzungsbedingungen Plactron App Webanwendung Nutzer.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ color: '#7CD2C6' }}>
          <TranslateText id="terms_and_condition" />
        </span>
      </a>
    )
  }

  const privacyPolicyFile = () => {
    return lang === 'en' ? (
      <a
        href="/Data Privacy Plactron Artists EN.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span style={{ color: '#7CD2C6' }}>
          <TranslateText id="privacy_policy" />
        </span>
      </a>
    ) : (
      <a href={paths.signupDataPrivacy}>
        <span style={{ color: '#7CD2C6' }}>
          <TranslateText id="privacy_policy" />
        </span>
      </a>
    )
  }

  const handleLogin = () => {
    const values = {
      loginType: 2,
      email: artistEmail,
      password: artistPassword,
    }

    dispatch(login(values)).then(resp => {
      if (resp) {
        if (resp.success) {
          if (resp.data) {
            if (resp.data.token) {
              props.history.push('/upload')
            } else if (resp.data.emailVerify === false) {
              props.history.push('/signup')
            } else if (resp?.data?.contactVerified === false) {
              setCountryCallingCode(resp?.data?.countryCode)
              setContactNumber(resp?.data?.contactNumber)
              setArtistEmail(resp?.data?.email)
              setSlider(4)
            } else {
              setSlider(0)
            }
          } else {
            setSlider(2)
          }
          setArtistEmail(values.email)
        } else {
          setSlider(1)
        }
      }
    })
  }

  const loginData = JSON.parse(localStorage.getItem('token'))
  if (loginData === null) {
    return (
      <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
        <div style={{ padding: '14px 0 16px 0' }}>
          <div className={styles.signInDiv}>
            <div
              className={styles.formDiv}
              style={{
                backgroundColor: '#fff',
                maxWidth: 700,
                alignSelf: 'center',
                position: 'relative',
                paddingBottom: '32px',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: -90,
                  right: -70,
                }}
              >
                <img alt="altTag" style={{ width: 400 }} src={mark} />
              </div>
              {slider === 0 ? (
                <>
                  <div>
                    <label
                      style={{
                        color: '#232324',
                        fontSize: '24px',
                        opacity: '0.9',
                      }}
                    >
                      <b>
                        <TranslateText id="create_account" />
                      </b>
                    </label>
                  </div>
                  <div style={{ marginTop: '15px', padding: '0 20px' }}>
                    <label
                      style={{
                        color: '#303135',
                        fontSize: '16px',
                        opacity: '0.5',
                      }}
                    >
                      <TranslateText id="plactron_description" />
                    </label>
                  </div>
                  <div
                    className={styles.scrollbar}
                    style={{
                      marginTop: '2vh',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Form
                      onFinish={storeSignUpValue}
                      {...layout}
                      validateTrigger="onFinish"
                      name="basic"
                      layout={'vertical'}
                      initialValues={{
                        email: email,
                      }}
                    >
                      <div
                        style={{
                          overflow: 'hidden auto',
                          //maxHeight: '400px',
                        }}
                      >
                        <Form.Item
                          style={{ marginBottom: '0px' }}
                          label={<TranslateText id="email" />}
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              message: intl.formatMessage({
                                id: 'enter_valid_email_id',
                              }),
                            },
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'enter_email_id',
                              }),
                            },
                          ]}
                        >
                          <Input
                            placeholder={intl.formatMessage({
                              id: 'enter_your_email',
                            })}
                            style={{
                              width: '315px',
                              height: '47px',
                              borderRadius: '4px',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          style={{ marginBottom: '0px' }}
                          label={<TranslateText id="password" />}
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'enter_your_password',
                              }),
                            },
                            { validator: passwordValidation },
                          ]}
                        >
                          <Input.Password
                            placeholder={intl.formatMessage({
                              id: 'enter_your_password',
                            })}
                            iconRender={visible =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <img src={eyeIcon} alt="" />
                              )
                            }
                            style={{
                              width: '315px',
                              height: '47px',
                              borderRadius: '4px',
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          label={<TranslateText id="confirm_password" />}
                          name="confirmPassword"
                          style={{ marginBottom: '0px' }}
                          dependencies={['password']}
                          rules={[
                            {
                              message: intl.formatMessage({
                                id: 'confirm_your_password',
                              }),
                              required: true,
                            },
                            ({ getFieldValue }) => ({
                              validator(_rule, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(
                                  intl.formatMessage({
                                    id: 'password_and_confirm_password_doesnt_match',
                                  })
                                )
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            placeholder={intl.formatMessage({
                              id: 'enter_your_password_again',
                            })}
                            iconRender={visible =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <img src={eyeIcon} alt="eyeIcon" />
                              )
                            }
                            style={{
                              height: '47px',
                              width: '315px',
                              borderRadius: '4px',
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="agree"
                          valuePropName="checked"
                          style={{
                            width: '316px',
                            marginTop: '8px',
                            marginBottom: '8px',
                          }}
                        >
                          <span style={{ display: 'flex' }}>
                            <Checkbox onChange={e => changeValue(e)}></Checkbox>
                            <label
                              style={{
                                color: '#303135',
                                fontSize: '14px',
                                marginLeft: '15px',
                                width: '260px',
                                textAlign: 'left',
                              }}
                            >
                              <TranslateText id="i_agree" />{' '}
                              {termsAndConditionsFile()}{' '}
                              <TranslateText id="and" /> {privacyPolicyFile()}
                            </label>
                          </span>
                        </Form.Item>
                        <Form.Item
                          name="newsletterAgree"
                          valuePropName="newsletterChecked"
                          style={{
                            width: '316px',
                            marginTop: '8px',
                            marginBottom: '8px',
                          }}
                        >
                          <span style={{ display: 'flex' }}>
                            <Checkbox
                              onChange={e => changeNewsletterValue(e)}
                            ></Checkbox>
                            <label
                              style={{
                                color: '#303135',
                                fontSize: '14px',
                                marginLeft: '15px',
                                width: '260px',
                                textAlign: 'left',
                              }}
                            >
                              <TranslateText id="agree_newsletter" />
                            </label>
                          </span>
                        </Form.Item>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '16px',
                            }}
                          >
                            <Button
                              disabled={disableBtn}
                              htmlType="submit"
                              className={NextStepStyle}
                            >
                              <TranslateText id="next_step" />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: '12px', opacity: 0.9 }}>
                        <img
                          style={{ marginRight: '16px' }}
                          src={greenDot}
                          alt="greenDot"
                        />
                        <img
                          style={{ marginRight: '16px' }}
                          src={whiteDot}
                          alt="whiteDot"
                        />
                        <img
                          style={{ marginRight: '16px' }}
                          src={whiteDot}
                          alt="whiteDot"
                        />
                        <img src={whiteDot} alt="whiteDot" />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: '12px',
                        }}
                      >
                        <label
                          style={{
                            color: '#232324',
                            opacity: '0.9',
                          }}
                        >
                          <TranslateText id="already_member" />
                          <span
                            onClick={() => {
                              props.history.push('/login')
                            }}
                            style={{
                              color: '#7CD2C6',
                              cursor: 'pointer',
                              paddingLeft: '3px',
                            }}
                          >
                            {' '}
                            <TranslateText id="signin" />
                          </span>{' '}
                        </label>
                      </div>
                    </Form>
                  </div>
                </>
              ) : (
                ''
              )}
              {slider === 1 ? (
                <UploadProfilePicScreen
                  btnDisabled={disableBtn}
                  handleDisableBtn={e => setDisableBtn(e)}
                  handleImageFile={e => setImageFile(e)}
                  handleStoreDetails={e => handleStoreDetails(e)}
                />
              ) : (
                ''
              )}
              {slider === 2 ? (
                <RegisterSuccessScreen login={handleLogin} />
              ) : (
                ''
              )}
              {slider === 3 ? (
                <ContactDetailsScreen
                  btnDisabled={disableBtn}
                  handleDisableBtn={e => setDisableBtn(e)}
                  handleAddress={e => setAddress(e)}
                  handleContactNumber={e => setContactNumber(e)}
                  handleCountryCallingCode={e => setCountryCallingCode(e)}
                  handleGemaNumber={e => setGemaNumber(e)}
                  handleInstagramName={e => setInstagramName(e)}
                  sliderChange={() => setSlider(1)}
                />
              ) : (
                ''
              )}
              {slider === 4 ? (
                <ConfirmCodeScreen
                  email={artistEmail}
                  sliderChange={() => setSlider(3)}
                />
              ) : (
                ''
              )}
              {slider === 5 ? (
                <ConfirmOtpScreen
                  data={{
                    countryCode: countryCallingCode,
                    contactNumber: contactNumber,
                    email: artistEmail,
                  }}
                  sliderChange={() => {
                    ReactPixel.trackCustom('ArtistDidRegister')
                    setSlider(2)
                  }}
                  goBack={() => setSlider(3)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/upload',
        }}
      />
    )
  }
}

export default withRouter(SignupPage)
