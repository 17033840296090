import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js'
import { useIntl } from 'react-intl'
import COLORS from '../../../colors'
import SPACING from '../../../spacing'
import moment from 'moment'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import styles from '../home.module.css'
import Heading from '../../../components/Heading/Heading'
import TranslateText from '../../../translation/TranslateText'
import { usePricesFormatting } from '../../../hooks/usePricesFormatting'

function getLastSevenDays() {
  const days = []
  for (let i = 6; i >= 0; i--) {
    const date = new Date()
    date.setDate(date.getDate() - i)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    days.push(`${day}.${month}.`)
  }
  return days
}

function getLastSevenDaysSingleSales(salesData) {
  if (
    salesData === undefined ||
    salesData.singleSalesOfTheLastSevenDays === undefined
  )
    return
  const lastSevenDays = []
  for (let i = 0; i < 7; i++) {
    const date = moment().subtract(i, 'days').format('YYYY-MM-DD')
    const dayData = salesData.singleSalesOfTheLastSevenDays.find(
      day =>
        `${day._id.year.toString().padStart(2, '0')}-${day._id.month
          .toString()
          .padStart(2, '0')}-${day._id.day.toString().padStart(2, '0')}` ===
        date
    )
    const daySales = dayData ? dayData.totalArtistFee : 0
    lastSevenDays.unshift(daySales)
  }
  return lastSevenDays
}

function getLastSevenDaysAlbumSales(salesData) {
  if (
    salesData === undefined ||
    salesData.singleSalesOfTheLastSevenDays === undefined
  )
    return
  const lastSevenDays = []
  for (let i = 0; i < 7; i++) {
    const date = moment().subtract(i, 'days').format('YYYY-MM-DD')
    const dayData = salesData.albumSalesOfTheLastSevenDays.find(
      day =>
        `${day._id.year.toString().padStart(2, '0')}-${day._id.month
          .toString()
          .padStart(2, '0')}-${day._id.day.toString().padStart(2, '0')}` ===
        date
    )
    const daySales = dayData ? dayData.totalArtistFee : 0
    lastSevenDays.unshift(daySales)
  }
  return lastSevenDays
}

function BarChart({ saleData, isLoading }) {
  const intl = useIntl()
  const chartRef = useRef(null)
  const albumsIntl = intl.formatMessage({ id: 'albums' })
  const singlesIntl = intl.formatMessage({ id: 'singles' })
  const formatPrice = usePricesFormatting()

  useEffect(() => {
    if (chartRef.current === null && isLoading) return
    const myChartRef = chartRef.current.getContext('2d')
    const singleData = getLastSevenDaysSingleSales(saleData)
    const albumData = getLastSevenDaysAlbumSales(saleData)

    const hasNoData =
      albumData.every(value => value === 0) &&
      singleData.every(value => value === 0)
    new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: getLastSevenDays(),
        datasets: [
          {
            label: albumsIntl,
            data: albumData,
            backgroundColor: COLORS.primary.dark,
            //borderColor: 'rgba(255, 99, 132, 1)',
            //borderWidth: 1,
          },
          {
            label: singlesIntl,
            data: singleData,
            backgroundColor: COLORS.primary.defaultLight,
            //borderColor: 'rgba(54, 162, 235, 1)',
            //borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                // added so the default yAxis goes from 0-100 if there is no data (input from Michaela)
                ...(hasNoData && { max: 100 }),
                maxTicksLimit: 9,
                beginAtZero: true,
                callback: function (value, index, values) {
                  return value === 0 ? 0 : formatPrice(value)
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: label => formatPrice(label.yLabel),
          },
        },
      },
    })
  }, [albumsIntl, saleData, singlesIntl, isLoading, formatPrice])

  return (
    <div className={styles.container} style={{ height: '307px' }}>
      <div className={styles.headingBox}>
        <Heading type="h2">
          <TranslateText id="weekly_purchases" />
        </Heading>
      </div>
      <div>
        {isLoading && (
          <div style={{ height: '100%', width: '100%' }}>
            <LoadingIndicator />
          </div>
        )}
        {!isLoading && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: SPACING.sm,
              }}
            >
              {[
                { name: albumsIntl, color: COLORS.primary.dark },
                { name: singlesIntl, color: COLORS.primary.defaultLight },
              ].map((label, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      marginRight: '8px',
                      marginLeft: '16px',
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%',
                      backgroundColor: label.color,
                    }}
                  ></div>
                  <div variant="subtitle2">{label.name}</div>
                </div>
              ))}
            </div>
            <div
              style={{
                height: '230px',
                paddingTop: SPACING.sm,
                paddingLeft: '20px',
                paddingRight: '20px',
                width: '100%',
              }}
            >
              <canvas id="myChart" ref={chartRef} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default BarChart
