import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, Row, Col, Upload } from 'antd'
import moment from 'moment'
import 'react-calendar/dist/Calendar.css'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'

import { getArtistById, setMessageDetails } from '../Redux/actions/auth'
import { createPost } from '../Redux/actions/fanPage'

import styles from '../Login/login.module.css'
import styleUpload from '../Upload/upload.module.css'

import PostForm from './postForm'
import SchedulePostScreen from './SchedulePostScreen'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'

const { Dragger } = Upload

export default function AudioScreen({ heading, postType }) {
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()
  const [slider, setSlider] = useState(0)
  const [title, setTitle] = useState('')
  const [time, setTime] = useState(moment().format('hh:mm:ss'))
  const [date, setDate] = useState(new Date())
  const [audioUrl, setAudioUrl] = useState()
  const [description, setDescription] = useState('')
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [audioFile, setAudioFile] = useState('')
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      hideLoader()
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const saveAudioFile = file => {
    console.log('file', file)
    if (file.type === 'audio/wav' || file.type === 'audio/mpeg') {
      setAudioFile(file)
    } else {
      setAudioUrl('')
      setAudioFile('')
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'upload_wav_or_mp3_file' })
        )
      )
    }
  }

  const handleAudioUpload = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      saveAudioFile(event.file.originFileObj)

      var reader = new FileReader()
      reader.readAsDataURL(event.file.originFileObj)
      reader.onload = function (e) {
        setAudioUrl(e.target.result)
      }
    }
  }

  const handleSchedule = () => {
    if (title.trim() !== '' && description.trim() !== '' && audioFile !== '') {
      setTime(moment().format('hh:mm:ss'))
      setSlider(1)
    } else {
      if (audioFile === '') {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'upload_wav_or_mp3_file' })
          )
        )
      }
    }
  }

  const handleTime = timeData => {
    setTime(moment(timeData && timeData._d).format('HH:mm:ss'))
  }

  const handleSendPost = () => {
    const shecuduleDate =
      date !== ''
        ? moment(date).format('YYYY-MM-DD') + 'T' + time + moment().format('Z')
        : ''
    if (slider === 1 && (date === '' || time === '')) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'enter_all_fields' })
        )
      )
    } else if (slider === 0 && audioFile === '') {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'upload_wav_or_mp3_file' })
        )
      )
    } else {
      const postData = {
        postName: title,
        postAudio: audioFile,
        postType: postType,
        description: description,
        scheduleOn: shecuduleDate,
        uploadedType: 3,
      }

      console.log('postdata', postData)

      var formdata = new FormData()

      formdata.append('postAudio', audioFile)
      formdata.append('postName', title)
      formdata.append('postType', postType)
      formdata.append('uploadedType', 3)
      formdata.append('description', description)
      formdata.append('scheduleOn', shecuduleDate)
      showLoader()
      dispatch(createPost(formdata, true)).then(resp => {
        if (resp && resp.success) {
          hideLoader()
          history.push('/fanpage')
        }
      })
    }
  }

  const showExclusiveAudioPost = () => {
    if (audioFile) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            margin: '30px 0 0',
            width: '540px',
          }}
        >
          <div style={{ padding: '15px 20px' }}>
            <div style={{ height: '100%' }}>
              <div
                style={{
                  fontSize: '18px',
                  fontFamily: 'Barlow',
                  fontWeight: '500',
                  textOverflow: 'ellipsis',
                  textAlign: 'left',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {title}
              </div>
              <div
                style={{
                  color: '#7CD2C6',
                  display: 'flex',
                  fontSize: '12px',
                  fontFamily: 'Barlow',
                  justifyContent: 'space-between',
                }}
              >
                <div>{moment().format('MMMM DD YYYY')}</div>
                <div>
                  <TranslateText id="audio_post" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styleUpload.newClass}>
              <div
                className="imageContainer"
                style={{ height: '100%', padding: '0 20px 20px' }}
              >
                <audio controls src={audioUrl}>
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  return (
    <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
      {slider === 0 ? (
        <div
          style={{
            padding: '40px 30px 90px',
            overflow: 'auto',
            height: '100%',
          }}
        >
          <Row gutter={[32, 32]} style={{ height: '100%' }}>
            <Col xs={24} lg={12}>
              <PostForm
                heading={heading}
                postTitle={title}
                postContent={description}
                handlePostTitle={e => setTitle(e.target.value)}
                handlePostContent={e => {
                  setDescription(e.target.value)
                }}
                handleSchedule={handleSchedule}
                handleSendPost={handleSendPost}
                buttonName={<TranslateText id="post_now" />}
                gotoFanpage={() => {
                  history.push('/fanpage')
                }}
              />
            </Col>
            <Col
              lg={12}
              xs={24}
              style={{ paddingLeft: '16px', height: '100%' }}
            >
              <div
                className={styleUpload.cardDiv2}
                style={{ overflow: 'hidden auto' }}
              >
                <Form {...layout} name="basic" layout={'vertical'}>
                  <div style={{ width: '100%', height: '231px' }}>
                    <Dragger
                      style={{
                        borderRadius: '12px',
                        border: '1px dashed #7CD2C6',
                        background: '#F2FBF9 0% 0% no-repeat padding-box',
                      }}
                      onChange={handleAudioUpload}
                    >
                      <p
                        className="ant-upload-text"
                        style={{ color: '#7CD2C6', fontSize: '14px' }}
                      >
                        <TranslateText id="drop_audio_for_post" />
                      </p>
                    </Dragger>
                  </div>
                  {showExclusiveAudioPost()}
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
      {slider === 1 ? (
        <SchedulePostScreen
          date={date}
          handleDate={setDate}
          time={time}
          handleTime={handleTime}
          goBack={() => setSlider(0)}
          handleSendPost={handleSendPost}
        />
      ) : (
        ''
      )}
      {loader}
    </div>
  )
}
