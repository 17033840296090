import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Col, Popconfirm, Row } from 'antd'
import { getArtistById } from '../Redux/actions/auth'
import { deleteSong, getArtistAllSongs } from '../Redux/actions/uploadPage'
import { uploadPageType } from '../Redux/types'
import devConfig from '../../services/index'
import styles from './upload.module.css'
import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import editIcon from '../../Assets/Images/editIcon.svg'
import TranslateText from '../../translation/TranslateText'
import deleteIcon from '../../Assets/Images/deleteUpload.png'
import { cloudFrontImageSize } from '../../constants'
import { getSinglePrice } from '../../iap-prices'
import { usePricesFormatting } from '../../hooks/usePricesFormatting'

export default function SeeAllSongs(props) {
  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const [data, setData] = useState([])
  const [limit, setLimit] = useState(16)
  const [startIndex, setStartIndex] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const formatPrice = usePricesFormatting()

  const dispatch = useDispatch()

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId }))
    dispatch(getArtistAllSongs(startIndex, limit)).then(res => {
      if (res && res.success) {
        setData(res.data)
        hideLoader()
        setStartIndex(startIndex + limit)
        setLimit(4)
      }
    })
    dispatch({
      type: uploadPageType.REMOVE_ALL_SONGS,
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const addMoreSongs = () => {
    setStartIndex(startIndex + limit)
    dispatch(getArtistAllSongs(startIndex, limit)).then(res => {
      if (res && res.success) {
        setData([...data, ...res.data])
      }
    })
  }

  const editSingleSong = id => {
    dispatch({
      type: uploadPageType.UPDATE_SINGLE_SONG_DATA,
      payload: id,
    })
    props.history.push('/upload/editSingleSong')
  }

  const handleDeleteSong = id => {
    dispatch(deleteSong(id))

    props.history.push('/upload/allSongs')
  }

  return (
    <div>
      <InfiniteScroll
        dataLength={data.length}
        next={addMoreSongs}
        hasMore={true}
      >
        <Row gutter={[30, 30]} style={{ width: '100%', maxWidth: '1140px' }}>
          {data &&
            data.map(song => (
              <Col>
                <img
                  key={song._id}
                  className={styles.coverImageSize}
                  src={
                    devConfig.IMAGE_URL + cloudFrontImageSize + song.songImage
                  }
                  alt={song.songName}
                  style={{ height: '255px' }}
                />
                <Popconfirm
                  title={<TranslateText id="are_you_sure" />}
                  okText={<TranslateText id="delete" />}
                  cancelText={<TranslateText id="no" />}
                  onConfirm={() => {
                    handleDeleteSong(song._id)
                  }}
                >
                  <img
                    style={{
                      position: 'absolute',
                      height: '32px',
                      top: '28px',
                      left: '28px',
                      cursor: 'pointer',
                    }}
                    src={deleteIcon}
                    alt="deleteIcon"
                  ></img>
                </Popconfirm>
                <img
                  onClick={() => editSingleSong(song._id)}
                  src={editIcon}
                  alt="editIcon"
                  style={{
                    position: 'absolute',
                    top: '28px',
                    right: '28px',
                    cursor: 'pointer',
                    height: '32px',
                  }}
                />
                <div className={styles.songPrice}>
                  {formatPrice(getSinglePrice('basic'))}
                </div>
                <div className={styles.titleStyle}>{song.songName}</div>
              </Col>
            ))}
        </Row>
      </InfiniteScroll>
      {loader}
    </div>
  )
}
