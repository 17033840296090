/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import {
  getArtistById,
  setMessageDetails,
  updateArtistProfileAboutDetails,
  updateProfilePic,
} from '../Redux/actions/auth'
import styles from '../Login/login.module.css'
import { Button, Dropdown, Form, Input, Menu, Radio, Upload } from 'antd'

import editIcon from '../../Assets/Images/editIcon.svg'
import mark from '../../Assets/Images/newWhiteBg.svg'
import uploadPic from '../../Assets/Images/user.png'
import AppConfig from '../../services'
import CropImageModalPopup from '../../components/CommonScreen/cropImageModalPopup'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { cloudFrontImageSize, minimumImageDimension } from '../../constants'
const { TextArea } = Input

export default function Profile() {
  const intl = useIntl()
  const artistProfilePic = useSelector(state => state.auth.profilePic)

  const profilePic =
    artistProfilePic === ''
      ? uploadPic
      : AppConfig.IMAGE_URL + cloudFrontImageSize + artistProfilePic

  const [loader, showLoader, hideLoader] = useFullPageLoader()

  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const dispatch = useDispatch()
  const [imageUrl, setImageUrl] = useState('')
  const [fileUrl, setFileUrl] = useState('')
  const [croppedFileImage, setCroppedFileImage] = useState('')
  const [recentImageUrl, setRecentImageUrl] = useState('')
  const [recentImageFile, setRecentImageFile] = useState()
  const [imageFile, setImageFile] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [nickName, setNickName] = useState('')
  const [instagramName, setInstagramName] = useState('')
  const oldNickName = useSelector(state => state.auth.nickName)
  const oldInstagramName = useSelector(state => state.auth.instagramName)
  const oldAboutTheArtist = useSelector(
    state => state.auth.loginData.aboutTheArtist
  )
  const [aboutTheArtistEmptyError, setAboutTheArtistEmptyError] =
    useState(false)
  const [nickNameEmptyError, setNickNameEmptyError] = useState(false)
  const [fileName, setFileName] = useState()
  const [aboutTheArtist, setAboutTheArtist] = useState('')

  useEffect(() => {
    dispatch(getArtistById({ id: artistId }))
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const updateAbout = values => {
    if (nickNameEmptyError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'all_fields_mandatory' })
        )
      )
    } else {
      nickNameValues(values)
      aboutTheArtistValues(values)
      instagramNameValues(values)
      const body = {
        nickName: values.nickName,
        aboutTheArtist: values.aboutTheArtist,
        instagramName: values.instagramName,
      }
      showLoader()
      dispatch(updateArtistProfileAboutDetails(body, artistId)).then(
        response => {
          hideLoader()
          if (response && response.success === true) {
            clearCurrentState()
            dispatch(getArtistById({ id: artistId }))
          }
        }
      )
    }
  }
  const handleProfilePicUpdate = newFileData => {
    var formdata = new FormData()

    formdata.append('profilePic', newFileData)
    showLoader()
    dispatch(updateProfilePic(formdata)).then(response => {
      hideLoader()
      if (response && response.success === true) {
        dispatch(getArtistById({ id: artistId }))
        clearCurrentState()
      }
    })
  }

  const uploadButton = (
    <div
      style={{
        background: '#F2FBF9',
        borderRadius: '50px',
        width: '100px',
        display: 'flex',
        height: '100px',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '8px',
      }}
    >
      {imageUrl === '' ? (
        <img
          style={{
            height: '100px',
            width: '100px',
            borderRadius: '50px',
            cursor: 'pointer',
          }}
          src={profilePic}
          alt="uploadIcon"
        />
      ) : (
        <img
          style={{
            height: '100px',
            width: '100px',
            borderRadius: '50px',
            cursor: 'pointer',
          }}
          src={imageUrl}
          alt="profileImage"
        />
      )}
    </div>
  )

  const cropImage = () => {
    setIsModalVisible(true)
  }

  const removeImage = () => {
    setImageUrl('')
  }

  const profileMenu = (
    <Menu>
      <Menu.Item key="0" onClick={cropImage}>
        Crop Image
      </Menu.Item>
      <Menu.Item key="1" onClick={removeImage}>
        Remove Image
      </Menu.Item>
    </Menu>
  )

  const handleProfilePicUpload = e => {
    e.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(e).includes('event') && e.file.status === 'done') {
      if (e.file.type === 'image/jpeg' || e.file.type === 'image/png') {
        getBase64(e.file.originFileObj, true)
      } else {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'upload_png_or_jpg' })
          )
        )
      }
    }
  }

  const getBase64 = (file, imageUploadedFirstTime) => {
    var fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = function (e) {
      var image = new Image()
      image.src = e.target.result
      image.onload = function () {
        if (
          this.width > minimumImageDimension &&
          this.height > minimumImageDimension
        ) {
          dispatch(
            setMessageDetails(
              'info',
              intl.formatMessage({ id: 'uploading_image' })
            )
          )
          setImageUrl(fileReader.result)
          setImageFile(file)
          setFileName(file.name)
          if (imageUploadedFirstTime) {
            setIsModalVisible(true)
          }
        } else {
          dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'image_is_too_small' })
            )
          )
          setImageUrl('')
          setImageFile('')
        }
      }
    }
  }

  const dataURLtoFile = dataurl => {
    let arr = dataurl.split(','),
      imageType = arr[0].match(/:(.*?);/)[1],
      decodedBase64String = atob(arr[1]),
      n = decodedBase64String.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = decodedBase64String.charCodeAt(n)
    }
    let croppedImage = new File([u8arr], fileName, { type: imageType })
    setCroppedFileImage(croppedImage)
    setFileUrl(dataurl)
  }

  const handleOk = () => {
    setImageFile(croppedFileImage)
    getBase64(croppedFileImage, false)
    handleProfilePicUpdate(croppedFileImage)
    setRecentImageUrl(fileUrl)
    setRecentImageFile(croppedFileImage)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setImageUrl(recentImageUrl)
    setImageFile(recentImageFile)
  }

  const dropdownMenu = () => {
    if (imageUrl) {
      return (
        <Dropdown menu={profileMenu} trigger={['click']}>
          <img
            src={editIcon}
            alt="editIcon"
            style={{ height: '32px', cursor: 'pointer' }}
          />
        </Dropdown>
      )
    }
    return ''
  }

  const clearCurrentState = () => {
    setNickName('')
    setImageUrl('')
    setNickNameEmptyError(false)
    setAboutTheArtist('')
    setInstagramName('')
  }

  function nickNameValues(values) {
    if (nickName) {
      values.nickName = nickName
    } else {
      values.nickName = oldNickName
    }
  }

  function instagramNameValues(values) {
    if (instagramName !== '') {
      values.instagramName = instagramName
    } else {
      values.instagramName = oldInstagramName
    }
  }

  function aboutTheArtistValues(values) {
    if (aboutTheArtist) {
      values.aboutTheArtist = aboutTheArtist
    } else {
      values.aboutTheArtist = oldAboutTheArtist
    }
  }

  const changeNickName = name => {
    const newNickName = name.trim()

    if (newNickName.length > 0) {
      setNickNameEmptyError(false)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'profile_name_cant_empty' })
        )
      )
      setNickNameEmptyError(true)
    }
    setNickName(newNickName)
  }

  const changeInstgramName = instagramName => {
    setInstagramName(instagramName.trim())
  }

  const changeAboutTheArtist = aboutTheArtist => {
    const newAboutTheArtist = aboutTheArtist.trim()

    if (newAboutTheArtist.length > 0) {
      setAboutTheArtistEmptyError(false)
    } else {
      setAboutTheArtistEmptyError(true)
    }
    setAboutTheArtist(aboutTheArtist)
  }

  const UpdateButton = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button className={styles.greenColorBtn} htmlType="submit">
          <TranslateText id="update" />
        </Button>
      </div>
    )
  }

  const showEmptyFieldError = (emptyFieldError, content, msg) => {
    if (emptyFieldError && content === '') {
      return <div className="errMsg">{msg}</div>
    }
    return ''
  }

  return (
    <div>
      <div className={styles.signInDiv}>
        <div
          className={styles.formDiv}
          style={{
            backgroundColor: '#fff',
            maxWidth: 700,
            alignSelf: 'center',
            position: 'relative',
            paddingBottom: '32px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: -90,
              right: -70,
            }}
          >
            <img alt="altTag" style={{ width: 400 }} src={mark} />
          </div>
          <div className={styles.slideDiv} style={{ height: '110%' }}>
            <label
              style={{
                color: '#232324',
                fontSize: '24px',
                opacity: '1',
                zIndex: 1,
                marginTop: '-5%',
              }}
            >
              <b>
                <TranslateText id="update_profile_picture" />
              </b>
            </label>

            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Form
                onFinish={values => {
                  updateAbout(values)
                }}
                name="basic"
                initialValues={{
                  nickName: oldNickName,
                  instagramName: oldInstagramName,
                }}
                validateTrigger="onFinish"
                layout={'vertical'}
              >
                <Form.Item style={{ marginBottom: '37px' }}>
                  <Upload onChange={handleProfilePicUpload}>
                    {uploadButton}
                    <TranslateText id="upload_image" />
                  </Upload>
                  <CropImageModalPopup
                    showModal={isModalVisible}
                    imageUrl={imageUrl}
                    handleOk={() => handleOk()}
                    handleCancel={handleCancel}
                    handleDataURLtoFile={dataUrl => dataURLtoFile(dataUrl)}
                  />
                  {dropdownMenu()}
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'profile_name' })}
                  name="nickName"
                  style={{ marginBottom: '0px', marginTop: '4px' }}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'enter_your_name',
                      }),
                    },
                  ]}
                >
                  <Input
                    maxLength="40"
                    onChange={e => changeNickName(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: 'enter_your_name',
                    })}
                    style={{ height: '40px' }}
                  />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'aboutTheArtist' })}
                  name="AboutTheArtist"
                  style={{ marginBottom: '0px', marginTop: '4px' }}
                >
                  <TextArea
                    onChange={e => changeAboutTheArtist(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: 'What should your fans know about you?',
                    })}
                    rows={5}
                    maxLength={300}
                    defaultValue={oldAboutTheArtist}
                  />
                  {showEmptyFieldError(
                    aboutTheArtistEmptyError,
                    aboutTheArtist,
                    intl.formatMessage({
                      id: 'What should your fans know about you?',
                    })
                  )}
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'instagram_name' })}
                  name="instagramName"
                  style={{ marginBottom: '0px', marginTop: '4px' }}
                >
                  <Input
                    maxLength="40"
                    onChange={e => changeInstgramName(e.target.value)}
                    placeholder={intl.formatMessage({
                      id: 'enter_instagram_name',
                    })}
                    style={{ height: '40px' }}
                  />
                </Form.Item>
                <div style={{ marginTop: '20px' }}>
                  <UpdateButton />
                </div>
              </Form>
            </div>
          </div>
          {loader}
        </div>
      </div>
    </div>
  )
}
