import React from 'react'
import logo from '../../../src/Assets/Images/new-logo-plactron.svg'
import BtnSecondary from '../../components/Button/BtnSecondary'
import styles from './index.module.css'
import { useHistory } from 'react-router-dom'
import beta from '../../Assets/Images/beta2.png'

const SecondNavbar = props => {
  let history = useHistory()
  return (
    <header className={styles.container}>
      <div className={styles.imageContainer}>
        <img
          style={{
            width: '180px',
            height: '90px',
            float: 'left',
            cursor: 'pointer',
          }}
          className="cursor"
          onClick={() => history.push('/home')}
          src={logo}
          alt="logo"
        />
      </div>
      <div className={styles.rightBtnContainer}>
        <div className={styles.btnContainer}>
          <img
            style={{
              height: '34px',
              position: 'relative',
            }}
            src={beta}
            alt="beta"
          />
          <BtnSecondary
            propStyles={{ marginLeft: '1vw' }}
            image={props.profilePic}
          />
        </div>
      </div>
    </header>
  )
}

export default SecondNavbar
