import React from 'react'
import TranslateText from '../../../translation/TranslateText'
import Heading from '../../../components/Heading/Heading'
import { Table } from 'antd'
import { addKeysToTableData } from '../utils'
import styles from '../home.module.css'
import { Tooltip } from '@material-ui/core'
import { useIntl } from 'react-intl'
import COLORS from '../../../colors'

const getColumnsWithTooltips = tooltips => [
  {
    title: <TranslateText id="song_title" />,
    align: 'center',
    dataIndex: 'songName',
    width: '45%',
    ellipsis: true,
  },
  {
    title: <TranslateText id="Gema protected" />,
    align: 'center',
    dataIndex: 'gemmaProtected',
    width: '26.5%',
    ellipsis: true,
    render: gemmaProtected => (
      <>
        {gemmaProtected ? (
          <TranslateText id="Yes" />
        ) : (
          <TranslateText id="No" />
        )}
      </>
    ),
  },
  {
    title: <TranslateText id="status" />,
    align: 'center',
    //dataIndex: 'publish',
    ellipsis: true,
    render: data => {
      let tooltipTitle = ''
      let translatedTextId = ''

      const isOnline = data.publish && data.songStatus === 1
      if (isOnline) {
        tooltipTitle = tooltips.status_online_tooltip
        translatedTextId = 'status_online'
      } else if (data.publish && data.songStatus === 2) {
        tooltipTitle = tooltips.status_offline_tooltip
        translatedTextId = 'status_offline'
      } else if (!data.publish) {
        tooltipTitle = tooltips.status_in_review_tooltip
        translatedTextId = 'status_in_review'
      } else {
        tooltipTitle = '-'
        translatedTextId = '-'
      }

      return (
        <Tooltip title={tooltipTitle}>
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipses',
              whiteSpace: 'nowrap',
              color: isOnline ? COLORS.special.onlineGreen : '',
            }}
          >
            <TranslateText id={translatedTextId} />
          </div>
        </Tooltip>
      )
      //  return (
      //    <>
      //      {data ? (
      //        <Tooltip title={tooltips.status_online_tooltip}>
      //          <div
      //            style={{
      //              flex: 1,
      //              overflow: 'hidden',
      //              textOverflow: 'ellipses',
      //              whiteSpace: 'nowrap',
      //            }}
      //          >
      //            <TranslateText id="status_online" />
      //          </div>
      //        </Tooltip>
      //      ) : (
      //        <Tooltip title={tooltips.status_in_review_tooltip}>
      //          <div
      //            style={{
      //              flex: 1,
      //              overflow: 'hidden',
      //              textOverflow: 'ellipses',
      //              whiteSpace: 'nowrap',
      //            }}
      //          >
      //            <TranslateText id="status_in_review" />
      //          </div>
      //        </Tooltip>
      //        //  <TranslateText id="status_offline" />
      //      )}
      //    </>
      //  );
    },
  },
]

export const AllSongsTable = ({ gemaSongsData }) => {
  const intl = useIntl()
  const status_online_tooltip = intl.formatMessage({
    id: 'status_online_tooltip',
  })
  const status_in_review_tooltip = intl.formatMessage({
    id: 'status_in_review_tooltip',
  })
  const status_offline_tooltip = intl.formatMessage({
    id: 'status_offline_tooltip',
  })

  return (
    <div className={styles.container}>
      <div className={styles.headingBox}>
        <Heading type="h2">
          <TranslateText id="gema_not_gema_songs" />
        </Heading>
      </div>
      <Table
        pagination={{ pageSize: 10 }}
        columns={getColumnsWithTooltips({
          status_online_tooltip,
          status_in_review_tooltip,
          status_offline_tooltip,
        })}
        dataSource={addKeysToTableData(gemaSongsData)}
      />
    </div>
  )
}

export default AllSongsTable
