import React, { useEffect } from 'react'
import styles from './upload.module.css'
import audio from '../../Assets/Images/file-audio.png'
import albumImage from '../../Assets/Images/music-album.png'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  deleteAlbum,
  deleteSong,
  getAllAlbums,
  getArtistAllSongs,
} from '../Redux/actions/uploadPage'
import devConfig from '../../services/index'
import editIcon from '../../Assets/Images/editIcon.svg'
import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import { Col, Popconfirm, Row } from 'antd'
import { uploadPageType } from '../Redux/types'
import { getArtistById } from '../Redux/actions/auth'
import TranslateText from '../../translation/TranslateText'
import deleteIcon from '../../Assets/Images/deleteUpload.png'
import { cloudFrontImageSize } from '../../constants'
import { usePricesFormatting } from '../../hooks/usePricesFormatting'
import { getAlbumPrice, getSinglePrice } from '../../iap-prices'

function Upload(props) {
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const artistId = JSON.parse(localStorage.getItem('artistId'))
  const formatPrice = usePricesFormatting()

  const dispatch = useDispatch()

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId }))
    dispatch(getArtistAllSongs())
    dispatch({
      type: uploadPageType.REMOVE_ALL_SONGS,
    })
    dispatch(getAllAlbums()).then(response => {
      if (response && response.success) {
        hideLoader()
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  const albums = useSelector(state => state.uploadPage.albums)
  const songs = useSelector(state => state.uploadPage.singleSongs)

  const editAlbum = id => {
    dispatch({
      type: uploadPageType.UPDATE_ALBUM_DATA,
      payload: id,
    })
    props.history.push('/upload/editAlbumById')
  }

  const editSingleSong = id => {
    dispatch({
      type: uploadPageType.UPDATE_SINGLE_SONG_DATA,
      payload: id,
    })
    props.history.push('/upload/editSingleSong')
  }

  const handleDeleteSong = id => {
    dispatch(deleteSong(id))

    window.location.reload(false)
  }

  const handleDeleteAlbum = id => {
    dispatch(deleteAlbum(id))

    window.location.reload(false)
  }

  const showAllSongs = () => {
    if (songs.length > 0) {
      return songs.map((song, index) => (
        <Col key={index}>
          <img
            key={song._id}
            className={styles.coverImageSize}
            src={devConfig.IMAGE_URL + cloudFrontImageSize + song.songImage}
            alt={song.songName}
            style={{ height: '255px' }}
          />
          <Popconfirm
            title={<TranslateText id="are_you_sure" />}
            okText={<TranslateText id="delete" />}
            cancelText={<TranslateText id="no" />}
            onConfirm={() => {
              handleDeleteSong(song._id)
            }}
          >
            <img
              style={{
                position: 'absolute',
                height: '32px',
                top: '22px',
                left: '22px',
                cursor: 'pointer',
              }}
              src={deleteIcon}
              alt="deleteIcon"
            ></img>
          </Popconfirm>

          <img
            onClick={() => editSingleSong(song._id)}
            src={editIcon}
            alt="editIcon"
            style={{
              position: 'absolute',
              height: '32px',
              top: '22px',
              right: '22px',
              cursor: 'pointer',
            }}
          />

          <div
            className={
              song.hasBeenPublished ? styles.songPrice : styles.notPushed
            }
          >
            {song.hasBeenPublished ? (
              formatPrice(getSinglePrice('basic'))
            ) : (
              <TranslateText id="not_published_yet" />
            )}
          </div>
          <div className={styles.titleStyle}>{song.songName}</div>
        </Col>
      ))
    }
    return ''
  }

  const showAllAlbums = () => {
    if (albums.length > 0) {
      return albums.map((album, index) => (
        <Col key={index}>
          <img
            key={album._id}
            className={styles.coverImageSize}
            src={
              devConfig.IMAGE_URL + cloudFrontImageSize + album.albumImages[0]
            }
            alt={album.albumName}
          />
          <Popconfirm
            title={<TranslateText id="are_you_sure" />}
            okText={<TranslateText id="delete" />}
            cancelText={<TranslateText id="no" />}
            onConfirm={() => {
              handleDeleteAlbum(album._id)
            }}
          >
            <img
              style={{
                position: 'absolute',
                height: '32px',
                top: '22px',
                left: '22px',
                cursor: 'pointer',
              }}
              src={deleteIcon}
              alt="deleteIcon"
            ></img>
          </Popconfirm>
          <img
            onClick={() => editAlbum(album._id)}
            src={editIcon}
            alt="editIcon"
            style={{
              position: 'absolute',
              height: '32px',
              top: '22px',
              right: '22px',
              cursor: 'pointer',
            }}
          />
          <div
            className={
              album.hasBeenPublished ? styles.songPrice : styles.notPushed
            }
          >
            {album.hasBeenPublished ? (
              formatPrice(getAlbumPrice(album.albumType))
            ) : (
              <TranslateText id="not_published_yet" />
            )}
          </div>
          <div className={styles.titleStyle}>{album.albumName}</div>
        </Col>
      ))
    }
    return ''
  }

  return (
    <>
      <div>
        <div>
          <div>
            <div className={styles.contentDiv}>
              <label className={styles.label}>
                <TranslateText id="upload_new_content" />
              </label>
            </div>
          </div>{' '}
          <br />
          <div style={{ marginRight: '-1.8rem' }} className={styles.contentDiv}>
            <div
              onClick={() => {
                props.history.push('/upload/single')
              }}
              style={{ marginRight: '1.8rem', marginBottom: '10px' }}
              className={styles.UploadDiv}
            >
              <div className={styles.uploadSingleDiv}>
                <img className={styles.audioIcon} src={audio} alt="audioIcon" />
              </div>
              <label className={styles.UploadLabel}>
                <TranslateText id="upload_singles" />
              </label>
              <div
                style={{ position: 'relative', left: '2px', width: '192px' }}
              >
                <label className={styles.UploadLabel1}>
                  <TranslateText id="upload_singles_description" />
                </label>
              </div>
            </div>
            <div
              onClick={() => {
                props.history.push('/upload/album')
              }}
              style={{ marginRight: '1.8rem', marginBottom: '10px' }}
              className={styles.UploadDiv}
            >
              <div className={styles.uploadSingleDiv}>
                <img
                  className={styles.audioIcon}
                  src={albumImage}
                  alt="albumIcon"
                />
              </div>
              <label className={styles.UploadLabel}>
                <TranslateText id="upload_albums" />
              </label>
              <div
                style={{ position: 'relative', left: '2px', width: '192px' }}
              >
                <label className={styles.UploadLabel1}>
                  <TranslateText id="upload_albums_description" />
                </label>
              </div>
            </div>
          </div>
        </div>
        <br />
        {songs && songs.length > 0 ? (
          <div id="songs">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '1110px',
              }}
            >
              <div className={styles.contentDiv}>
                <label className={styles.label}>
                  <TranslateText id="song_upload_history" />
                </label>
              </div>
              <div onClick={() => props.history.push('/upload/allSongs')}>
                <p
                  style={{ marginBottom: '0px', cursor: 'pointer' }}
                  className={styles.label}
                >
                  <TranslateText id="see_all" />
                </p>
              </div>
            </div>
            <br />
            <div className={styles.albumsDiv}>
              <Row gutter={[30, 30]}>{showAllSongs()}</Row>
            </div>
          </div>
        ) : (
          ''
        )}
        {albums && albums.length > 0 ? (
          <div style={{ marginTop: '-10px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '1110px',
              }}
            >
              <div className={styles.contentDiv}>
                <label className={styles.label}>
                  <TranslateText id="album_upload_history" />
                </label>
              </div>
              <div onClick={() => props.history.push('/upload/allAlbums')}>
                <p
                  style={{ marginBottom: '0px', cursor: 'pointer' }}
                  className={styles.label}
                >
                  <TranslateText id="see_all" />
                </p>
              </div>
            </div>
            <br />
            <div className={styles.albumsDiv}>
              <Row gutter={[30, 30]}>{showAllAlbums()}</Row>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {loader}
    </>
  )
}
const WrappedLogin = withRouter(Upload)
export default WrappedLogin
