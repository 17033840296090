import React from 'react'
import TranslateText from '../../../translation/TranslateText'
import Heading from '../../../components/Heading/Heading'
import { Table } from 'antd'
import { addKeysToTableData, getMonthName } from '../utils'
import styles from '../home.module.css'

const getColumns = tableData => [
  {
    title: <TranslateText id="month" />,
    dataIndex: 'month',
    align: 'center',
    render: month => <b>{getMonthName(month)}</b>,
    key: 'month',
    ellipsis: true,
  },
  {
    title: <TranslateText id="won_followers_in_month" />,
    dataIndex: 'total',
    key: 'total',
    align: 'center',
    responsive: ['md'],
    ellipsis: true,
  },
  {
    title: <TranslateText id="total_followers" />,
    key: 'accumulated_total',
    align: 'center',
    responsive: ['md'],
    ellipsis: true,
    render: data => {
      const [selectedMonthValue, selectedYearValue] = data.month.split('.')
      const filteredArray = tableData.filter(item => {
        const [itemMonthValue, itemYearValue] = item.month.split('.')
        if (itemYearValue > selectedYearValue) return false
        return itemMonthValue <= selectedMonthValue
      })

      return filteredArray.reduce((acc, d) => acc + d.total, 0)
    },
  },
]

export const WonFansTable = ({ artistFollowerData }) => {
  const tableData = addKeysToTableData(artistFollowerData)
  return (
    <div className={styles.container}>
      <div className={styles.headingBox}>
        <Heading type="h2">
          <TranslateText id="fans_and_followers" />
        </Heading>
      </div>
      <Table
        pagination={{ pageSize: 10 }}
        columns={getColumns(tableData)}
        dataSource={tableData}
      />
    </div>
  )
}

export default WonFansTable
