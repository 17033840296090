import React from 'react'
import { useIntl } from 'react-intl'
import UrlFrame from '../../components/UrlFrame'

function DataPrivacy({ hasOuterDiv }) {
  const intl = useIntl()
  const url = intl.formatMessage({ id: 'data_privacy_url' })

  return (
    <>
      {hasOuterDiv && (
        <div
          style={{
            marginTop: '70px',
            marginBottom: '10px',
            height: `calc(100vh - 70px)`,
          }}
        >
          <UrlFrame url={url} />
        </div>
      )}
      {!hasOuterDiv && <UrlFrame url={url} />}
    </>
  )
}

export default DataPrivacy
