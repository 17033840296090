//User

export const GET_DOWNLOAD_COUNT = 'GET_DOWNLOAD_COUNT'
export const GET_SALE_COUNT = 'GET_SALE_COUNT'
export const GET_SALE_COUNT_LOADING = 'GET_SALE_COUNT_LOADING'
export const GET_MOST_VISITED_POST = 'GET_MOST_VISITED_POST'
export const ARTIST_SONGS_LIST = 'ARTIST_SONGS_LIST'

//auth

export const AuthType = {
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  CHANGE_PASSWORD_REQUEST_SUCCESS: 'CHANGE_PASSWORD_REQUEST_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  PASSWORD_CHANGE_SUCCESS: 'PASSWORD_CHANGE_SUCCESS',
  DIRECT_PASSSWORD_CHANGE_SUCCESS: 'DIRECT_PASSSWORD_CHANGE_SUCCESS',
  ARTIST_DETAIL_SUCESS: 'ARTIST_DETAIL_SUCCESS',
  ARTIST_FOLLOWER_COUNT: 'ARTIST_FOLLOWER_COUNT',
  SUPERFAN_ARTIST_FOLLOWER_COUNT: 'SUPERFAN_ARTIST_FOLLOWER_COUNT',
  CHANGED_SUBSCRIPTION_STATUS: 'CHANGED_SUBSCRIPTION_STATUS',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
}

// FAN PAGE
export const fanPageType = {
  GET_ALL_POSTS_SUCCESS: 'GET_ALL_POSTS_SUCCESS',
  GET_POST_CHART_DETAILS_SUCCESS: 'GET_POST_CHART_DETAILS_SUCCESS',
  GET_ALL_EVENTS_SUCCESS: 'GET_ALL_EVENTS_SUCCESS',
  DELETE_POST: 'DELETE_POST',
  DELETE_EVENT: 'DELETE_EVENT',
  UPDATE_POST_DATA: 'UPDATE_POST_DATA',
  UPDATE_EVENT_DATA: 'UPDATE_EVENT_DATA',
  GET_ALL_COMMENTS_SUCCESS: 'GET_ALL_COMMENTS_SUCCESS',
  PROGRESS: 'PROGRESS',
  UPDATE_POST_TYPE: 'UPDATE_POST_TYPE',
}

// UPLOAD PAGE

export const uploadPageType = {
  CREATE_SONG: 'CREATE_SONG',
  DELETE_SONG: 'DELETE_SONG',
  DELETE_ALBUM: 'DELETE_ALBUM',
  CREATE_ALBUM: 'CREATE_ALBUM',
  GET_ALL_ALBUMS_SUCCESS: 'GET_ALL_ALBUMS_SUCCESS',
  REMOVE_ALL_SONGS: 'REMOVE_ALL_SONGS',
  GET_ALL_SONGS_SUCCESS: 'GET_ALL_SONGS_SUCCESS',
  UPDATE_ALBUM_DATA: 'UPDATE_ALBUM_DATA',
  UPDATE_SINGLE_SONG_DATA: 'UPDATE_SINGLE_SONG_DATA',
}

// POPUP MESSAGES

export const SET_MESSAGE_DETAILS = 'SET_MESSAGE_DETAILS'
