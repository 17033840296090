import React from 'react'
import styles from './Loading.module.css'

import { Spin, Space, Progress } from 'antd'

const Loading = () => {
  return (
    <div className={styles.fullPageLoaderContainer}>
      <div className={styles.loader}>
        <Space>
          <Spin size="large" />
        </Space>
      </div>
    </div>
  )
}

export default Loading

export const ProgressBar = props => {
  console.log('props', props.percent)
  return (
    <div className={styles.fullPageLoaderContainer}>
      <div className={styles.loader}>
        <Space>
          <Progress
            type="circle"
            percent={props.percent}
            format={percent => `${percent} %`}
          />
        </Space>
      </div>
    </div>
  )
}
