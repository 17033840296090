import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import NavbarDashboard from '../../containers/DashboardTopbar/index'
import SecondNavbar from '../../containers/DashboardTopbar/SecondNavbar'
import Sidemenubar from '../../containers/Sidebar'
import Navbar from '../../containers/TopBar/index'

export const LayoutOne = ({ children }) => (
  <div>
    <div>
      <Navbar />
    </div>
    <div>{children}</div>
  </div>
)

export const LayoutTwo = ({ children }) => {
  const history = useHistory()
  const logindetails = JSON.parse(localStorage.getItem('token'))
  const profilePic = useSelector(state => state.auth.profilePic)
  const adminVerified = useSelector(state => state.auth.adminVerified)

  if (adminVerified === false) {
    localStorage.removeItem('token')
    history.push('/')
  }

  const showDrawer = () => {
    console.log('set visible true here')
  }

  return (
    <>
      <NavbarDashboard openSidebar={showDrawer} profilePic={profilePic} />
      <main>
        <div style={{ display: 'flex', backgroundColor: '#f2fbf9' }}>
          <Sidemenubar />
          {logindetails === null ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            <div className="content">{children}</div>
          )}
        </div>
      </main>
    </>
  )
}

export const LayoutThree = ({ children }) => {
  const history = useHistory()

  const logindetails = JSON.parse(localStorage.getItem('token'))
  const profilePic = useSelector(state => state.auth.profilePic)
  const adminVerified = useSelector(state => state.auth.adminVerified)

  if (adminVerified === false) {
    localStorage.removeItem('token')
    history.push('/')
  }

  return (
    <>
      <SecondNavbar profilePic={profilePic} />
      {logindetails === null ? (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      ) : (
        <div className="aContent">{children}</div>
      )}
    </>
  )
}
