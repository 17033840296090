/**
 * steps for center text sizes
 *  < 100
 * height / 10
 *  < 1000
 * height / 12
 *  < 10000
 * height / 15
 *  < 1000000
 * height / 18
 *
 * +€ 9.099.999,00 = length 15
 */

export const getFontSize = (text, height) => {
  const textLength = text.length
  if (textLength < 9) return (height / 10).toFixed(2)
  if (textLength < 10) return (height / 12).toFixed(2)
  if (textLength < 15) return (height / 15).toFixed(2)
  return (height / 18).toFixed(2)
}
