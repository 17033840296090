import React, { Component } from 'react'
import Chart from 'chart.js'
import classes from './pieGraph.module.css'
import { getFontSize } from './utils'
let myLineChart

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.legend.display = false

export default class PieGraph extends Component {
  componentDidMount() {
    this.buildChart()
  }

  componentDidUpdate() {
    this.buildChart()
  }

  componentWillUnmount() {
    myLineChart.destroy()
  }

  buildChart = () => {
    const myChartRef = document.getElementById(this.props.id)
    const { labels, data, centerText, id } = this.props
    const formattedData = data.map(value =>
      value ? Math.round(value * 100) / 100 : value
    )
    if (!id) throw new Error('Prop "id" is missing in PieGraph!')

    myLineChart = new Chart(myChartRef, {
      type: 'doughnut',
      data: {
        //Bring in data
        datasets: [
          {
            label: 'Downloads',
            data: formattedData,
            fill: true,
            backgroundColor: ['#7CD2C6', '#479A8E', '#f9cb88', '#fadcb1'],
          },
        ],
        labels: labels,
      },
      options: {
        cutoutPercentage: 60,
        hover: { mode: null },
        maintainAspectRatio: false,
        title: {
          text: centerText,
        },
        plugins: {
          filler: {
            propagate: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 0,
            },
          },
        },
        tooltips: {
          callbacks: {
            label: d => {
              return `${labels[d.index]} ${this.props.formatPrice(
                formattedData[d.index]
              )}`
            },
          },
        },
        spanGaps: false,
      },
    })
  }

  render() {
    return (
      <div className={classes.graphContainer} style={{ height: '200px' }}>
        <canvas id={this.props.id} />
      </div>
    )
  }
}

Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.type !== 'doughnut') {
      return
    }
    var width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx

    ctx.restore()
    const fontSize = getFontSize(chart.config.options.title.text, height)
    ctx.font = fontSize + 'px verdana, sans-serif '
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#479A8E'
    ctx.textBaseline = 'middle'

    var text = chart.config.options.title.text,
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2

    ctx.fillText(text, textX, textY)
    ctx.save()
  },
})
