import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import {
  LayoutOne,
  LayoutThree,
  LayoutTwo,
} from '../../components/Utility/layout'
import Login from '../Login/LoginPage'
import Upload from '../Upload/index'
import UploadAlbum from '../Upload/uploadAlbum'
import FanPage from '../FanPage/index'
import CreatePost from '../FanPage/createPost'
import ErrorBoundary from '../../ErrorBoundary'
import UploadSingle from '../Upload/UploadSingle'
import EditAlbum from '../Upload/editAlbum'
import CreateExclusivePost from '../FanPage/createExclusivePost'
import EditSingleSong from '../Upload/editSingleSong'
import Home from '../Login/HomePage'
import SignUp from '../Login/SignupPage'
import ForgotPassword from '../Login/ForgotPasswordScreen'
import OnlyForDashboard from '../OnlyForDashboard'
import SeeAllSongs from '../Upload/seeAllSongs'
import SeeAllAlbums from '../Upload/seeAllAlbums'
import EditPost from '../FanPage/editPost'
import EditEvent from '../FanPage/editEvent'
import HomeDashboard from '../HomeScreen'
import Settings from '../Settings'
import Profile from '../Settings/profile'
import UploadAudio from '../FanPage/uploadAudio'
import UploadExclusiveAudio from '../FanPage/uploadExclusiveAudio'
import UploadVideo from '../FanPage/uploadVideo'
import UploadExclusiveVideo from '../FanPage/uploadExclusiveVideo'
import Impressum from '../Impressum'
import { useDispatch, useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { messages } from '../../translation'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import { setMessageDetails } from '../Redux/actions/auth'
import CreateEvent from '../FanPage/createEvent'
import { paths } from '../../paths'
import TermsAndConditions from '../TermsAndConditions'
import DataPrivacy from '../DataPrivacy'

class Routes extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <RouteWrapper path="/" exact component={Home} layout={LayoutOne} />
          <RouteWrapper
            path="/signUp"
            exact
            component={SignUp}
            layout={LayoutOne}
          />
          <RouteWrapper
            path="/signup-data-privacy"
            exact
            component={() => <DataPrivacy hasOuterDiv />}
            layout={LayoutOne}
          />
          <RouteWrapper
            path="/login"
            exact
            component={Login}
            layout={LayoutOne}
          />
          <RouteWrapper
            path="/forgotPassword"
            exact
            component={ForgotPassword}
            layout={LayoutOne}
          />
          <RouteWrapper
            path="/home"
            exact
            component={HomeDashboard}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path="/settings"
            exact
            component={Settings}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path="/profile"
            exact
            component={Profile}
            layout={LayoutTwo}
            initialSlider={1}
          />
          <RouteWrapper
            path="/onlyForDashboard"
            exact
            component={OnlyForDashboard}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/upload"
            exact
            component={Upload}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path="/upload/allSongs"
            exact
            component={SeeAllSongs}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path="/upload/allAlbums"
            exact
            component={SeeAllAlbums}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path="/upload/single"
            exact
            component={UploadSingle}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/upload/editSingleSong"
            exact
            component={EditSingleSong}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/upload/album"
            exact
            component={UploadAlbum}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/upload/editAlbumById"
            exact
            component={EditAlbum}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage"
            exact
            component={FanPage}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path="/fanpage/createPost"
            exact
            component={CreatePost}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage/editPost"
            exact
            component={EditPost}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage/editEvent"
            exact
            component={EditEvent}
            layout={LayoutThree}
          />
          {/* <RouteWrapper
            path="/fanpage/postAnalytics"
            exact
            component={PostAnalytics}
            layout={LayoutTwo}
          /> */}
          <RouteWrapper
            path="/fanpage/createExclusivePost"
            exact
            component={CreateExclusivePost}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage/uploadAudio"
            exact
            component={UploadAudio}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage/uploadEvent"
            exact
            component={CreateEvent}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage/uploadExclusiveAudio"
            exact
            component={UploadExclusiveAudio}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage/uploadVideo"
            exact
            component={UploadVideo}
            layout={LayoutThree}
          />
          <RouteWrapper
            path="/fanpage/uploadExclusiveVideo"
            exact
            component={UploadExclusiveVideo}
            layout={LayoutThree}
          />
          <RouteWrapper
            path={paths.imprint}
            exact
            component={Impressum}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path={paths.termsAndConditions}
            exact
            component={TermsAndConditions}
            layout={LayoutTwo}
          />
          <RouteWrapper
            path={paths.dataPrivacy}
            exact
            component={DataPrivacy}
            layout={LayoutTwo}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  const lang = useSelector(state => state.auth.currentLanguage)
  const message = useSelector(state => state.auth.message)
  const infoButtonText = useSelector(state => state.auth.infoButtonText)
  const infoButtonAction = useSelector(state => state.auth.infoButtonAction)
  const messageType = useSelector(state => state.auth.type)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true)
    }
  }, [message])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    dispatch(setMessageDetails())
  }

  const action =
    infoButtonText !== undefined && infoButtonText.length > 0 ? (
      <Button
        style={{ color: 'white' }}
        size="small"
        onClick={infoButtonAction}
      >
        {infoButtonText}
      </Button>
    ) : null

  const autoHideDuration = action === null ? 6000 : 10000

  return (
    <ErrorBoundary>
      <Route
        {...rest}
        render={props => (
          <IntlProvider messages={messages[lang]} locale={lang}>
            <Layout {...props}>
              <Component {...props} />
              {message.length > 0 ? (
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={open}
                  autoHideDuration={autoHideDuration}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity={messageType}
                    action={action}
                  >
                    <p style={{ marginBottom: '0px' }}>{message}</p>
                  </Alert>
                </Snackbar>
              ) : null}
            </Layout>
          </IntlProvider>
        )}
      />
    </ErrorBoundary>
  )
}

export default Routes
