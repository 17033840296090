import React from 'react'
import TranslateText from '../../../translation/TranslateText'
import { Table } from 'antd'
import styles from '../home.module.css'
import { addKeysToTableData, getMonthNameFromObject } from '../utils'
import superFanIcon from '../../../Assets/Images/superFan.png'
import { usePricesFormatting } from '../../../hooks/usePricesFormatting'
import { Tooltip } from '@material-ui/core'
import { useIntl } from 'react-intl'
import moment from 'moment'

const getColumns = (
  formatPrice,
  tooltipTitlePastMonths,
  tooltipTitleCurrentMonth
) => [
  {
    title: <TranslateText id="month" />,
    key: 'month',
    align: 'center',
    render: data => getMonthNameFromObject(data._id),
    ellipsis: true,
  },
  {
    title: <TranslateText id="total_superfan_followers_count" />,
    dataIndex: 'count',
    key: 'count',
    align: 'center',
    ellipsis: true,
  },
  {
    title: <TranslateText id="total_superfan_followers_won" />,
    dataIndex: 'wonSubscriptions',
    key: 'unsubscribedCount',
    align: 'center',
    ellipsis: true,
  },
  {
    title: <TranslateText id="total_superfan_revenue_this_month" />,
    ellipsis: true,
    key: 'revenue',
    render: ({ totalArtistFee, _id: { year, month } }) => {
      const today = moment()
      const currentYear = today.year()
      const currentMonth = today.month()
      const isCurrentMonth = year === currentYear && month === currentMonth + 1
      return (
        <Tooltip
          title={
            isCurrentMonth ? tooltipTitleCurrentMonth : tooltipTitlePastMonths
          }
        >
          <div>{formatPrice(totalArtistFee)}</div>
        </Tooltip>
      )
    },
    align: 'center',
  },
]

export const SuperFansTable = ({ artistSuperFanFollowerData }) => {
  const formatPrice = usePricesFormatting()
  const intl = useIntl()

  return (
    <div className={styles.container}>
      <div className={styles.headingBox}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span
            style={{
              color: '#232324',
              cursor: 'pointer',
              fontWeight: '500',
              fontSize: '18px',
              marginRight: '4px',
            }}
          >
            <TranslateText id="total_superfan_followers" />
          </span>
          <img
            style={{ width: '28px', height: '28px' }}
            src={superFanIcon}
            alt="superFanIcon"
          />
        </div>
      </div>
      <Table
        pagination={{ pageSize: 3 }}
        columns={getColumns(
          formatPrice,
          intl.formatMessage({ id: 'total_superfan_tooltip_past_months' }),
          intl.formatMessage({ id: 'total_superfan_tooltip_current_month' })
        )}
        dataSource={addKeysToTableData(artistSuperFanFollowerData)}
      />
    </div>
  )
}

export default SuperFansTable
