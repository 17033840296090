import React from 'react'
import Heading from '../../../../components/Heading/Heading'
import styles from '../../home.module.css'
import TranslateText from '../../../../translation/TranslateText'
import 'moment/locale/de'
import { usePricesFormatting } from '../../../../hooks/usePricesFormatting'

export const ShowPieGraphDetails = ({ setShowSalesData, saleData }) => {
  const formatPrice = usePricesFormatting()
  return (
    <div className={styles.dataConatiner}>
      <span>
        <div className={styles.textContainer}>
          <Heading type="h2">{formatPrice(saleData.albums)}</Heading>
          <p
            style={{
              color: '#479A8E',
              fontSize: '12px',
              marginBottom: 10,
              marginTop: -4,
            }}
          >
            <TranslateText id="albums" />
          </p>
        </div>
      </span>

      <div className={styles.textContainer}>
        <Heading type="h2">{formatPrice(saleData.singles)}</Heading>
        <p style={{ fontSize: '12px', marginBottom: 10, marginTop: -4 }}>
          <TranslateText id="singles" />
        </p>
      </div>

      <span
        onClick={() => {
          setShowSalesData(false)
        }}
      >
        <div className={styles.textContainer}>
          <Heading type="h2">
            {formatPrice(saleData.currentMonthlyDonation)}
          </Heading>
          <p
            style={{
              color: '#fadcb1',
              fontSize: '12px',
              marginBottom: 10,
              marginTop: -4,
            }}
          >
            <TranslateText id="donation" />
          </p>
        </div>
      </span>

      <div className={styles.textContainer}>
        <Heading type="h2">{formatPrice(saleData.revenue)}</Heading>
        <p
          style={{
            color: '#f9cb88',
            fontSize: '12px',
            marginBottom: 10,
            marginTop: -4,
          }}
        >
          <TranslateText id="superfan_subscription" />
        </p>
      </div>
    </div>
  )
}
