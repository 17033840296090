import React from 'react'
import { Modal, Button } from 'antd'
import TranslateText from '../../translation/TranslateText'

export default function GemmaModal(props) {
  return (
    <Modal
      title=""
      centered
      open={props.show}
      width={300}
      maskClosable={false}
      footer={null}
      closable={false}
      onCancel={() => props.closePopup()}
    >
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <span>
              <label
                style={{
                  color: '#303135',
                  fontSize: '13px',
                  fontWeight: 500,
                  marginRight: '15px',
                  textAlign: 'left',
                }}
              >
                {props.type === 'song' ? (
                  <TranslateText id="gema_warning_song" />
                ) : (
                  <TranslateText id="gema_warning_album" />
                )}
              </label>
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2em',
          }}
        >
          <Button
            onClick={() => {
              props.handleOk()
            }}
            style={{
              marginRight: '4em',
              borderColor: '#7cd2c6',
              background: '#7cd2c6',
              borderRadius: '24px',
              color: 'white',
            }}
          >
            <TranslateText id="continue" />
          </Button>
          <Button
            onClick={() => {
              props.handleCancel()
            }}
            style={{
              background: '#ffffff',
              borderRadius: '24px',
              marginRight: '12px',
              borderColor: '#7cd2c6',
              color: '#7cd2c6',
            }}
          >
            <TranslateText id="cancel" />
          </Button>
        </div>
      </div>
    </Modal>
  )
}
