import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './root-reducer'
const logger = createLogger({
  level: 'log',
})
const composerEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose

export default createStore(
  rootReducer,
  {},
  composerEnhancer(applyMiddleware(thunk, logger))
)
