import { Modal } from 'antd'
import React from 'react'
import TranslateText from '../../translation/TranslateText'
import { CloseOutlined } from '@ant-design/icons'

const SimpleModal = props => {
  const {
    isModalOpen,
    translationIdTitle,
    handleCloseClick,
    width,
    children,
    icon,
  } = props
  return (
    <Modal
      closable={false}
      centered
      open={isModalOpen}
      maskClosable={false}
      footer={null}
      width={width}
    >
      <div style={{ margin: '-1em 0 -0.5em' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '0.5em',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {icon && (
              <img
                style={{ width: '28px', height: '28px', marginRight: '8px' }}
                src={icon}
                alt="superFanIcon"
              />
            )}
            <h2 style={{ width: '100%', textAlign: 'center', marginBottom: 0 }}>
              <TranslateText id={translationIdTitle} />
            </h2>
          </div>
          <div>
            <CloseOutlined
              style={{ color: 'black', marginTop: '12px' }}
              onClick={handleCloseClick}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {children}
        </div>
      </div>
    </Modal>
  )
}

export default SimpleModal
