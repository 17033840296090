import { AuthType, SET_MESSAGE_DETAILS } from '../types'

const initialState = {
  signUp: [],
  loginData: [],
  passwordOTP: '',
  nickName: '',
  profilePic: '',
  contactNumber: '',
  countryCode: '',
  address: {},
  artistFollowerData: [],
  artistSuperFanFollowerData: [],
  subscriptionEnabledStatus: true,
  currentLanguage: JSON.parse(localStorage.getItem('language')) || 'en',
  adminVerified: '',
  message: '',
  type: '',
  email: '',
  artistPaymentDetails: {},
  infoButtonText: '',
  infoButtonAction: () => {},
}

export default function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case AuthType.SIGN_UP_SUCCESS:
      return {
        ...state,
        signUp: action.payload,
        type: 'success',
        message: action.payload.message,
      }

    case AuthType.LOGIN_SUCCESS:
      return {
        ...state,
        loginData: action.payload,
        adminVerified: action.payload.data.artist.adminVerified,
        type: 'success',
        message: action.payload.message,
      }

    case AuthType.ARTIST_DETAIL_SUCESS:
      return {
        ...state,
        loginData: action.payload,
        nickName: action.payload.nickName,
        profilePic: action.payload.profilePic,
        contactNumber: action.payload.contactNumber,
        countryCode: action.payload.countryCode,
        address: action.payload.address,
        subscriptionEnabledStatus: action.payload.subscriptionEnable,
        adminVerified: action.payload.adminVerified,
        email: action.payload.email,
        artistPaymentDetails: action.payload.artistPayment,
        gemaNumber: action.payload.gemaNumber,
        instagramName: action.payload.instagramName,
      }

    case AuthType.ARTIST_FOLLOWER_COUNT:
      return {
        ...state,
        artistFollowerData: action.payload,
      }
    case AuthType.SUPERFAN_ARTIST_FOLLOWER_COUNT:
      return {
        ...state,
        artistSuperFanFollowerData: action.payload,
      }
    case AuthType.CHANGED_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscriptionEnabledStatus: action.payload,
      }
    case AuthType.CHANGE_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      }
    case SET_MESSAGE_DETAILS:
      return {
        ...state,
        type: action.payload.status,
        message: action.payload.message,
        infoButtonText: action.payload.infoButtonText,
        infoButtonAction: action.payload.infoButtonAction,
      }
    default:
      return state
  }
}
