import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import { DownCircleTwoTone, UpCircleTwoTone } from '@ant-design/icons'
import { IoIosArrowDown } from 'react-icons/io'
import { AiFillDelete } from 'react-icons/ai'
import { FcInfo } from 'react-icons/fc'
import { useDispatch, useSelector } from 'react-redux'
import Draggable from 'react-draggable'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Popconfirm,
  TimePicker,
  Dropdown,
  Menu,
  Select,
  Progress,
  Checkbox,
  Tooltip,
} from 'antd'
import html2canvas from 'html2canvas'
import moment from 'moment'

import 'react-calendar/dist/Calendar.css'
import styles from '../Login/login.module.css'
import styleUpload from './upload.module.css'

import { uploadPageType } from '../Redux/types'
import {
  addArtistGemaNumber,
  getArtistById,
  setMessageDetails,
} from '../Redux/actions/auth'
import {
  deleteSong,
  createAlbum,
  fileUpload,
} from '../Redux/actions/uploadPage'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'

import AlignCenter from '../../Assets/Images/align-center.svg'
import AlignJustify from '../../Assets/Images/align-justify.svg'
import AlignLeft from '../../Assets/Images/align-left.svg'
import AlignRight from '../../Assets/Images/align-right.svg'
import DropdownIcon from '../../Assets/Images/dropdownIcon.svg'
import nextIcon from '../../Assets/Images/nextIcon.svg'
import play from '../../Assets/Images/play.png'
import previousIcon from '../../Assets/Images/previousIcon.svg'
import RedoIcon from '../../Assets/Images/forward-arrow.svg'
import sampleImage from '../../Assets/Images/sampleImage.png'
import TextIcon from '../../Assets/Images/TextIcon.svg'
import UndoIcon from '../../Assets/Images/back-arrow.svg'
import { useIntl } from 'react-intl'

import {
  font_size,
  font_family,
  font_color,
  background_color,
} from './bookletEditorOptions'
import CropImageModalPopup from '../../components/CommonScreen/cropImageModalPopup'
import TranslateText from '../../translation/TranslateText'
import InsertGemaModal from '../../components/GemmaModalPopup/InsertGemaModal'
import mark from '../../Assets/Images/newWhiteBg.svg'
import { usePricesFormatting } from '../../hooks/usePricesFormatting'
import { getAlbumPrice } from '../../iap-prices'

const { Dragger } = Upload
const { Option } = Select

export default function UploadAlbum(props) {
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const dispatch = useDispatch()
  const intl = useIntl()
  const songs = useSelector(state => state.uploadPage.songs)
  const artistId = JSON.parse(localStorage.getItem('artistId'))
  const [albumName, setAlbumName] = useState('')
  const [slider, setSlider] = React.useState(0)
  const [changeDate, setChangeDate] = useState(new Date())
  const [songDataArray, setSongDataArray] = React.useState([])
  const [allCoverImageUrl, setAllCoverImageUrl] = useState([])
  const [bookletUrl, setBookletUrl] = useState('')
  const [allBookletCoverImageUrl, setAllBookletCoverImageUrl] = useState([])
  const [time, setTime] = useState(moment().format('hh:mm:ss'))
  const [albumPrice, setAlbumPrice] = useState('')

  const [modalVisible, setModalVisible] = useState(false)
  const [fileUrl, setFileUrl] = useState()
  const [croppedFileName, setCroppedFileName] = useState('')
  const [croppedImageUrl, setCroppedImageUrl] = useState()
  const [croppedImageFile, setCroppedImageFile] = useState()
  const [currentCoverImageIndex, setCurrenCoverImageIndex] = useState(0)
  const [backSlideNum, setBackSlideNum] = useState()
  const [removeImagebyIndex, setremoveImagebyIndex] = useState('')
  const [songTitleArray, setSongTitleArray] = useState([])
  const [songTitleEmptyError, setSongTitleEmptyError] = useState(false)

  const [align, setAlign] = useState(false)
  const [selectedAlign, setSelectedAlign] = useState('left')
  const [songPositionUpdate, setSongPositionUpdate] = useState(false)

  const [backgroundColor, setBackgroundColor] = useState(false)
  const [selectedBackgroundColor, setSelectedBackgroundColor] =
    useState('transparent')
  const backgroundColorList = background_color

  const [currentFileIndex, setCurrentFileIndex] = useState(null)
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null)
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null)
  const [files, setFiles] = useState([])
  const [songDuration, setSongDuration] = useState([])
  const [songName, setSongName] = useState([])
  const [gemaProtectedSongIds, setGemaProtectedSongIds] = useState([])
  const [length, setLength] = useState(0)
  const [albumType, setAlbumType] = useState('')
  const userGemaNumber = useSelector(state => state.auth.gemaNumber)
  const [insertGemaNumber, setInsertGemaNumber] = useState(false)
  const formatPrice = usePricesFormatting()

  const changeBackgroundColor = ({ key }) => {
    setSelectedBackgroundColor(key)
    const activeTextBox = allText.filter(text => text.id === selectedTextBox)
    const newTextBoxList = allText.filter(text => text.id !== selectedTextBox)
    if (activeTextBox.length !== 0) {
      const activeTextBoxUpdatedStyle = {
        ...activeTextBox[0],
        style: {
          ...activeTextBox[0].style,
          backgroundColor: key,
        },
      }
      setAllText([...newTextBoxList, activeTextBoxUpdatedStyle])
    }
    addNewAllStatusStack()
  }

  const backGroundColorMenu = (
    <Menu
      style={{ height: '200px', overflow: 'auto', marginTop: '-4px' }}
      onClick={changeBackgroundColor}
    >
      {backgroundColorList.map(bgColor => (
        <Menu.Item key={bgColor.value}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: `${bgColor.value}`,
                borderRadius: '20px',
                marginRight: '10px',
              }}
            ></div>
            {bgColor.name}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const [color, setColor] = useState(false)
  const colorList = font_color

  const changeFontColor = ({ key }) => {
    setSelectedColor(key)
    const activeTextBox = allText.filter(text => text.id === selectedTextBox)
    const newTextBoxList = allText.filter(text => text.id !== selectedTextBox)
    if (activeTextBox.length !== 0) {
      const activeTextBoxUpdatedStyle = {
        ...activeTextBox[0],
        style: {
          ...activeTextBox[0].style,
          color: key,
        },
      }
      setAllText([...newTextBoxList, activeTextBoxUpdatedStyle])
    }
    addNewAllStatusStack()
  }

  const colorMenu = (
    <Menu
      style={{ height: '200px', overflow: 'auto', marginTop: '-4px' }}
      onClick={changeFontColor}
    >
      {colorList.map(fontColor => (
        <Menu.Item key={fontColor.value}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: `${fontColor.value}`,
                borderRadius: '20px',
                marginRight: '10px',
              }}
            ></div>
            {fontColor.name}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const [selectedFontSize, setSelectedFontSize] = useState('40px')
  const fontSizeList = font_size

  const [selectedFontFamily, setSelectedFontFamily] = useState('Barlow')
  const fontFamilyList = font_family

  const [allText, setAllText] = useState([])
  const [selectedColor, setSelectedColor] = useState('black')

  const [selectedTextBox, setSelectedTextBox] = useState('')

  const [allStatusStack, setAllStatusStack] = useState([
    {
      allTextBoxes: [],
    },
  ])
  const [currentStatusStack, setCurrentStatusStack] = useState(0)

  const addNewAllStatusStack = () => {
    setAllStatusStack([
      ...allStatusStack,
      {
        allTextBoxes: allText,
      },
    ])
    setCurrentStatusStack(currentStatusStack + 1)
  }

  const crypto = window.crypto
  const arrayValue = new Uint32Array(1)
  const randomValues = crypto.getRandomValues(arrayValue)

  const createNewTextBox = () => {
    setAllText([
      ...allText,
      {
        id: 'dragText' + randomValues[0],
        value: 'Default Text',
        style: {
          padding: '10px 20px',
          color: 'black',
          backgroundColor: 'transparent',
          textAlign: 'left',
          fontSize: '40px',
          fontFamily: 'Barlow',
        },
        transformStyles: {
          transform: 'translate(0px, 0px)',
        },
      },
    ])
    addNewAllStatusStack()
  }

  const handleTextBoxClick = e => {
    let allStyles = ''
    let selectedId = ''
    if (e.target.attributes.length === 2) {
      if (e.target.attributes.style.nodeValue !== undefined) {
        allStyles = e.target.attributes.style.nodeValue
        selectedId = e.target.parentElement.id
      }
    } else if (e.target.attributes.length === 0) {
      allStyles = e.target.parentElement.attributes.style.nodeValue
      selectedId = e.target.parentElement.parentElement.id
    }
    let splittedStyles = allStyles.split('; ')
    let styledComponentStyle = {
      padding: splittedStyles[0].split(': ')[1],
      color: splittedStyles[1].split(': ')[1],
      backgroundColor: splittedStyles[2].split(': ')[1],
      textAlign: splittedStyles[3].split(': ')[1],
      fontSize: splittedStyles[4].split(': ')[1],
      fontFamily: splittedStyles[5].split(': ')[1],
      width: splittedStyles[6].slice(0, -1).split(': ')[1],
    }
    setSelectedColor(styledComponentStyle.color)
    setSelectedBackgroundColor(styledComponentStyle.backgroundColor)
    setSelectedAlign(styledComponentStyle.textAlign)
    setSelectedFontSize(styledComponentStyle.fontSize)
    setSelectedFontFamily(styledComponentStyle.fontFamily)
    setSelectedTextBox(selectedId)
  }

  const handleUndo = () => {
    let prevState = currentStatusStack - 1
    if (prevState > 0) {
      setCurrentStatusStack(prevState)
      setAllText(allStatusStack[prevState].allTextBoxes)
    }
  }

  const handleRedo = () => {
    let nextState = currentStatusStack + 1
    if (nextState < allStatusStack.length) {
      setCurrentStatusStack(nextState)
      setAllText(allStatusStack[nextState].allTextBoxes)
    }
  }

  const checkAlignValue = e => {
    if (e.target.attributes.value.nodeValue !== undefined) {
      setSelectedAlign(e.target.attributes.value.nodeValue)
      const activeTextBox = allText.filter(text => text.id === selectedTextBox)
      const newTextBoxList = allText.filter(text => text.id !== selectedTextBox)
      if (activeTextBox.length !== 0) {
        const activeTextBoxUpdatedStyle = {
          ...activeTextBox[0],
          style: {
            ...activeTextBox[0].style,
            textAlign: e.target.attributes.value.nodeValue,
          },
        }
        setAllText([...newTextBoxList, activeTextBoxUpdatedStyle])
      }
      toggle(align, setAlign)
      addNewAllStatusStack()
    }
  }

  const changeFontFamily = e => {
    const activeTextBox = allText.filter(text => text.id === selectedTextBox)
    const newTextBoxList = allText.filter(text => text.id !== selectedTextBox)
    if (activeTextBox.length !== 0) {
      const activeTextBoxUpdatedStyle = {
        ...activeTextBox[0],
        style: {
          ...activeTextBox[0].style,
          fontFamily: e.target.value,
        },
      }
      setAllText([...newTextBoxList, activeTextBoxUpdatedStyle])
    }
    setSelectedFontFamily(e.target.value)
    addNewAllStatusStack()
  }

  const changeFontSize = e => {
    const activeTextBox = allText.filter(text => text.id === selectedTextBox)
    const newTextBoxList = allText.filter(text => text.id !== selectedTextBox)
    if (activeTextBox.length !== 0) {
      const activeTextBoxUpdatedStyle = {
        ...activeTextBox[0],
        style: {
          ...activeTextBox[0].style,
          fontSize: e.target.value,
        },
      }
      setAllText([...newTextBoxList, activeTextBoxUpdatedStyle])
    }
    setSelectedFontSize(e.target.value)
    addNewAllStatusStack()
  }

  const deleteText = () => {
    const newTextBoxList = allText.filter(text => text.id !== selectedTextBox)
    setSelectedTextBox('')
    setAllText(newTextBoxList)
    addNewAllStatusStack()
  }

  const clearAllTextBox = () => {
    setAllText([])
  }

  const saveEditImage = () => {
    html2canvas(document.getElementById('uploadContainer'), {
      scrollY: -window.scrollY,
    }).then(canvas => {
      const dataurl = canvas.toDataURL()
      let arr = dataurl.split(','),
        imageType = arr[0].match(/:(.*?);/)[1],
        decodedBase64String = atob(arr[1]),
        n = decodedBase64String.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = decodedBase64String.charCodeAt(n)
      }
      let newCroppedImageFile = new File([u8arr], croppedFileName, {
        type: imageType,
      })
      setBookletUrl('')
      clearAllTextBox()
      if (removeImagebyIndex !== '') {
        allBookletCoverImageUrl[removeImagebyIndex].url = dataurl
        allBookletCoverImageUrl[removeImagebyIndex].imageFile =
          newCroppedImageFile
        setAllBookletCoverImageUrl(allBookletCoverImageUrl)
        setremoveImagebyIndex('')
      } else {
        setAllBookletCoverImageUrl([
          ...allBookletCoverImageUrl,
          { url: dataurl, imageFile: newCroppedImageFile },
        ])
      }
    })
  }

  const removeBookletImage = () => {
    setBookletUrl('')
    setAllBookletCoverImageUrl(
      allBookletCoverImageUrl.filter(
        (image, index) => index !== removeImagebyIndex
      )
    )
    setCurrenCoverImageIndex(allBookletCoverImageUrl.length - 2)
    setremoveImagebyIndex('')
  }

  const handleStart = e => {
    let selectedId = ''
    if (e.target.attributes.length === 2) {
      if (e.target.attributes.style.nodeValue !== undefined) {
        selectedId = e.target.parentElement.id
      }
    } else if (e.target.attributes.length === 0) {
      selectedId = e.target.parentElement.parentElement.id
    }
    setSelectedTextBox(selectedId)
  }

  const handleStop = e => {
    const activeTextBox = allText.filter(text => text.id === selectedTextBox)
    const newTextBoxList = allText.filter(text => text.id !== selectedTextBox)
    if (activeTextBox.length === 1) {
      const updatedNewData = {
        ...activeTextBox[0],
        transformStyles: {
          transform: e.target.offsetParent.style.transform,
        },
      }
      setAllText([...newTextBoxList, updatedNewData])
      addNewAllStatusStack()
    }
    // setTransformStyles()
  }

  let array2 = []
  const layout = {
    labelCol: {
      offset: 6,
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const toggle = (state, func) => {
    func(() => !state)
  }

  useEffect(() => {
    showLoader()
    dispatch(getArtistById({ id: artistId })).then(response => {
      if (response && response.success) {
        hideLoader()
      }
    })
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, artistId])

  const draggerProps = {
    name: 'file',
    multiple: true,
    onChange(event) {
      event.fileList.map(file => (file.status = 'done'))
      if (
        !Object.keys(event).includes('event') &&
        event.file.status === 'done'
      ) {
        var audio = document.createElement('audio')
        var reader = new FileReader()
        reader.readAsDataURL(event.file.originFileObj)
        reader.onload = function (e) {
          audio.src = e.target.result
          audio.addEventListener('loadedmetadata', function () {
            let duration = moment.duration(
              Math.floor(audio.duration),
              'seconds'
            )
            let seconds = duration._data.seconds
            let minutes = duration._data.minutes
            let hours = duration._data.hours
            seconds = seconds > 9 ? seconds : `0${seconds}`
            minutes = minutes > 9 ? minutes : `0${minutes}`
            hours = hours > 9 ? hours : `0${hours}`
            let songTime =
              hours > 0
                ? `${hours}:${minutes}:${seconds}`
                : `${minutes}:${seconds}`
            var songData = {
              song: event.file.originFileObj,
              songDuration: songTime,
              songType: 2,
            }
            console.log(songData)
            console.log(array2)
            if (!songDataArray.some(x => x.song.uid === songData.song.uid)) {
              setSongDataArray([...songDataArray, songData])
            }
          })
        }
      }
    },
  }

  // chunk upload functionality

  function uploadChunk(readerEvent) {
    const file = files[currentFileIndex]
    const name = songName[currentFileIndex]
    const duration = songDuration[currentFileIndex]
    const data = readerEvent.target.result
    const params = new URLSearchParams()
    params.set('songName', name)
    params.set('songType', 2)
    params.set('name', file.name)
    params.set('size', file.size)
    params.set('duration', duration)
    params.set('currentChunkIndex', currentChunkIndex)
    params.set('totalChunks', 100)
    dispatch(fileUpload(data, params)).then(response => {
      const currentFile = files[currentFileIndex]
      const isLastChunk = currentChunkIndex === 99
      if (isLastChunk) {
        if (response && response.success) {
          currentFile.uploaded = response.success
        }
        setLastUploadedFileIndex(currentFileIndex)
        setCurrentChunkIndex(null)
      } else {
        setCurrentChunkIndex(currentChunkIndex + 1)
      }
    })
  }

  function readAndUploadCurrentChunk() {
    const reader = new FileReader()
    const file = files[currentFileIndex]
    const chunkSize = Math.ceil(file.size / 100)
    if (!file) {
      return
    }
    const from = currentChunkIndex * chunkSize
    const to = from + chunkSize
    const blob = file.slice(from, to)
    reader.onload = e => uploadChunk(e)
    reader.readAsDataURL(blob)
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null) {
      return
    }
    const isLastFile = lastUploadedFileIndex === songDataArray.length - 1
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1
    setCurrentFileIndex(nextFileIndex)
    if (lastUploadedFileIndex === songDataArray.length - 1) {
      setLength(0)
      setFiles([])
      setSongDuration([])
      setSongName([])
      setSongDataArray([])
      setSongTitleArray([])
      setLastUploadedFileIndex(null)
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUploadedFileIndex])

  useEffect(() => {
    if (length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(
          lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1
        )
      }
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length])

  useEffect(() => {
    if (currentFileIndex !== null) {
      const tempfile = songDataArray[currentFileIndex].song
      const tempduration = songDataArray[currentFileIndex].songDuration

      const changedSongName = songTitleArray.find(
        obj => obj.songId === songDataArray[currentFileIndex].song.uid
      )

      if (changedSongName) {
        const tempname = changedSongName.updateSongName
        setSongName([...songName, tempname])
      } else {
        const tempname = songDataArray[currentFileIndex].song.name.split('.')[0]
        setSongName([...songName, tempname])
      }
      setFiles([...files, tempfile])
      setSongDuration([...songDuration, tempduration])
      setCurrentChunkIndex(0)
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFileIndex])

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk()
    }
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChunkIndex])

  const handleSongsUpload = e => {
    if (songDataArray.length === 0) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_atleast_one_song' })
        )
      )
    } else if (songTitleEmptyError) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'enter_song_name' })
        )
      )
    } else {
      setLength(songDataArray.length)
      dispatch(
        setMessageDetails('info', intl.formatMessage({ id: 'uploading_song' }))
      )
    }
  }

  // const handleGemaStatusWarning = () => {
  //   const result = songGemmaStatus.some(element => element.gemmaProtected);
  //   if (result) {
  //     handleEmptySongList();
  //   } else {
  //     setGemmaModalVisible(true);
  //   }
  // };

  const handleDeleteSong = id => {
    dispatch(deleteSong(id))
  }

  const onFinish = values => {
    values.price = parseFloat(albumPrice)
    if (isNaN(values.price)) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'enter_correct_price' })
        )
      )
    } else {
      setSlider(1)
    }
  }

  //function to handle calendar changevalue

  const handleDateChange = nextValue => {
    setChangeDate(nextValue)
  }

  //function to handle time change

  const handleTimeChange = timeValue => {
    setTime(moment(timeValue && timeValue._d).format('HH:mm:ss'))
  }

  const getBase64 = (file, str) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      var image = new Image()
      image.src = e.target.result
      image.onload = function () {
        if (this.width === 400 && this.height === 370 && str === 'image') {
          dispatch(
            setMessageDetails(
              'info',
              intl.formatMessage({ id: 'uploading_song' })
            )
          )
          setAllCoverImageUrl([
            ...allCoverImageUrl,
            { url: reader.result, imageFile: file },
          ])
        } else if (
          this.width === 400 &&
          this.height === 370 &&
          str === 'booklet'
        ) {
          dispatch(
            setMessageDetails(
              'info',
              intl.formatMessage({ id: 'uploading_booklet_image' })
            )
          )
          setCroppedFileName(file.name)
          setBookletUrl(reader.result)
        } else if (this.width > 400 && this.height > 370) {
          setFileUrl(reader.result)
          setCroppedFileName(file.name)
          setModalVisible(true)
        } else {
          dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'image_is_too_small' })
            )
          )
        }
      }
    }
  }

  const handleImageUpload = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      if (event.file.type === 'image/jpeg' || event.file.type === 'image/png') {
        getBase64(event.file.originFileObj, 'image')
        setAllBookletCoverImageUrl([])
      } else {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'upload_jpg_or_png_file' })
          )
        )
      }
    }
  }

  // function to handle Booklet cover ImageUpload
  const handleBookletCoverImage = event => {
    event.fileList.map(file => (file.status = 'done'))
    if (!Object.keys(event).includes('event') && event.file.status === 'done') {
      if (event.file.type === 'image/png' || event.file.type === 'image/jpeg') {
        getBase64(event.file.originFileObj, 'booklet')
        setAllCoverImageUrl([])
      } else {
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'upload_jpg_or_png_file' })
          )
        )
      }
    }
  }

  // function to handle empty songs list

  const handleEmptySongList = () => {
    // setGemmaModalVisible(false);
    if (songs.length === 0) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_atleast_one_song' })
        )
      )
    } else {
      setSlider(2)
    }
  }

  //function to handle empty cover image

  const handleEmptyImage = () => {
    if (allCoverImageUrl.length > 0) {
      setBackSlideNum(3)
      setSlider(4)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_image_to_continue' })
        )
      )
    }
  }

  const handleBookletEmptyImage = () => {
    if (allBookletCoverImageUrl.length > 0) {
      setBackSlideNum(7)
      setSlider(4)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_image_to_continue' })
        )
      )
    }
  }

  //function to handle Schedule Release Calendar and Time
  const handleScheduleRelease = () => {
    if (time === '') {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_release_time_for_album' })
        )
      )
    } else {
      setSlider(6)
    }
  }

  //function to handle back
  const handleBackOrCancel = () => {
    props.history.push('/upload')
  }

  // function to handle create album

  const handleAlbumUpload = () => {
    if (songs.length === 0) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'add_songs_to_continue' })
        )
      )
      setSlider(1)
      return
    }

    const releaseOn =
      moment(changeDate).format('YYYY-MM-DD') +
      'T' +
      time +
      moment().format('Z')

    var formdata = new FormData()
    formdata.append('albumName', albumName)
    formdata.append('releaseOn', releaseOn)
    formdata.append('albumOwner', artistId)
    formdata.append('albumType', albumType)

    let songsArray = []
    songs.forEach(song => songsArray.push(song._id))

    formdata.append('songsId', JSON.stringify(songsArray))
    formdata.append(
      'gemmaProtectedStatus',
      JSON.stringify(gemaProtectedSongIds)
    )

    if (allCoverImageUrl.length > 0) {
      for (const file of allCoverImageUrl) {
        if (
          file.imageFile.type === 'image/jpeg' ||
          file.imageFile.type === 'image/png'
        ) {
          formdata.append('albumImages', file.imageFile)
        } else {
          return dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'upload_png_or_jpg' })
            )
          )
        }
      }
    } else {
      for (const file of allBookletCoverImageUrl) {
        if (
          file.imageFile.type === 'image/jpeg' ||
          file.imageFile.type === 'image/png'
        ) {
          formdata.append('albumImages', file.imageFile)
        } else {
          return dispatch(
            setMessageDetails(
              'error',
              intl.formatMessage({ id: 'upload_png_or_jpg' })
            )
          )
        }
      }
    }

    showLoader()
    dispatch(createAlbum(formdata))
      .then(resp => {
        if (resp && resp.success) {
          setTimeout(() => {
            props.history.push('/upload')
            hideLoader()
          }, 1000)
        }
      })
      .catch(() => {
        hideLoader()
        dispatch(
          setMessageDetails(
            'error',
            intl.formatMessage({ id: 'uploading_failed' })
          )
        )
      })
  }

  const gotoSlider = num => {
    setSlider(num)
    if (num === 0) {
      setSongDataArray([])
      dispatch({
        type: uploadPageType.REMOVE_ALL_SONGS,
      })
    }
  }

  const removeImage = (imageName, i) => {
    setAllCoverImageUrl(allCoverImageUrl.filter((image, index) => index !== i))
  }
  let totalDuration = '00:00:00'
  const totalSongDuration = () => {
    songs.map(song => {
      let duration =
        song.songDuration.length > 5
          ? `${song.songDuration}`
          : `00:${song.songDuration}`
      totalDuration = moment.duration(totalDuration) + moment.duration(duration)
      return true
    })
    return {
      hours: moment.duration(totalDuration)._data.hours,
      minutes: moment.duration(totalDuration)._data.minutes,
      seconds: moment.duration(totalDuration)._data.seconds,
    }
  }
  let totalTime = totalSongDuration()

  const updateGemaNumber = gemaNumber => {
    const data = {
      artistId: artistId,
      gemaNumber: gemaNumber,
    }
    dispatch(addArtistGemaNumber(data)).then(response => {
      if (response && response.success) {
        dispatch(getArtistById({ id: artistId }))
      }
    })
    setInsertGemaNumber(false)
  }

  const resetGemaStatus = () => {
    setGemaProtectedSongIds([])
    setInsertGemaNumber(false)
  }

  const dataURLtoFile = dataurl => {
    let arr = dataurl.split(','),
      imageType = arr[0].match(/:(.*?);/)[1],
      decodedBase64String = atob(arr[1]),
      n = decodedBase64String.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = decodedBase64String.charCodeAt(n)
    }
    let croppedImage = new File([u8arr], croppedFileName, { type: imageType })
    setCroppedImageUrl(dataurl)
    setCroppedImageFile(croppedImage)
  }

  const handleOk = () => {
    if (slider === 7) {
      setremoveImagebyIndex('')
      setBookletUrl(croppedImageUrl)
    }

    if (slider === 3) {
      setAllCoverImageUrl([
        ...allCoverImageUrl,
        { url: croppedImageUrl, imageFile: croppedImageFile },
      ])
    }
    setModalVisible(false)
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  const showImage = index => {
    setBookletUrl(allBookletCoverImageUrl[index].url)
    setremoveImagebyIndex(index)
    setCurrenCoverImageIndex(index)
  }

  const showBookletImage = () => {
    if (bookletUrl !== '') {
      return (
        <img
          style={{
            width: '100%',
            borderRadius: '12px',
            // height: "484px",
          }}
          src={bookletUrl}
          alt="bookletIcon"
        />
      )
    }

    return (
      <img
        style={{
          width: '100%',
          borderRadius: '12px',
          // height: "484px",
          cursor: 'default',
        }}
        src={sampleImage}
        alt="bookletIcon"
      />
    )
  }

  const saveImageButton = () => {
    if (bookletUrl !== '') {
      return (
        <div
          className={styleUpload.changeText}
          onClick={() => {
            saveEditImage()
          }}
          data-html2canvas-ignore
        >
          <p>
            <TranslateText id="save_image" />
          </p>
        </div>
      )
    }

    return (
      <div
        className={styleUpload.changeText}
        style={{ cursor: 'default' }}
        data-html2canvas-ignore
      >
        <p>
          <TranslateText id="save_image" />
        </p>
      </div>
    )
  }

  const removeImageButton = () => {
    if (bookletUrl !== '') {
      return (
        <div
          className={styleUpload.removeImage}
          onClick={() => {
            removeBookletImage()
          }}
          data-html2canvas-ignore
        >
          <p>
            <TranslateText id="remove_image" />
          </p>
        </div>
      )
    }

    return (
      <div
        className={styleUpload.removeImage}
        style={{ cursor: 'default' }}
        data-html2canvas-ignore
      >
        <p>
          <TranslateText id="remove_image" />
        </p>
      </div>
    )
  }

  const previousButton = () => {
    if (currentCoverImageIndex > 0) {
      return (
        <Button
          style={{ height: '40px', width: '120px' }}
          className={styleUpload.button}
          onClick={() => {
            showImage(currentCoverImageIndex - 1)
          }}
        >
          <TranslateText id="previous" />
        </Button>
      )
    }

    return (
      <Button
        style={{ height: '40px', width: '120px' }}
        className={styleUpload.button}
      >
        <TranslateText id="previous" />
      </Button>
    )
  }

  const bookletPageIndex = () => {
    if (allBookletCoverImageUrl.length > 0) {
      return (
        <label style={{ color: '#313135' }}>
          <TranslateText id="page" /> {currentCoverImageIndex + 1}/
          {allBookletCoverImageUrl.length}
        </label>
      )
    }

    return (
      <label style={{ color: '#313135' }}>
        <TranslateText id="page" /> 0 / 0
      </label>
    )
  }

  const nextButton = () => {
    if (currentCoverImageIndex < allBookletCoverImageUrl.length - 1) {
      return (
        <Button
          style={{ height: '40px', width: '120px' }}
          className={styleUpload.button}
          onClick={() => {
            showImage(currentCoverImageIndex + 1)
          }}
        >
          <TranslateText id="next" />
        </Button>
      )
    }

    return (
      <Button
        style={{ height: '40px', width: '120px' }}
        className={styleUpload.button}
      >
        <TranslateText id="next" />
      </Button>
    )
  }

  const getAlignIcons = () => {
    let alignedIcon = ''
    if (selectedAlign === 'center') alignedIcon = AlignCenter
    else if (selectedAlign === 'right') alignedIcon = AlignRight
    else if (selectedAlign === 'left') alignedIcon = AlignLeft
    else if (selectedAlign === 'justify') alignedIcon = AlignJustify
    return `url(${alignedIcon})`
  }

  const uploadYourFileButton = () => {
    if (window.innerWidth < 600) {
      return (
        <Button
          style={{ marginTop: '120px', width: '260px' }}
          className={styleUpload.greenColorBtn}
          onClick={() => {
            gotoSlider(3)
          }}
        >
          <TranslateText id="upload_your_own_files" />
        </Button>
      )
    } else {
      return (
        <Button
          style={{ marginTop: '190px', width: '260px' }}
          className={styleUpload.greenColorBtn}
          onClick={() => {
            gotoSlider(3)
          }}
        >
          <TranslateText id="upload_your_own_files" />
        </Button>
      )
    }
  }

  const showAllCoverImage = () => {
    // Previous Icon
    const previousIconCheck = () => {
      if (currentCoverImageIndex > 0)
        return (
          <img
            src={previousIcon}
            onClick={() => {
              setCurrenCoverImageIndex(currentCoverImageIndex - 1)
            }}
            alt="previousIcon"
            className={styleUpload.leftArrowIcon}
            style={{ cursor: 'pointer' }}
          />
        )
      else
        return (
          <img
            src={previousIcon}
            className={styleUpload.leftArrowIcon}
            alt="previousIcon"
          />
        )
    }

    // Next Icon
    const nextIconCheck = () => {
      if (
        currentCoverImageIndex < allCoverImageUrl.length - 1 ||
        currentCoverImageIndex < allBookletCoverImageUrl.length - 1
      )
        return (
          <img
            onClick={() => {
              setCurrenCoverImageIndex(currentCoverImageIndex + 1)
            }}
            src={nextIcon}
            alt="nextIcon"
            className={styleUpload.rightArrowIcon}
            style={{ cursor: 'pointer' }}
          />
        )
      else
        return (
          <img
            src={nextIcon}
            className={styleUpload.rightArrowIcon}
            alt="nextIcon"
          />
        )
    }

    if (allCoverImageUrl && allCoverImageUrl.length > 0)
      return (
        <div>
          <div>
            {previousIconCheck()}
            <img
              className={styleUpload.Imgcontent}
              src={allCoverImageUrl[currentCoverImageIndex].url}
              alt="sample"
              style={{ marginLeft: 0 }}
            />
            {nextIconCheck()}
          </div>
          <p
            style={{
              marginLeft: '170px',
              color: '#7CD2C6',
              fontSize: '12px',
              fontWeight: '500',
            }}
          >
            <TranslateText id="page" /> {currentCoverImageIndex + 1}/
            {allCoverImageUrl.length}
          </p>
        </div>
      )
    else
      return (
        <div>
          <div>
            {previousIconCheck()}
            <img
              className={styleUpload.Imgcontent}
              src={allBookletCoverImageUrl[currentCoverImageIndex].url}
              alt="sample"
              style={{ marginLeft: 0 }}
            />
            {nextIconCheck()}
          </div>
          <p
            style={{
              marginLeft: '170px',
              color: '#7CD2C6',
              fontSize: '12px',
              fontWeight: '500',
            }}
          >
            <TranslateText id="page" /> {currentCoverImageIndex + 1}/
            {allBookletCoverImageUrl.length}
          </p>
        </div>
      )
  }

  const playTimeData = () => {
    const hoursData = () => {
      if (totalTime.hours > 9) return totalTime.hours
      else return `0${totalTime.hours}`
    }
    const minutesData = () => {
      if (totalTime.minutes > 9) return totalTime.minutes
      else return `0${totalTime.minutes}`
    }
    const secondsData = () => {
      if (totalTime.seconds > 9) return totalTime.seconds
      else return `0${totalTime.seconds}`
    }

    return `${hoursData()}:${minutesData()}:${secondsData()}`
  }

  const changeSongTitle = (song, songTitle) => {
    const newSongTitle = songTitle.trim()

    if (newSongTitle) {
      setSongTitleEmptyError(false)
      const temp = songTitleArray.find(obj => obj.songId === song.uid)
      if (!temp) {
        songTitleArray.push({ songId: song.uid, updateSongName: newSongTitle })
      } else {
        songTitleArray.find(obj => obj.songId === song.uid).updateSongName =
          newSongTitle
      }
    } else {
      songTitleArray.find(obj => obj.songId === song.uid).updateSongName =
        newSongTitle
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'song_name_cant_empty' })
        )
      )
      setSongTitleEmptyError(true)
    }
  }

  const moveSongPositionUp = currentIndex => {
    if (currentIndex > 0) {
      const currentmovesong = songDataArray.splice(currentIndex, 1)
      songDataArray.splice(currentIndex - 1, 0, currentmovesong[0])
      setSongPositionUpdate(!songPositionUpdate)
      console.log(
        'move song up from position ' +
          (currentIndex + 1) +
          ' to position ' +
          currentIndex,
        songDataArray
      )
    } else
      return dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({
            id: 'you_cant_move_song_up_from_first_position',
          })
        )
      )
  }

  const moveSongPositionDown = currentIndex => {
    if (currentIndex < songDataArray.length - 1) {
      const currentmovesong = songDataArray.splice(currentIndex, 1)
      songDataArray.splice(currentIndex + 1, 0, currentmovesong[0])
      console.log(
        'move song down from position ' +
          (currentIndex + 1) +
          ' to position ' +
          (currentIndex + 2),
        songDataArray
      )
      setSongPositionUpdate(!songPositionUpdate)
    } else
      return dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({
            id: 'you_cant_move_song_down_from_last_position',
          })
        )
      )
  }

  const handleGemaStatusChange = (value, id) => {
    let dataCopy = gemaProtectedSongIds.map(x => x)
    if (value === false) {
      dataCopy = dataCopy.filter(x => x !== id)
    } else {
      dataCopy.push(id)
    }
    setGemaProtectedSongIds(dataCopy)
    if (
      value === true &&
      (userGemaNumber === undefined ||
        userGemaNumber === null ||
        userGemaNumber === '')
    ) {
      setInsertGemaNumber(true)
    } else {
      setInsertGemaNumber(false)
    }
  }

  return (
    <div className={styles.mainDiv} style={{ padding: '70px 0 0 0' }}>
      {slider === 0 ? (
        <div
          style={{
            padding: '40px 0',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              width: 690,
              margin: 'auto',
              backgroundColor: '#fff',
              zIndex: 0,
              borderRadius: 12,
              boxShadow: '2px 1px 9px #e0e0e0',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <img
              alt="mark"
              style={{
                position: 'absolute',
                width: 400,
                bottom: '-12%',
                right: '-10%',
              }}
              src={mark}
            />
            <div
              style={{
                height: '100%',
                zIndex: 2,
                margin: '0 auto',
                maxWidth: '700px',
                padding: '0 24px 40px 24px',
              }}
              className={styleUpload.cardDiv}
            >
              <div className={styles.formDiv}>
                <div>
                  <label
                    style={{
                      color: '#232324',
                      fontSize: '24px',
                      opacity: '0.9',
                    }}
                  >
                    <b>
                      <TranslateText id="upload_new_album" />
                    </b>
                  </label>
                </div>
                <div
                  style={{
                    maxWidth: '448px',
                    margin: '15px auto 0',
                  }}
                >
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      opacity: '0.5',
                    }}
                  >
                    <TranslateText id="upload_new_heading" />
                  </label>
                  <br />
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      opacity: '0.5',
                    }}
                  >
                    <TranslateText id="upload_new_heading_second_row" />
                  </label>
                  <br />
                  <br />
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      opacity: '0.5',
                    }}
                  >
                    <TranslateText id="upload_new_heading_content" />
                  </label>
                </div>
                <div
                  style={{
                    marginTop: '65px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Form
                    name="basic"
                    layout={'vertical'}
                    onFinish={onFinish}
                    initialValues={{
                      albumName: albumName,
                    }}
                  >
                    <Form.Item>
                      <Form.Item
                        name="albumName"
                        value={albumName}
                        style={{ display: 'inline-block', width: '240px' }}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Enter your album name',
                          },
                        ]}
                      >
                        <Input
                          maxLength="100"
                          size="large"
                          value={albumName}
                          defaultValue={albumName}
                          onChange={e => setAlbumName(e.target.value)}
                          placeholder={intl.formatMessage({
                            id: 'album_name',
                          })}
                          style={{ width: '243px' }}
                        />
                      </Form.Item>
                      <Select
                        size="large"
                        style={{
                          width: '200px',
                          marginLeft: '13px',
                          textAlign: 'left',
                          marginRight: '-6px',
                          cursor: 'pointer',
                          color: '#303135',
                          borderRadius: '6px',
                          outline: 0,
                        }}
                        value={albumPrice}
                        onChange={value => {
                          setAlbumType(value)
                          setAlbumPrice(getAlbumPrice(value))
                        }}
                      >
                        <Option key={1} value="">
                          {' '}
                          Select{' '}
                        </Option>
                        <Option value={'small'}>
                          {formatPrice(getAlbumPrice('small')) + '   -   Small'}
                        </Option>
                        <Option value={'medium'}>
                          {formatPrice(getAlbumPrice('medium')) +
                            '   -   Medium'}
                        </Option>
                        <Option value={'large'}>
                          {formatPrice(getAlbumPrice('large')) + '   -   Large'}
                        </Option>
                      </Select>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Form.Item style={{ marginBottom: '27px' }}>
                        <Button
                          style={{ width: '240px' }}
                          htmlType="submit"
                          className={styleUpload.greenColorBtn}
                        >
                          <TranslateText id="upload_songs" />
                        </Button>
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        zIndex: 2,
                      }}
                    >
                      <label>
                        <span
                          onClick={handleBackOrCancel}
                          style={{
                            color: '#7CD2C6',
                            zIndex: 2,
                            opacity: 0.9,
                            cursor: 'pointer',
                            textTransform: 'uppercase',
                          }}
                        >
                          <TranslateText id="cancel" />
                        </span>{' '}
                      </label>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {slider === 7 ? (
        <div
          style={{
            padding: '40px 30px 90px',
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Row gutter={[32, 32]} style={{ height: '100%' }}>
            <Col xs={24} lg={12}>
              <div
                style={{
                  position: 'relative',
                  margin: 'auto',
                  backgroundColor: '#fff',
                  zIndex: 0,
                  borderRadius: 12,
                  boxShadow: '2px 1px 9px #e0e0e0',
                  overflow: 'hidden',
                  paddingBottom: '40px',
                }}
              >
                <img
                  alt="mark"
                  style={{
                    position: 'absolute',
                    width: 400,
                    bottom: '-12%',
                    right: '-10%',
                  }}
                  src={mark}
                />
                <div
                  style={{
                    height: '100%',
                    zIndex: 2,
                    margin: '0 auto',
                    maxWidth: '700px',
                  }}
                  className={styleUpload.cardDiv}
                >
                  <div style={{ paddingTop: '88px' }}>
                    <div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div className={styleUpload.selectedPageCount}>1</div>
                        <div className={styleUpload.selectedPageCount}>2</div>
                        <div className={styleUpload.UnselectedPageCount}>3</div>
                        <div className={styleUpload.UnselectedPageCount}>4</div>
                      </div>
                      <div style={{ marginTop: '60px' }}>
                        <label
                          style={{
                            color: '#232324',
                            fontSize: '24px',
                            opacity: '1',
                          }}
                        >
                          <b>
                            <TranslateText id="cover_designer" />
                          </b>
                        </label>
                        <div style={{ width: '73%', margin: '15px auto 0' }}>
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="cover_designer_heading" />
                          </label>
                        </div>
                        <div>
                          <Form {...layout} name="basic" layout={'vertical'}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Form.Item>
                                <Button
                                  style={{
                                    marginTop: '248px',
                                    width: '240px',
                                  }}
                                  htmlType="submit"
                                  className={styleUpload.greenColorBtn}
                                  onClick={() => handleBookletEmptyImage()}
                                >
                                  <TranslateText id="i_m_done" />
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label
                              style={{
                                color: '#232324',
                                opacity: '1',
                                position: 'relative',
                                cursor: 'pointer',
                              }}
                            >
                              <span
                                style={{
                                  color: '#7CD2C6',
                                  cursor: 'pointer',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => {
                                  setSlider(2)
                                }}
                              >
                                <TranslateText id="back" />
                              </span>{' '}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              lg={12}
              style={{ height: '100%', paddingLeft: '16px' }}
            >
              <div className={styleUpload.cardDiv2}>
                {/* {bookletUrl !== "" ? ( */}
                <div className={styleUpload.container} id="uploadContainer">
                  {showBookletImage()}
                  <div
                    className={styleUpload.undo}
                    onClick={handleUndo}
                    data-html2canvas-ignore
                  >
                    <img
                      style={{
                        width: '23px',
                        margin: '10px',
                        borderRadius: '10px',
                      }}
                      src={UndoIcon}
                      alt="bookletIcon"
                    />
                  </div>
                  <div
                    className={styleUpload.redo}
                    onClick={handleRedo}
                    data-html2canvas-ignore
                  >
                    <img
                      style={{
                        width: '23px',
                        margin: '10px',
                        borderRadius: '10px',
                      }}
                      src={RedoIcon}
                      alt="bookletIcon"
                    />
                  </div>
                  <Dropdown
                    menu={backGroundColorMenu}
                    placement="bottomCenter"
                    trigger={['click']}
                  >
                    <div
                      className={styleUpload.bgColor}
                      style={{ zIndex: '2' }}
                      data-html2canvas-ignore
                    >
                      <img
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '18px',
                          width: '10px',
                        }}
                        src={DropdownIcon}
                        alt="bookletIcon"
                        onClick={() => {
                          toggle(backgroundColor, setBackgroundColor)
                        }}
                      />
                      <ul
                        style={{
                          padding: '10px 20px',
                          borderRadius: '10px',
                          appearance: 'none',
                          border: '0',
                          paddingRight: '40px',
                          listStyleType: 'none',
                          height: '40px',
                          margin: '0',
                          overflow: 'hidden',
                          fontSize: '12px',
                          width: '130px',
                        }}
                      >
                        <li
                          value={selectedBackgroundColor}
                          style={{
                            position: 'relative',
                            height: '40px',
                          }}
                          onClick={() => {
                            toggle(backgroundColor, setBackgroundColor)
                          }}
                        >
                          <div
                            value={selectedBackgroundColor}
                            style={{
                              position: 'absolute',
                              width: '20px',
                              height: '20px',
                              borderRadius: '20px',
                              backgroundColor: selectedBackgroundColor,
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          ></div>
                          <div
                            value={selectedBackgroundColor}
                            style={{ textAlign: 'right' }}
                          >
                            <TranslateText id="bg_color" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Dropdown>
                  <div
                    className={styleUpload.fontFamily}
                    data-html2canvas-ignore
                  >
                    <img
                      style={{
                        position: 'absolute',
                        right: '15px',
                        top: '18px',
                        width: '10px',
                      }}
                      src={DropdownIcon}
                      alt="bookletIcon"
                    />
                    <select
                      value={selectedFontFamily}
                      style={{
                        padding: '10px 20px',
                        borderRadius: '10px',
                        appearance: 'none',
                        border: '0',
                        overflow: 'scroll',
                      }}
                      onChange={e => changeFontFamily(e)}
                    >
                      {fontFamilyList.map(fontFamily => {
                        return (
                          <option value={fontFamily.value}>
                            {fontFamily.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <Dropdown
                    menu={colorMenu}
                    placement="bottomCenter"
                    trigger={['click']}
                  >
                    <div className={styleUpload.image} data-html2canvas-ignore>
                      <img
                        style={{
                          position: 'absolute',
                          right: '15px',
                          top: '18px',
                          width: '10px',
                        }}
                        src={DropdownIcon}
                        alt="bookletIcon"
                        onClick={() => {
                          toggle(color, setColor)
                        }}
                      />
                      <ul
                        style={{
                          padding: '10px 20px',
                          borderRadius: '10px',
                          appearance: 'none',
                          lineHeight: '30px',
                          border: '0',
                          paddingRight: '40px',
                          listStyleType: 'none',
                          height: '40px',
                          margin: '0',
                          overflow: 'hidden',
                          fontSize: '12px',
                          width: '90px',
                          zIndex: '100',
                        }}
                        onClick={() => {
                          toggle(color, setColor)
                        }}
                      >
                        <li
                          value={selectedColor}
                          style={{
                            position: 'relative',
                            height: '50px',
                            zIndex: '100',
                          }}
                          onClick={() => {
                            toggle(color, setColor)
                          }}
                        >
                          <div
                            value={selectedColor}
                            style={{
                              position: 'absolute',
                              width: '20px',
                              height: '20px',
                              borderRadius: '20px',
                              backgroundColor: selectedColor,
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          ></div>
                        </li>
                      </ul>
                    </div>
                  </Dropdown>
                  <div className={styleUpload.text} data-html2canvas-ignore>
                    <img
                      style={{
                        width: '47px',
                        borderRadius: '10px',
                      }}
                      src={TextIcon}
                      alt="bookletIcon"
                      onClick={createNewTextBox}
                    />
                  </div>
                  <div
                    style={{
                      top: '10px',
                      position: 'absolute',
                    }}
                  >
                    {allText.map(text => {
                      return (
                        <Draggable
                          // axis="x"
                          style={{
                            minWidth: '100px',
                            textAlign: 'left',
                          }}
                          key={text.id}
                          handle=".handle"
                          // bounds="parent"
                          defaultPosition={{ x: 0, y: 0 }}
                          // positionOffset={{x: '-10%', y: '-10%'}}
                          // position={null}
                          // grid={[25, 25]}
                          // scale={1}
                          onStart={handleStart}
                          // onDrag={handleDrag}
                          onStop={handleStop}
                        >
                          <div
                            className="handle"
                            style={{
                              padding: '10px 0',
                              transform: `${text.transformStyles.transform}`,
                              // transform: `translate(0px, 0px)`,
                              position: 'absolute',
                              maxWidth: '650px',
                            }}
                            id={text.id}
                          >
                            <div
                              contentEditable="true"
                              suppressContentEditableWarning={true}
                              style={{
                                padding: '10px 20px',
                                color: text.style.color,
                                backgroundColor: text.style.backgroundColor,
                                textAlign: text.style.textAlign,
                                fontSize: text.style.fontSize,
                                fontFamily: text.style.fontFamily,
                                width: 'max-content',
                              }}
                              onClick={e => handleTextBoxClick(e)}
                            >
                              {text.value}
                            </div>
                          </div>
                        </Draggable>
                      )
                    })}
                  </div>
                  <div className={styleUpload.pt1} data-html2canvas-ignore>
                    <img
                      style={{
                        position: 'absolute',
                        right: '15px',
                        top: '18px',
                        width: '10px',
                      }}
                      src={DropdownIcon}
                      alt="bookletIcon"
                    />
                    <select
                      value={selectedFontSize}
                      style={{
                        padding: '10px 20px',
                        borderRadius: '10px',
                        appearance: 'none',
                        border: '0',
                        paddingRight: '40px',
                      }}
                      onChange={e => changeFontSize(e)}
                    >
                      {fontSizeList.map(fontSize => {
                        return (
                          <option value={fontSize.value}>
                            {fontSize.value}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className={styleUpload.pt2} data-html2canvas-ignore>
                    <img
                      style={{
                        position: 'absolute',
                        right: '15px',
                        top: '18px',
                        width: '10px',
                      }}
                      src={DropdownIcon}
                      alt="bookletIcon"
                      onClick={e => {
                        toggle(align, setAlign)
                      }}
                    />
                    <ul
                      style={{
                        borderRadius: '10px',
                        appearance: 'none',
                        lineHeight: '30px',
                        border: '0',
                        paddingRight: '40px',
                        color: '#7cd2c6',
                        listStyleType: 'none',
                        height: align ? 'auto' : '42px',
                        margin: '0',
                        overflow: 'hidden',
                        fontSize: '30px',
                      }}
                    >
                      <li
                        value={selectedAlign}
                        style={{
                          padding: '10px 10px 10px 20px',
                          borderBottom: '2px solid #f5f5f5',
                          width: '42px',
                          backgroundImage: getAlignIcons(),
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          height: '42px',
                        }}
                        onClick={e => {
                          toggle(align, setAlign)
                        }}
                      ></li>
                      {align && (
                        <>
                          <li
                            value="center"
                            style={{
                              padding: '10px 10px 10px 20px',
                              borderBottom: '2px solid #f5f5f5',
                              width: '42px',
                              backgroundImage: `url(${AlignCenter})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '42px',
                            }}
                            onClick={e => checkAlignValue(e)}
                          >
                            {' '}
                          </li>
                          <li
                            value="left"
                            style={{
                              padding: '10px 10px 10px 20px',
                              borderBottom: '2px solid #f5f5f5',
                              width: '42px',
                              backgroundImage: `url(${AlignLeft})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '42px',
                            }}
                            onClick={e => checkAlignValue(e)}
                          >
                            {' '}
                          </li>
                          <li
                            value="right"
                            style={{
                              padding: '10px 10px 10px 20px',
                              borderBottom: '2px solid #f5f5f5',
                              width: '42px',
                              backgroundImage: `url(${AlignRight})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '42px',
                            }}
                            onClick={e => checkAlignValue(e)}
                          >
                            {' '}
                          </li>
                          <li
                            value="justify"
                            style={{
                              padding: '10px 10px 10px 20px',
                              width: '42px',
                              backgroundImage: `url(${AlignJustify})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '42px',
                            }}
                            onClick={e => checkAlignValue(e)}
                          >
                            {' '}
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  <div
                    className={styleUpload.deleteText}
                    onClick={deleteText}
                    data-html2canvas-ignore
                  >
                    <AiFillDelete />
                  </div>
                  {saveImageButton()}
                  {removeImageButton()}
                </div>

                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '500px',
                      overflow: 'auto hidden',
                    }}
                  >
                    {allBookletCoverImageUrl.length > 0 &&
                      allBookletCoverImageUrl.map((image, index) => (
                        <div
                          className={styleUpload.coverImageStyle}
                          onClick={() => {
                            showImage(index)
                          }}
                        >
                          <img
                            style={{
                              height: '160px',
                              borderRadius: '12px',
                              cursor: 'pointer',
                            }}
                            src={image.url}
                            alt="coverImage"
                          />
                        </div>
                      ))}
                  </div>
                  <div>
                    <div
                      style={{
                        height: '160px',
                        width: '160px',
                        marginTop: '8px',
                        marginLeft: '12px',
                      }}
                    >
                      <Dragger
                        style={{
                          background: '#F2FBF9 0% 0% no-repeat padding-box',
                          border: '1px dashed #7CD2C6',
                          borderRadius: '12px',
                        }}
                        onChange={handleBookletCoverImage}
                      >
                        <p
                          className="ant-upload-text"
                          style={{ color: '#7CD2C6', fontSize: '14px' }}
                        >
                          <TranslateText id="drop_image_here" />
                        </p>
                      </Dragger>
                      <CropImageModalPopup
                        showModal={modalVisible}
                        imageUrl={fileUrl}
                        handleOk={() => handleOk()}
                        handleCancel={handleCancel}
                        aspectRatio={1000 / 1000}
                        handleDataURLtoFile={dataUrl => dataURLtoFile(dataUrl)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '8px',
                  }}
                >
                  <button
                    style={{
                      width: '100px',
                      background: 'white',
                      height: '40px',
                      visibility: 'hidden',
                      borderRadius: '4px',
                      borderColor: '#e4e9f2',
                    }}
                  >
                    <span>
                      Cover
                      <IoIosArrowDown />
                    </span>
                  </button>
                  <p
                    className="ant-upload-text"
                    style={{
                      color: '#7CD2C6',
                      cursor: 'pointer',
                      visibility: 'hidden',
                      width: '30%',
                      fontSize: '12px',
                    }}
                  >
                    Drag to change the
                  </p>
                  {previousButton()}
                  {nextButton()}
                  {bookletPageIndex()}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
      {slider === 1 ? (
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            padding: '40px 30px 90px',
          }}
        >
          <Row style={{ height: '100%' }} gutter={[32, 32]}>
            <Col xs={24} lg={12}>
              <div
                style={{
                  backgroundColor: '#fff',
                  borderRadius: 12,
                  boxShadow: '2px 1px 9px #e0e0e0',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <img
                  alt="mark"
                  style={{
                    position: 'absolute',
                    width: 400,
                    bottom: '-12%',
                    right: '-10%',
                  }}
                  src={mark}
                />
                <div
                  style={{ height: '100%', zIndex: 2 }}
                  className={styleUpload.cardDiv}
                >
                  <div style={{ padding: '40px 24px', zIndex: 2 }}>
                    <div>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <div className={styleUpload.selectedPageCount}>1</div>
                        <div className={styleUpload.UnselectedPageCount}>2</div>
                        <div className={styleUpload.UnselectedPageCount}>3</div>
                        <div className={styleUpload.UnselectedPageCount}>4</div>
                      </div>
                      <div style={{ marginTop: '50px' }}>
                        <label
                          style={{
                            color: '#232324',
                            fontSize: '24px',
                            opacity: '1',
                          }}
                        >
                          <b>
                            <TranslateText id="upload_songs_to_album" />
                          </b>
                        </label>
                        <div style={{ width: '73%', margin: '15px auto 0' }}>
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="upload_songs_to_album_heading" />
                          </label>
                          <br />
                          <br />
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="upload_single_song_heading_description" />
                          </label>
                          <br />
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="upload_single_song_heading_description_content" />
                          </label>
                        </div>
                        <div>
                          <Form {...layout} name="basic" layout={'vertical'}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Form.Item>
                                <Button
                                  disabled={songs.length <= 0}
                                  style={{
                                    marginTop: '200px',
                                    width: '240px',
                                  }}
                                  htmlType="submit"
                                  className={
                                    songs.length > 0
                                      ? styles.greenColorBtn
                                      : styles.disableColorBtn
                                  }
                                  onClick={() => handleEmptySongList()}
                                >
                                  <TranslateText id="next" />
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label
                              style={{
                                color: '#232324',
                                opacity: '1',
                                position: 'relative',
                                cursor: 'pointer',
                              }}
                            >
                              <span
                                style={{
                                  color: '#7CD2C6',
                                  cursor: 'pointer',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => gotoSlider(0)}
                              >
                                <TranslateText id="back" />
                              </span>{' '}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={12} style={{ height: '100%' }}>
              <div className={styleUpload.cardDiv2}>
                <Form {...layout} name="basic" layout={'vertical'}>
                  <div style={{ height: '231px', width: '100%' }}>
                    <Dragger
                      style={{
                        borderRadius: '12px',
                        border: '1px dashed #7CD2C6',
                        background: '#F2FBF9 0% 0% no-repeat padding-box',
                      }}
                      {...draggerProps}
                    >
                      <p
                        className="ant-upload-text"
                        style={{ fontSize: '14px', color: '#7CD2C6' }}
                      >
                        <TranslateText id="drop_songs_upload" />
                      </p>
                    </Dragger>
                  </div>
                  <div style={{ maxHeight: '370px', overflow: 'auto' }}>
                    {songDataArray.length > 0 &&
                      songDataArray.map((data, index) => {
                        let progress = 0
                        if (data.song.uploaded) {
                          progress = 100
                        } else {
                          const uploading = index === currentFileIndex
                          if (uploading) {
                            progress = Math.round(currentChunkIndex)
                          }
                        }
                        return (
                          <div
                            className={styleUpload.UploadedlistDiv}
                            style={{ padding: '8px', textAlign: 'left' }}
                            key={data.song.uid}
                          >
                            <span>
                              <UpCircleTwoTone
                                twoToneColor="#7cd2c6"
                                style={{ margin: '0 4px' }}
                                onClick={() => moveSongPositionUp(index)}
                              />
                              {index + 1}
                              <DownCircleTwoTone
                                twoToneColor="#7cd2c6"
                                style={{ marginLeft: '4px' }}
                                onClick={() => moveSongPositionDown(index)}
                              />
                              <Input
                                style={{
                                  border: '1px solid #7CD2C6',
                                  height: '40px',
                                  color: '#303135',
                                  margin: '4px 16px 0',
                                  width: 'calc(100% - 150px)',
                                }}
                                onChange={e =>
                                  changeSongTitle(data.song, e.target.value)
                                }
                                defaultValue={data.song.name}
                              />
                              {length > 0 ? (
                                <Progress
                                  style={{ marginBottom: '4px' }}
                                  type="circle"
                                  percent={progress}
                                  width={40}
                                />
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                  <div
                    style={{
                      marginTop: '45px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Form.Item>
                      <Button
                        style={{
                          width: '240px',
                        }}
                        htmlType="submit"
                        className={styleUpload.greenColorBtn}
                        onClick={() => {
                          handleSongsUpload()
                        }}
                      >
                        <TranslateText id="upload_action" />
                      </Button>
                    </Form.Item>
                  </div>
                  {/* <GemmaModal
                        show={gemmaModalVisible}
                        closePopup={() => setGemmaModalVisible(false)}
                        handleOk={() => handleEmptySongList()}
                        handleCancel={() => setGemmaModalVisible(false)}
                      /> */}
                </Form>
                <div style={{ maxHeight: '350px', overflow: 'auto' }}>
                  {songs.length > 0 &&
                    songs.map((song, index) => (
                      <div
                        key={song._id}
                        className={styleUpload.UploadedlistDiv}
                        style={{
                          alignItems: 'center',
                          paddingTop: '8px',
                          height: '90px',
                        }}
                      >
                        {window.innerWidth < 600 ? (
                          <span>
                            {index + 1}
                            <Input
                              style={{
                                border: '1px solid #7CD2C6',
                                color: '#303135',
                                width: 'calc(100% - 160px)',
                                height: '40px',
                                borderRightStyle: 'none',
                                marginLeft: '8px',
                                marginRight: '-6px',
                                marginTop: '4px',
                                background: '#FFFFFF',
                                cursor: 'default',
                                padding: '4px',
                              }}
                              value={song.songName}
                              disabled
                            />

                            <label
                              style={{
                                color: '#303135',
                                padding: '4px',
                                fontSize: '14px',
                              }}
                            >
                              {song.songDuration}
                            </label>
                            <img
                              style={{
                                marginRight: '4px',
                                marginLeft: '4px',
                                width: '24px',
                                height: '24px',
                              }}
                              src={play}
                              alt="playIcon"
                            />
                            <span
                              style={{
                                padding: '10px',
                                verticalAlign: 'middle',
                              }}
                            >
                              <Popconfirm
                                title={<TranslateText id="are_you_sure" />}
                                okText={<TranslateText id="delete" />}
                                cancelText={<TranslateText id="no" />}
                                onConfirm={() => {
                                  handleDeleteSong(song._id)
                                }}
                              >
                                <AiFillDelete
                                  style={{
                                    alignSelf: 'center',
                                    fontSize: '22px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Popconfirm>
                            </span>
                          </span>
                        ) : (
                          <div style={{ flexDirection: 'column', flex: 1 }}>
                            {index + 1}{' '}
                            <Input
                              style={{
                                background: '#FFFFFF',
                                border: '1px solid #7CD2C6',
                                color: '#303135',
                                cursor: 'default',
                                height: '40px',
                                marginLeft: '12px',
                                marginRight: '12px',
                                width: 'calc(100% - 140px)',
                              }}
                              value={song.songName}
                              disabled
                            />
                            <label style={{ color: '#303135', padding: '4px' }}>
                              {song.songDuration}
                            </label>
                            <span
                              style={{
                                padding: '10px',
                                verticalAlign: 'middle',
                              }}
                            >
                              <Popconfirm
                                title={<TranslateText id="are_you_sure" />}
                                okText={<TranslateText id="delete" />}
                                cancelText={<TranslateText id="no" />}
                                onConfirm={() => {
                                  handleDeleteSong(song._id)
                                }}
                              >
                                <AiFillDelete
                                  style={{
                                    marginLeft: 8,
                                    alignSelf: 'center',
                                    fontSize: '22px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Popconfirm>
                            </span>
                          </div>
                        )}
                        <div
                          style={{
                            textAlign: 'right',
                            paddingTop: '4px',
                            width: 'calc(100% - 20px)',
                          }}
                        >
                          <span
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                          >
                            <Tooltip
                              placement="bottom"
                              color="#7CD2C6"
                              title={intl.formatMessage({ id: 'gema_info' })}
                            >
                              <FcInfo
                                style={{
                                  fontSize: '18px',
                                  cursor: 'pointer',
                                  marginRight: '4px',
                                }}
                              />
                            </Tooltip>
                            <span style={{ marginRight: '12px' }}>
                              <TranslateText id="is_song_gema_protected" />
                            </span>
                            <span>
                              {' '}
                              <Checkbox
                                checked={gemaProtectedSongIds.some(
                                  x => x === song._id
                                )}
                                onChange={e =>
                                  handleGemaStatusChange(
                                    e.target.checked,
                                    song._id
                                  )
                                }
                              />{' '}
                            </span>
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
          <InsertGemaModal
            visible={insertGemaNumber}
            onCancel={() => {
              props.closePopup()
              resetGemaStatus()
            }}
            updateGemaNumber={updateGemaNumber}
            resetGemaStatus={resetGemaStatus}
          />
        </div>
      ) : (
        ''
      )}
      {slider === 2 ? (
        <div
          style={{
            height: '100%',
            padding: '40px 0',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              width: 690,
              margin: 'auto',
              backgroundColor: '#fff',
              zIndex: 0,
              borderRadius: 12,
              boxShadow: '2px 1px 9px #e0e0e0',
              overflow: 'hidden',
              position: 'relative',
              padding: '40px',
            }}
          >
            <img
              alt="mark"
              style={{
                position: 'absolute',
                width: 400,
                bottom: '-12%',
                right: '-10%',
              }}
              src={mark}
            />
            <div
              style={{
                height: '100%',
                zIndex: 2,
                margin: '0 auto',
                maxWidth: '700px',
              }}
              className={styleUpload.cardDiv}
            >
              <div className={styles.formDiv}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: -20,
                    }}
                  >
                    <div className={styleUpload.selectedPageCount}>1</div>
                    <div className={styleUpload.selectedPageCount}>2</div>
                    <div className={styleUpload.UnselectedPageCount}>3</div>
                    <div className={styleUpload.UnselectedPageCount}>4</div>
                  </div>
                </div>
                <div style={{ marginTop: '50px' }}>
                  <label
                    style={{
                      color: '#232324',
                      fontSize: '24px',
                      opacity: '1',
                    }}
                  >
                    <b>
                      <TranslateText id="cover_booklet_design" />
                    </b>
                  </label>
                </div>
                <div style={{ width: '73%', margin: '15px auto 0' }}>
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      opacity: '0.5',
                    }}
                  >
                    <TranslateText id="cover_booklet_design_heading" />
                  </label>
                  <br />
                  <br />
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    <TranslateText id="cover_booklet_design_heading_content" />
                  </label>
                  <br />
                  <label
                    style={{
                      color: '#303135',
                      fontSize: '16px',
                      opacity: '0.5',
                    }}
                  >
                    <TranslateText id="cover_booklet_design_heading_content_two" />
                  </label>
                </div>
                <div>
                  <Form {...layout} name="basic" layout={'vertical'}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Form.Item style={{ marginBottom: '16px' }}>
                        {uploadYourFileButton()}
                      </Form.Item>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Form.Item style={{ marginBottom: '16px' }}>
                        <Button
                          style={{
                            display: 'block',
                            width: '260px',
                            marginBottom: '15px',
                            border: '1px solid #7CD2C5',
                            color: '#7CD2C6',
                            height: '48px',
                            borderRadius: '24px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                          onClick={() => setSlider(7)}
                        >
                          <TranslateText id="use_booklet_creator" />
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <label
                      style={{
                        color: '#232324',
                        opacity: '1',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    >
                      <span
                        style={{
                          color: '#7CD2C6',
                          cursor: 'pointer',
                          textTransform: 'uppercase',
                        }}
                        onClick={() => gotoSlider(1)}
                      >
                        <TranslateText id="back" />
                      </span>{' '}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {slider === 3 ? (
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            padding: '40px 30px 90px',
          }}
        >
          <Row gutter={[32, 32]} style={{ height: '100%' }}>
            <Col lg={12} xs={24}>
              <div
                style={{
                  backgroundColor: '#fff',
                  zIndex: 0,
                  position: 'relative',
                  borderRadius: 12,
                  boxShadow: '2px 1px 9px #e0e0e0',
                  overflow: 'hidden',
                  paddingBottom: '40px',
                }}
              >
                <img
                  alt="mark"
                  style={{
                    position: 'absolute',
                    width: 400,
                    bottom: '-12%',
                    right: '-10%',
                  }}
                  src={mark}
                />
                <div
                  style={{ height: '100%', zIndex: 2 }}
                  className={styleUpload.cardDiv}
                >
                  <div style={{ paddingTop: '88px' }}>
                    <div>
                      <div
                        style={{ justifyContent: 'center', display: 'flex' }}
                      >
                        <div className={styleUpload.selectedPageCount}>1</div>
                        <div className={styleUpload.selectedPageCount}>2</div>
                        <div className={styleUpload.UnselectedPageCount}>3</div>
                        <div className={styleUpload.UnselectedPageCount}>4</div>
                      </div>
                      <div style={{ marginTop: '50px' }}>
                        <label
                          style={{
                            opacity: '0.9',
                            fontSize: '24px',
                            color: '#232324',
                          }}
                        >
                          <b>
                            <TranslateText id="upload_your_own_files" />
                          </b>
                        </label>
                        <div style={{ width: '73%', margin: '15px auto 0' }}>
                          <label
                            style={{
                              color: '#303135',
                              fontSize: '16px',
                              opacity: '0.5',
                            }}
                          >
                            <TranslateText id="upload_your_own_files_heading" />
                          </label>
                        </div>
                        <div>
                          <Form {...layout} name="basic" layout={'vertical'}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Form.Item>
                                <Button
                                  style={{
                                    marginTop: '256px',
                                    width: '240px',
                                  }}
                                  htmlType="submit"
                                  className={styleUpload.greenColorBtn}
                                  onClick={() => {
                                    handleEmptyImage()
                                  }}
                                >
                                  <TranslateText id="next" />
                                </Button>
                              </Form.Item>
                            </div>
                          </Form>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label
                              style={{
                                color: '#232324',
                                opacity: '1',
                                position: 'relative',
                                cursor: 'pointer',
                              }}
                            >
                              <span
                                style={{
                                  color: '#7CD2C6',
                                  cursor: 'pointer',
                                  textTransform: 'uppercase',
                                }}
                                onClick={() => gotoSlider(2)}
                              >
                                <TranslateText id="back" />
                              </span>{' '}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={12} style={{ height: '100%' }}>
              <div className={styleUpload.cardDiv2}>
                <div style={{ height: '231px', width: '100%' }}>
                  <Dragger
                    style={{
                      background: '#F2FBF9 0% 0% no-repeat padding-box',
                      border: '1px dashed #7CD2C6',
                      borderRadius: '12px',
                    }}
                    onChange={handleImageUpload}
                  >
                    <p
                      className="ant-upload-text"
                      style={{ color: '#7CD2C6', fontSize: '14px' }}
                    >
                      <TranslateText id="drop_your_files_here" />
                    </p>
                    <p
                      className="ant-upload-hint"
                      style={{ color: '#7CD2C6', fontSize: '12px' }}
                    >
                      <TranslateText id="album_cover_image_size" />
                    </p>
                  </Dragger>
                  <CropImageModalPopup
                    showModal={modalVisible}
                    imageUrl={fileUrl}
                    handleOk={() => handleOk()}
                    aspectRatio={1000 / 1000}
                    handleCancel={handleCancel}
                    handleDataURLtoFile={dataUrl => dataURLtoFile(dataUrl)}
                  />
                  <div
                    style={{
                      maxHeight: '470px',
                      overflow: 'auto',
                      marginTop: '16px',
                    }}
                  >
                    {allCoverImageUrl.length > 0 &&
                      allCoverImageUrl.map((image, index) => (
                        <div
                          className={styleUpload.UploadedlistDiv}
                          style={{ padding: '8px 0' }}
                        >
                          {window.innerWidth < 600 ? (
                            <span>
                              <img
                                style={{
                                  marginLeft: '8px',
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '16px',
                                }}
                                src={image.url}
                                alt="coverImage"
                              />
                              <span
                                style={{
                                  marginLeft: '8px',
                                  padding: '8px 12px',
                                  border: '1px solid #E4E9F2',
                                  borderRadius: '4px',
                                }}
                              >
                                <label style={{ color: '#313135' }}>
                                  Cover {index + 1}
                                </label>
                              </span>
                              <Input
                                style={{
                                  border: 'none',
                                  width: 'calc(100% - 170px)',
                                  color: '#303135',
                                  marginLeft: '4px',
                                  marginRight: '4px',
                                  height: '40px',
                                  background: '#FFFFFF',
                                  cursor: 'default',
                                  opacity: '0.5',
                                  padding: '4px',
                                }}
                                value={image.imageFile.name}
                                disabled
                              />
                              <span
                                style={{
                                  padding: '10px',
                                  verticalAlign: 'middle',
                                }}
                              >
                                <Popconfirm
                                  title={<TranslateText id="are_you_sure" />}
                                  okText={<TranslateText id="delete" />}
                                  cancelText={<TranslateText id="no" />}
                                  onConfirm={() => {
                                    removeImage(image.imageFile.name, index)
                                  }}
                                >
                                  <AiFillDelete
                                    style={{
                                      alignSelf: 'center',
                                      fontSize: '22px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Popconfirm>
                              </span>
                            </span>
                          ) : (
                            <span>
                              <img
                                style={{
                                  marginLeft: '16px',
                                  // width: "50px",
                                  height: '50px',
                                  borderRadius: '16px',
                                }}
                                alt="coverImage"
                                src={image.url}
                              />
                              <span
                                style={{
                                  marginLeft: '24px',
                                  padding: '10px 16px',
                                  border: '1px solid #E4E9F2',
                                  borderRadius: '4px',
                                }}
                              >
                                <label style={{ color: '#313135' }}>
                                  Cover {index + 1}
                                </label>
                              </span>
                              <Input
                                style={{
                                  width: 'calc(100% - 270px)',
                                  padding: '5px',
                                  border: 'none',
                                  marginLeft: '24px',
                                  marginRight: '16px',
                                  height: '40px',
                                  background: '#FFFFFF',
                                  cursor: 'default',
                                  opacity: '0.5',
                                  color: '#303135',
                                }}
                                disabled
                                value={image.imageFile.name}
                              />
                              <span
                                style={{
                                  padding: '10px',
                                  verticalAlign: 'middle',
                                }}
                              >
                                <Popconfirm
                                  title={<TranslateText id="are_you_sure" />}
                                  okText={<TranslateText id="delete" />}
                                  cancelText={<TranslateText id="no" />}
                                  onConfirm={() => {
                                    removeImage(image.imageFile.name, index)
                                  }}
                                >
                                  <AiFillDelete
                                    style={{
                                      alignSelf: 'center',
                                      fontSize: '22px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Popconfirm>
                              </span>
                            </span>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
      {slider === 4 ? (
        <div
          style={{
            padding: '14px 0 90px 0',
            overflow: 'auto',
            height: '100%',
          }}
        >
          <div
            style={{
              minHeight: 750,
              width: 690,
              position: 'absolute',
              left: 0,
              right: 0,
              margin: 'auto',
              backgroundColor: '#fff',
              zIndex: 0,
              borderRadius: 12,
              boxShadow: '2px 1px 9px #e0e0e0',
              overflow: 'hidden',
            }}
          >
            <img
              alt="mark"
              style={{
                position: 'absolute',
                width: 400,
                bottom: '-12%',
                right: '-10%',
              }}
              src={mark}
            />
          </div>
          <div
            style={{
              height: '100%',
              zIndex: 2,
              margin: '0 auto',
              maxWidth: '700px',
            }}
            className={styleUpload.cardDiv}
          >
            <div className={styles.formDiv}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className={styleUpload.selectedPageCount}>1</div>
                <div className={styleUpload.selectedPageCount}>2</div>
                <div className={styleUpload.selectedPageCount}>3</div>
                <div className={styleUpload.UnselectedPageCount}>4</div>
              </div>
              <div style={{ marginTop: '50px' }}>
                <label
                  style={{ color: '#232324', fontSize: '24px', opacity: '1' }}
                >
                  <b>
                    <TranslateText id="release_date_and_time" />
                  </b>
                </label>
              </div>
              <div style={{ width: '73%', margin: '15px auto 0' }}>
                <label
                  style={{
                    color: '#303135',
                    fontSize: '16px',
                    opacity: '0.5',
                  }}
                >
                  <TranslateText id="release_date_and_time_heading" />
                </label>
              </div>
              <div>
                <Form {...layout} name="basic" layout={'vertical'}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Form.Item style={{ marginBottom: '16px' }}>
                      <Button
                        style={{ marginTop: '190px', width: '240px' }}
                        htmlType="submit"
                        className={styleUpload.greenColorBtn}
                        onClick={() => {
                          gotoSlider(6)
                        }}
                      >
                        <TranslateText id="release_now" />
                      </Button>
                    </Form.Item>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Form.Item style={{ marginBottom: '16px' }}>
                      <Button
                        style={{
                          width: '240px',
                          position: 'relative',
                          marginBottom: '15px',
                          border: '1px solid #7CD2C5',
                          color: '#7CD2C6',
                          height: '48px',
                          fontWight: 'bold',
                          borderRadius: '24px',
                          textTransform: 'uppercase',
                        }}
                        htmlType="submit"
                        onClick={() => {
                          setSlider(5)
                        }}
                      >
                        <TranslateText id="schedule_release" />
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <label
                    style={{
                      color: '#232324',
                      opacity: '1',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                  >
                    <span
                      style={{
                        color: '#7CD2C6',
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                      }}
                      onClick={() => gotoSlider(backSlideNum)}
                    >
                      <TranslateText id="back" />
                    </span>{' '}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {slider === 5 ? (
        <div
          style={{
            height: '100%',
            overflow: 'auto',
            padding: '14px 0 90px 0',
          }}
        >
          <div
            style={{
              minHeight: 750,
              width: 690,
              position: 'absolute',
              left: 0,
              right: 0,
              margin: 'auto',
              backgroundColor: '#fff',
              zIndex: 0,
              borderRadius: 12,
              boxShadow: '2px 1px 9px #e0e0e0',
              overflow: 'hidden',
            }}
          >
            <img
              alt="mark"
              style={{
                position: 'absolute',
                width: 400,
                bottom: '-12%',
                right: '-10%',
              }}
              src={mark}
            />
          </div>
          <div
            style={{
              height: '100%',
              zIndex: 2,
              margin: '0 auto',
              maxWidth: '700px',
            }}
            className={styleUpload.cardDiv}
          >
            <div className={styles.formDiv}>
              <div style={{ justifyContent: 'center', display: 'flex' }}>
                <div className={styleUpload.selectedPageCount}>1</div>
                <div className={styleUpload.selectedPageCount}>2</div>
                <div className={styleUpload.selectedPageCount}>3</div>
                <div className={styleUpload.UnselectedPageCount}>4</div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <label
                  style={{
                    fontSize: '24px',
                    opacity: '0.9',
                    color: '#232324',
                  }}
                >
                  <b>
                    <TranslateText id="schedule_release" />
                  </b>
                </label>
                <div
                  style={{ width: '100%', margin: '10px auto 0', zIndex: 2 }}
                >
                  <Calendar
                    className={styleUpload.reactCalender}
                    onChange={handleDateChange}
                    minDate={new Date()}
                    value={changeDate}
                  />
                </div>
                <div>
                  <TimePicker
                    style={{
                      marginTop: '30px',
                      width: '270px',
                      height: '40px',
                      border: '1px solid #E4E9F2',
                      borderRadius: '4px',
                    }}
                    defaultValue={moment(time, 'HH:mm')}
                    format="HH:mm"
                    onChange={handleTimeChange}
                  />
                </div>
                <div
                  style={{
                    marginTop: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Form {...layout} name="basic" layout={'vertical'}>
                    <Form.Item>
                      <Button
                        style={{
                          width: '270px',
                        }}
                        htmlType="submit"
                        className={styleUpload.greenColorBtn}
                        onClick={() => {
                          handleScheduleRelease()
                        }}
                      >
                        <TranslateText id="next" />
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{
                          width: '270px',
                          boxShadow: 'none',
                          textTransform: 'uppercase',
                          display: 'block',
                          color: '#7CD2C6',
                          border: 'transparent',
                          background: 'transparent',
                          marginTop: '-12px',
                        }}
                        onClick={() => gotoSlider(4)}
                      >
                        <TranslateText id="back" />
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {slider === 6 ? (
        <div
          style={{
            height: '100%',
            padding: '40px 30px 90px',
            overflow: 'auto',
          }}
        >
          <Row style={{ height: '100%' }} gutter={[32, 32]}>
            <Col lg={12} xs={24}>
              <div
                style={{
                  minHeight: 720,
                  width: 690,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  margin: 'auto',
                  backgroundColor: '#fff',
                  zIndex: 0,
                  borderRadius: 12,
                  boxShadow: '2px 1px 9px #e0e0e0',
                  overflow: 'hidden',
                }}
              >
                <img
                  alt="mark"
                  style={{
                    position: 'absolute',
                    width: 400,
                    bottom: '-12%',
                    right: '-10%',
                  }}
                  src={mark}
                />
              </div>
              <div
                style={{
                  height: '100%',
                  zIndex: 2,
                  margin: '0 auto',
                  maxWidth: '700px',
                }}
                className={styleUpload.cardDiv}
              >
                {' '}
                <div style={{ paddingTop: '88px' }}>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className={styleUpload.selectedPageCount}>1</div>
                      <div className={styleUpload.selectedPageCount}>2</div>
                      <div className={styleUpload.selectedPageCount}>3</div>
                      <div className={styleUpload.selectedPageCount}>4</div>
                    </div>
                    <div style={{ marginTop: '50px' }}>
                      <label
                        style={{
                          color: '#232324',
                          fontSize: '24px',
                          opacity: '1',
                        }}
                      >
                        <b>
                          <TranslateText id="confirm_upload_data" />
                        </b>
                      </label>{' '}
                      <div style={{ width: '73%', margin: '15px auto 0' }}>
                        <label
                          style={{
                            color: '#303135',
                            fontSize: '16px',
                            opacity: '0.5',
                          }}
                        >
                          <TranslateText id="confirm_upload_data_heading" />
                        </label>
                      </div>
                      <div>
                        <Form {...layout} name="basic" layout={'vertical'}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Form.Item>
                              <Button
                                style={{ marginTop: '248px', width: '240px' }}
                                htmlType="submit"
                                className={styleUpload.greenColorBtn}
                                onClick={() => handleAlbumUpload()}
                              >
                                <TranslateText id="good_to_go" />
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <label
                            style={{
                              color: '#232324',
                              opacity: '1',
                              position: 'relative',
                              cursor: 'pointer',
                            }}
                          >
                            <span
                              style={{ color: '#7CD2C6', cursor: 'pointer' }}
                              onClick={() => gotoSlider(backSlideNum)}
                            >
                              <TranslateText id="go_back_and_edit" />
                            </span>{' '}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={12} style={{ height: '100%' }}>
              <div className={styleUpload.cardDiv2} style={{ height: '100%' }}>
                <div style={{ width: '100%' }}>
                  {showAllCoverImage()}
                  <label
                    style={{
                      color: '#303135',
                      opacity: '1',
                      fontSize: '32px',
                    }}
                  >
                    {albumName}
                  </label>
                  <br />
                  <label
                    style={{
                      color: '#7CD2C6',
                      opacity: '1',
                      fontSize: '16px',
                    }}
                  >
                    {moment(changeDate).format('DD MMMM YYYY')},{' '}
                    {moment(time && time._d).format('hh:mm a')}
                  </label>
                </div>
                <div>
                  <div>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <p>
                        {songs.length} <TranslateText id="songs" />
                      </p>
                      <p>
                        <TranslateText id="play_time" />: {playTimeData()}{' '}
                      </p>
                    </span>
                    <div style={{ maxHeight: '350px', overflow: 'auto' }}>
                      {songs.length > 0 &&
                        songs.map((song, index) => (
                          <div
                            className={styleUpload.UploadedlistDiv}
                            style={{ padding: '13px 0px' }}
                          >
                            {window.innerWidth < 600 ? (
                              <span>
                                {index + 1}
                                <Input
                                  style={{
                                    border: '1px solid #7CD2C6',
                                    width: 'calc(100% - 110px)',
                                    color: '#303135',
                                    marginLeft: '8px',
                                    marginRight: '8px',
                                    height: '40px',
                                    background: '#FFFFFF',
                                    cursor: 'default',
                                    padding: '4px',
                                  }}
                                  value={song.songName}
                                  disabled
                                />

                                <label
                                  style={{
                                    color: '#303135',
                                    padding: '4px',
                                    fontSize: '14px',
                                  }}
                                >
                                  {song.songDuration}
                                </label>
                                {/* <img style={{marginRight: "4px", marginLeft: "4px", width: "24px", height: "24px"}} src={play} alt="playIcon"/> */}
                                <span
                                  style={{
                                    padding: '10px',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  <Popconfirm
                                    title={<TranslateText id="are_you_sure" />}
                                    okText={<TranslateText id="delete" />}
                                    cancelText={<TranslateText id="no" />}
                                    onConfirm={() => {
                                      handleDeleteSong(song._id)
                                    }}
                                  >
                                    <AiFillDelete
                                      style={{
                                        alignSelf: 'center',
                                        fontSize: '22px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Popconfirm>
                                </span>
                              </span>
                            ) : (
                              <span>
                                {index + 1}{' '}
                                <Input
                                  style={{
                                    border: '1px solid #7CD2C6',
                                    width: 'calc(100% - 180px)',
                                    color: '#303135',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    height: '40px',
                                    background: '#FFFFFF',
                                    cursor: 'default',
                                  }}
                                  disabled
                                  value={song.songName}
                                />
                                <label
                                  style={{ padding: '4px', color: '#303135' }}
                                >
                                  {song.songDuration}
                                </label>
                                <span
                                  style={{
                                    padding: '10px',
                                    verticalAlign: 'middle',
                                    paddingLeft: '8px',
                                  }}
                                >
                                  <Popconfirm
                                    title={<TranslateText id="are_you_sure" />}
                                    okText={<TranslateText id="delete" />}
                                    cancelText={<TranslateText id="no" />}
                                    onConfirm={() => {
                                      handleDeleteSong(song._id)
                                    }}
                                  >
                                    <AiFillDelete
                                      style={{
                                        alignSelf: 'center',
                                        fontSize: '22px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </Popconfirm>
                                </span>
                              </span>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
      {loader}
    </div>
  )
}
