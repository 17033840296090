import React from 'react'
import TranslateText from '../../../translation/TranslateText'
import Heading from '../../../components/Heading/Heading'
import { Table } from 'antd'
import { addKeysToTableData } from '../utils'
import styles from '../home.module.css'

const COLUMNS = [
  {
    title: <TranslateText id="post_name" />,
    dataIndex: 'postName',
    key: 'postName',
    ellipsis: true,
  },
  {
    title: <TranslateText id="likes" />,
    dataIndex: 'likesCount',
    key: 'likesCount',
    align: 'center',
    responsive: ['md'],
    ellipsis: true,
  },
  {
    title: <TranslateText id="comment" />,
    dataIndex: 'commentsCount',
    key: 'commentsCount',
    responsive: ['md'],
    align: 'center',
    ellipsis: true,
  },
]

export const MostVisitedPostsTable = ({ mostVisitedData }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headingBox}>
        <Heading type="h2">
          <TranslateText id="most_visited_post" />
        </Heading>
      </div>
      <Table
        pagination={false}
        columns={COLUMNS}
        dataSource={addKeysToTableData(mostVisitedData)}
      />
    </div>
  )
}

export default MostVisitedPostsTable
