import { fanPageType } from '../types'

const initialState = {
  posts: '',
  postDetails: '',
  artistEvents: '',
  postId: '',
  selectedPost: '',
  postName: '',
  postImage: '',
  postYoutubeLink: '',
  postAudio: '',
  postVideo: '',
  postDescription: '',
  allComments: [],
  progress: '',

  eventId: '',
  selectedEvent: '',
  eventName: '',
  eventImage: '',
  eventDescription: '',
  eventLocation: '',
  eventLink: '',
  eventPrice: '',
  eventDate: '',
}

export default function fanPageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case fanPageType.GET_ALL_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload,
      }

    case fanPageType.GET_POST_CHART_DETAILS_SUCCESS:
      return {
        ...state,
        postDetails: action.payload,
      }

    case fanPageType.GET_ALL_EVENTS_SUCCESS:
      return {
        ...state,
        artistEvents: action.payload.data,
      }

    case fanPageType.DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter(post => post._id !== action.payload),
      }
    case fanPageType.DELETE_EVENT:
      return {
        ...state,
        artistEvents: state.artistEvents.filter(
          event => event._id !== action.payload
        ),
      }

    case fanPageType.UPDATE_POST_DATA:
      return {
        ...state,
        postId: action.payload,
        selectedPost: state.posts.filter(
          post => post._id === action.payload
        )[0],
        postName: state.posts.filter(post => post._id === action.payload)[0]
          .postName,
        postDescription: state.posts.filter(
          post => post._id === action.payload
        )[0].description,
        postImage: state.posts.filter(post => post._id === action.payload)[0]
          .postImage,
        postYoutubeLink: state.posts.filter(
          post => post._id === action.payload
        )[0].postYoutubeLink,
        postAudio: state.posts.filter(post => post._id === action.payload)[0]
          .postAudio,
        postVideo: state.posts.filter(post => post._id === action.payload)[0]
          .postVideo,
        postIsLandscape: state.posts.filter(
          post => post._id === action.payload
        )[0].mediaIsLandscape,
      }
    case fanPageType.UPDATE_EVENT_DATA:
      return {
        ...state,
        eventId: action.payload,
        selectedEvent: state.artistEvents.filter(
          event => event._id === action.payload
        )[0],
        eventName: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].eventName,
        eventDescription: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].description,
        eventImage: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].postImage,
        eventLocation: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].location,
        eventLink: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].ticketLink,
        eventPrice: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].price.value,
        eventDate: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].eventOn,
        eventMediaIsLandscape: state.artistEvents.filter(
          event => event._id === action.payload
        )[0].mediaIsLandscape,
      }

    case fanPageType.GET_ALL_COMMENTS_SUCCESS:
      return {
        ...state,
        allComments: action.payload,
      }

    case fanPageType.PROGRESS:
      return {
        ...state,
        progress: action.payload,
      }

    default:
      return state
  }
}
