import {
  GET_DOWNLOAD_COUNT,
  GET_SALE_COUNT,
  GET_MOST_VISITED_POST,
  ARTIST_SONGS_LIST,
  GET_SALE_COUNT_LOADING,
} from '../types'
import { API_POST, API_GET } from '../../../services/ApiMethods'
import { plactronUrl } from '../../../services/api'

export const getDownloadCount = data => async dispatch => {
  try {
    const response = await API_POST(plactronUrl.TOTAL_DOWNLOAD_COUNT, data)
    dispatch({ type: GET_DOWNLOAD_COUNT, payload: response.data })
  } catch (error) {
    console.log(error)
  }
}

export const getTotalSale = data => async dispatch => {
  try {
    dispatch({ type: GET_SALE_COUNT_LOADING })
    const response = await API_POST(plactronUrl.TOTAL_SALE, data)
    console.log('TotalSales', response)
    dispatch({ type: GET_SALE_COUNT, payload: response.data })
  } catch (error) {
    console.log(error)
  }
}

export const getMostVisitedPost = () => async dispatch => {
  try {
    const response = await API_GET(plactronUrl.MOST_VISITED_POST)
    dispatch({ type: GET_MOST_VISITED_POST, payload: response.data })
  } catch (error) {
    console.log(error)
  }
}

// Get Total Artist Gema and Non Gema Songs

export const getArtistSongList =
  (startIndex, limit = 5) =>
  async dispatch => {
    try {
      const response = await API_GET(
        `${plactronUrl.ARTIST_SONGS_LIST}?skip=${startIndex}&limit=${limit}`
      )
      dispatch({ type: ARTIST_SONGS_LIST, payload: response.data })
    } catch (error) {
      console.log(error)
    }
  }
