import React from 'react'
import { Form, Button } from 'antd'

import styles from '../../containers/Login/login.module.css'

import headphoneIcon from '../../Assets/Images/headphone.svg'
import TranslateText from '../../translation/TranslateText'

export default function RegisterSuccessScreen({ login }) {
  return (
    <>
      <div>
        <label style={{ color: '#232324', fontSize: '24px', opacity: '0.9' }}>
          <b>
            <TranslateText id="account_registered" />
          </b>
        </label>
      </div>
      <div
        style={{ margin: '15px auto 0', padding: '0 20px', maxWidth: '460px' }}
      >
        <label style={{ color: '#303135', fontSize: '16px', opacity: '0.5' }}>
          <TranslateText id="account_registered_message" />
        </label>
      </div>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form name="basic" layout={'vertical'}>
          <div style={{ opacity: 0.9 }}>
            <img src={headphoneIcon} alt="" />
          </div>
          <div>
            <Form.Item style={{ marginBottom: '0px', marginTop: '70px' }}>
              <Button onClick={login} className={styles.greenColorBtn}>
                <TranslateText id="back_home" />
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  )
}
