import React from 'react'
import styles from '../home.module.css'
import TranslateText from '../../../translation/TranslateText'
import 'moment/locale/de'
import PieGraph from '../../../components/PieGraph/PieGraph'
import { getLabelListForTotalSales } from './utils'
import { ShowPieGraphDetails } from './TotalSalesGraphDetails'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { usePricesFormatting } from '../../../hooks/usePricesFormatting'

export default function TotalSales({
  showSalesData,
  setShowSalesData,
  saleData,
  filteredDonationAmount,
  isLoading,
}) {
  const formatPrice = usePricesFormatting()
  const pieChartObject = {
    singles: saleData.singleSales,
    albums: saleData.albumSales,
    revenue: saleData.superfanSales,
    currentMonthlyDonation: saleData.donationSales,
  }
  const pieChartData = Object.values(pieChartObject)

  return (
    <div className={styles.container}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className={styles.headingBox}
      >
        <span
          onClick={() => {
            setShowSalesData(true)
          }}
        >
          <span
            style={{
              color: '#232324',
              fontWeight: '500',
              fontSize: '18px',
              marginRight: '4px',
              cursor: 'pointer',
            }}
          >
            <TranslateText id="total_sales" />
          </span>
        </span>
      </div>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <LoadingIndicator />
        </div>
      )}
      <div className={styles.box}>
        <div className={styles.innerBox}>
          {!isLoading && (
            <>
              {showSalesData && (
                <PieGraph
                  id={'total_sales'}
                  data={pieChartData}
                  labels={getLabelListForTotalSales(pieChartObject)}
                  formatPrice={formatPrice}
                  centerText={formatPrice(saleData.totalSales)}
                />
              )}
              {!showSalesData && (
                <PieGraph
                  data={Object.values(filteredDonationAmount)}
                  labels={Object.keys(filteredDonationAmount)}
                  formatPrice={formatPrice}
                  centerText={'test'}
                />
              )}
              {showSalesData && (
                <ShowPieGraphDetails
                  setShowSalesData={setShowSalesData}
                  saleData={pieChartObject}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
