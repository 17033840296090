import AppConfig from './index'

export const plactronUrl = {
  //AUTH

  ARTIST_SIGN_UP: `${AppConfig.BASE_URL}artistSignUp`,
  LOGIN: `${AppConfig.BASE_URL}artistSignIn`,
  EMAIL_VERIFY: `${AppConfig.BASE_URL}emailVerify`,
  CHANGE_PASSWORD_REQUEST: `${AppConfig.BASE_URL}artistChangePasswordRequest`,
  ARTIST_PASSWORD_CHANGE: `${AppConfig.BASE_URL}artistPasswordChange`,
  DIRECT_CHANGE_PASSSWORD: `${AppConfig.BASE_URL}artistDirectChangePassword`,
  ARTIST_EMAIL_VERIFICATION: `${AppConfig.BASE_URL}artistEmailVerify`,
  UPDATE_ARTIST_PROFILE_DATA: `${AppConfig.BASE_URL}updateArtistProfile`,
  RESEND_EMAIL_VERIFICATION_CODE: `${AppConfig.BASE_URL}resendVerifyEmail`,
  ARTIST_CONTACT_VERIFICATION: `${AppConfig.BASE_URL}artist/contact/verify`,
  RESEND_CONTACT_VERIFICATION_OTP: `${AppConfig.BASE_URL}artist/resend/otp`,
  ADD_ARTIST_ACCOUNT_DETAILS: `${AppConfig.BASE_URL}artist/acct`,
  ADD_ARTIST_GEMA_NUMBER: `${AppConfig.BASE_URL}artist/acct/gema`,

  //ARTIST

  TOTAL_DOWNLOAD_COUNT: `${AppConfig.BASE_URL}totalPurchaseCount`,
  TOTAL_SALE: `${AppConfig.BASE_URL}totalSale`,
  MOST_VISITED_POST: `${AppConfig.BASE_URL}mostVisitedPost/0/5`,
  GET_ARTIST: `${AppConfig.BASE_URL}getArtistById`,
  UPDATE_PROFILE_PIC: `${AppConfig.BASE_URL}addArtistProfilePic`,
  UPDATE_CONTACT_DETAILS: `${AppConfig.BASE_URL}artist/edit/profile`,
  UPDATE_ABOUT: `${AppConfig.BASE_URL}/artist/edit/profile/about`,
  ARTIST_FOLLOW_COUNT: `${AppConfig.BASE_URL}artistFollowCount`,
  SUPERFAN_ARTIST_FOLLOW_COUNT: `${AppConfig.BASE_URL}superFanArtistFollowCount`,
  UPDATE_ARTIST_SUBSCRIPTION_MANAGEMENT: `${AppConfig.BASE_URL}setArtistSubscription`,
  UPDATE_ENTREPRENEUR_STATUS: `${AppConfig.BASE_URL}update/status`,
  ARTIST_SONGS_LIST: `${AppConfig.BASE_URL}artist/songs`,

  //FANPAGE

  CREATE_POST: `${AppConfig.BASE_URL}createPost`,
  GET_ALL_POST: `${AppConfig.BASE_URL}allPostsByArtist/0/1000`,
  GET_POST_CHART_DETAILS: `${AppConfig.BASE_URL}postChartDetails`,
  CREATE_EVENT: `${AppConfig.BASE_URL}createEvent`,
  UPDATE_EVENT: `${AppConfig.BASE_URL}editEventById`,
  GET_ALL_EVENT: `${AppConfig.BASE_URL}getAllEventsByArtist/0/1000`,
  DELETE_POST: `${AppConfig.BASE_URL}deletePost`,
  DELETE_EVENT: `${AppConfig.BASE_URL}deleteEvent`,
  UPDATE_POST: `${AppConfig.BASE_URL}editPostById`,
  GET_ALL_POST_COMMENTS: `${AppConfig.BASE_URL}commentsByPostId`,
  CREATE_COMMENT: `${AppConfig.BASE_URL}createComment`,
  DELETE_COMMENT: `${AppConfig.BASE_URL}deleteComment`,
  REPLY_TO_COMMENT: `${AppConfig.BASE_URL}replyToComment`,
  UPDATE_POST_TYPE: `${AppConfig.BASE_URL}post/type`,

  //UPLOADPAGE

  CREATE_MULTIPLE_SONGS: `${AppConfig.BASE_URL}createMultipleSongs`,
  DELETE_SONG: `${AppConfig.BASE_URL}deleteSong`,
  CREATE_ALBUM: `${AppConfig.BASE_URL}createAlbum`,
  DELETE_ALBUM: `${AppConfig.BASE_URL}deleteAlbum`,
  GET_ALL_ALBUMS: `${AppConfig.BASE_URL}getArtistAllAlbums`,
  GET_ALL_SONGS: `${AppConfig.BASE_URL}getArtistAllSongs`,
  UPDATE_SONG_PRICE: `${AppConfig.BASE_URL}updateSongPriceAndImage`,
  UPDATE_ALBUM_DATA: `${AppConfig.BASE_URL}editAlbumById`,
  UPDATE_SINGLE_SONG_DATA: `${AppConfig.BASE_URL}editSongById`,
  UPLOAD: `${AppConfig.BASE_URL}upload`,
}
