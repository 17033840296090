/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useFullPageLoader from '../../components/Loader/useFullPageLoader'
import {
  addArtistAccountDetails,
  addArtistGemaNumber,
  getArtistById,
  setMessageDetails,
  updateArtistContactDetails,
} from '../Redux/actions/auth'
import styles from '../Login/login.module.css'
import { Button, Form, Input, Radio } from 'antd'
import { useHistory } from 'react-router-dom'

import mark from '../../Assets/Images/newWhiteBg.svg'
import rightArrowIcon from '../../Assets/Images/rightArrow.svg'
import uploadPic from '../../Assets/Images/user.png'
import AppConfig from '../../services'
import TranslateText from '../../translation/TranslateText'
import { useIntl } from 'react-intl'
import { cloudFrontImageSize } from '../../constants'
const { TextArea } = Input

const countryCodesList = require('country-codes-list')

export default function Settings() {
  const intl = useIntl()
  const artistProfilePic = useSelector(state => state.auth.profilePic)
  const history = useHistory()

  const allCountryNames = Object.keys(
    countryCodesList.customList('countryNameEn', '+{countryCallingCode}')
  ).sort()
  const allCountryCodes = Object.values(
    countryCodesList.customList('countryNameEn', '+{countryCallingCode}')
  )
    .filter(x => x.length <= 3)
    .sort()

  const profilePic =
    artistProfilePic === ''
      ? uploadPic
      : AppConfig.IMAGE_URL + cloudFrontImageSize + artistProfilePic

  const [slider, setSlider] = useState(0)
  const [loader, showLoader, hideLoader] = useFullPageLoader()

  const artistId = JSON.parse(localStorage.getItem('artistId'))

  const dispatch = useDispatch()
  const [contactNumberValid, setContactNumberValid] = useState(false)
  const [streetValue, setStreetValue] = useState('')
  const [city, setCity] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const oldEmail = useSelector(state => state.auth.email)
  const oldNickName = useSelector(state => state.auth.nickName)
  const oldInstagramName = useSelector(state => state.auth.instagramName)
  const oldContactNumber = useSelector(state => state.auth.contactNumber)
  const oldAddress = useSelector(state => state.auth.address)
  const oldCountryCode = useSelector(state => state.auth.countryCode)
  const oldAboutTheArtist = useSelector(
    state => state.auth.loginData.aboutTheArtist
  )
  const [streetValueEmptyError, setStreetValueEmptyError] = useState(false)
  const [cityNameEmptyError, setCityNameEmptyError] = useState(false)
  const [contactNumberEmptyError, setContactNumberEmptyError] = useState(false)

  const [showOldContactNumber, setShowOldContactNumber] = useState(true)
  const [country, setCountry] = useState('')
  const [countryCallingCode, setCountryCallingCode] = useState('')
  const [entrepreneur, setEntreprenur] = useState('')
  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [iban, setIban] = useState('')
  const [bankName, setBankName] = useState('')
  const [paypalEmail, setPaypalEmail] = useState('')
  const [gemaNumber, setGemaNumber] = useState('')
  const artistPaymentDetails = useSelector(
    state => state.auth.artistPaymentDetails
  )
  const oldGemaNumber = useSelector(state => state.auth.gemaNumber)

  const [zipcode, setZipcode] = useState('')
  useEffect(() => {
    dispatch(getArtistById({ id: artistId }))
    // here to fix warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    setEntreprenur(artistPaymentDetails?.isEntrepreneur)
  }, [artistPaymentDetails])

  const layout = {
    labelCol: {
      span: 16,
    },
    wrapperCol: {
      span: 24,
    },
  }

  const clearCurrentState = () => {
    setStreetValue('')
    setContactNumber('')
    setCity('')
    setShowOldContactNumber(true)
    setStreetValueEmptyError(false)
    setCityNameEmptyError(false)
    setContactNumberEmptyError(false)
    setContactNumberValid(false)
    setCountryCallingCode('')
    setCountry('')
    setSlider(0)
  }

  function contactNumberValues(values) {
    if (contactNumber) {
      values.contactNumber = contactNumber
    } else {
      values.contactNumber = oldContactNumber
    }
  }

  function countryCallingCodeValues(values) {
    if (countryCallingCode) {
      values.countryCallingCode = countryCallingCode
    } else {
      values.countryCallingCode = oldCountryCode
    }
  }

  function streetValues(values) {
    if (streetValue) {
      values.street = streetValue
    } else {
      values.street = oldAddress.street
    }
  }

  function cityValues(values) {
    if (city) {
      values.city = city
    } else {
      values.city = oldAddress.city
    }
  }

  function countryValues(values) {
    if (country) {
      values.country = country
    } else {
      values.country = oldAddress.country
    }
  }

  const updateContactDetails = values => {
    if (
      contactNumberEmptyError ||
      streetValueEmptyError ||
      cityNameEmptyError
    ) {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'all_fields_mandatory' })
        )
      )
    } else {
      countryCallingCodeValues(values)
      contactNumberValues(values)
      cityValues(values)
      streetValues(values)
      countryValues(values)

      if (values.contactNumber.length < 8) {
        setContactNumberValid(true)
      } else {
        const body = {
          address: {
            street: values.street,
            city: values.city,
            country: values.country,
          },
          email: values.email,
          nickName: oldNickName,
          contactNumber: values.contactNumber,
          countryCode: values.countryCallingCode,
          aboutTheArtist: oldAboutTheArtist,
          instagramName: oldInstagramName,
        }

        showLoader()
        dispatch(updateArtistContactDetails(body, artistId)).then(response => {
          hideLoader()
          if (response && response.success === true) {
            clearCurrentState()
            dispatch(getArtistById({ id: artistId }))
          }
        })
      }
    }
  }

  const showEmptyFieldError = (emptyFieldError, content, msg) => {
    if (emptyFieldError && content === '') {
      return <div className="errMsg">{msg}</div>
    }
    return ''
  }

  const showContactNumberInvalidError = msg => {
    if (contactNumberValid) {
      return <div className="errMsg">{msg}</div>
    }
    return ''
  }

  const contactInfo = () => {
    if (showOldContactNumber && oldContactNumber) {
      return oldContactNumber
    } else if (!showOldContactNumber) {
      return contactNumber
    }
    return oldContactNumber
  }

  const changeContactNumber = number => {
    const newContactNumber = number.trim()

    if (newContactNumber.length > 0) {
      setContactNumberEmptyError(false)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'contact_number_cant_empty' })
        )
      )
      setContactNumberEmptyError(true)
    }
    setContactNumber(newContactNumber)
  }

  const changeStreetValue = street => {
    const newStreetValue = street.trim()

    if (newStreetValue.length > 0) {
      setStreetValueEmptyError(false)
    } else {
      dispatch(
        setMessageDetails(
          'error',
          intl.formatMessage({ id: 'address_cant_empty' })
        )
      )
      setStreetValueEmptyError(true)
    }
    setStreetValue(newStreetValue)
  }

  const changeCityValue = cityName => {
    const newCityName = cityName.trim()

    if (newCityName.length > 0) {
      setCityNameEmptyError(false)
    } else {
      setCityNameEmptyError(true)
    }
    setCity(newCityName)
  }

  const UpdateButton = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button className={styles.greenColorBtn} htmlType="submit">
          <TranslateText id="update" />
        </Button>
      </div>
    )
  }

  const BackButton = () => {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}
      >
        <label
          style={{
            color: '#232324',
            opacity: '1',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <span
            style={{
              color: '#7CD2C6',
              cursor: 'pointer',
              textTransform: 'uppercase',
            }}
            onClick={() => clearCurrentState()}
          >
            <TranslateText id="back" />
          </span>{' '}
        </label>
      </div>
    )
  }

  const updatePaymentDetails = values => {
    if (firstname) {
      values.firstname = firstname
    }
    if (lastname) {
      values.lastname = lastname
    }
    if (iban) {
      values.iban = iban
    }
    if (paypalEmail) {
      values.paypal = paypalEmail
    }
    if (bankName) {
      values.bankName = bankName
    }

    const data = {
      artistId: artistId,
      firstName: values.firstname,
      lastName: values.lastname,
      address: {
        street: values.street,
        city: values.city,
        country: values.country,
        zip: values?.zip,
      },
      iban: values.iban,
      bankName: values.bankName,
      paypal: values.paypal,
      isEntrepreneur: entrepreneur,
    }
    dispatch(addArtistAccountDetails(data)).then(response => {
      if (response && response.success) {
        clearCurrentState()
        dispatch(getArtistById({ id: artistId }))
      }
    })
  }

  const updateGemaNumber = values => {
    if (gemaNumber) {
      values.gemaNumber = gemaNumber
    }

    const data = {
      artistId: artistId,
      gemaNumber: gemaNumber,
    }
    dispatch(addArtistGemaNumber(data)).then(response => {
      if (response && response.success) {
        clearCurrentState()
        dispatch(getArtistById({ id: artistId }))
      }
    })
  }

  return (
    <div>
      <div className={styles.signInDiv}>
        <div
          className={styles.formDiv}
          style={{
            backgroundColor: '#fff',
            maxWidth: 700,
            alignSelf: 'center',
            position: 'relative',
            paddingBottom: '32px',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: -90,
              right: -70,
            }}
          >
            <img alt="altTag" style={{ width: 400 }} src={mark} />
          </div>
          {slider === 0 ? (
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  padding: '28px 28px 50px',
                  maxWidth: '540px',
                  borderRadius: '4px',
                }}
              >
                <div>
                  <div style={{ fontSize: '24px', color: '#232324' }}>
                    <TranslateText id="account_settings" />
                  </div>
                  <div style={{ marginTop: '60px' }}>
                    <div
                      onClick={() => history.push('/profile')}
                      style={{
                        display: 'flex',
                        cursor: 'pointer',
                        borderBottom: '2px solid #d9d9d9',
                        borderTop: '2px solid #d9d9d9',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '22px 0',
                        margin: '0 32px',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '14px',
                          color: '#303135',
                          opacity: '0.8',
                          textTransform: 'uppercase',
                        }}
                      >
                        <TranslateText id="update_profile_picture" />
                      </div>
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={rightArrowIcon}
                          alt="rightArrowIcon"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => setSlider(2)}
                      style={{
                        display: 'flex',
                        borderBottom: '2px solid #d9d9d9',
                        cursor: 'pointer',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '22px 0',
                        margin: '0 32px',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '14px',
                          color: '#303135',
                          opacity: '0.8',
                          textTransform: 'uppercase',
                        }}
                      >
                        <TranslateText id="update_contact_details" />
                      </div>
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={rightArrowIcon}
                          alt="rightArrowIcon"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => setSlider(3)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #d9d9d9',
                        cursor: 'pointer',
                        padding: '22px 0',
                        margin: '0 32px',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '14px',
                          opacity: '0.8',
                          color: '#303135',
                          textTransform: 'uppercase',
                        }}
                      >
                        <TranslateText id="update_payment_information" />
                      </div>
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={rightArrowIcon}
                          alt="rightArrowIcon"
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => setSlider(4)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '2px solid #d9d9d9',
                        cursor: 'pointer',
                        padding: '22px 0',
                        margin: '0 32px',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '14px',
                          opacity: '0.8',
                          color: '#303135',
                          textTransform: 'uppercase',
                        }}
                      >
                        <TranslateText id="update_gema_number" />
                      </div>
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={rightArrowIcon}
                          alt="rightArrowIcon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* slider === 1 is the extra profile page! */}
          {slider === 2 ? (
            <div>
              <div className={styles.slideDiv} style={{ height: '100%' }}>
                <label
                  style={{
                    color: '#232324',
                    fontSize: '24px',
                    opacity: '1',
                    zIndex: 1,
                  }}
                >
                  <b>
                    <TranslateText id="update_contact_details" />
                  </b>
                </label>
                <div
                  style={{
                    marginTop: '1vh',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Form
                    onFinish={updateContactDetails}
                    {...layout}
                    validateTrigger="onFinish"
                    name="basic"
                    layout={'vertical'}
                    initialValues={{
                      email: oldEmail,
                      address: oldAddress,
                      street: oldAddress.street,
                      city: oldAddress.city,
                    }}
                  >
                    <Form.Item
                      label={intl.formatMessage({ id: 'email' })}
                      name="email"
                      style={{ marginBottom: '0px', marginTop: '4px' }}
                      rules={[
                        {
                          type: 'email',
                          message: intl.formatMessage({
                            id: 'enter_valid_email_id',
                          }),
                        },
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'enter_email_id',
                          }),
                        },
                      ]}
                    >
                      <Input
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        placeholder={intl.formatMessage({
                          id: 'enter_your_email',
                        })}
                        style={{ height: '40px' }}
                      />
                    </Form.Item>

                    <Form.Item
                      label={intl.formatMessage({ id: 'contact_number' })}
                      name="contact_number"
                      style={{ marginBottom: '0px', marginTop: '4px' }}
                    >
                      <div style={{ display: 'flex' }}>
                        <select
                          style={{
                            width: '80px',
                            padding: '8px',
                            marginRight: '-6px',
                            cursor: 'pointer',
                            zIndex: '10',
                            color: '#303135',
                            border: '1px solid #d9d9d9',
                            borderTopLeftRadius: '6px',
                            borderBottomLeftRadius: '6px',
                            outline: 0,
                          }}
                          defaultValue={oldCountryCode}
                          onChange={e => setCountryCallingCode(e.target.value)}
                        >
                          {allCountryCodes.map((callingCode, index) => (
                            <option key={index} value={callingCode}>
                              {callingCode}
                            </option>
                          ))}
                        </select>
                        <input
                          className="ant-input"
                          pattern="[0-9]*"
                          maxlength="12"
                          placeholder={intl.formatMessage({
                            id: 'enter_your_contact_number',
                          })}
                          style={{
                            height: '40px',
                            borderLeftStyle: 'none',
                          }}
                          type="text"
                          name=""
                          id=""
                          value={contactInfo()}
                          onChange={e => {
                            if (e.target.validity.valid) {
                              changeContactNumber(e.target.value)
                              setContactNumberValid(false)
                              setShowOldContactNumber(false)
                            }
                          }}
                        />
                      </div>
                      {showEmptyFieldError(
                        contactNumberEmptyError,
                        contactNumber,
                        intl.formatMessage({
                          id: 'enter_your_contact_number',
                        })
                      )}
                      {showContactNumberInvalidError(
                        'Enter Correct Contact Number'
                      )}
                    </Form.Item>

                    <Form.Item
                      label={intl.formatMessage({ id: 'address' })}
                      name="street"
                      style={{ marginBottom: '0px', marginTop: '4px' }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'enter_your_address',
                          }),
                        },
                      ]}
                    >
                      <Input
                        onChange={e => changeStreetValue(e.target.value)}
                        placeholder={intl.formatMessage({
                          id: 'enter_your_address',
                        })}
                        style={{ height: '40px' }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'city' })}
                      name="city"
                      style={{ marginBottom: '0px', marginTop: '4px' }}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'enter_your_city',
                          }),
                        },
                      ]}
                    >
                      <Input
                        onChange={e => changeCityValue(e.target.value)}
                        placeholder={intl.formatMessage({
                          id: 'enter_your_city',
                        })}
                        style={{ height: '40px' }}
                        defaultValue={oldAddress.city}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({ id: 'country' })}
                      name="country"
                      style={{ marginBottom: '0px', marginTop: '4px' }}
                    >
                      <select
                        style={{
                          padding: '10px',
                          borderRadius: '4px',
                          width: '100%',
                          color: '#303135',
                        }}
                        className="ant-input"
                        defaultValue={oldAddress.country}
                        onChange={e => setCountry(e.target.value)}
                      >
                        {allCountryNames.map((countryName, index) => (
                          <option key={index} value={countryName}>
                            {countryName}
                          </option>
                        ))}
                      </select>
                    </Form.Item>

                    <div style={{ marginTop: '40px' }}>
                      <UpdateButton />
                    </div>
                    <BackButton />
                  </Form>
                </div>
              </div>
            </div>
          ) : (
            //</div>
            ''
          )}
          {slider === 3 ? (
            <div style={{ height: '120%' }} className={styles.slideDiv}>
              <label
                style={{
                  color: '#232324',
                  opacity: '1',
                  fontSize: '24px',
                  zIndex: 1,
                }}
              >
                <b>
                  <TranslateText id="update_payment_information" />
                </b>
              </label>
              <div>
                <label
                  style={{
                    color: '#232324',
                    opacity: '1',
                    fontSize: '18px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    zIndex: 1,
                    display: 'inline-block',
                  }}
                >
                  <TranslateText id="update_payment_info_p_1" />
                </label>
              </div>
              <div>
                <label
                  style={{
                    color: '#232324',
                    opacity: '1',
                    fontSize: '18px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    display: 'inline-block',
                    zIndex: 1,
                  }}
                >
                  <TranslateText id="update_payment_info_p_2" />
                </label>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '60px',
                  justifyContent: 'center',
                }}
              >
                <Form
                  name="basic"
                  {...layout}
                  layout={'vertical'}
                  validateTrigger="onFinish"
                  onFinish={updatePaymentDetails}
                  initialValues={{
                    entrepreneur: artistPaymentDetails?.isEntrepreneur,
                    firstname: artistPaymentDetails?.firstName,
                    iban: artistPaymentDetails?.iban,
                    lastname: artistPaymentDetails?.lastName,
                    paypal: artistPaymentDetails?.paypal,
                    address: artistPaymentDetails?.address,
                    city: artistPaymentDetails?.address?.city,
                    street: artistPaymentDetails?.address?.street,
                    country: artistPaymentDetails?.address?.country,
                    zip: artistPaymentDetails?.address?.zip,
                    bankName: artistPaymentDetails?.bankName,
                  }}
                >
                  <Form.Item
                    name="entrepreneur"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                  >
                    <div style={{ margin: '10px auto 0' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <TranslateText id="are_you_entrepreneur" />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Radio.Group
                            size="small"
                            onChange={e => setEntreprenur(e.target.value)}
                            value={entrepreneur}
                          >
                            <Radio.Button value={true}>
                              <TranslateText id="yes" />
                            </Radio.Button>
                            <Radio.Button value={false}>
                              <TranslateText id="no" />
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'firstname' })}
                    name="firstname"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'enter_first_name',
                        }),
                      },
                    ]}
                  >
                    <Input
                      onChange={e => setFirstname(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_first_name',
                      })}
                      style={{ height: '40px' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'lastname' })}
                    name="lastname"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'enter_last_name',
                        }),
                      },
                    ]}
                  >
                    <Input
                      onChange={e => setLastname(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_last_name',
                      })}
                      style={{ height: '40px' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'address' })}
                    name="street"
                    initialValue={oldAddress.street}
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'enter_your_address',
                        }),
                      },
                    ]}
                  >
                    <Input
                      onChange={e => changeStreetValue(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_your_address',
                      })}
                      style={{ height: '40px' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'city' })}
                    name="city"
                    initialValue={oldAddress.city}
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'enter_your_city',
                        }),
                      },
                    ]}
                  >
                    <Input
                      onChange={e => setCity(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_your_city',
                      })}
                      style={{ height: '40px' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'zipcode' })}
                    name="zip"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'enter_your_zipcode',
                        }),
                      },
                    ]}
                  >
                    <Input
                      value={zipcode}
                      onChange={e => setZipcode(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_your_zipcode',
                      })}
                      style={{ height: '40px' }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'country' })}
                    name="country"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    initialValue={oldAddress.country}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'enter_your_country',
                        }),
                      },
                    ]}
                  >
                    <select
                      style={{
                        padding: '10px',
                        borderRadius: '4px',
                        width: '100%',
                        color: '#303135',
                      }}
                      className="ant-input"
                      onChange={e => setCountry(e.target.value)}
                    >
                      {allCountryNames.map((countryName, index) => (
                        <option key={index} value={countryName}>
                          {countryName}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'IBAN' })}
                    name="iban"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'enter_iban' }),
                      },
                    ]}
                  >
                    <Input
                      onChange={e => setIban(e.target.value)}
                      placeholder={intl.formatMessage({ id: 'enter_iban' })}
                      style={{ height: '40px' }}
                      value={iban}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'bank_name' })}
                    name="bankName"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'enter_your_bank_name',
                        }),
                      },
                    ]}
                  >
                    <Input
                      onChange={e => setBankName(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_your_bank_name',
                      })}
                      style={{ height: '40px' }}
                      value={bankName}
                    />
                  </Form.Item>
                  <Form.Item
                    label={intl.formatMessage({ id: 'paypal_email' })}
                    name="paypal"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                    rules={[
                      {
                        type: 'email',
                        message: intl.formatMessage({
                          id: 'enter_valid_email_id',
                        }),
                      },
                    ]}
                  >
                    <Input
                      onChange={e => setPaypalEmail(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_paypal_email',
                      })}
                      style={{ height: '40px' }}
                      value={paypalEmail}
                    />
                  </Form.Item>
                  <div style={{ marginTop: '40px' }}>
                    <UpdateButton />
                  </div>
                  <BackButton />
                </Form>
              </div>
            </div>
          ) : (
            ''
          )}
          {slider === 4 ? (
            <div style={{ height: '100%' }} className={styles.slideDiv}>
              <label
                style={{
                  color: '#232324',
                  opacity: '1',
                  fontSize: '24px',
                  zIndex: 1,
                }}
              >
                <b>
                  <TranslateText id="update_gema_number" />
                </b>
              </label>
              <div
                style={{
                  display: 'flex',
                  marginTop: '60px',
                  justifyContent: 'center',
                }}
              >
                <Form
                  name="basic"
                  {...layout}
                  layout={'vertical'}
                  validateTrigger="onFinish"
                  onFinish={updateGemaNumber}
                  initialValues={{
                    gemaNumber: oldGemaNumber,
                  }}
                >
                  <Form.Item
                    label={intl.formatMessage({ id: 'gema_number' })}
                    name="gemaNumber"
                    style={{ marginBottom: '0px', marginTop: '4px' }}
                  >
                    <Input
                      onChange={e => setGemaNumber(e.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'enter_gema_number',
                      })}
                      style={{ height: '40px' }}
                    />
                  </Form.Item>
                  <div style={{ marginTop: '40px' }}>
                    <UpdateButton />
                  </div>
                  <BackButton />
                </Form>
              </div>
            </div>
          ) : (
            ''
          )}
          {loader}
        </div>
      </div>
    </div>
  )
}
