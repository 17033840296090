import React from 'react'
import { Form, Button, TimePicker } from 'antd'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'react-calendar/dist/Calendar.css'
import styleUpload from '../Upload/upload.module.css'
import TranslateText from '../../translation/TranslateText'

export default function SchedulePostScreen({
  time,
  date,
  handleTime,
  handleDate,
  handleSendPost,
  goBack,
}) {
  const layout = {
    labelCol: {
      span: 16,
      offset: 6,
    },
    wrapperCol: {
      span: 24,
    },
  }

  return (
    <div style={{ padding: '40px 0 90px 0', height: '100%', overflow: 'auto' }}>
      <div
        className={styleUpload.cardDiv}
        style={{ height: '100%', margin: '0 auto' }}
      >
        <div style={{ paddingTop: '88px', paddingLeft: '26px' }}>
          <label
            style={{
              color: '#232324',
              fontSize: '24px',
              opacity: '1',
              lineHeight: '60px',
            }}
          >
            <b>
              <TranslateText id="schedule_release" />
            </b>
          </label>
          <br />
          <div>
            <Calendar
              className={styleUpload.reactCalender}
              onChange={handleDate}
              minDate={new Date()}
              value={date}
            />
            <TimePicker
              style={{
                marginTop: '30px',
                width: '276px',
                border: '1px solid #E4E9F2',
                borderRadius: '4px',
              }}
              onChange={handleTime}
              defaultValue={moment(time, 'HH:mm')}
              format="HH:mm"
            />
          </div>
          <div
            style={{
              marginTop: '90px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Form {...layout} name="basic" layout={'vertical'}>
              <Form.Item>
                <Button
                  style={{
                    width: '270px',
                  }}
                  htmlType="submit"
                  className={styleUpload.greenColorBtn}
                  onClick={handleSendPost}
                >
                  <TranslateText id="post" />
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    width: '270px',
                    textTransform: 'uppercase',
                    display: 'block',
                    color: '#7CD2C6',
                    border: 'transparent',
                    background: 'transparent',
                    marginTop: '-12px',
                  }}
                  onClick={goBack}
                >
                  <TranslateText id="back" />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}
